import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import './SummaryView.scss';
import { Doughnut, Bar, defaults } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
Chart.register(ChartDataLabels, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend);
Chart.defaults.backgroundColor = '#FF5733';
Chart.defaults.font.size = 11;

class SummaryView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doughnutOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display: false,
                        color: "white",
                        align: "center",
                        font: { weight: 'bold' }
                    },
                    title: {
                        display: true,
                        text: 'DAILY DEVICE USAGE',
                        position: "bottom",
                        color: "#328280"
                    }
                }
            },
            barOptions:
            {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            color: "white",
                            lineWidth: 3,
                            drawTicks: false,
                            offset: true
                        },
                        title: {
                            display: true,
                            text: "Avg Hrs"
                        },
                        max: 12,
                        min: 2,
                        ticks: {

                            stepSize: 2,
                            color: "#328280"
                        }
                    },
                    y: {
                        ticks: {
                            fontSize: 20
                        },
                        grid: {
                            drawTicks: false,
                            drawOnChartArea: false
                        },
                    }
                },
                indexAxis: 'y',
                elements: {
                    bar: {
                        // borderWidth: 0,
                    },
                },
                responsive: true,
                plugins: {
                    datalabels: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'DAILY WORK TASK BREAKUP',
                        position: "bottom",
                        color: "#328280"
                    }
                }
            }
        }
    }
    render() {
        return (
            <div className="container noScroll linen-bg">
                {this.props.introOpen &&
                    <div className="responsiveDiv homeImg height100vh">
                        <div className="mineShaft">
                            <Header home={this.props.home}
                                intro={true}
                                isShowProfileNavigator={false}
                            />
                        </div>
                        <div className="main">
                            {this.props.reUploadCompletionDate && 
                               <div>
                               <div className="h3 mineShaft nxtLine">Thank you for re-uploading the <br />workstation photos. </div>
                               <div className="h7 mineShaft">
                                   <p>Our team will review and send you the final report with an indepth evaluation of your assessment in the <b>next 4-5 working days.</b></p>
                               </div>
                               </div>
                            }
                            {!this.props.reUploadCompletionDate && 
                               <div>
                               <div className="h3 mineShaft nxtLine">Thank you for filling in <br />the questionnaire. </div>
                               <div className="h7 mineShaft"><p style={{ 'marginTop': '0' }}>The assessment states that the <b>Rapid Office Strain Assessment Score is {this.props.rosaGrandScore}</b>, {this.props.rosaGrandScore === "Low" ? "indicating at the moment that you do not need any further evaluation." : "your workstation requires further assessment; changes should be considered immediately."}</p>
                                   <p>Our team will send you the final report with an indepth evaluation of your assessment in the <b>next 4-5 working days.</b></p>
                               </div>
                               </div>
                            }
                            {/* <button className="toreaBay-bg linen viewSummary-btn save-btn" onClick={this.props.openSummaryReport}>View Summary</button> */}
                            <div className="h11 toreaBay absolute footDiv">
                                In any case, if you feel prolonged discomfort or severe pain, you may contact help.rwa@recoup.com or +91 9992229992
                            </div>
                        </div>
                    </div>}
                {this.props.summaryReport &&
                    <div className="responsiveDiv">
                        <div className="mineShaft">
                            <Header home={this.props.home}
                                intro={this.props.introOpen}
                                goBack={this.props.goIntro} />
                        </div>
                        <div className="page4">
                            <div className='linen-bg main pageScroll' >
                                <div className="h3 paradiso"> Daily work tasks distribution</div>
                                <div className="responsiveDiv">
                                    <div className='white-bg workTasks nxtLine row' style={{ gap: "3.75rem" }}>


                                        <div style={{ width: "70%" }}><Doughnut options={this.state.doughnutOptions} data={this.props.doughnutData} /></div>
                                        <div className="col">
                                            <div className="h6 fountainBlue">{this.props.devicePercentage}%</div>
                                            <div className="h10 mineShaft nxtLine"> Laptop</div>
                                            <div className="h6 tacao">{this.props.phonePercentage}%</div>
                                            <div className="h10 mineShaft nxtLine"> Phone</div>
                                            <div className="h6 lavenderPink">{this.props.breakPercentage}%</div>
                                            <div className="h10 mineShaft"> Break</div>
                                        </div>

                                    </div>
                                    <div className='white-bg workTasks nxtLine barChart'>
                                        <Bar width={100}
                                            height={50} options={this.state.barOptions} data={this.props.barData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default SummaryView;