import React, { useState } from 'react';
import edit from '../../../../assets/img/rosa/admin/edit.png';
import i18n from '../../../../i18n';
const IMAGE_MAX_SIZE = process.env.REACT_APP_FILE_MAX_SIZE;
const IMAGE_TYPE_FILES = process.env.REACT_APP_FILE_TYPE_ACCEPT;

const ImageChangeable = ({
  keyImage,
  src = '',
  alt = '',
  onSelectNewImage = (base64) => {},
  isChangeable = true,
  changeIconPosition = "right",
  isRequestedToReupload = false
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [isMaxFileSize, setIsMaxFileSize] = useState(false);

  const pickImage = () => {
    document.getElementById(`imageInput${keyImage}`).click();
  };
  const handleLoadPickedFile = (e) => {
    const file = e.target.files[0];

    if (file.size > IMAGE_MAX_SIZE) {
      e.target.value = null; // Clear the input field
      setIsMaxFileSize(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImageSrc(reader.result);
      onSelectNewImage(reader.result);
    };
    setIsMaxFileSize(false);
  };

  return (
    <div className={'imgWp__container'}>
      {isChangeable && (
        <>
          <input
            type="file"
            id={`imageInput${keyImage}`}
            accept={IMAGE_TYPE_FILES}
            className="d-none"
            onChange={handleLoadPickedFile}
          />
          <div className={changeIconPosition === "right" ?"icAbsoluteRightContainer":"icAbsoluteRightContainer icAbsoluteLeftContainer"} onClick={pickImage}>
            <img className="icEditImage" src={edit} alt="Icon change" />
          </div>
        </>
      )}

      {isRequestedToReupload && (
        <p className="h12 reuploadRequestedContainer">Re-upload Requested</p>
      )}

      <img src={imageSrc} alt={alt} />

      {/* Error block */}
      {isMaxFileSize && (
        <div className={'error'}>
          <div className={'h14 error-text'}>
            {i18n.image.maxSize + IMAGE_MAX_SIZE / 1024 / 1024 + 'MB'}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ImageChangeable);
