const BasicInformationInput = {
  OCCUPATIONS: [
    'Manager',
    'Professional',
    'Technicians and Associate Professional',
    'Clerical Support Worker',
    'Services and Sales Worker',
    'Skilled Agricultural, Forestry and Fishery Worker',
    'Craft and Related Trades Worker',
    'Plant and Machine Operators and Assembler',
    'Elementary Occupation',
    'Armed Forces Occupation',
  ],
};

export default BasicInformationInput;
