import React from 'react';
import i18n from '../../../../i18n';
import logoutIc from '../../../../assets/img/rosa/common/logout.png';
import useProviderAuth from '../../../../redux/hooks/useProviderAuth';
import Routes from '../../../../constants/routes';

const LogoutArea = () => {
  const { logOut } = useProviderAuth();

  return (
    <div
      className='flex-row pointer logout'
      onClick={() =>
        logOut(
          () => {
            window.location.href = Routes.ADMIN_LOGIN_PAGE;
          },
          (e) => {}
        )
      }
    >
      <img src={logoutIc} className='logout__icon' alt='logout icon' />
      <div className='logout__text'>{i18n.logOut}</div>
    </div>
  );
};

export default React.memo(LogoutArea);
