import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import './HomeView.scss'
import i18n from '../../i18n/index';
class HomeView extends Component {
    render() {
        // The information in the section below under "reUploadRequestedImages" is helpful 
        // when a user returns to the system to submit new workstation photos since it gives him a list 
        // of the images that still need to be uploaded.
        const reUploadRequestedImages = {
            workstationSide: "Workstation Side",
            workstationBack: "Workstation Back",
            userSide: "User Side",
            userBack: "User Back"
        }
        return (
            <div className="container noScroll linen-bg">
                {this.props.introOpen && this.props.requestedReUploadWorkstationImages.length === 0 &&
                    <div className="responsiveDiv homeImg height100vh">
                        <div className="mineShaft">
                            <Header home={this.props.home}
                                intro={this.props.introOpen}
                                onProfileClick={this.props.onProfileClick}/>
                        </div>
                        <div className="main">

                            <div className="h5 toreaBay">HELLO!</div><br /><br />
                            <div className="h1 mineShaft">{this.props.user.name}</div>
                            <div className="h7 mineShaft"><p style={{ 'marginTop': '0' }}>Let’s begin your workspace ergonomics assessment to help set up an optimal workspace for your better comfort and performance.</p>
                                <p>It is important to fill the full questionnaire to recieve an accurate assessment of your workspace. The entire questionnaire will take about 10-15 minutes and requires subjective inputs that only you will be able to provide.</p>
                            </div>
                            { this.props.isShowStartAssessment &&
                                <button className="toreaBay-bg linen save-btn" onClick={this.props.openConsentModal}>{i18n.startAssessment}</button>
                            }
                            { this.props.isShowContinueAssessment &&
                                <button className="toreaBay-bg linen save-btn" onClick={this.props.onContinueAssessment}>{i18n.resumeAssessment}</button>
                            }
                        </div>
                    </div>
                }
                {this.props.requestedReUploadWorkstationImages.length > 0 &&
                    (<div className="responsiveDiv homeImg height100vh">
                         <div className="mineShaft">
                            <Header home={this.props.home}
                                intro={this.props.introOpen}
                                onProfileClick={this.props.onProfileClick}/>
                        </div>
                        <div className="main">
                            <div className="h5 toreaBay">HELLO!</div><br /><br />
                            <div className="h1 mineShaft">{this.props.user.name}</div>
                            <div className="h7 mineShaft">
                                <p style={{ 'marginTop': '0' }}>Please re-upload the following wokstation images -</p>
                                <ul>
                                    {this.props.requestedReUploadWorkstationImages.map((imageType) => {
                                        return (<li key={imageType}>{reUploadRequestedImages[imageType]}</li>);
                                    })}
                                </ul>
                            </div>
                            <Link to="/workstationImages">
                                <button className="toreaBay-bg linen save-btn">Upload Images</button>
                            </Link>
                        </div>
                    </div>)
                }
                {this.props.menuOpen &&
                    <div className="responsiveDiv">
                        <div className="mineShaft">
                            <Header home={this.props.home}
                                intro={this.props.introOpen}
                                goBack={this.props.goIntro} />
                        </div>
                        <div className="main">
                            <div className="h7" style={{ 'margin': '26px 0' }}>
                                <p>The assessment contains 5 broad sections in the form of a custom questionnaire as listed below.
                                    The last section contains instructions for specific photographs required of your workspace. </p>
                            </div>
                            <div className="h3 toreaBay tableOfContent">
                                <div>Basic Information</div>
                                <div>Nature of Work</div>
                                <div>Workstation Assessment</div>
                                <div>Pain and Discomfort</div>
                                <div>Workstation Photos</div>

                            </div>
                            <div className="txtRight nxt-btn-div">
                                <button className='nxt-btn nxt-btn1 toreaBay-bg' onClick={() =>
                                    window.location.href = '/basicInfo'
                                }>
                                    <i className='fa fa-angle-right linen'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {this.props.consentModal &&
                    <Modal
                        modalClasses="white-bg"
                        modalTitle="CONSENT POLICY"
                        modalBody={i18n.assessment.homeViewConsentPolicyContent}
                        txtBtn={true}
                        btn1={this.props.ConsentDisagree}
                        btn2={this.props.ConsentAgree}
                        btnText1="DISAGREE"
                        btnText2="I AGREE"
                    />}
            </div>
        )
    }

}

export default HomeView;