import React, { Component } from "react";
import LoginScreenWave from "../../assets/img/rosa/onboard/login-wave.png";
import AlertModal from "../../components/rosa/Modal";
import './style.scss'
import i18n from '../../i18n'

const ForgetPassword = (props) => {
  const { form, error, onFormChange } = props
  return (
    <div className="container noScroll linen-bg height100vh width100">
      <div className="col responsiveDiv login-view forget-pass height100">
        <img src={LoginScreenWave} className='wave-img' />
        <div className="col form-box width100 height100">
          <div className="h3">Forgot Password</div>
          <form className="col width100 f-rest" onSubmit={props.onSubmit} noValidate>
            <div className="col width100 height100">
              <div className={`h7 mineShaft questionBox ${Boolean(error.email) ? 'error' : ''}`}>
                <label htmlFor='email'>Email</label><br />
                <input autoFocus required type='email' id="email" name="email" className='cornflowerBlue-bg' value={form.email} onChange={onFormChange} />
              </div>
              <div class="row v-ctr width95"><div class="h12 mineShaft">{i18n.forgotPassword.description}</div></div>
              <div className="col v-end f-rest h-end next-blc">
                <button className="nxt-btn mineShaft-bg" type="submit">
                  <i className="fa fa-angle-right linen"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {
        Boolean(props.message) &&
        <AlertModal
          modalClasses="linen-bg linen"
          modalTitleClass="h3 toreaBay"
          modalTitle={props.message}
          txtBtn={false}
          btn2Classes="toreaBay-bg linen"
          btn2={props.onAlertNext}
          btn2IconClass="linen "
        />
      }
    </div>
  )
}

export default ForgetPassword;