import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { add_basic_info } from '../../redux/ActionCreator';
import BasicInformationView from '../../views/rosa/BasicInformationView';
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import Routes from '../../constants/routes';
import { getUserInfo } from '../../redux';
import helpers from '../../helpers';

const mapDispatchToProps = (dispatch) => ({
    add_basic_info: (
        workPlace,
        occupation,
        height,
        weight,
        dominantHand,
        pregnant,
        correctiveLens,
        lensType,
        monitorView,
        paperView,
        peopleView,
        lastSection,
        lastPage,
        restState
    ) => {
        dispatch(
          add_basic_info(
            workPlace,
            occupation,
            height,
            weight,
            dominantHand,
            pregnant,
            correctiveLens,
            lensType,
            monitorView,
            paperView,
            peopleView,
            lastSection,
            lastPage,
            restState
          )
        );
    },
});
class BasicInformationController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);

        const userInfo = getUserInfo() ?? {};
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            workPlace: "",
            occupation: "",
            feetUnitSelected: true,
            heightinFeet: "",
            heightInInches: "0",
            height: "",
            poundUnitSelected: true,
            weightInLbs: "",
            weight: "",
            dominantHand: "",
            pregnant: userInfo.gender === 'male' ? 'no': '',
            correctiveLens: "",
            lensType: "",
            lensView: false,
            monitorView: "",
            paperView: "",
            peopleView: "",
            errors: {
                workPlace: "",
                occupation: "",
                heightinFeet: "",
                heightInInches: "",
                height: "",
                weightInLbs: "",
                weight: "",
                dominantHand: "",
                pregnant: "",
                correctiveLens: "",
                lensType: "",
                monitorView: "",
                paperView: "",
                peopleView: ""
            },
            modal2: false,
            isMale : userInfo.gender === 'male',
        }
    }
    mandatoryFields = (pageNo) => {
        let { errors, workPlace, occupation, feetUnitSelected, poundUnitSelected, heightinFeet, heightInInches,
            height, weightInLbs, weight, dominantHand, pregnant, correctiveLens, lensType, lensView, monitorView,
            paperView, peopleView } = this.state;
        if (pageNo === 1) {
            errors.workPlace = (workPlace.length < 1 || workPlace.length > 20) ? "Value should be between 1 to 20 characters" : "";
            errors.occupation = helpers.inputValidatorHelpers.emptyValidate({
              key: 'Occupation',
              value: occupation,
            });
        }
        if (pageNo === 2) {
            if (feetUnitSelected) {
                errors.heightinFeet = helpers.inputValidatorHelpers.emptyValidate({
                    key: 'Height',
                    value: heightinFeet,
                    nextValidateCallback: () => (heightinFeet < 0 || heightinFeet > 7) ? "Value should be between 4 feet to 7 feet" : "",
                })
                errors.heightInInches = helpers.inputValidatorHelpers.emptyValidate({
                    key: 'Inches',
                    value: heightInInches,
                    nextValidateCallback: (heightInInches < 0 || heightInInches > 11) ? "Value should be between 0 inch to 11 inches" : "",
                })
            }
            else {
                errors.height = helpers.inputValidatorHelpers.emptyValidate({
                    key: 'Height',
                    value: height,
                    nextValidateCallback: () => (height < 140 || height > 210) ? "Value should be between 140 cms to 210 cms" : "",
                });
            }
            if (poundUnitSelected) {
                errors.weightInLbs = helpers.inputValidatorHelpers.emptyValidate({
                    key: 'Weight',
                    value: weightInLbs,
                    nextValidateCallback: () => (weightInLbs < 44 || weightInLbs > 400) ? "Value should be between 44lbs to 400lbs" : "",
                });
            }
            else {
                errors.weight = helpers.inputValidatorHelpers.emptyValidate({
                    key: 'Weight',
                    value: weight,
                    nextValidateCallback: () => (weight < 20 || weight > 300) ? "Value should be between 20kgs to 300kgs" : "",
                });
            }
            errors.dominantHand = (dominantHand === "") ? "Please select an option" : "";
            errors.pregnant = (pregnant === "" && !this.state.isMale) ? "Please select an option" : "";
            errors.correctiveLens = (correctiveLens === "") ? "Please select an option" : "";
            if (correctiveLens === "yes") {
                errors.lensType = (lensType === "") ? "Please select an option" : "";
                if (lensView) {
                    errors.monitorView = (monitorView === "") ? "Please select an option" : "";
                    errors.paperView = (paperView === "") ? "Please select an option" : "";
                    errors.peopleView = (peopleView === "") ? "Please select an option" : ""
                }
            }
        }
        this.setState({ errors })
    }

    checkPageFilled = () => {
        let heightFilled = false;
        let weightFilled = false;

        let { page1, page2, page1Filled, page2Filled, feetUnitSelected, heightinFeet, heightInInches, height,
            weightInLbs, weight, dominantHand, pregnant, correctiveLens, lensType, lensView, monitorView, paperView, peopleView } = this.state;
        
        let isValidPregnant = pregnant.length !== 0 || this.state.isMale;
        if (page1) {
            page1Filled = this.state.workPlace.length !== 0 && this.state.occupation.length !== 0;
        } else if (page2) {
            if (feetUnitSelected) {
                heightFilled = heightinFeet.length !== 0 && heightInInches.length !== 0
            } else {
                heightFilled = height.length !== 0;
            }
            weightFilled = weightInLbs.length !== 0 || weight.length !== 0;
            if (heightFilled && weightFilled && dominantHand.length !== 0 && isValidPregnant && correctiveLens.length !== 0) {
                if (correctiveLens === "yes") {
                    if (lensType.length !== 0) {
                        if (lensView) {
                            page2Filled = monitorView.length !== 0 && paperView.length !== 0 && peopleView.length !== 0;
                        } else {
                            page2Filled = true;
                        }
                    } else {
                        page2Filled = false;
                    }
                } else {
                    page2Filled = true;
                }
            } else {
                page2Filled = false;
            }
        }
        this.setState({ page1Filled, page2Filled });

    }

    saveInfo = (event, stateKey) => {
        let { errors } = this.state;
        let value = event.target.value;
        if (stateKey === "workPlace") {
            errors.workPlace = (value.length < 1 || value.length > 20) ? "Value should be between 1 to 20 characters" : "";
        }
        if (stateKey === "occupation") {
            errors.occupation = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Occupation',
                value,
                nextValidateCallback: null,
            })
        }
        if (stateKey === "heightinFeet") {
            errors.heightinFeet = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Height',
                value,
                nextValidateCallback: () => (value < 4 || value > 7) ? "Value can only be between 4 feet to 7 feet" : ""
            })
        }
        if (stateKey === "heightInInches") {
            errors.heightInInches = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Inches',
                value,
                nextValidateCallback: () => (value < 0 || value > 11) ? "Value can only be between 0 inch to 11 inches" : "",
            });
        }
        if (stateKey === "height") {
            errors.height = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Height',
                value,
                nextValidateCallback: () => (value < 140 || value > 210) ? "Value can only be between 140 cms to 210 cms" : "",
            });
        }
        if (stateKey === "weightInLbs") {
            errors.weightInLbs = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Weight',
                value,
                nextValidateCallback: () => (value < 44 || value > 400) ? "Value can only be between 44lbs to 400lbs" : "",
            });
        }
        if (stateKey === "weight") {
            errors.weight = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Weight',
                value,
                nextValidateCallback: () => (value < 20 || value > 300) ? "Value can only be between 20kgs to 300kgs" : "",
            });
        }
        if (stateKey === "dominantHand") {
            errors.dominantHand = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "pregnant" && !this.state.isMale) {
            errors.pregnant = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "correctiveLens") {
            errors.correctiveLens = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "lensType") {
            let { lensView } = this.state;
            errors.lensType = (value === "") ? "Please select an option" : "";
            lensView = (value === "bifocals" || value === "trifocals" || value === "progressive") ? true : false;
            this.setState({ lensView });
        }
        if (stateKey === "monitorView") {
            errors.monitorView = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "paperView") {
            errors.paperView = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "peopleView") {
            errors.peopleView = (value === "") ? "Please select an option" : "";
        }
        if (stateKey === "workPlace") {
            value = value.slice(0, 20);
            this.setState({ [stateKey]: value }, () => { this.checkPageFilled() });
        } else {
            this.setState({ [stateKey]: errors[stateKey] && errors[stateKey].length !== 0 ? "" : value }, () => { this.checkPageFilled() });
        }
    }

    goBack = (pageNo) => {
        if (pageNo === 1) {
            this.setState({ page1: false, sectionIntroOpen: true, page2: false });
        } else {
            this.setState({ page1: true, sectionIntroOpen: false, page2: false });
        }
    }


    saveProgress = () => {
        let state = this.state;
        let heightFinal = 0;
        let weightFinal = 0;
        if (state.feetUnitSelected) {
            heightFinal = (state.heightinFeet * 30.48) + (state.heightInInches * 2.54);
        }
        if (state.poundUnitSelected) {
            weightFinal = (state.weightInLbs * 0.453592)
        }
        heightFinal = state.feetUnitSelected ? ((state.heightinFeet * 30.48) + (state.heightInInches * 2.54)).toFixed(2) : state.height;
        weightFinal = state.poundUnitSelected ? (state.weightInLbs * 0.453592).toFixed(2) : state.weight;

        this.setState({ height: heightFinal, weight: weightFinal }, () => {
            this.sendToStore({ page: 3 });

            const params = { isPreventReloadEwa: true };
            this.props.history.push('/natureOfWork', params);
        })
    }
    sendToStore = ({ page = 1 }) => {
        let state = this.state;
        this.props.add_basic_info(
            state.workPlace,
            state.occupation,
            state.height,
            state.weight,
            state.dominantHand,
            state.pregnant,
            state.correctiveLens,
            state.lensType,
            state.monitorView,
            state.paperView,
            state.peopleView,
            this.computedNextSection({isLastPage: page > 2, currentRoute: Routes.ASSESSMENT_INFO}),
            page > 2 ? 0 : page,
            this.state,
        );
    }

    reset = () => {
        this.setState({
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            workPlace: "",
            occupation: "",
            feetUnitSelected: true,
            heightinFeet: "",
            heightInInches: "",
            height: "",
            poundUnitSelected: true,
            weightInLbs: "",
            weight: "",
            dominantHand: "",
            pregnant: "",
            correctiveLens: "",
            lensType: "",
            lensView: false,
            monitorView: "",
            paperView: "",
            peopleView: "",
            errors: {
                workPlace: "",
                occupation: "",
                heightinFeet: "",
                heightInInches: "",
                height: "",
                weightInLbs: "",
                weight: ""
            },
            modal2: false
        });
    }
    selectHeightUnit = (event) => {
        let resetHeightError = {
            errors: {
                ...this.state.errors,
                heightinFeet: '',
                heightInInches: '',
                height: '',
            }
        };
        if (event.target.value === "ft/inches") {
            document.getElementById('cms').value = '';
            this.setState({
                feetUnitSelected: true,
                ...resetHeightError,
            }, () => { this.checkPageFilled() })
        } else {
            document.getElementById('feet').value = '';
            document.getElementById('inches').value = '';
            this.setState({
                feetUnitSelected: false,
                ...resetHeightError,
            }, () => { this.checkPageFilled() })
        }
    }
    selectWeightUnit = (event) => {
        let resetWeightError = {
            errors: {
                ...this.state.errors,
                weightInLbs: '',
                weight: '',
            }
        };
        if (event.target.value === "lbs") {
            document.getElementById('weightinKgs').value = '';
            this.setState({
                poundUnitSelected: true,
                ...resetWeightError,
            }, () => { this.checkPageFilled() });
        } else {
            document.getElementById('weightInLbs').value = '';
            this.setState({
                poundUnitSelected: false,
                ...resetWeightError,
            }, () => { this.checkPageFilled() });
        }
    }

    async componentDidMount() {
        let ewaRecord = await this.loadEwaRecord();
        
        if (!ewaRecord) { 
            return;
        }
        let jsonState = JSON.parse(ewaRecord.savedProcessJson) ?? {};

        this.setState({
          ...this.state,
          ...jsonState,
          ...this.computedDonePages({
            maxPage: 2,
            lastPage: ewaRecord?.lastPage,
          }),
        });
    }

    render() {
        return (
            <BasicInformationView
                {...this.state}
                openQuiz={() => this.setState({ page1: true, sectionIntroOpen: false })}
                openPage2={() => {
                    this.sendToStore({page: 2});
                    this.setState({ page1: false, page2: true })
                }}
                warning={() => this.setState({ modal1: true })}
                closeWarning={() => this.setState({ modal1: false })}
                saveInfo={(event, stateKey) => this.saveInfo(event, stateKey)}
                heightConverter={(event, unit) => this.heightConverter(event, unit)}
                selectHeightUnit={(event) => this.selectHeightUnit(event)}
                selectWeightUnit={(event) => this.selectWeightUnit(event)}
                goBack={(pageNo) => this.goBack(pageNo)}
                saveProgress={this.saveProgress}
                checkCompletion={() => this.setState({ modal2: true })}
                goNextModule={() => this.setState({ modal2: false })}
                reset={this.reset}
                mandatoryFields={(pageNo) => this.mandatoryFields(pageNo)}
            />
        );
    }
}

export default withRouter(connect(null, mapDispatchToProps)(BasicInformationController));