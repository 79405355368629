import React from 'react';
import reverse from '../../../../assets/img/rosa/reverse.png';

const FlipCamera = ({ isShow = false, onClick = () => {} }) => {
  return isShow ? (
    <img onClick={onClick} src={reverse} alt='' />
  ) : (
    <div className='ic44'></div>
  );
};

export default React.memo(FlipCamera);
