import React, { Component } from 'react'
import AdminReportBuilderView from '../../../views/rosa/admin/AdminReportBuilderView'

class AdminReportBuilderController extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <AdminReportBuilderView
        {...this.state}
        saveProgress={this.saveProgress}
      />
    )
  }
}

export default AdminReportBuilderController
