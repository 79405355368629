import React, { Component } from "react";
import SplashView from "../../../views/Onboarding/Splash";
import { getAccessToken } from "../../../redux";

class SplashController extends Component {
  componentDidMount() {
    let timer = setTimeout(() => {
      if (timer) {
        clearTimeout(timer)
      }
      const token = getAccessToken()
      if (token) {
        this.props.history.push('/intro')
      } else {
        this.props.history.push('/login')
      }
    }, 2000)
  }
  render() {
    return (
      <SplashView />
    )
  }
}

export default SplashController;