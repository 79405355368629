import React from 'react';
import edit from '../../assets/img/rosa/admin/edit.png';

const EditableIcon = ({ onClick = () => {} }) => {
  return (
    <div className={'icAbsoluteRightContainer'} onClick={onClick}>
      <img className='icEditImage' src={edit} alt='Icon change' />
    </div>
  );
};

export default React.memo(EditableIcon);
