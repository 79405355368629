import React, { useState } from 'react';
import i18n from '../../../i18n';
import icUpload from '../../../assets/img/rosa/ic_upload.png';

const IMAGE_MAX_SIZE = process.env.REACT_APP_FILE_MAX_SIZE;
const IMAGE_TYPE_FILES = process.env.REACT_APP_FILE_TYPE_ACCEPT;

const UploadImage = ({
  keyImage,
  src = '',
  alt = '',
  onUploadImage = (base64) => {},
  isChangeable = true,
  isShowErrorInside = true,
}) => {
  const [isMaxFileSize, setIsMaxFileSize] = useState(false);

  const pickImage = () => {
    document.getElementById(`imageInput${keyImage}`).click();
  };
  const handleLoadPickedFile = (e) => {
    const file = e.target.files[0];

    if (file.size > IMAGE_MAX_SIZE) {
      e.target.value = null; // Clear the input field
      showError();
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      onUploadImage(reader.result);
    };
    hideError();
  };
  const showError = () => {
    if (isShowErrorInside) {
      setIsMaxFileSize(true);
      return;
    }

    document.getElementById(`error_${keyImage}`).style.display = 'block';
  };
  const hideError = () => {
    if (isShowErrorInside) {
      setIsMaxFileSize(false);
    }

    document.getElementById(`error_${keyImage}`).style.display = 'none';
  };

  return (
    <div className={''}>
      {isChangeable && (
        <>
          <input
            type='file'
            id={`imageInput${keyImage}`}
            accept={IMAGE_TYPE_FILES}
            className={'d-none'}
            onChange={handleLoadPickedFile}
          />
          <div className={''} onClick={pickImage}>
            <img className='ic44' src={icUpload} alt='Icon upload' />
          </div>
        </>
      )}

      {/* Error block */}
      {isShowErrorInside && isMaxFileSize && (
        <div className={'error'}>
          <div className={'h14 error-text'}>
            {i18n.image.maxSize + IMAGE_MAX_SIZE / 1024 / 1024 + 'MB'}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(UploadImage);
