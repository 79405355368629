import Constants from "../constants";

/**
 * The function helper handle redirect to specific login page base on user role
 */
const handleRedirectAfterSentMail = ({
    routerHistory,
    userRole
}) => {
    if (userRole === Constants.UserRole.userRoleProvider) {
        routerHistory.push(Constants.Routes.ADMIN_LOGIN_PAGE);

        return;
    }

    routerHistory.push(Constants.Routes.CUSTOMER_LOGIN_PAGE);
}

const resetPasswordHelpers  = {
    handleRedirectAfterSentMail,
};
export default resetPasswordHelpers;


