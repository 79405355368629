const ModelDefault = {
  provider: {
    _id: null,
    prefix: '',
    firstname: '',
    lastname: '',
    computedName: '',
  },
};

export default ModelDefault;
