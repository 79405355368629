import React, { Component } from "react";
import LoginScreenWave from "../../assets/img/rosa/onboard/login-wave.png";
import AlertModal from "../../components/rosa/Modal";
import './style.scss'

const ResetPassword = (props) => {
  const { form, error, onFormChange, tokenStatus, commonError } = props
  console.log('tokenStatus', tokenStatus);
  return (
    <div className="container noScroll linen-bg height100vh width100">
      <div className="col responsiveDiv login-view forget-pass height100">
        <img src={LoginScreenWave} className='wave-img' />
        {
          tokenStatus === null ?
            <div className="row h3 width100 height100 v-ctr h-ctr">
              Please Wait....
            </div>
            : tokenStatus === true ?
              <div className="col form-box width100 height100">
                <div className="h3">Change Password</div>
                <form className="col width100 f-rest" onSubmit={props.onSubmit} noValidate>
                  <div className="col width100 height100">
                    <div className={`h7 mineShaft questionBox ${Boolean(error.password) ? 'error' : ''}`}>
                      <label htmlFor='email'>New Password</label><br />
                      <input autoFocus required type='password' id="password" name="password" className='cornflowerBlue-bg' value={form.password} onChange={onFormChange} />
                      {
                        Boolean(error.password) &&
                        <div className="h14 error-text">{error.password}</div>
                      }
                    </div>
                    <div className={`h7 mineShaft questionBox ${Boolean(error.confirmPassword) ? 'error' : ''}`}>
                      <label htmlFor='email'>Confirm Password</label><br />
                      <input required type='password' id="confirm-password" name="confirmPassword" className='cornflowerBlue-bg' value={form.confirmPassword} onChange={onFormChange} />
                      {
                        Boolean(error.confirmPassword) &&
                        <div className="h14 error-text">{error.confirmPassword}</div>
                      }
                    </div>
                    {commonError && 
                      <div class="error w95percent">
                        <div class="h14 error-text">{ commonError }</div>
                      </div>
                    }
                    <div className="col v-end f-rest h-end next-blc">
                      <button className="nxt-btn mineShaft-bg" type="submit">
                        <i className="fa fa-angle-right linen"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              :
              <div className="row h3 width100 height100 v-ctr h-ctr">
                {tokenStatus}
              </div>

        }
      </div>
      {
        Boolean(props.message) &&
        <AlertModal
          modalClasses="linen-bg linen"
          modalTitleClass="h3 toreaBay"
          modalTitle={props.message}
          txtBtn={false}
          btn2Classes="toreaBay-bg linen"
          btn2={props.onAlertNext}
          btn2IconClass="linen "
        />
      }
    </div>
  )
}

export default ResetPassword;