import React, { Component } from "react";
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import SectionIntro from "../../components/rosa/SectionIntro";
import './NatureOfWorkView.scss';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import i18n from '../../i18n/index';

Chart.register(ChartDataLabels, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend);
Chart.defaults.backgroundColor = '#FF5733';
Chart.defaults.font.size = 11;
class NatureOfWorkView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typingHrs: "",
            mouseHrs: "",
            writingHrs: "",
            filingHrs: "",
            callsHrs: "",
            timeAwayHrs: "",
            othersHrs: "",
            typingMins: "",
            mouseMins: "",
            writingMins: "",
            filingMins: "",
            callsMins: "",
            timeAwayMins: "",
            othersMins: "",
            showTyping: false,
            showMouse: false,
            showWriting: false,
            showFiling: false,
            showCalls: false,
            showTimeAway: false,
            showOthers: false,
            doughnutOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        display: false,
                        color: "white",
                        align: "center",
                        font: { weight: 'bold' }
                    },
                    title: {
                        display: true,
                        text: '',
                        position: "bottom",
                        color: "#328280"
                    }
                }
            },
            barOptions:
            {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            color: "white",
                            lineWidth: 3,
                            drawTicks: false,
                            offset: true
                        },
                        title: {
                            display: true,
                            text: "Hours"
                        },
                        max: 12,
                        min: 0,
                        ticks: {

                            stepSize: 2,
                            color: "#328280"
                        }
                    },
                    y: {
                        ticks: {
                            fontSize: 20
                        },
                        grid: {
                            drawTicks: false,
                            drawOnChartArea: false
                        },
                    }
                },
                indexAxis: 'y',
                elements: {
                    bar: {
                        // borderWidth: 0,
                    },
                },
                responsive: true,
                plugins: {
                    datalabels: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'DAILY WORK TASK BREAKUP',
                        position: "bottom",
                        color: "#328280"
                    }
                }
            }
        }
    }


    drawOptions = (iVal, iLim, increment, iName) => {
        let arr = []
        let optionStr = ""
        for (var i = iVal; i <= iLim; i = i + increment) {
            optionStr = i + " " + iName;
            arr.push(<option key={i} value={i}>{optionStr}</option>);
        }
        return arr;
    }

    computedBreakInfo = () => {
        return this.props.workBreak === 'yes' ? [
            i18n.takeABreakOf,
            this.props.breakTimeMins,
            i18n.minutes,
            i18n.every,
            this.props.breakNumHrs,
            i18n.hours,
            i18n.and,
            this.props.breakNumMins,
            i18n.minutes,
        ].join(' ') : '';   
    }

    render() {
        return (
            <div className="container noScroll">
                {this.props.sectionIntroOpen &&
                    <div className="fountainBlue-bg linen minHeight100">
                        <Header backBtnColor="linen"
                            goBack={this.props.warning}
                            intro={true}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="0%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#d8ebea"
                            completedColor="#328280"
                            active="natureOfWork"
                            headerTitle=""
                            isShowProfileNavigator={false}
                        />

                        <SectionIntro
                            sectionHeader1="Nature"
                            sectionHeader2="of Work"
                            sectionDesc="The following section contains questions regarding your typical 
                            work day and nature of tasks that you perform at work."
                            bgColor="fountainBlue-bg nwBgImg"
                            sectionColor="white"
                            nxtBtnClass="fountainBlue nxt-btn1"
                            title=''
                            backBtnColor='linen'
                            nextSection={this.props.openQuiz} />
                    </div>
                }
                {this.props.modal1 &&
                    <Modal
                        modalClasses="tacao-bg mineShaft"
                        modalTitleClass="h3"
                        modalBodyClass="h7"
                        modalTitle="Are you sure?"
                        modalBody="Once you proceed, all entries made in this section will be lost. "
                        txtBtn={true}
                        btnText1="PROCEED"
                        btnText2="CANCEL"
                        btn1Classes="linen"
                        btn2Classes="vinRouge"
                        btn1={() => { this.props.reset(); window.location.href = '/intro' }}
                        btn2={this.props.closeWarning} />
                }
                {this.props.page1 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="fountainBlue-bg"
                            goBack={() => this.props.goBack(1)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="25%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#d8ebea"
                            completedColor="#328280"
                            active="natureOfWork"
                            headerTitle="NATURE OF WORK"
                            isShowProfileNavigator={false}
                         />
                        <div className="nw-page1 swansDown-bg">

                            <div className='main'>
                                <form className="responsiveDiv">
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Which device do you use?</p>
                                        <div className="grid-3-cols nxtLine">
                                            <label htmlFor="laptop">
                                                <input id="laptop" name="device" type='radio' value="laptop" className='cornflowerBlue-bg'
                                                    checked={this.props.workDevice === "laptop"}
                                                    onChange={(event) => this.props.saveInfo(event, "workDevice")} />
                                                Laptop</label>

                                            <label htmlFor="desktop">
                                                <input id="desktop" name="device" type='radio' value="desktop" className='cornflowerBlue-bg'
                                                    checked={this.props.workDevice === "desktop"}
                                                    onChange={(event) => this.props.saveInfo(event, "workDevice")} />
                                                Desktop</label>

                                            <label htmlFor="bothDevice">
                                                <input id="bothDevice" name="device" type='radio' value="both" className='cornflowerBlue-bg'
                                                    checked={this.props.workDevice === "both"}
                                                    onChange={(event) => this.props.saveInfo(event, "workDevice")} />
                                                Both</label>
                                        </div>
                                        <div>{this.props.errors.workDevice && <div className="crail h9">{this.props.errors.workDevice}</div>}</div>
                                        {(this.props.workDevice === "laptop" || this.props.workDevice === "both") && <div>
                                            <p className="nxtLine font-bold">How do you transport your laptop?</p>
                                            <div className="grid-3-cols">
                                                <label htmlFor="oneShoulder" className="flex-label ">
                                                    <input id="oneShoulder" name="carryDevice" type='radio' value="one shoulder strap" className='cornflowerBlue-bg'
                                                        checked={this.props.carryDevice === "one shoulder strap"}
                                                        onChange={(event) => this.props.saveInfo(event, "carryDevice")} />
                                                    One shoulder strap</label>

                                                <label htmlFor="backpack" className="flex-label ">
                                                    <input id="backpack" name="carryDevice" type='radio' value="backpack" className='cornflowerBlue-bg'
                                                        checked={this.props.carryDevice === "backpack"}
                                                        onChange={(event) => this.props.saveInfo(event, "carryDevice")} />
                                                    Backpack</label>

                                                <label htmlFor="rollerCase" className="flex-label ">
                                                    <input id="rollerCase" name="carryDevice" type='radio' value="rollerCase" className='cornflowerBlue-bg'
                                                        checked={this.props.carryDevice === "rollerCase"}
                                                        onChange={(event) => this.props.saveInfo(event, "carryDevice")} />
                                                    Roller case</label>
                                            </div>
                                            <div>{this.props.errors.carryDevice && <div className="crail h9">{this.props.errors.carryDevice}</div>}</div>
                                        </div>}
                                        {this.props.workDevice?.length !== 0 &&
                                            <div>
                                                <p className="nxtLine font-bold">Average {this.props.workDevice === "desktop" ? "desktop" : "laptop"} usage during work</p>
                                                <div className="row space-between">
                                                    <select className="h9 mineShaft selectHrs"
                                                        value={this.props.workDevice === "desktop" ? this.props.desktopHrs : this.props.laptopHrs}
                                                        onChange={(event) => {
                                                            if (this.props.workDevice === "desktop") { 
                                                                this.props.saveInfo(event, "desktopHrs")
                                                            } else {
                                                                this.props.saveInfo(event, "laptopHrs")
                                                            }
                                                        }}>
                                                        <option value="" disabled >Hrs</option>
                                                        {this.drawOptions(0, 12, 1, "Hrs", this.props.workDevice === "desktop" ? this.props.desktopHrs : this.props.laptopHrs)}
                                                    </select>

                                                    <select className="h9 mineShaft selectMins"
                                                        value={this.props.workDevice === "desktop" ? this.props.desktopMins : this.props.laptopMins}
                                                        onChange={(event) => {
                                                            if (this.props.workDevice === "desktop") {
                                                                this.props.saveInfo(event, "desktopMins")
                                                            } else {
                                                                this.props.saveInfo(event, "laptopMins")
                                                            }
                                                        }}>
                                                        <option value="" disabled >Mins</option>
                                                        {this.drawOptions(0, 55, 5, "Mins")}
                                                    </select>
                                                </div>
                                                <div> {this.props.errors.laptopHrs && <div className="crail h9"> {this.props.errors.laptopHrs}</div>}
                                                    {this.props.errors.desktopHrs && <div className="crail h9"> {this.props.errors.desktopHrs}</div>}</div>
                                            </div>}
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Which audio device do you use?</p>
                                        <div className="grid-3-cols">
                                            <label htmlFor="headset">
                                                <input id="headset" name="audioDevice" type='radio' value="headset" className='cornflowerBlue-bg'
                                                    checked={this.props?.audioDevice === "headset"}
                                                    onChange={(event) => this.props.saveInfo(event, "audioDevice")} />
                                                Headset</label>

                                            <label htmlFor="handset">
                                                <input id="handset" name="audioDevice" type='radio' value="handset" className='cornflowerBlue-bg'
                                                    checked={this.props?.audioDevice === "handset"}
                                                    onChange={(event) => this.props.saveInfo(event, "audioDevice")} />
                                                Handset</label>

                                            <label htmlFor="bothAudDevice">
                                                <input id="bothAudDevice" name="audioDevice" type='radio' value="both" className='cornflowerBlue-bg'
                                                    checked={this.props?.audioDevice === "both"}
                                                    onChange={(event) => this.props.saveInfo(event, "audioDevice")} />
                                                Both</label>
                                        </div>
                                        <div>{this.props.errors.audioDevice && <div className="crail h9">{this.props.errors.audioDevice}</div>}</div>
                                        {this.props.audioDevice?.length !== 0 && <div>
                                            <p className="nxtLine font-bold">Average phone usage during work</p>
                                            <div className="row space-between">
                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.props?.phoneHrs}
                                                    onChange={(event) => this.props.saveInfo(event, "phoneHrs")}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.props?.phoneMins}
                                                    onChange={(event) => this.props.saveInfo(event, "phoneMins")}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                            <div>{this.props.errors.phoneHrs && <div className="crail h9"> {this.props.errors.phoneHrs}</div>}</div>
                                        </div>}
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Do you stretch regularly?</p>
                                        <div className="grid-3-cols">
                                            <label htmlFor="stretchYes">
                                                <input id="stretchYes" name="stretchReg" type='radio' value="yes" className='cornflowerBlue-bg'
                                                    checked={this.props.stretchRegularly === "yes"}
                                                    onChange={(event) => this.props.saveInfo(event, "stretchRegularly")} />
                                                Yes</label>

                                            <label htmlFor="stretchNo">
                                                <input id="stretchNo" name="stretchReg" type='radio' value="no" className='cornflowerBlue-bg'
                                                    checked={this.props.stretchRegularly === "no"}
                                                    onChange={(event) => this.props.saveInfo(event, "stretchRegularly")} />
                                                No</label>
                                            <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                            </label>
                                        </div>
                                        <div>{this.props.errors.stretchRegularly && <div className="crail h9"> {this.props.errors.stretchRegularly}</div>}</div>
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Do you excercise regularly?</p>
                                        <div className="grid-3-cols">
                                            <label htmlFor="exerciseYes">
                                                <input id="exerciseYes" name="exerciseReg" type='radio' value="yes" className='cornflowerBlue-bg'
                                                    checked={this.props?.exerciseRegularly === "yes"}
                                                    onChange={(event) => this.props.saveInfo(event, "exerciseRegularly")} />
                                                Yes</label>

                                            <label htmlFor="exerciseNo">
                                                <input id="exerciseNo" name="exerciseReg" type='radio' value="no" className='cornflowerBlue-bg'
                                                    checked={this.props?.exerciseRegularly === "no"}
                                                    onChange={(event) => this.props.saveInfo(event, "exerciseRegularly")} />
                                                No</label>
                                            <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' /></label>
                                        </div>
                                        <div>{this.props.errors.exerciseRegularly && <div className="crail h9"> {this.props.errors.exerciseRegularly}</div>}</div>
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            //  disabled={!this.props.enableNext1}
                                            className={"nxt-btn" + (this.props.enableNext1 ? " paradiso-bg" : " mineShaft-bg")} onClick={(e) => {
                                                e.preventDefault();
                                                if (this.props.enableNext1) { this.props.nextPage(2) }
                                                else { this.props.mandatoryFields(1) }
                                            }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page2 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="fountainBlue-bg"
                            goBack={() => this.props.goBack(2)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="50%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#d8ebea"
                            completedColor="#328280"
                            active="natureOfWork"
                            headerTitle="NATURE OF WORK"
                            isShowProfileNavigator={false}
                        />
                        <div className="nw-page2 swansDown-bg">
                            <div className='main height100' >
                                <form className="responsiveDiv">
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">How long is your typical work day?</p>
                                        <div className="row space-between">
                                            <select className="h9 mineShaft selectHrs"
                                                value={this.props.workHours}
                                                onChange={(event) => this.props.saveInfo(event, "workHours")}>
                                                <option value="" disabled >Hrs</option>
                                                {this.drawOptions(0, 12, 1, "Hrs")}
                                            </select>

                                            <select className="h9 mineShaft selectMins"
                                                value={this.props.workMins}
                                                onChange={(event) => this.props.saveInfo(event, "workMins")}>
                                                <option value="" disabled >Mins</option>
                                                {this.drawOptions(0, 55, 5, "Mins")}
                                            </select>
                                        </div>
                                        <div> {this.props.errors.workHours && <div className="crail h9"> {this.props.errors.workHours}</div>}</div>
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Do you take breaks during work?</p>
                                        <div className="row space-between">
                                            <label htmlFor="workBreakYes">
                                                <input id="workBreakYes" name="workBreak" type='radio' value="yes" className='cornflowerBlue-bg'
                                                    checked={(this.props.workBreak === "yes")}
                                                    onChange={(event) => this.props.saveInfo(event, "workBreak")} />
                                                Yes</label>

                                            <label htmlFor="workBreakNo">
                                                <input id="workBreakNo" name="workBreak" type='radio' value="no" className='cornflowerBlue-bg'
                                                    checked={this.props.workBreak === "no"}
                                                    onChange={(event) => this.props.saveInfo(event, "workBreak")} />
                                                No</label>
                                            <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                            </label>
                                        </div>
                                        <div> {this.props.errors.workBreak && <div className="crail h9"> {this.props.errors.workBreak}</div>}</div>
                                        {this.props.workBreak === "yes" &&
                                                <div>
                                                <div>
                                                    <p className="nxtLine font-bold">I take a break of</p>
                                                    <div className="row space-between">
                                                        {/* <select className="cornflowerBlue-bg h9 mineShaft selectHrs"
                                                            value={this.props?.breakTimeHrs}
                                                            onChange={(event) => this.props.saveInfo(event, "breakTimeHrs")}>
                                                            <option value="" disabled >Hrs</option>
                                                            {this.drawOptions(0, 12, 1, "Hrs")}
                                                        </select> */}

                                                        <select className="h9 mineShaft selectMins"
                                                            value={this.props.breakTimeMins}
                                                            onChange={(event) => this.props.saveInfo(event, "breakTimeMins")}>
                                                            <option value="" disabled >Mins</option>
                                                            {this.drawOptions(0, 55, 5, "Mins")}
                                                        </select>
                                                    </div>
                                                    <div> {this.props.errors.breakLength && <div className="crail h9"> {this.props.errors.breakLength}</div>}</div>
                                                </div>
                                                <div>
                                                    <p className="nxtLine font-bold">every</p>
                                                    <div className="row space-between">
                                                        <select className="h9 mineShaft selectHrs"
                                                            value={this.props.breakNumHrs}
                                                            onChange={(event) => this.props.saveInfo(event, "breakNumHrs")}>
                                                            <option value="" disabled >Hrs</option>
                                                            {this.drawOptions(0, 12, 1, "Hrs")}
                                                        </select>

                                                        <select className="h9 mineShaft selectMins"
                                                            value={this.props.breakNumMins}
                                                            onChange={(event) => this.props.saveInfo(event, "breakNumMins")}>
                                                            <option value="" disabled >Mins</option>
                                                            {this.drawOptions(0, 55, 5, "Mins")}
                                                        </select>
                                                    </div>
                                                    <div> {this.props.errors.breakFrequency && <div className="crail h9"> {this.props.errors.breakFrequency}</div>}</div>
                                                </div>
                                                
                                            </div>}
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">If you input data from document holder, do you
                                            have a document holder?</p>
                                        <div className="row space-between">
                                            <label htmlFor="docHolderYes">
                                                <input id="docHolderYes" name="docHolder" type='radio' value="yes" className='cornflowerBlue-bg'
                                                    checked={this.props.docHolder === "yes"}
                                                    onChange={(event) => this.props.saveInfo(event, "docHolder")} />
                                                Yes</label>

                                            <label htmlFor="docHolderNo">
                                                <input id="docHolderNo" name="docHolder" type='radio' value="no" className='cornflowerBlue-bg'
                                                    checked={this.props.docHolder === "no"}
                                                    onChange={(event) => this.props.saveInfo(event, "docHolder")} />
                                                No</label>
                                            <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                            </label>
                                        </div>
                                        <div> {this.props.errors.docHolder && <div className="crail h9"> {this.props.errors.docHolder}</div>}</div>
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            // disabled={!this.props.enableNext2}
                                            className={"nxt-btn" + (this.props.enableNext2 ? " paradiso-bg" : " mineShaft-bg")} onClick={(e) => {
                                                e.preventDefault();
                                                if (this.props.enableNext2) { this.props.nextPage(3) }
                                                else { this.props.mandatoryFields(2) }
                                            }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page3 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="fountainBlue-bg"
                            goBack={() => this.props.goBack(3)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="75%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#d8ebea"
                            completedColor="#328280"
                            active="natureOfWork"
                            headerTitle="NATURE OF WORK"
                            isShowProfileNavigator={false}
                        />
                        <div className="nw-page3 swansDown-bg">
                            <div className='main' >
                                <div className="h4 mineShaft">What are your daily work tasks ?</div>
                                <p className="h8 paradiso">SELECT ALL THAT APPLY</p>
                                <div className="responsiveDiv">
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showTyping: !this.state.showTyping })}        
                                        >
                                            <div></div>
                                            Typing
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showTyping ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {(this.state.showTyping) && <div className="h7 mineShaft">
                                            <div className="txtCenter font-bold">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.typingHrs || this.props.typingHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Typing", "hrs", 'typingHrs'); this.setState({ typingHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.typingMins || this.props.typingMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Typing", "mins", "typingMins"); this.setState({ typingMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showMouse: !this.state.showMouse })}
                                        >
                                            <div></div>
                                            Using a pointing
                                            device (mouse)
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showMouse ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showMouse && <div className="h7 mineShaft">
                                            <div className="txtCenter font-bold">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.mouseHrs || this.props.mouseHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Mouse", "hrs", "mouseHrs"); this.setState({ mouseHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.mouseMins || this.props.mouseMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Mouse", "mins", "mouseMins"); this.setState({ mouseMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showWriting: !this.state.showWriting })}
                                        >
                                            <div></div>
                                            Writing / Drawing
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showWriting ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showWriting && <div className="h7 mineShaft">
                                            <div className="txtCenter bold-font">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.writingHrs || this.props.writingHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Writing", "hrs", "writingHrs"); this.setState({ writingHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.writingMins || this.props.writingMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Writing", "mins", "writingMins"); this.setState({ writingMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>

                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showFiling: !this.state.showFiling })}
                                        >
                                            <div></div>
                                            Filing
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showFiling ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showFiling && <div className="h7 mineShaft">
                                            <div className="txtCenter bold-font">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.filingHrs || this.props.filingHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Filing", "hrs", "filingHrs"); this.setState({ filingHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.filingMins || this.props.filingMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Filing", "mins", "filingMins"); this.setState({ filingMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showCalls: !this.state.showCalls })}
                                        >
                                            <div></div>
                                            Phone calls
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showCalls ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showCalls && <div className="h7 mineShaft">
                                            <div className="txtCenter bold-font">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.callsHrs || this.props.callsHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Calls", "hrs", "callsHrs"); this.setState({ callsHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.callsMins || this.props.callsMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Calls", "mins", "callsMins"); this.setState({ callsMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showTimeAway: !this.state.showTimeAway })}
                                        >
                                            <div></div>
                                            Time away
                                            from workstation
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showTimeAway ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showTimeAway && <div className="h7 mineShaft">
                                            <div className="txtCenter bold-font">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.timeAwayHrs || this.props.timeAwayHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Time Away", "hrs", "timeAwayHrs"); this.setState({ timeAwayHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.timeAwayMins || this.props.timeAwayMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Time Away", "mins", "timeAwayMins"); this.setState({ timeAwayMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showOthers: !this.state.showOthers })}
                                        >
                                            <div></div>
                                            <div> {this.props.othersWorkTask === "" ? "Other (Specify)" : this.props.othersWorkTask}</div>
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showOthers ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showOthers && <div className="h7 mineShaft">
                                                <input type="text" value={ this.props.othersWorkTask} onChange={(event) => this.props.saveInfo(event, "othersWorkTask")} />
                                            <div className="txtCenter bold-font">How much time do you spend daily?</div>
                                            <div className="row space-between nxtLine">

                                                <select className="h9 mineShaft selectHrs"
                                                    value={this.state.othersHrs || this.props.othersHrs}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Others", "hrs", "othersHrs"); this.setState({ othersHrs: event.target.value }) }}>
                                                    <option value="" disabled >Hrs</option>
                                                    {this.drawOptions(0, 12, 1, "Hrs")}
                                                </select>

                                                <select className="h9 mineShaft selectMins"
                                                    value={this.state.othersMins || this.props.othersMins}
                                                    onChange={(event) => { this.props.addToWorkTask(event, "Others", "mins", "othersMins"); this.setState({ othersMins: event.target.value }) }}>
                                                    <option value="" disabled >Mins</option>
                                                    {this.drawOptions(0, 55, 5, "Mins")}
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className={'workTaskError'}>{this.props.errors.workTasks && <div className="crail h9"> {this.props.errors.workTasks}</div>}</div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            //  disabled={Object.keys(this.props.workTasks).length === 0}
                                            className={"nxt-btn" + (Object.keys(this.props?.workTasks ?? {})?.length > 0 ? " fountainBlue-bg" : " mineShaft-bg")}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (this.props.enableNext3) { this.props.buildChart() }
                                                else { this.props.mandatoryFields(3) }
                                            }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page4 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="fountainBlue-bg"
                            goBack={() => this.props.goBack(4)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#d8ebea"
                            completedColor="#328280"
                            active="natureOfWork"
                            headerTitle="NATURE OF WORK"
                            isShowProfileNavigator={false}
                        />
                        <div className="page4">
                            <div className='linen-bg main' >
                                <div className="h3 paradiso"> Daily work tasks distribution</div>
                                <div className="responsiveDiv">

                                    <div className='white-bg workTasks nxtLine ' style={{ gap: "3.75rem" }}>
                                        <div className="row">

                                            <div style={{ width: "70%" }}><Doughnut options={this.state.doughnutOptions} data={this.props.doughnutData} /></div>
                                            <div className="col">
                                                <div className="h6 fountainBlue">{this.props.devicePercentage}%</div>
                                                <div className="h10 mineShaft nxtLine"> Laptop</div>
                                                <div className="h6 tacao">{this.props.phonePercentage}%</div>
                                                <div className="h10 mineShaft nxtLine"> Phone</div>
                                            </div>
                                        </div>
                                        <div className="txtCenter paradiso h10"><b>DAILY DEVICE USAGE</b></div>
                                    </div>
                                    <div className='white-bg workTasks nxtLine barChart'>
                                        <Bar width={100}
                                            height={50} options={this.state.barOptions} data={this.props.barData}/>
                                            <div className="txtCenter paradiso h10">
                                                <b>
                                                    {this.computedBreakInfo()}
                                                </b>
                                            </div>
                                    </div>
                                    <div className="txtRight">
                                        <button
                                            className={"save-btn save-progress linen paradiso-bg"}
                                            onClick={() => this.props.checkCompletion()}>Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.modal2 &&
                            <Modal
                                modalClasses="paradiso-bg linen"
                                modalTitleClass="h3"
                                modalBodyClass="h11"
                                modalTitle="Section 2: Completed!"
                                modalBody="You can now proceed to fill in the details for 
                                Section 3 : Workstation Assessment "
                                txtBtn={false}
                                btn2Classes=" lavenderPink-bg linen"
                                btn2={this.props.saveProgress}
                                btn2IconClass="linen "
                                btnText1=""
                                btnText2="OK"
                            />}
                    </div>
                }
            </div >
        )
    }
}

export default NatureOfWorkView;