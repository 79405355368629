import { axios } from '../common';

const PATH_EWA = '/api/ewa/';

const userGetEwa = () =>
  axios.get(PATH_EWA + 'user');

const userUpsertEwa = async (data) => {
  return await axios.post(PATH_EWA + 'user/upsert', data);
};

const userDeleteEwa = async () => {
  return await axios.post(PATH_EWA + 'user/delete');
};

const ewa = {
  userGetEwa,
  userUpsertEwa,
  userDeleteEwa,
};
export default ewa;
