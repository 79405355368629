var CryptoJS = require('crypto-js');

const encryptAndSaveEwa = (ewa) => {
  if (!ewa) {
    return;
  }

  let ewaReportBuilder = CryptoJS.AES.encrypt(
    JSON.stringify(ewa),
    'sensical@123$%6789trueEWA'
  ).toString();

  localStorage.setItem('sensicalewa', ewaReportBuilder);
};

const localStorageHelpers = {
  encryptAndSaveEwa,
};
export default localStorageHelpers;
