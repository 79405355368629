import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const CircularProgress = ({ score = '', data = [] }) => {
  return (
    <Doughnut
      data={{
        datasets: [
          {
            data: data,
            backgroundColor: ['#C3C97D', '#FAEEE4'],
            borderColor: ['#C3C97D', '#FAEEE4'],
            borderWidth: 0,
            cutoutPercentage: 50,
          },
        ],
      }}
      plugins={[
        {
          beforeDraw(chart) {
            const { width } = chart;
            const { height } = chart;
            const { ctx } = chart;
            ctx.restore();
            ctx.font = `2.25rem sans-serif`;
            ctx.textBaseline = 'top';
            const text = score;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = (height / 2) + 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
          },
        },
      ]}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 0 },
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
          title: { display: true, enabled: true },
          subtitle: { display: true, enabled: true },
          datalabels: {
            display: false,
          },
        },
        hover: false,
        scales: {
          y: { display: false },
          x: { display: false },
        },
        cutout: 28,
      }}
    />
  );
};

export default React.memo(CircularProgress);
