import React, { Component } from "react";
import EWALogo from '../../assets/img/rosa/EWA.svg'
import EWABlack from '../../assets/img/rosa/EWABlack.png'
import profilePic from '../../assets/img/rosa/Profile.png'
import ProfileBlue from '../../assets/img/rosa/ProfileBlue.png'
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
		basicInfo: 2,
		natureOfWork: 4,
		workstationAssessment: 10,
		painAndDiscomfort: 8,
		workstationImages: 5,
		basicInfoPerc: 0,
		natureOfWorkPerc: 0,
		workstationAssessmentPerc: 0,
		painAndDiscomfortPerc: 0,
		workstationImagesPerc: 0,
		basicInfoColors: ['#FAA9BC', '#9B3E4E'],
		isShowProfileNavigator: this.props?.isShowProfileNavigator ?? true,
    };
	}
	calculateWidth = (moduleName, pageNo) => {
		let percentageStr = moduleName + "Perc"
		let percentage = Number(pageNo) / Number(this.state[moduleName]) * 100;
		this.setState({ [percentageStr]: percentage + "%" });
	}
	render() {
		return (
			<div className={this.props.headerBG + " header"}>
				<div className='navigation row space-between'>
					<div className='row'>
						<button className="back-btn-header"
							style={(this.props.intro ? { 'visibility': 'hidden' } : { 'display': 'block' })}
							onClick={this.props.goBack}>
							<i className={this.props.backBtnColor + ' fa fa-angle-left '}></i></button>
						<img src={this.props.home ? EWABlack : EWALogo} alt="logo" />
					</div>
					{ this.state.isShowProfileNavigator &&
						<div onClick={this.props.onProfileClick}><img src={this.props.home ? ProfileBlue : profilePic} alt="profile" />
						</div>
					}
				</div>

				<div className='progressBar'
					style={this.props.home ? (this.props.intro ? { 'visibility': 'hidden' } : { 'display': 'none' }) : { 'display': 'block' }}>
					<div className='h10 txtCenter nxtLine'>{this.props.home ? '' : this.props.title}</div>

					<div className="h10 nxtLine txtCenter white">{this.props.headerTitle}</div>
					<div className="row space-between">
						<div style={{ width: "52px", height: "4px", borderRadius: "4px", background: this.props.active === "basicInfo" ? "#FAA9BC" : this.props.baseColor }}>
							<div style={{ width: this.props.basicInfoCompleted, height: "4px", background: this.props.completedColor }}></div>
						</div>
						<div style={{ width: "52px", height: "4px", borderRadius: "4px", background: this.props.active === "natureOfWork" ? "#DAEDEC" : this.props.baseColor }}>
							<div style={{ width: this.props.natureOfWorkCompleted, height: "4px", background: this.props.completedColor }}></div>
						</div>
						<div style={{ width: "52px", height: "4px", borderRadius: "4px", background: this.props.active === "workstationAssessment" ? "#DCE3EE" : this.props.baseColor }}>
							<div style={{ width: this.props.workstationAssessCompleted, height: "4px", background: this.props.completedColor }}></div>
						</div>
						<div style={{ width: "52px", height: "4px", borderRadius: "4px", background: this.props.active === "painAndDiscomfort" ? "#D8E7E3" : this.props.baseColor }}>
							<div style={{ width: this.props.painAndDiscomfortCompleted, height: "4px", background: this.props.completedColor }}></div>
						</div>
						<div style={{ width: "52px", height: "4px", borderRadius: "4px", background: this.props.active === "workstationImages" ? "#ECDCD3" : this.props.baseColor }}>
							<div style={{ width: this.props.workstationImgCompleted, height: "4px", background: this.props.completedColor }}></div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Header;