import React, { Component } from "react";
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import SectionIntro from "../../components/rosa/SectionIntro";
import './BasicInformationView.scss'
import helpers from "../../helpers";
import Constants from "../../constants";
class BasicInformationView extends Component {

    render() {
        return (
            <div className="container noScroll">
                {this.props.sectionIntroOpen &&
                    <div className="cranberry-bg linen">
                        <Header backBtnColor="linen"
                            goBack={this.props.warning}
                            intro={true}
                            basicInfoCompleted="0%"
                            natureOfWorkCompleted="0%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#e57388"
                            completedColor="#9B3E4E"
                            active="basicInfo"
                            headerTitle=""
                            isShowProfileNavigator={false}
                        />

                        <SectionIntro
                            sectionHeader1="Basic"
                            sectionHeader2="Information"
                            sectionDesc=" The following section contains questions about you that are important for us to note and understand, before we analyse your workspace."
                            bgColor="cranberry-bg biBgImg"
                            sectionColor="white"
                            nxtBtnClass="linen vinRouge nxt-btn1"
                            title=''
                            backBtnColor='linen'
                            nextSection={this.props.openQuiz} />
                    </div>
                }
                {this.props.modal1 &&
                    <Modal
                        modalClasses="tacao-bg mineShaft"
                        modalTitleClass="h3"
                        modalBodyClass="h7"
                        modalTitle="Are you sure?"
                        modalBody="Once you proceed, all entries made in this section will be lost. "
                        txtBtn={true}
                        btnText1="PROCEED"
                        btnText2="CANCEL"
                        btn1Classes="linen"
                        btn2Classes="vinRouge"
                        btn1={() => { this.props.reset(); window.location.href = '/intro' }}
                        btn2={this.props.closeWarning} />
                }
                {this.props.page1 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="cranberry-bg"
                            goBack={() => this.props.goBack(1)}
                            basicInfoCompleted="50%"
                            natureOfWorkCompleted="0%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#e57388"
                            completedColor="#9B3E4E"
                            active="basicInfo"
                            headerTitle="BASIC INFORMATION"
                            isShowProfileNavigator={false}
                        />
                        <div className="basicPage1 ">

                            <div className='vanillaIce-bg main height100' >
                                <form className="responsiveDiv">
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <label className='font-bold' htmlFor='work'>Where do you work?</label><br />
                                        <input type='text' id="work" className='cornflowerBlue-bg' autoFocus="autofocus" value={this.props.workPlace} onChange={(event) => this.props.saveInfo(event, "workPlace")} />
                                        {this.props.errors.workPlace && <div className="crail h9">{this.props.errors.workPlace}</div>}
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <label className='font-bold' htmlFor='occupation'>What is your current occupation?</label><br />

                                        <select className="occupation__select h9 mineShaft"
                                            defaultValue={this.props.occupation}
                                            onChange={(event) => {
                                                this.props.saveInfo(event, 'occupation')
                                            }}
                                        >
                                            <option value={''}></option>
                                            {Constants.BasicInformationInput.OCCUPATIONS.map(occupation => {
                                                return <option value={occupation}>{occupation}</option>;
                                            })}
                                        </select>
                                        {this.props.errors.occupation && <div className="crail h9">{this.props.errors.occupation}</div>}
                                    </div>
                                    {/* <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine">What is the nature of your work?</p>
                                        <div className="row space-between">
                                            <label htmlFor="online"><input id="online" name="natureOfWork" type='radio' value="Online" className='cornflowerBlue-bg'
                                                checked={this.props.natureOfWork === "Online"}
                                                onChange={(event) => this.props.saveInfo(event, "natureOfWork")} />
                                                Online</label>

                                            <label htmlFor="offline">
                                                <input id="offline" name="natureOfWork" type='radio' value="Offline" className='cornflowerBlue-bg'
                                                    checked={this.props.natureOfWork === "Offline"}
                                                    onChange={(event) => this.props.saveInfo(event, "natureOfWork")} />
                                                Offline</label>
                                            <label htmlFor="hybrid">
                                                <input id="hybrid" name="natureOfWork" type='radio' value="Hybrid" className='cornflowerBlue-bg'
                                                    checked={this.props.natureOfWork === "Hybrid"}
                                                    onChange={(event) => this.props.saveInfo(event, "natureOfWork")} />
                                                Hybrid</label>
                                        </div>
                                    </div> */}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            //disabled={!this.props.page1Filled} 
                                            className={"nxt-btn" + (this.props.page1Filled ? " cranberry-bg" : " mineShaft-bg")} onClick={(e) => { e.preventDefault(); if (this.props.page1Filled) { this.props.openPage2() } else { this.props.mandatoryFields(1) } }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                }

                {
                    this.props.page2 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="cranberry-bg"
                            goBack={() => this.props.goBack(2)}
                            basicInfoCompleted="90%"
                            natureOfWorkCompleted="0%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#e57388"
                            completedColor="#9B3E4E"
                            active="basicInfo"
                            headerTitle="BASIC INFORMATION"
                            isShowProfileNavigator={false}
                        />
                        <div>
                            <div className='vanillaIce-bg main min-h-80vh' >
                                <form className="responsiveDiv">
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <div className='font-bold'>Height</div>
                                        <div className="row space-between ht-info">
                                            { this.props.feetUnitSelected &&
                                               <div>
                                                    <label htmlFor="feet">
                                                        <input id="feet" type="tel" className='textNumber cornflowerBlue-bg h9 mineShaft' placeholder="Feet"
                                                            maxLength={1} autoComplete="off"
                                                            onChange={(event) => {
                                                                event.target.value = helpers.inputHelpers.filterNumberOnly(event.target.value)
                                                                this.props.saveInfo(event, "heightinFeet")
                                                            }} 
                                                        />
                                                    </label>
                                                   <div>{this.props.errors.heightinFeet && <div className="crail h9 info__error">{this.props.errors.heightinFeet}</div>}</div>
                                                </div>
                                            }

                                            { this.props.feetUnitSelected &&
                                                <div>
                                                    <label htmlFor='inches'>
                                                    <input id="inches" type="tel" className='textNumber cornflowerBlue-bg ht-info h9 mineShaft' placeholder="Inches"
                                                        maxLength={2} autoComplete="off"
                                                        onChange={(event) => {
                                                            event.target.value = helpers.inputHelpers.filterNumberOnly(event.target.value);
                                                            this.props.saveInfo(event, "heightInInches")
                                                        }}
                                                    />
                                                        </label>
                                                    <div> {this.props.errors.heightInInches && <div className="crail h9 info__error">{this.props.errors.heightInInches}</div>}</div>
                                                </div>
                                            }

                                            {!this.props.feetUnitSelected && <label htmlFor='cms'>
                                                    <input id="cms" type="tel" className='textNumber cornflowerBlue-bg ht-cms h9 mineShaft' placeholder="Cms"
                                                        maxLength={3} autoComplete="off"
                                                        onChange={(event) => {
                                                            event.target.value = helpers.inputHelpers.filterNumberOnly(event.target.value)
                                                            this.props.saveInfo(event, "height")
                                                        }} 
                                                />
                                            </label>}
                                            <select className="textNumber h9 mineShaft height__select-type" defaultValue="ft/inches" onChange={(event) => this.props.selectHeightUnit(event)}>
                                                <option value="ft/inches">FT/Inch</option>
                                                <option value="cms">Cms</option>
                                            </select>

                                        </div>
                                        {!this.props.feetUnitSelected && <div className="nxtLine"> {this.props.errors.height && <div className="crail h9">{this.props.errors.height}</div>}</div>}

                                        <div className='font-bold'>Weight</div>
                                        <div className="row space-between wt-info">
                                            <label htmlFor='weight'>
                                                <div style={{ display: this.props.poundUnitSelected ? "block" : "none" }}>
                                                    <input id="weightInLbs" type='tel' className='textNumber cornflowerBlue-bg wt-info h9 mineShaft' placeholder="Lbs"
                                                            maxLength={3} autoComplete="off"
                                                            onChange={(event) => {
                                                                event.target.value = helpers.inputHelpers.filterNumberOnly(event.target.value)
                                                                this.props.saveInfo(event, "weightInLbs")
                                                            }}
                                                        />
                                                        {this.props.errors.weightInLbs && <div className="crail h9 info__error">{this.props.errors.weightInLbs}</div>}
                                                    </div>

                                                    <div style={{ display: this.props.poundUnitSelected ? "none" : "block" }}>
                                                        <input id="weightinKgs" type='tel' className='textNumber cornflowerBlue-bg wt-info h9 mineShaft' placeholder="Kgs"
                                                            maxLength={3} autoComplete="off"
                                                            onChange={(event) => {
                                                                event.target.value = helpers.inputHelpers.filterNumberOnly(event.target.value)
                                                                this.props.saveInfo(event, "weight")
                                                            }}
                                                        />
                                                        {this.props.errors.weight && <div className="crail h9 info__error">{this.props.errors.weight}</div>}
                                                    </div>

                                            </label>
                                            <select className="h9 mineShaft" defualtvalue="lbs" onChange={(event) => this.props.selectWeightUnit(event)}>
                                                <option value="lbs">Lbs</option>
                                                <option value="kgs">Kgs</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Which is your dominant hand?</p>
                                        <div className="grid-3-cols nxtLine">
                                            <label htmlFor='leftHand'><input id="leftHand" name="dominantHand" value="left" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "dominantHand")} />
                                                Left</label>
                                            <label htmlFor='rightHand'><input id="rightHand" name="dominantHand" value="right" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "dominantHand")} />
                                                Right</label>
                                            <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                            </label>
                                        </div>
                                        {this.props.errors.dominantHand && <div className="crail h9">{this.props.errors.dominantHand}</div>}

                                    </div>
                                    { !this.props.isMale && 
                                        <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                            <p className="nxtLine font-bold">Are you pregnant?</p>
                                            <div className="grid-3-cols nxtLine">
                                                <label htmlFor='pregYes'><input id="pregYes" name="pregnant" value="yes" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "pregnant")} />
                                                    Yes</label>
                                                <label htmlFor='pregNo'> <input id="pregNo" name="pregnant" value="no" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "pregnant")} />
                                                    No</label>
                                                <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                                </label>
                                            </div>
                                            {this.props.errors.pregnant && <div className="crail h9">{this.props.errors.pregnant}</div>}
                                        </div>
                                    }
                                    <div className='white-bg h7 mineShaft questionBoxLens nxtLine'>
                                        <div className="collapse-box">
                                            <p className="nxtLine font-bold">Do you wear corrective lenses?</p>
                                            <div className="grid-3-cols nxtLine">
                                                <label htmlFor='lensYes'><input id="lensYes" name="correctiveLens" value="yes" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "correctiveLens")} />
                                                    Yes</label>
                                                <label htmlFor='lensNo'><input id="lensNo" name="correctiveLens" value="no" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "correctiveLens")} />
                                                    No</label>
                                                <label style={{ 'visibility': 'hidden' }}><input type='radio' className='cornflowerBlue-bg' />
                                                </label>
                                            </div>
                                            {this.props.errors.correctiveLens && <div className="crail h9">{this.props.errors.correctiveLens}</div>}
                                        </div>

                                        {this.props.correctiveLens === "yes" &&
                                            <div className="collapse-box">
                                                <p className="nxtLine font-bold">What are the lenses you use?</p>
                                                <div className="grid-3-cols grid-row-gap10 nxtLine">
                                                    <label className="lens-type" htmlFor='singleLens'><input id="singleLens" name="lensType" value="single lens" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "lensType")} />
                                                        Single Lens</label>
                                                    <label className="lens-type" htmlFor='bifocals'><input id="bifocals" name="lensType" value="bifocals" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "lensType")} />
                                                        Bifocals</label>
                                                    <label className="lens-type" htmlFor='trifocals'><input id="trifocals" name="lensType" value="trifocals" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "lensType")} />
                                                        Trifocals</label>
                                                    <label className="lens-type " htmlFor='progressive'><input id="progressive" name="lensType" value="progressive" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "lensType")} />
                                                        Progressive</label>
                                                    <label className="lens-type " htmlFor='contacts'><input id="contacts" name="lensType" value="contacts" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "lensType")} />
                                                        Contacts</label>
                                                </div>
                                                {this.props.errors.lensType && <div className="crail h9">{this.props.errors.lensType}</div>}
                                            </div>}
                                        {this.props.correctiveLens === "yes" && this.props.lensView &&
                                            <div className="collapse-box">
                                                <p class='font-bold'>What part of the lenses do you look through?</p>
                                                <p className="nxtLine font-bold">To view the monitor</p>
                                                <div className="grid-3-cols">
                                                    <label htmlFor='monitorBottom'> <input name="monitorView" id="monitorBottom" value="bottom" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "monitorView")} />
                                                        Bottom</label>
                                                    <label htmlFor='monitorTop'><input name="monitorView" id="monitorTop" value="top" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "monitorView")} />
                                                        Top</label>
                                                    <label htmlFor='monitorMiddle'><input name="monitorView" id="monitorMiddle" value="middle" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "monitorView")} />
                                                        Middle</label><br />
                                                </div>
                                                {this.props.errors.monitorView && <div className="crail h9">{this.props.errors.monitorView}</div>}
                                                <p className="nxtLine font-bold">To read paper documents</p>
                                                <div className="grid-3-cols">
                                                    <label htmlFor='paperBottom'><input name="docView" id="paperBottom" value="bottom" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "paperView")} />
                                                        Bottom</label>
                                                    <label htmlFor='paperTop'><input name="docView" id="paperTop" value="top" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "paperView")} />
                                                        Top</label>
                                                    <label htmlFor='paperMiddle'><input name="docView" id="paperMiddle" value="middle" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "paperView")} />
                                                        Middle</label><br />
                                                </div>
                                                {this.props.errors.paperView && <div className="crail h9">{this.props.errors.paperView}</div>}
                                                <p className="nxtLine font-bold">To look at people</p>
                                                <div className="grid-3-cols">
                                                    <label htmlFor='peopleBottom'><input name="peopleView" id="peopleBottom" value="bottom" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "peopleView")} />
                                                        Bottom</label>
                                                    <label htmlFor='peopleTop'> <input name="peopleView" id="peopleTop" value="top" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "peopleView")} />
                                                        Top</label>
                                                    <label htmlFor='peopleMiddle'><input name="peopleView" id="peopleMiddle" value="middle" type='radio' className='cornflowerBlue-bg' onChange={(event) => this.props.saveInfo(event, "peopleView")} />
                                                        Middle</label><br />
                                                </div>
                                                {this.props.errors.peopleView && <div className="crail h9">{this.props.errors.peopleView}</div>}
                                            </div>}

                                    </div>
                                </form>
                                <div className="txtRight">
                                    <button className={"save-btn save-progress linen" + (this.props.page2Filled ? " cranberry-bg" : " mineShaft-bg")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (this.props.page2Filled) { this.props.checkCompletion() }
                                            else { this.props.mandatoryFields(2) }
                                        }}>Proceed</button>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.modal2 &&
                            <Modal
                                modalClasses="cranberry-bg linen"
                                modalTitleClass="h3"
                                modalBodyClass="h11"
                                modalTitle="Section 1: Completed!"
                                modalBody="You can now proceed to fill in the details for Section 2: Nature of Work "
                                btn2={this.props.saveProgress}
                                btnText1=""
                                txtBtn={false}
                                btnText2="OK"
                                btn2Classes="pineGlade"
                                btn2IconClass="linen "
                            />
                        }
                    </div >
                }
            </div >
        )
    }
}

export default BasicInformationView;