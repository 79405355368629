import * as ActionTypes from './ActionTypes';
export const INITIAL_STATE = {
  isLoading: false,
  reviewers: [],
};

export const ReducerProvider = (state = INITIAL_STATE, action) => {
  let { payload } = action;

  switch (action.type) {
    case ActionTypes.PROVIDER_ACTIONS.GET_REVIEWERS:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.PROVIDER_ACTIONS.SET_REVIEWERS:
      return {
        ...state,
        isLoading: false,
        reviewers: payload?.reviewers,
      };
    default:
      return state;
  }
};
