import React, { Component } from 'react'
import observation from '../../../assets/observation.json'
import recommendation from '../../../assets/recommendation.json'
import observationMisc from '../../../assets/observationMisc.json'
import recommendationMisc from '../../../assets/recommendationMisc.json'
import './AdminView.scss'
import { Link, Redirect, withRouter } from 'react-router-dom'
import Draggable from 'react-draggable'
import bgreportbuilder from '../../../assets/img/rosa/admin/bgreportbuilder.png'
import up from '../../../assets/img/rosa/admin/up.png'
import down from '../../../assets/img/rosa/admin/down.png'
import edit from '../../../assets/img/rosa/admin/edit.png'
import tick from '../../../assets/img/rosa/admin/tick.png'
import minus from '../../../assets/img/rosa/admin/minus.png'
import plus from '../../../assets/img/rosa/admin/plus.png'
import drag from '../../../assets/img/rosa/admin/drag.png'
import drsharan from '../../../assets/img/rosa/drsharan.png'
import back from '../../../assets/img/rosa/admin/back.png'
import close from '../../../assets/img/rosa/admin/close.png'
import loading from '../../../assets/img/rosa/loading.gif'
import clockRotateLeft from '../../../assets/img/rosa/clockRotateLeft.svg'
import { Doughnut, Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js'
import helpers from "../../../helpers";
import ModalReviewerSelector from './components/ModalReviewerSelector';
import Constants from '../../../constants'
import i18n from '../../../i18n';
import ImageChangeable from './components/ImageChangeable'
import WorkStationSnapshot from './components/WorkStationSnapshot'
import ModalChangeSeverityScore from './components/ModalChangeSeverityScore'
import EditableIcon from '../../../components/atoms/EditableIcon'
import PainDistributionFilter from './components/PainDistributionFilter'
import PainDiscomfortSummaryTable from '../components/painDiscomfortSummaryTable'
import PatientPersonalInfo from './components/PatientPersonalInfo'
import NatureOfWorkSummaryBreakInfo from './components/NatureOfWorkSummaryBreakInfo'
import DoctorContactInfo from './components/DoctorContactInfo'
import Alert from '../../../components/rosa/Alert.js'
import CircularProgress from '../../../components/atoms/CircularProgress'
import LoadingView from '../../../components/atoms/LoadingView'
import EwaStatus from '../../../constants/ewaStatus'
import WorkstationGoniometer from './components/WorkstationGoniometer'
import { ToolTip } from '../../../components/rosa/ToolTip'
import { format } from 'date-fns';

Chart.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
)
Chart.defaults.backgroundColor = '#FF5733'
Chart.defaults.font.size = 11
var CryptoJS = require('crypto-js')


const REGEX_FIRST_LOWER_STRING = Constants.Regex.firstLowerString;
const ICONS = {
  EDIT: 'edit',
  TICK: 'tick',
}
class AdminReportBuilderView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      userData: {},
      sendReport: i18n.sendReport,
      drNote: '',
      doughnutOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false,
            color: 'white',
            align: 'center',
            font: { weight: 'bold' }
          },
          title: {
            display: true,
            text: 'DAILY DEVICE USAGE',
            position: 'bottom',
            color: '#328280'
          }
        }
      },
      doughnutData: {
        labels: ['Phone', 'Laptop'],
        datasets: [
          {
            label: 'Daily device usage',
            data: [],
            backgroundColor: [
              'rgba(238, 194, 128, 1)',
              'rgba(72, 177, 175, 1)'
            ],
            borderWidth: 1
          }
        ]
      },
      barOptions: {
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              color: 'white',
              lineWidth: 3,
              drawTicks: false,
              offset: true
            },
            title: {
              display: true,
              text: 'Hours'
            },
            max: 12,
            min: 0,
            ticks: {
              stepSize: 2,
              color: '#328280'
            }
          },
          y: {
            ticks: {
              fontSize: 20
            },
            grid: {
              drawTicks: false,
              drawOnChartArea: false
            }
          }
        },
        indexAxis: 'y',
        elements: {
          bar: {
            // borderWidth: 0,
          }
        },
        responsive: true,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'DAILY WORK TASK BREAKUP',
            position: 'bottom',
            color: '#328280'
          }
        }
      },
      barData: {
        labels: [],
        datasets: [
          {
            barThickness: 8,
            data: [],
            borderRadius: 8,
            backgroundColor: '#48B1AF'
          }
        ]
      },
      viewReport: false,
      tab: {
        complaint: true,
        observation: false,
        recommendation: false,
      },
      section1: true,
      section2: false,
      section3: false,
      section4: false,
      section5: false,
      section6: false,
      showDraggable: false,
      annotateCurrentNum: 0,
      annotateObservationId: 0,
      observationCount: 0,
      recommendationCount: 0,
      diagnosis: [
        {
          type: 'CHAIR HEIGHT',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'SEAT PAN',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'ARM REST',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'BACK SUPPORT',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'MONITOR/LAPTOP',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'KEYBOARD',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'MOUSE',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'TELEPHONE',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'SOFT TISSUE',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        },
        {
          type: 'MISCELLANEOUS',
          observationImg: 'down',
          recommendationImg: 'down',
          observation: '',
          recommendation: '',
          suggestsObs: [],
          suggestsRec: [],
          annotateImg: '',
          annotateNum: 0,
          annotateCoord: []
        }
      ],
      isShowModalReviewerSelector: false,
      isShowModalChangeSeverityScore: false,
      reviewers: [],
      currentUser: {},
      isShowSavedSuccess: false,
      isShowSentSuccess: false,
      isGoniometerReadingGenetared: false,
      isGoniometerReadingEditable: false,
      isGoniometerReadingsLoading: false,
      isErrorInGoniometerReadingRequest: false,
      goniometerInputImagePath: "",
      goniometerReadings: {
        goniometerInputImagePath: "",
        goniometerInputImageUrl: "",
        goniometers: []
      },
      viewReUploadImageSection: false,
      imageReUploadRequestStatus: {
        workstationSide: false,
        workstationBack: false,
        userSide: false,
        userBack: false
      },
      imageReUploadRequestList: [],
      isImageReUploadRequestListEmpty: false,
      reUploadRequestAdditionalComments: "",
      isReUploadImageRequestMailSent: false,
      reUploadRequestedDate: "",
      reUploadRequestUser: "",
      reUploadReminderDate: "",
      reUploadCompletionDate: "",
      isSendingReUploadRequestMail: false,
      isErrorWhileStoringReUploadData: false,
      isErrorWhileSendingReUploadMail: false
    };
  }

  handleEWAData = (userData) => {
    if (userData.rosaSelectedItems) {
      let workstationSnapshot = []
      let keyPattern = '';
      for (var rosa in userData.rosaSelectedItems) {
        let newKeyPattern = REGEX_FIRST_LOWER_STRING.exec(userData.rosaSelectedItems[rosa])?.[0];
        let isShowGroupKey = false;

        if (newKeyPattern && newKeyPattern !== keyPattern) {
          keyPattern = newKeyPattern;
          isShowGroupKey = true;
        }
        workstationSnapshot.push({
          keyPattern: keyPattern,
          isShowGroupKey: isShowGroupKey,
          image: userData.rosaSelectedItems[rosa],
          text: rosa,
        });
      }
      userData.workstationSnapshot = workstationSnapshot
    }
    if (!userData.goniometerInputImagePath) {
      userData.goniometerInputImageUrl = userData.userSideImageUrl
    }
    
    userData.age = helpers.stringHelpers.ageFromDate(userData?.customer?.dob);

    this.setState({
      userData,
      doughnutData: {
        ...this.state.doughnutData,
        datasets: [{
          ...this.state.doughnutData.datasets[0],
          data: [userData.phonePercentage, userData.devicePercentage],
        }],
      },
      goniometerReadings: userData.goniometers && userData.goniometers[0] ? userData.goniometers[0] : this.state.goniometerReadings,
      isGoniometerReadingGenetared: userData.goniometers && userData.goniometers[0] && userData.goniometers[0].length !== 0
    });

    if(userData.reUploadWorkstationImages && userData.reUploadWorkstationImages.length > 0) {
      this.setState({
        imageReUploadRequestList: userData.reUploadWorkstationImages,
        isReUploadImageRequestMailSent: true
      });
    }

    if(userData.reUploadRequestedDate && userData.reUploadRequestedDate.length > 0) {
      this.setState({
        reUploadRequestedDate: userData.reUploadRequestedDate
      });
    }

    if(userData.reUploadReminderDate && userData.reUploadReminderDate.length > 0) {
      this.setState({
        reUploadReminderDate: userData.reUploadReminderDate
      })
    }

    if(userData.reUploadCompletionDate && userData.reUploadCompletionDate.length > 0) {
      this.setState({
        reUploadCompletionDate: userData.reUploadCompletionDate
      });
    }

    if(userData.reUploadRequestAdditionalComments && userData.reUploadRequestAdditionalComments.length > 0) {
      this.setState({
        reUploadRequestAdditionalComments: userData.reUploadRequestAdditionalComments
      });
    }

    let userD = userData
    let barD = this.state.barData
    let workTasks = userData.workTasks
    workTasks.forEach(function (item, i) {
      for (var min in item) {
        barD['labels'].push(min)
        let taskHrs = Number((Number(item[min]) / 60).toFixed(2))
        barD['datasets'][0]['data'].push(taskHrs)
      }
    })
    let diagnosis = this.state.diagnosis
    
    if (!this.isEmptyDiagnosis(userD.diagnosis)) {
      diagnosis = userD.diagnosis
    } else {
      diagnosis.forEach(function (item, i) {
        if (item.type === 'CHAIR HEIGHT') {
          if (userD.chairHeight.find(a => a.includes('comfortably'))) {
            item.suggestsObs.push([observation.chairHeight[0], false])
          }
          if (userD.chairHeight.find(a => a.includes('too low'))) {
            item.suggestsObs.push([observation.chairHeight[1], false])
            item.suggestsRec.push([recommendation.chairHeight[0], false])
          }
          if (userD.chairHeight.find(a => a.includes('too high'))) {
            item.suggestsObs.push([observation.chairHeight[2], false])
            item.suggestsObs.push([observation.chairHeight[3], false])
            item.suggestsObs.push([observation.chairHeight[4], false])
            item.suggestsObs.push([observation.chairHeight[5], false])
            item.suggestsRec.push([recommendation.chairHeight[1], false])
          }
          if (userD.chairHeight.find(a => a.includes('touch the ground'))) {
            item.suggestsObs.push([observation.chairHeight[6], false])
            item.suggestsRec.push([recommendation.chairHeight[2], false])
          }
          if (userD.chairHeight.find(a => a.includes('desk'))) {
            item.suggestsObs.push([observation.chairHeight[7], false])
            item.suggestsRec.push([recommendation.chairHeight[3], false])
          }
          if (userD.chairHeight.find(a => a.includes('adjustable'))) {
            item.suggestsObs.push([observation.chairHeight[8], false])
            item.suggestsRec.push([recommendation.chairHeight[4], false])
          }
          item.suggestsObs.push([observation.chairHeight[31], false])
          item.suggestsRec.push([recommendation.chairHeight[8], false])
          item.suggestsRec.push([recommendation.chairHeight[9], false])
          item.suggestsRec.push([recommendation.chairHeight[10], false])
        }
        if (item.type === 'SEAT PAN') {
          if (userD.seatPan.find(a => a.includes('inches'))) {
            item.suggestsObs.push([observation.seatPan[0], false])
          }
          if (userD.seatPan.find(a => a.includes('too long'))) {
            item.suggestsObs.push([observation.seatPan[1], false])
            item.suggestsRec.push([recommendation.seatPan[0], false])
          }
          if (userD.seatPan.find(a => a.includes('too short'))) {
            item.suggestsObs.push([observation.seatPan[2], false])
            item.suggestsRec.push([recommendation.seatPan[1], false])
          }
          if (userD.seatPan.find(a => a.includes('adjustable'))) {
            item.suggestsObs.push([observation.seatPan[3], false])
          }
        }
        if (item.type === 'ARM REST') {
          if (userD.armRest.find(a => a.includes('position'))) {
            item.suggestsObs.push([observation.armRest[0], false])
          }
          if (userD.armRest.find(a => a.includes('too high'))) {
            item.suggestsObs.push([observation.armRest[1], false])
            item.suggestsObs.push([observation.armRest[2], false])
            item.suggestsRec.push([recommendation.armRest[0], false])
            item.suggestsRec.push([recommendation.armRest[1], false])
          }
          if (userD.armRest.find(a => a.includes('damaged'))) {
            item.suggestsObs.push([observation.armRest[3], false])
            item.suggestsObs.push([observation.armRest[4], false])
            item.suggestsRec.push([recommendation.armRest[2], false])
          }
          if (userD.armRest.find(a => a.includes('too wide'))) {
            item.suggestsObs.push([observation.armRest[5], false])
            item.suggestsRec.push([recommendation.armRest[3], false])
          }
          if (userD.armRest.find(a => a.includes('adjustable'))) {
            item.suggestsObs.push([observation.armRest[6], false])
            item.suggestsRec.push([recommendation.armRest[4], false])
          }
          item.suggestsObs.push([observation.armRest[12], false])
          item.suggestsObs.push([observation.armRest[13], false])
        }
        if (item.type === 'BACK SUPPORT') {
          if (userD.backSupport.find(a => a.includes('adequate'))) {
            item.suggestsObs.push([observation.backSupport[0], false])
          }
          if (userD.backSupport.find(a => a.includes('lumbar'))) {
            item.suggestsObs.push([observation.backSupport[1], false])
            item.suggestsRec.push([recommendation.backSupport[0], false])
          }
          if (userD.backSupport.find(a => a.includes('too far'))) {
            item.suggestsObs.push([observation.backSupport[2], false])
            item.suggestsObs.push([observation.backSupport[3], false])
            item.suggestsRec.push([recommendation.backSupport[1], false])
            item.suggestsRec.push([recommendation.backSupport[2], false])
          }
          if (userD.backSupport.find(a => a.includes('back support'))) {
            item.suggestsObs.push([observation.backSupport[4], false])
            item.suggestsObs.push([observation.backSupport[5], false])
            item.suggestsObs.push([observation.backSupport[6], false])
            item.suggestsRec.push([recommendation.backSupport[3], false])
          }
          if (userD.backSupport.find(a => a.includes('adjustable'))) {
            item.suggestsObs.push([observation.backSupport[7], false])
            item.suggestsObs.push([observation.backSupport[8], false])
            item.suggestsRec.push([recommendation.backSupport[4], false])
            item.suggestsRec.push([recommendation.backSupport[5], false])
          }
        }
        if (item.type === 'MONITOR/LAPTOP') {
          if (userD.monitorSetup.find(a => a.includes('away'))) {
            item.suggestsObs.push([observation.monitor[0], false])
            item.suggestsObs.push([observation.monitor[1], false])
          }
          if (userD.monitorSetup.find(a => a.includes('too low'))) {
            item.suggestsObs.push([observation.monitor[2], false])
            item.suggestsObs.push([observation.monitor[3], false])
            item.suggestsRec.push([recommendation.monitor[0], false])
            item.suggestsRec.push([recommendation.monitor[1], false])
          }
          if (userD.monitorSetup.find(a => a.includes('too far'))) {
            item.suggestsObs.push([observation.monitor[4], false])
            item.suggestsObs.push([observation.monitor[5], false])
            item.suggestsRec.push([recommendation.monitor[2], false])
          }
          if (userD.monitorSetup.find(a => a.includes('too high'))) {
            item.suggestsObs.push([observation.monitor[6], false])
            item.suggestsObs.push([observation.monitor[7], false])
            item.suggestsObs.push([observation.monitor[8], false])
            item.suggestsRec.push([recommendation.monitor[3], false])
            item.suggestsRec.push([recommendation.monitor[4], false])
          }
          if (userD.monitorSetup.find(a => a.includes('bifocals'))) {
            item.suggestsObs.push([observation.monitor[9], false])
          }
          if (userD.monitorSetup.find(a => a.includes('twist'))) {
            item.suggestsObs.push([observation.monitor[10], false])
            item.suggestsObs.push([observation.monitor[11], false])
            item.suggestsRec.push([recommendation.monitor[5], false])
          }
          if (userD.monitorSetup.find(a => a.includes('significant'))) {
            item.suggestsObs.push([observation.monitor[12], false])
            item.suggestsRec.push([recommendation.monitor[6], false])
            item.suggestsRec.push([recommendation.monitor[7], false])
          }
          if (userD.monitorSetup.find(a => a.includes('document'))) {
            item.suggestsObs.push([observation.monitor[13], false])
            item.suggestsRec.push([recommendation.monitor[8], false])
          }
          item.suggestsObs.push([observation.monitor[33], false])
          item.suggestsObs.push([observation.monitor[34], false])
          item.suggestsObs.push([observation.monitor[35], false])
          item.suggestsObs.push([observation.monitor[36], false])
          item.suggestsObs.push([observation.monitor[37], false])
          item.suggestsObs.push([observation.monitor[38], false])
          item.suggestsObs.push([observation.monitor[39], false])
          item.suggestsObs.push([observation.monitor[40], false])
          item.suggestsObs.push([observation.monitor[41], false])
          item.suggestsRec.push([recommendation.monitor[12], false])
          item.suggestsRec.push([recommendation.monitor[13], false])
        }
        if (item.type === 'TELEPHONE') {
          if (userD.telephone.find(a => a.includes('headset'))) {
            item.suggestsObs.push([observation.telephone[0], false])
            item.suggestsObs.push([observation.telephone[1], false])
            item.suggestsRec.push([recommendation.telephone[3], false])
          }
          if (userD.telephone.find(a => a.includes('too far'))) {
            item.suggestsObs.push([observation.telephone[2], false])
            item.suggestsRec.push([recommendation.telephone[0], false])
          }
          if (userD.telephone.find(a => a.includes('shoulder'))) {
            item.suggestsObs.push([observation.telephone[3], false])
            item.suggestsObs.push([observation.telephone[4], false])
            item.suggestsRec.push([recommendation.telephone[1], false])
            item.suggestsRec.push([recommendation.telephone[2], false])
          }
          if (userD.telephone.find(a => a.includes('handsfree'))) {
            item.suggestsObs.push([observation.telephone[5], false])
            item.suggestsRec.push([recommendation.telephone[3], false])
          }
        }
        if (item.type === 'MOUSE') {
          if (userD.mouse.find(a => a.includes('shoulders'))) {
            item.suggestsObs.push([observation.mouse[0], false])
          }
          if (userD.mouse.find(a => a.includes('reaching'))) {
            item.suggestsObs.push([observation.mouse[1], false])
            item.suggestsObs.push([observation.mouse[2], false])
            item.suggestsRec.push([recommendation.mouse[0], false])
          }
          if (userD.mouse.find(a => a.includes('different'))) {
            item.suggestsObs.push([observation.mouse[3], false])
            item.suggestsRec.push([recommendation.mouse[1], false])
          }
          if (userD.mouse.find(a => a.includes('pinch'))) {
            item.suggestsObs.push([observation.mouse[4], false])
            item.suggestsObs.push([observation.mouse[5], false])
            item.suggestsRec.push([recommendation.mouse[2], false])
          }
          if (userD.mouse.find(a => a.includes('palm'))) {
            item.suggestsObs.push([observation.mouse[6], false])
          }
          item.suggestsRec.push([recommendation.mouse[9], false])
        }
        if (item.type === 'KEYBOARD') {
          if (userD.keyboard.find(a => a.includes('straight'))) {
            item.suggestsObs.push([observation.keyboard[0], false])
          }
          if (userD.keyboard.find(a => a.includes('extended'))) {
            item.suggestsObs.push([observation.keyboard[1], false])
            item.suggestsRec.push([recommendation.keyboard[0], false])
          }
          if (userD.keyboard.find(a => a.includes('deviate'))) {
            item.suggestsObs.push([observation.keyboard[2], false])
            item.suggestsRec.push([recommendation.keyboard[1], false])
            item.suggestsRec.push([recommendation.keyboard[2], false])
          }
          if (userD.keyboard.find(a => a.includes('too high'))) {
            item.suggestsObs.push([observation.keyboard[3], false])
            item.suggestsObs.push([observation.keyboard[3], false])
            item.suggestsRec.push([recommendation.keyboard[3], false])
          }
          if (userD.keyboard.find(a => a.includes('overhead'))) {
            item.suggestsObs.push([observation.keyboard[5], false])
            item.suggestsRec.push([recommendation.keyboard[4], false])
            item.suggestsRec.push([recommendation.keyboard[5], false])
          }
          if (userD.keyboard.find(a => a.includes('adjustable'))) {
            item.suggestsObs.push([observation.keyboard[6], false])
            item.suggestsRec.push([recommendation.keyboard[6], false])
          }
          item.suggestsRec.push([recommendation.keyboard[12], false])
        }
        if (item.type === 'SOFT TISSUE') {
          if (userD.softTissueCompression.find(a => a.includes('any point'))) {
            item.suggestsObs.push([observation.softTissue[0], false])
          }
          if (userD.softTissueCompression.find(a => a.includes('typing'))) {
            item.suggestsObs.push([observation.softTissue[1], false])
            item.suggestsObs.push([observation.softTissue[2], false])
            item.suggestsObs.push([observation.softTissue[3], false])
            item.suggestsObs.push([observation.softTissue[4], false])
            item.suggestsRec.push([recommendation.softTissue[0], false])
          }
          if (userD.softTissueCompression.find(a => a.includes('red mark'))) {
            item.suggestsObs.push([observation.softTissue[5], false])
            item.suggestsRec.push([recommendation.softTissue[1], false])
            item.suggestsRec.push([recommendation.softTissue[2], false])
            item.suggestsRec.push([recommendation.softTissue[3], false])
          }
          item.suggestsRec.push([recommendation.softTissue[4], false])
        }
        if (item.type === 'MISCELLANEOUS') {
          item.suggestsRec.push([recommendation.miscellaneous[22], false])
          item.suggestsRec.push([recommendation.miscellaneous[23], false])
        }
      })
    }

    let observationCount = 0
    let recommendationCount = 0
    let annotateCurrentNum = 0
    diagnosis.forEach(function (item, i) {
       // Collapse all data in observations/recommendations
      item.observationImg = ICONS.EDIT;
      item.recommendationImg = ICONS.EDIT;

      observationCount += item.suggestsObs.length
      recommendationCount += item.suggestsRec.length
      if (Number(item.annotateNum) > 0) {
        annotateCurrentNum++
      }
    })
    this.setState({
      barData: barD,
      diagnosis: this.sortDiagnosis(diagnosis),
      observationCount: observationCount,
      recommendationCount: recommendationCount,
      annotateCurrentNum: annotateCurrentNum
    })


    // Event close modal
    document.onkeydown = (evt) => {
    if (evt.keyCode === 27) {
      // Escape key pressed
      this.setState({
        ...this.state,
        viewReport: false,
        isShowModalReviewerSelector: false,
        isShowSavedSuccess: false,
        isShowSentSuccess: false,
        isShowModalChangeSeverityScore: false,
      });
    }
  };
  }

  handleGoniometerReadings = (data) => {
    return data.data.goniometerReadings.measurements.reduce((acc, reading, index) => {
      const {title, image, value, bodyPart} = reading;
      
      let sittingSide, readingValue;
      Object.keys(value).map((side, index) => {
          if(typeof value[side] === "number") {
              readingValue = value[side];
              sittingSide = side;
          }
          return value;
      });
      if(typeof value['left'] === "number" && typeof value['right'] === "number" && value['left'] > value['right']) {
        readingValue = value['right'];
        sittingSide = 'right';
      }
      if(typeof value['left'] === "number" && typeof value['right'] === "number" && value['left'] < value['right']) {
        readingValue = value['left'];
        sittingSide = 'left';
      }
        
      const goniometerReading = {};
      goniometerReading["id"]=`goniometer_reading_${index+1}`;
      goniometerReading["name"]=title;
      goniometerReading["image"]=image.data;
      goniometerReading["value"]=sittingSide ? `${readingValue}` : "null";
      goniometerReading["bodyPart"]=bodyPart;
      goniometerReading["side"]=sittingSide ? sittingSide.charAt(0).toUpperCase() + sittingSide.slice(1) : "";
      
      acc.push(goniometerReading);
      return acc;
    }, [])
  }

  getGoniometerReadings = async (key, imageUrl) => {
    try {
      this.setState({
        ...this.state,
        isGoniometerReadingsLoading: true,
        isErrorInGoniometerReadingRequest: false
      });
      const data = {
        ewaId: this.props.match.params.ewaId,
        imageUrl: imageUrl,
        key: key
      }

      const response = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/provider/goniometer', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({data}),
        credentials: 'include',
      });

      if(response.ok) {
        const json = await response.json();
        const goniometers = this.handleGoniometerReadings(json);
        this.setState({
          ...this.state,
          goniometerReadings: {
            ...this.state.goniometerReadings,
            goniometers,
          },
          isGoniometerReadingGenetared: true,
          isGoniometerReadingsLoading: false,
          isErrorInGoniometerReadingRequest: false
        });
      } else {
        throw new Error("Error while requsting goniometer readings");
      }
    } catch (error) {
      this.setState({
        ...this.state,
        isGoniometerReadingsLoading: false,
        isErrorInGoniometerReadingRequest: true
      });
      console.log("Error Message :::",error.message);
    }
  }

  getEWAData = () => {
    const that = this
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      let isUnauthorizedUser = helpers.unauthorizedResponseHelpers.isUnauthorizedResponse({statusCode: this.status});
      if (isUnauthorizedUser) {
        helpers.unauthorizedResponseHelpers.redirectToLoginPage({
          isProvider: true,
          windowLocation: window.location,
        });

        return;
      }

      if (this.readyState === 4) {
        that.handleEWAData(JSON.parse(this.responseText));
      }
    })

    xhr.open('POST', process.env.REACT_APP_API_URL + '/api/ewa/getEWAById')
    xhr.setRequestHeader('Content-Type', 'application/json')
    const id = that.props.match.params.ewaId || localStorage.getItem('ewaId')
    if (id) {
      if (localStorage.getItem('sensical')) {
        localStorage.removeItem('ewaId')
      } else {
        localStorage.setItem('ewaId', id)
      }
    }
    xhr.send(JSON.stringify({id}))
  }

  getDoctorData = () => {
    const that = this
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      let isUnauthorizedUser = helpers.unauthorizedResponseHelpers.isUnauthorizedResponse({statusCode: this.status});
      if (isUnauthorizedUser) {
        helpers.unauthorizedResponseHelpers.redirectToLoginPage({
          isProvider: true,
          windowLocation: window.location,
        });

        return;
      }

      if (this.readyState === 4) {
        that.setState({currentUser: JSON.parse(this.responseText)})
      }
    })

    xhr.open('GET', process.env.REACT_APP_API_URL + '/api/auth/provider/profile')
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send()
  }

  componentDidMount () {
    this.getEWAData()
    this.getDoctorData()
  }

  sendReport = () => {
    this.setState({ sendReport: 'SENDING' })
    let that = this
    let reportJSON = { ...this.state.userData }

    let newDiagnosis = [];
    this.state.diagnosis.forEach(function (item, i) {
      let diagnosisDetail = {
        type: item.type,
        observation: item.observation,
        recommendation: item.recommendation,
        // annotateNum: item.annotateNum,
        annotateNum: i + 1, // Change to increment number
        annotateCoord: item.annotateCoord
      }
      // if (item.annotateImg === 'image1') {
      //   diagnosis[0].observations.push(diagnosisDetail)
      // }
      // if (item.annotateImg === 'image2') {
      //   diagnosis[1].observations.push(diagnosisDetail)
      // }
      // if (item.annotateImg === 'image3') {
      //   diagnosis[2].observations.push(diagnosisDetail)
      // }
      // if (item.annotateImg === 'image4') {
      //   diagnosis[3].observations.push(diagnosisDetail)
      // }

      /* We do not map observations into image workplace anymore, 
        the observations now are a common information
      */
      newDiagnosis.push(diagnosisDetail);
    })
    delete reportJSON['diagnosis']
    reportJSON.doctor = this.state.currentUser
    reportJSON.diagnosis = newDiagnosis;
    reportJSON.isSendRecommendationUrl = this.state.userData.isSendRecommendationUrl;
    reportJSON.isSendResourceUrl = this.state.userData.isSendResourceUrl;

    fetch(process.env.REACT_APP_API_URL + '/api/report/export', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(reportJSON),
      credentials: 'include',
    })
      .then((res) => {
        let isUnauthorizedUser = !res.ok && helpers.unauthorizedResponseHelpers.isUnauthorizedResponse({
          statusCode: res.status,
        });

        if (isUnauthorizedUser) {
          helpers.unauthorizedResponseHelpers.redirectToLoginPage({
            isProvider: true,
            windowLocation: window.location,
          });

          return;
        }
        return res.blob()
      })
      .then(res => {
        // var blob = new Blob([res], { type: 'application/pdf' })
        // saveAs(blob, 'report.pdf')
         that.saveProgress('send')
      })
  }

  saveProgress = async (from) => {
    let userData = this.state.userData;
    const date = format(new Date(), 'MMMM d, yyyy');
    const goniometers = this.state.goniometerReadings;
    const { imageReUploadRequestList, reUploadRequestAdditionalComments, reUploadRequestedDate }= this.state;
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json');

    if(from === "sendReUploadImageData" && imageReUploadRequestList.length > 0) {
      userData.reUploadWorkstationImages = imageReUploadRequestList;
      userData.reUploadWorkstationImagesStatus = true;
      userData.reUploadRequestAdditionalComments = reUploadRequestAdditionalComments;
      userData.reUploadRequestUser = this.state.currentUser.email
      userData.reUploadRequestedDate = date;
        this.setState({
          reUploadRequestedDate: date
        });

      if(reUploadRequestedDate.length !== 0) {
        userData.reUploadReminderDate = date;
        this.setState({
          reUploadReminderDate: date
        });
      }
    } else {
      if(goniometers.length !== 0) {
        userData.goniometers = [goniometers];
      }
      userData.status = from === 'send' ? 'complete' : 'continue'
      userData.diagnosis = this.state.diagnosis
      if (userData.diagnosis && userData.diagnosis[4].type === 'MONITOR') {
        userData.diagnosis[4].type = 'MONITOR/LAPTOP'
      }
      userData.isSendRecommendationUrl = this.state.userData.isSendRecommendationUrl;
      userData.isSendResourceUrl = this.state.userData.isSendResourceUrl;

      delete userData.goniometerInputImagePath
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(userData),
      redirect: 'follow',
      credentials: 'include',
    };

    try {
      const res = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/updateEWAbyId', requestOptions);

      let isUnauthorizedUser = !res.ok && helpers.unauthorizedResponseHelpers.isUnauthorizedResponse({
        statusCode: res.status,
      });

      if (isUnauthorizedUser) {
        helpers.unauthorizedResponseHelpers.redirectToLoginPage({
          isProvider: true,
          windowLocation: window.location,
        });

        return;
      }

      if (!res.ok) {
        return;
      }

      // eslint-disable-next-line eqeqeq
      if (userData.status == EwaStatus.COMPLETED) { 
        this.setState({
          ...this.state,
          viewReport: false,
          isShowSentSuccess: true,
          sendReport: i18n.sendReport,
        });

        return res;
      }

      this.setState({
        ...this.state,
        viewReport: false,
        isShowSavedSuccess: from !== 'auto' && from !== 'sendReUploadImageData',
      })

      return res;
    } catch(error) {
      console.log("ERROR :::", error);
    }
  }

  onStop = (e, ui) => {
    let diagnosis = this.state.diagnosis
    let x = parseInt(ui.x) > 272 ? parseInt(ui.x) - 272 : ui.x
    let y = parseInt(ui.y) > 272 ? parseInt(ui.y) - 272 : ui.y
    diagnosis[this.state.annotateObservationId].annotateCoord = [x, y]
    diagnosis[this.state.annotateObservationId].annotateImg =
      parseInt(ui.x) > 272 && parseInt(ui.y) > 272
        ? 'image4'
        : parseInt(ui.x) > 272 && parseInt(ui.y) <= 272
        ? 'image2'
        : parseInt(ui.x) <= 272 && parseInt(ui.y) > 272
        ? 'image3'
        : 'image1'
    this.setState({ diagnosis: diagnosis, showDraggable: false })
  }

  arrowToggle = (sectionName, num) => {
    if (sectionName === 'section1') {
      this.setState({ section1: !this.state.section1 })
    }
    if (sectionName === 'section2') {
      this.setState({ section2: !this.state.section2 })
    }
    if (sectionName === 'section3') {
      this.setState({ section3: !this.state.section3 })
    }
    if (sectionName === 'section4') {
      this.setState({ section4: !this.state.section4 })
    }
    if (sectionName === 'section5') {
      this.setState({ section5: !this.state.section5 })
    }
    if (sectionName === 'section6') {
      this.setState({ section6: !this.state.section6 })
    }
    if (sectionName === 'recommendation') {
      let diagnosis = this.state.diagnosis
      diagnosis[num].recommendationImg =
        diagnosis[num].recommendationImg === 'down'
          ? 'tick'
          : diagnosis[num].recommendationImg === 'tick'
          ? 'edit'
          : 'tick'
      this.setState({
        diagnosis: diagnosis
      })
      document.getElementById('recommendation' + num).focus()
    }
    if (sectionName === 'observation') {
      let diagnosis = this.state.diagnosis
      let annotateCurrentNum =
        diagnosis[num].observationImg === 'down'
          ? this.state.annotateCurrentNum + 1
          : this.state.annotateCurrentNum
      let showDraggable =
        diagnosis[num].observationImg === 'down' ? true : false
      if (diagnosis[num].observationImg === 'down') {
        diagnosis[num].annotateNum = annotateCurrentNum
      }

      diagnosis[num].observationImg =
        diagnosis[num].observationImg === 'down'
          ? 'tick'
          : diagnosis[num].observationImg === 'tick'
          ? 'edit'
          : 'tick'
      this.setState({
        diagnosis: this.sortDiagnosis(diagnosis),
        annotateCurrentNum: annotateCurrentNum,
        annotateObservationId: num,
        // showDraggable: showDraggable,
        section1: false,
        section2: true,
        section3: false,
        section4: false,
        section5: false,
        section6: false
      }, () => {
        /* 
          annotateCurrentNum start from 0. annotateCurrentNum was added 1 above
          num start from 0
        */ 
        if (annotateCurrentNum <= num + 1) {
          let newNum = annotateCurrentNum - 1; // reset from 0
          document.getElementById('observation' + newNum)?.focus();
        } else { //focus on existed num
          document.getElementById('observation' + num)?.focus();
        }
      })
    }
  }

  addToInput = (observationNum, suggestNum, type) => {
    let diagnosis = this.state.diagnosis
    if (type === 'observation') {
      diagnosis[observationNum].suggestsObs[suggestNum][1] =
        !diagnosis[observationNum].suggestsObs[suggestNum][1]
      diagnosis[observationNum].observation = diagnosis[observationNum]
        .suggestsObs[suggestNum][1]
        ? diagnosis[observationNum].observation +
          '\n' +
          diagnosis[observationNum].suggestsObs[suggestNum][0]
        : diagnosis[observationNum].observation.replace(
            diagnosis[observationNum].suggestsObs[suggestNum][0],
            ''
          )
    } else {
      diagnosis[observationNum].suggestsRec[suggestNum][1] =
        !diagnosis[observationNum].suggestsRec[suggestNum][1]
      diagnosis[observationNum].recommendation = diagnosis[observationNum]
        .suggestsRec[suggestNum][1]
        ? diagnosis[observationNum].recommendation +
          '\n' +
          diagnosis[observationNum].suggestsRec[suggestNum][0]
        : diagnosis[observationNum].recommendation.replace(
            diagnosis[observationNum].suggestsRec[suggestNum][0],
            ''
          )
    }
    this.setState({
      diagnosis: diagnosis
    })
  }

  addToInputMisc = (observationNum, suggestNum, type) => {
    let diagnosis = this.state.diagnosis
    if (type === 'observation') {
      diagnosis[observationNum].suggestsObsMisc[suggestNum][1] =
        !diagnosis[observationNum].suggestsObsMisc[suggestNum][1]
      
      let concatObservations = diagnosis[observationNum].suggestsObsMisc
        .map(e => e[1] === true ? e[0] : null)
        .filter(e => e)
        .join('\n').trim();

      diagnosis[observationNum].observation = concatObservations;
    } else {
      diagnosis[observationNum].suggestsRecMisc[suggestNum][1] =
        !diagnosis[observationNum].suggestsRecMisc[suggestNum][1]
      
      let concatRecommendations = diagnosis[observationNum].suggestsRecMisc
        .map((e) => (e[1] === true ? e[0] : null))
        .filter((e) => e)
        .join('\n').trim();

      diagnosis[observationNum].recommendation = concatRecommendations;
    }
    this.setState({
      diagnosis: diagnosis
    })
  }

  inputCapture = (observationNum, txt, type) => {
    let diagnosis = this.state.diagnosis

    if (type === 'observation') {
      diagnosis[observationNum].observation = txt
      let suggestsObsMisc = []
      observationMisc[Object.keys(observationMisc)[observationNum]]?.map(
        function (searchLookup) {
          if (searchLookup.toLowerCase().includes(txt.toLowerCase())) {
            suggestsObsMisc.push([searchLookup, false])
          }
        }
      )
      diagnosis[observationNum].suggestsObsMisc = suggestsObsMisc
    } else {
      diagnosis[observationNum].recommendation = txt
      let suggestsRecMisc = []
      recommendationMisc[Object.keys(recommendationMisc)[observationNum]]?.map(
        function (searchLookup) {
          if (searchLookup.toLowerCase().includes(txt.toLowerCase())) {
            suggestsRecMisc.push([searchLookup, false])
          }
        }
      )
      diagnosis[observationNum].suggestsRecMisc = suggestsRecMisc
    }
    
    this.setState({ diagnosis: diagnosis })
  }


  showModalReviewerSelector = () => {
    this.saveProgress('auto')
    this.setState({ ...this.state, isShowModalReviewerSelector: true });
  }

  closeModalReviewerSelector = () => {
    this.setState({ ...this.state, isShowModalReviewerSelector: false });
  }

  showModalChangeSeverityScore = () => {
    this.setState({ ...this.state, isShowModalChangeSeverityScore: true });
  }
  closeModalChangeSeverityScore = () => {
    this.setState({ ...this.state, isShowModalChangeSeverityScore: false });
  }

  computedInitialEvaluator = () => {
    return this.state.userData?.ewaHistories?.[0]?.initialEvaluator ?? Constants.ModelDefault.provider;
  }
  isShowActionSaveProgress = () => {
    if(this.isCompletedEwa()){
      return false;
    }

    if (!this.state.userData.ewaHistories?.length) {
      return true;
    }
      if (!this.state.currentUser) {
        return false;
      }

    let isReviewerThisReport = false;

    this.state.userData.ewaHistories.map(ewaHistory => {
      isReviewerThisReport = this.state.currentUser.id === ewaHistory?.reviewer?._id;
    })

    return this.state.userData.status?.toLowerCase() !== Constants.EwaStatus.COMPLETED &&
      this.state.userData.isSendReportAble && isReviewerThisReport;
  }

  isShowActionSendToReviewer = () => {
    if (this.isCompletedEwa()) {
      return false;
    }
    if (!this.state.userData.ewaHistories?.length) {
      return true;
    }
    let isInitialEvaluator = false;

    isInitialEvaluator =
      [
        Constants.EwaStatus.CONTINUE.toLowerCase(),
        Constants.EwaStatus.REVIEW.toLowerCase()
      ].includes(
        this.state.userData.status?.toLowerCase()
      ) && !this.state.userData.isSendReportAble;
    
    // eslint-disable-next-line array-callback-return
    this.state.userData.ewaHistories.map((ewaHistory) => {
      isInitialEvaluator = !ewaHistory?.reviewer?._id;
    })

    return isInitialEvaluator;
  }

  sortDiagnosis = (diagnosis) => {
    return diagnosis.sort((a, b) => {
        if (!a.annotateNum) return 1;
        if (!b.annotateNum) return -1;

        return a.annotateNum - b.annotateNum;
    })
  }

  isCompletedEwa = () => {
    return this.state.userData.status?.toLowerCase() ===
      Constants.EwaStatus.COMPLETED.toLowerCase();
  }
  isChangeableImageWorkplace = () => {
    return this.isShowActionSaveProgress() && !this.isCompletedEwa();
  }

  isChangeableEwaScore = () => {
    return this.isShowActionSaveProgress() && !this.isCompletedEwa();
  }

  handleChangeWorkPlaceImage = (key, newImageBase64) => {
    this.setState({
      ...this.state,
      userData : {
        ...this.state.userData,
        [key]: newImageBase64,
      }
    })
  }

  handleGoniometerInputImageChange = async (key, newImageBase64) => {
    try {
      this.setState({
        ...this.state,
        isGoniometerReadingsLoading: true,
        isErrorInGoniometerReadingRequest: false
      });

      const ewaId = this.state.userData._id;
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/handleGoniometerInputImageChange', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          ewaId,
          [key]: newImageBase64
        }),
        credentials: 'include',
      });

      const {goniometerInputImagePath, goniometerInputImageUrl} = await response.json();

      this.setState({
        ...this.state,
        goniometerReadings: {
          ...this.state.goniometerReadings,
          goniometerInputImagePath,
          goniometerInputImageUrl
        },
      });

      this.getGoniometerReadings(goniometerInputImagePath)
    } catch (error) {
      console.log("Error Message :::",error.message);
    }
  }


  computedBreakInfo = () => {
    const ewa = this.state.userData;
    
    if (!ewa?.totalWorkTime || !ewa?.numberOfBreaks) { 
      return '';
    }

    const takeBreakEvery = ewa.totalWorkTime / ewa.numberOfBreaks;

    const breakOnEveryHrs = Math.floor(takeBreakEvery / 60);
    const breakOnEveryMins = Math.round(takeBreakEvery - (breakOnEveryHrs * 60));


        return ewa.totalBreakTime
          ? [
              Math.round(ewa.totalBreakTime / ewa.numberOfBreaks),
              i18n.minutes,
              i18n.every,
              breakOnEveryHrs,
              i18n.hours,
              i18n.and,
              breakOnEveryMins,
              i18n.minutes,
            ].join(' ')
          : '';
  }

  computedEwaScoreDescription = () => {
    const userData = this.state.userData;

    const content = userData.ewaScoreDescription ?
      userData.ewaScoreDescription :
      (Number(userData.ewaScore) <= 5 ?
        i18n.assessment.workAssessment.severityScoreLow
        : i18n.assessment.workAssessment.severityScoreHigh);
  
    return content;
  }

  handleUpdateSeverityScore = (ewaScore, ewaScoreDescription) => {
    this.setState({
      ...this.state,
      isShowModalChangeSeverityScore: false,
      userData: {
        ...this.state.userData,
        ewaScore: ewaScore,
        ewaScoreDescription: ewaScoreDescription,
      },
    });
  }

  isEmptyDiagnosis = (diagnosis = []) => {
    let isEmpty = true;
    diagnosis.map((d) => {
      if (d.observation) { 
        isEmpty = false;
      }

      return d;
    })

    return isEmpty;
  }

  renderObservationReportModal = () => {
    let incrementObs = 1; 

    return this.state.diagnosis?.map(function (observation, i) {
      if (
        observation.observationImg === 'edit' &&
        observation.observation
      ) {
        return (
          <div
            key={'ReportImages2' + i}
            className='margLeft8px'
          >
            <div className='flex alignItemsCenter'>
              <div className='toreaBay-bg linenImageCircle white posRelative'>
                  {incrementObs++}
              </div>
              <div className='h10'>
                {observation.type}
              </div>
            </div>
            <div className='h12 obsTxt pre-wrap--break-line'>
              {observation.observation}
            </div>
          </div>
        );
      } else {
        return <div key={'ReportImages2' + i}></div>;
      }
    });
  }
  renderRecommendedReportModal = () => {
    let incrementRecommend = 1; 

    return this.state.diagnosis?.map(function (observation, i) {
                      if (observation.observationImg === 'edit' && observation.recommendation) {
                        return (
                          <div
                            key={'ReportImages2' + i}
                            className='margLeft8px'
                          >
                            <div className='flex alignItemsCenter'>
                              <div className='toreaBay-bg linenImageCircle white posRelative'>
                                {incrementRecommend++}
                                {/* {observation.annotateNum} */}
                              </div>
                              <div className='h10'>{observation.type}</div>
                            </div>
                            <div className='h12 obsTxt pre-wrap--break-line'>
                              {observation.recommendation}
                            </div>
                          </div>
                        );
                      } else {
                        return <div key={'ReportImages2' + i}></div>
                      }
    })
  }

  handleReUploadImageSelection = (workstationImage) => {
    const { imageReUploadRequestStatus } = this.state;
    this.setState({
      imageReUploadRequestStatus: {
        ...imageReUploadRequestStatus,
        [workstationImage]: !imageReUploadRequestStatus[workstationImage]
      },
      isImageReUploadRequestListEmpty: false
    });
  }

  prepareImageReUploadRequestListAndSendMail = () => {
    const { workstationSide, workstationBack, userSide, userBack } = this.state.imageReUploadRequestStatus;
    const imageReUploadRequestList = [];

    if(workstationSide) {
      imageReUploadRequestList.push("workstationSide");
    }
    if(workstationBack) {
      imageReUploadRequestList.push("workstationBack");
    }
    if(userSide) {
      imageReUploadRequestList.push("userSide");
    }
    if(userBack) {
      imageReUploadRequestList.push("userBack");
    }

    if(imageReUploadRequestList.length === 0) {
      this.setState({
        isImageReUploadRequestListEmpty: true
      });
    } else {
      this.setState({
        imageReUploadRequestList,
        isImageReUploadRequestListEmpty: false,
       }, async () => {
        const response = await this.saveProgress("sendReUploadImageData");

        if(response?.ok) {
          this.mailRequestToChangeWorkstationImages();
          this.setState({
            isErrorWhileStoringReUploadData: false
          });
        } else {
          this.setState({
            isErrorWhileStoringReUploadData: true,
          });
        }
       });
    }
    return;
  }

  mailRequestToChangeWorkstationImages = async () => {
    try {
      this.setState({
        isSendingReUploadRequestMail: true
      });

      const { currentUser, userData, reUploadRequestAdditionalComments, imageReUploadRequestList } = this.state;
      const isAdditionalComments = reUploadRequestAdditionalComments && reUploadRequestAdditionalComments.length > 0;
      const data = {
        email: userData.customer.email,
        customer: {
          firstname: userData.customer.firstname,
          lastname: userData.customer.lastname
        },
        provider: {
          firstname: currentUser.firstname,
          lastname: currentUser.lastname
        },
        comments: reUploadRequestAdditionalComments,
        isAdditionalComments,
        workstationSide: imageReUploadRequestList.includes("workstationSide"),
        workstationBack: imageReUploadRequestList.includes("workstationBack"),
        userSide: imageReUploadRequestList.includes("userSide"),
        userBack: imageReUploadRequestList.includes("userBack"),
        reUploadReminderDate: format(new Date(), 'MMMM d, yyyy'),
        ewaId: this.state.userData._id
      }
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/user/workstationImageChangeRequestMail', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      });

      if(response?.ok) {
        this.setState({
          isReUploadImageRequestMailSent: true,
          viewReUploadImageSection: false,
          isSendingReUploadRequestMail: false,
          isErrorWhileSendingReUploadMail: false
        });
      } else {
        throw new Error("Error while sending image change request");
      }
    } catch(error) {
      console.log("ERROR :::",error.message);
      this.setState({
        isSendingReUploadRequestMail: false,
        isErrorWhileSendingReUploadMail: true
      });
    }
  }

  render () {
    let that = this
    let userData = this.state.userData
    const dragHandlers = { onStop: this.onStop }
    const { goniometers } = this.state.goniometerReadings;

    if (!userData?._id) {
      return <LoadingView />
    }

    return (
      <div>
        {!localStorage.getItem('sensical') ? (
          <Redirect to='/ewadminlogin' />
        ) : (
          <div
            className='container landing noScroll'
            style={{ backgroundImage: `url(${bgreportbuilder})` }}
          >
            <div className='toreaBay-bg leftPanelFrameRB'>
              <div className='width100 profileFrameRB'>
                <div className='profileImg white-bg'>
                  <img alt='' src={drsharan} className='profileImg' />
                </div>
              </div>
            </div>
            <div className='rightPanelFrameRB'>
              <div className='landingHeader'>
                <div className='flex alignItemsCenter'>
                  <Link to='/ewadminlanding'>
                    <img className='backArrow' src={back} alt='' />
                  </Link>
                  <div>
                    <div className='h3'>EWA Request</div>
                      {this.computedInitialEvaluator()._id &&
                        <div className='h4'>
                          Report Created by:
                          {` ${this.computedInitialEvaluator().prefix} 
                          ${this.computedInitialEvaluator().firstname} 
                          ${this.computedInitialEvaluator().lastname}`}
                        </div>
                      }
                      {this.state.userData.status === Constants.EwaStatus.COMPLETED.toLowerCase() &&
                        <div className='h4'>
                          Evaluated by:
                          {this.state.userData.ewaHistories.map(e => {
                            let reviewer = e?.reviewer;
                            return <> {`${reviewer?.prefix} ${reviewer?.firstname} ${reviewer?.lastname}`}<br/></>
                          })}
                        </div>
                      }
                  </div>
                </div>
                <div>
                  <Link
                    to='#'
                    onClick={() => {
                      this.setState({ viewReport: true });
                    }}
                    className='btnReport cta-btn__view-report'
                  >
                    VIEW REPORT
                  </Link>
                  { this.isShowActionSaveProgress() && (
                      <Link
                        to='#'
                        onClick={() => this.saveProgress('save')}
                        className='toreaBay-bg btn white cta-btn'
                      >
                        SAVE PROGRESS
                      </Link>
                    )}

                  { this.isShowActionSendToReviewer() && (
                    <Link
                      to='#'
                      onClick={this.showModalReviewerSelector}
                      className='toreaBay-bg btn white margLeft8px cta-btn'
                    >
                      SEND TO REVIEW
                    </Link>
                  )}
                </div>
              </div>
              <div className='flex'>
                <div className='whiteBoxBuilderLeft white-bg height80 padding2'>
                  <div className='margin32px'>
                    <div
                      className='flex justifySB alignItemsCenter pointer'
                      onClick={() => this.arrowToggle('section1')}
                    >
                      <div className='h11 toreaBay marg16px'>OVERVIEW</div>
                      <img
                        className='arrow'
                        src={this.state.section1 ? up : down}
                        alt=''
                      />
                    </div>
                    {this.state.section1 ? (
                      <div>
                        <PatientPersonalInfo ewa={this.state.userData} />
                          
                        <br/>
                        <hr />
                          
                        <div className='flex justify-content-between'>
                          <div>
                            <br />
                            <div className={'overview-severity'}>
                              <div className='toreaBay h9 overview-severity__header'>Severity Score</div>
                              {this.isChangeableEwaScore() &&  <EditableIcon onClick={this.showModalChangeSeverityScore}/>}
                            </div>
                              <br />
                            <div className='toreaBay h11 overview-severity__content'>
                              {this.computedEwaScoreDescription()}
                            </div>
                            <br />
                          </div>
                          <div className='doughnutChart'>
                            <div className='black h3 doughnutChart__content' key={`${userData?.ewaScore}_table`}>
                              <CircularProgress
                                score={userData?.ewaScore}
                                data={[userData?.ewaScore ?? 0, 100 - userData?.ewaScore ?? 0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <hr />
                    <div
                      className='flex justifySB alignItemsCenter pointer'
                      onClick={() => this.arrowToggle('section2')}
                    >
                      <div className='h11 toreaBay marg16px'>
                        WORKSTATION PICTURES
                      </div>
                      <img
                        className='arrow'
                        src={this.state.section2 ? up : down}
                        alt=''
                      />
                    </div>
                    <div
                        className={this.state.section2 ? 'fadeIn workstationImageContainer' : 'fadeOut'}
                      >
                    { this.isEmptyDiagnosis(this.state.diagnosis) ? (
                      <>
                        {this.state.showDraggable ? (
                          <Draggable {...dragHandlers}>
                            <div className='dragImg'>
                              <img src={drag} alt='drag' />
                              <div className='h14 markBox'>
                                MARK REGION FOR
                                <br />
                                {this.state.annotateCurrentNum}.
                                {
                                  this.state.diagnosis[
                                    this.state.annotateObservationId
                                  ].type
                                }
                              </div>
                            </div>
                          </Draggable>
                        ) : (
                          ''
                        )}
                        <div className='flex'>
                          <div className='imgWP'>
                            {this.state.diagnosis?.map(function (
                              observation,
                              i
                            ) {
                              if (observation.annotateImg === 'image1') {
                                return (
                                  <div
                                    key={'linenImages1' + i}
                                    className='linenImageCircle white-bg'
                                    style={{
                                      top: observation.annotateCoord[1],
                                      left: observation.annotateCoord[0]
                                    }}
                                  >
                                    {observation.annotateNum}
                                  </div>
                                )
                              } else {
                                return <div key={'linenImages1' + i}></div>
                              }
                            })}
                              <ImageChangeable
                                isChangeable={!this.state.imageReUploadRequestList.includes("workstationSide") && this.isChangeableImageWorkplace()}
                                keyImage={'WorkstationSide'}
                                src={userData?.workstationSideImageUrl ?? userData.workstationSide}
                                alt='Workstation Side'
                                onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage("workstationSide", newImageBase64)}
                                isRequestedToReupload={this.state.imageReUploadRequestList.includes("workstationSide") && this.isChangeableImageWorkplace()}
                              />
                          </div>
                          <div className='imgWP'>
                            {this.state.diagnosis?.map(function (
                              observation,
                              i
                            ) {
                              if (observation.annotateImg === 'image2') {
                                return (
                                  <div
                                    key={'linenImages2' + i}
                                    className='linenImageCircle white-bg'
                                    style={{
                                      top: observation.annotateCoord[1],
                                      left: observation.annotateCoord[0]
                                    }}
                                  >
                                    {observation.annotateNum}
                                  </div>
                                )
                              } else {
                                return <div key={'linenImages2' + i}></div>
                              }
                            })}
                              <ImageChangeable
                                isChangeable={!this.state.imageReUploadRequestList.includes("workstationBack") && this.isChangeableImageWorkplace()}
                                keyImage={'WorkstationBack'}
                                src={userData?.workstationBackImageUrl ?? userData.workstationBack}
                                alt='Workstation Back'
                                onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('workstationBack', newImageBase64)}
                                isRequestedToReupload={this.state.imageReUploadRequestList.includes("workstationBack") && this.isChangeableImageWorkplace()}
                              />
                          </div>
                        </div>
                        <div className='flex'>
                          <div className='imgWP'>
                            {this.state.diagnosis?.map(function (
                              observation,
                              i
                            ) {
                              if (observation.annotateImg === 'image3') {
                                return (
                                  <div
                                    key={'linenImages3' + i}
                                    className='linenImageCircle white-bg'
                                    style={{
                                      top: observation.annotateCoord[1],
                                      left: observation.annotateCoord[0]
                                    }}
                                  >
                                    {observation.annotateNum}
                                  </div>
                                )
                              } else {
                                return <div key={'linenImages3' + i}></div>
                              }
                            })}
                              <ImageChangeable
                                isChangeable={!this.state.imageReUploadRequestList.includes("userSide") && this.isChangeableImageWorkplace()}
                                keyImage={'UserSideImage'}
                                src={userData?.userSideImageUrl ?? userData.userSideImage}
                                alt='User Side'
                                onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('userSideImage', newImageBase64)}
                                isRequestedToReupload={this.state.imageReUploadRequestList.includes("userSide") && this.isChangeableImageWorkplace()}
                              />
                          </div>
                          <div className='imgWP'>
                            {this.state.diagnosis?.map(function (
                              observation,
                              i
                            ) {
                              if (observation.annotateImg === 'image4') {
                                return (
                                  <div
                                    key={'linenImages4' + i}
                                    className='linenImageCircle white-bg'
                                    style={{
                                      top: observation.annotateCoord[1],
                                      left: observation.annotateCoord[0]
                                    }}
                                  >
                                    {observation.annotateNum}
                                  </div>
                                )
                              } else {
                                return <div key={'linenImages4' + i}></div>
                              }
                            })}
                              <ImageChangeable
                                isChangeable={!this.state.imageReUploadRequestList.includes("userBack") && this.isChangeableImageWorkplace()}
                                keyImage={'UserBackImage'}
                                src={userData?.userBackImageUrl ?? userData.userBackImage}
                                alt='User Back'
                                onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('userBackImage', newImageBase64)}
                                isRequestedToReupload={this.state.imageReUploadRequestList.includes("userBack") && this.isChangeableImageWorkplace()}
                              />
                            </div>
                        </div>
                        <>
                            {
                              this.isChangeableImageWorkplace() &&
                              <>
                                {
                                          this.state.isReUploadImageRequestMailSent 
                                          ? (
                                            <div>
                                              <div className="flex btn_reUploadRequestContainer text_reUploadRequestContainer">
                                              <p className="h9 text_reUploadRequest">Re-upload Requested</p>
                                              <ToolTip
                                                title={
                                                  <div className="h12 reUploadToolTipDetails">
                                                    <p>Re-upload requested on - {this.state.reUploadRequestedDate && this.state.reUploadRequestedDate.length > 0 ? this.state.reUploadRequestedDate : "Not Requested"}</p>
                                                    <p>Reminder sent on - {this.state.reUploadReminderDate && this.state.reUploadReminderDate.length > 0 ? this.state.reUploadReminderDate : "No Reminder"}</p>
                                                    <p>Picture uploaded - {this.state.reUploadCompletionDate && this.state.reUploadCompletionDate.length > 0 ? this.state.reUploadCompletionDate : "Not Completed"}</p>
                                                  </div>
                                                }
                                                placement="right"
                                              >
                                                <img src={clockRotateLeft} alt="clock" className="clockRotateLeft"/>
                                              </ToolTip>
                                              </div>
                                              <div className="flex">
                                                <button onClick={this.mailRequestToChangeWorkstationImages} className="toreaBay sendReUploadReminder">Send Reminder</button>
                                                {this.state.isSendingReUploadRequestMail && <p className="loadingText">Sending Reminder ...</p>}
                                                {
                                                  this.state.isErrorWhileSendingReUploadMail
                                                  && <p className="h9 errorText">Failed: try again!</p>
                                                }
                                              </div>
                                            </div>
                                          )
                                          : (
                                            <div className='btn_reUploadRequestContainer'>
                                            <button
                                            onClick={() => this.setState({viewReUploadImageSection: true})}
                                            className='btnReport btn_reUploadRequest'
                                            >
                                              REQUEST RE-UPLOAD
                                            </button>
                                            <ToolTip
                                              title={
                                                <div className="h12 reUploadToolTipDetails">
                                                 <p>Re-upload requested on - {this.state.reUploadRequestedDate && this.state.reUploadRequestedDate.length > 0 ? this.state.reUploadRequestedDate : "Not Requested"}</p>
                                                 <p>Reminder sent on - {this.state.reUploadReminderDate && this.state.reUploadReminderDate.length > 0 ? this.state.reUploadReminderDate : "No Reminder"}</p>
                                                 <p>Picture uploaded - {this.state.reUploadCompletionDate && this.state.reUploadCompletionDate.length > 0 ? this.state.reUploadCompletionDate : "Not Completed"}</p>
                                                </div>
                                              }
                                              placement="right"
                                            >
                                              <img src={clockRotateLeft} alt="clock" className="clockRotateLeft"/>
                                            </ToolTip>
                                            </div>
                                          )
                                        }
                              </>
                            }
                        </>
                      </>
                    ) : (
                        <div className='flex'>
                          <div>
                            <div className='imgWP'>
                              {this.state.diagnosis?.map(function (
                                observation,
                                i
                              ) {
                                if (observation.annotateImg === 'image1') {
                                  return (
                                    <div
                                      key={'linenImages1' + i}
                                      className='linenImageCircle white-bg'
                                      style={{
                                        top: observation.annotateCoord[1],
                                        left: observation.annotateCoord[0]
                                      }}
                                    >
                                      {observation.annotateNum}
                                    </div>
                                  )
                                } else {
                                  return <div key={'linenImages1' + i}></div>
                                }
                              })}
                                  <ImageChangeable
                                    isChangeable={!this.state.imageReUploadRequestList.includes("workstationSide") && this.isChangeableImageWorkplace()}
                                    keyImage={'WorkstationSide'}
                                    src={userData?.workstationSideImageUrl ?? userData.workstationSide}
                                    alt='Workstation Side'
                                    onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('workstationSide', newImageBase64)}
                                    isRequestedToReupload={this.state.imageReUploadRequestList.includes("workstationSide") && this.isChangeableImageWorkplace()}
                                  />
                            </div>
                            <div className='imgWP'>
                              {this.state.diagnosis?.map(function (
                                observation,
                                i
                              ) {
                                if (observation.annotateImg === 'image2') {
                                  return (
                                    <div
                                      key={'linenImages2' + i}
                                      className='linenImageCircle white-bg'
                                      style={{
                                        top: observation.annotateCoord[1],
                                        left: observation.annotateCoord[0]
                                      }}
                                    >
                                      {observation.annotateNum}
                                    </div>
                                  )
                                } else {
                                  return <div key={'linenImages2' + i}></div>
                                }
                              })}
                                  <ImageChangeable
                                    isChangeable={!this.state.imageReUploadRequestList.includes("workstationBack") && this.isChangeableImageWorkplace()}
                                    keyImage={'WorkstationBack'}
                                    src={userData.workstationBackImageUrl ?? userData.workstationBack}
                                    alt='Workstation Back'
                                    onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('workstationBack', newImageBase64)}
                                    isRequestedToReupload={this.state.imageReUploadRequestList.includes("workstationBack") && this.isChangeableImageWorkplace()}
                                  />
                            </div>
                            <div className='imgWP'>
                              {this.state.diagnosis?.map(function (
                                observation,
                                i
                              ) {
                                if (observation.annotateImg === 'image3') {
                                  return (
                                    <div
                                      key={'ReportImages1' + i}
                                      className='linenImageCircle white-bg'
                                      style={{
                                        top: observation.annotateCoord[1],
                                        left: observation.annotateCoord[0]
                                      }}
                                    >
                                      {observation.annotateNum}
                                    </div>
                                  )
                                } else {
                                  return <div key={'ReportImages1' + i}></div>
                                }
                              })}
                                  <ImageChangeable
                                    isChangeable={!this.state.imageReUploadRequestList.includes("userSide") && this.isChangeableImageWorkplace()}
                                    keyImage={'UserSideImage'}
                                    src={userData.userSideImageUrl ?? userData.userSideImage}
                                    alt='User Side'
                                    onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('userSideImage', newImageBase64)}
                                    isRequestedToReupload={this.state.imageReUploadRequestList.includes("userSide") && this.isChangeableImageWorkplace()}
                                  />
                            </div>
                            <div className='imgWP'>
                              {this.state.diagnosis?.map(function (
                                observation,
                                i
                              ) {
                                if (observation.annotateImg === 'image4') {
                                  return (
                                    <div
                                      key={'linenImages4' + i}
                                      className='linenImageCircle white-bg'
                                      style={{
                                        top: observation.annotateCoord[1],
                                        left: observation.annotateCoord[0]
                                      }}
                                    >
                                      {observation.annotateNum}
                                    </div>
                                  )
                                } else {
                                  return <div key={'linenImages4' + i}></div>
                                }
                              })}
                                  <ImageChangeable
                                    isChangeable={!this.state.imageReUploadRequestList.includes("userBack") && this.isChangeableImageWorkplace()}
                                    keyImage={'UserBackImage'}
                                    src={userData.userBackImageUrl ?? userData.userBackImage}
                                    alt='User Back'
                                    onSelectNewImage={(newImageBase64) => this.handleChangeWorkPlaceImage('userBackImage', newImageBase64)}
                                    isRequestedToReupload={this.state.imageReUploadRequestList.includes("userBack") && this.isChangeableImageWorkplace()}
                                  />
                                </div>
                                <>
                                  {
                                    this.isChangeableImageWorkplace() &&
                                    <>
                                      {
                                        this.state.isReUploadImageRequestMailSent
                                        ? (
                                          <div>
                                            <div className="flex btn_reUploadRequestContainer text_reUploadRequestContainer">
                                            <p className="h9 text_reUploadRequest">Re-upload Requested</p>
                                            <ToolTip
                                              title={
                                                <div className="h12 reUploadToolTipDetails">
                                                  <p>Re-upload requested on - {this.state.reUploadRequestedDate && this.state.reUploadRequestedDate.length > 0 ? this.state.reUploadRequestedDate : "Not Requested"}</p>
                                                  <p>Reminder sent on - {this.state.reUploadReminderDate && this.state.reUploadReminderDate.length > 0 ? this.state.reUploadReminderDate : "No Reminder"}</p>
                                                  <p>Picture uploaded - {this.state.reUploadCompletionDate && this.state.reUploadCompletionDate.length > 0 ? this.state.reUploadCompletionDate : "Not Completed"}</p>
                                                </div>
                                              }
                                              placement="right"
                                            >
                                              <img src={clockRotateLeft} alt="clock" className="clockRotateLeft"/>
                                            </ToolTip>
                                            </div>
                                            <div className="flex">
                                              <button onClick={this.mailRequestToChangeWorkstationImages} className="toreaBay sendReUploadReminder">Send Reminder</button>
                                              {this.state.isSendingReUploadRequestMail && <p className="loadingText">Sending Reminder ...</p>}
                                              {
                                                this.state.isErrorWhileSendingReUploadMail
                                                && <p className="errorText">Failed: try again!</p>
                                              }
                                            </div>
                                          </div>
                                        )
                                        : (
                                          <div className='btn_reUploadRequestContainer btn_reUploadRequestContainerWithDiagnosis'>
                                          <button
                                          onClick={() => this.setState({viewReUploadImageSection: true})}
                                          className='btnReport btn_reUploadRequest'
                                          >
                                            REQUEST RE-UPLOAD
                                          </button>
                                          <ToolTip
                                            title={
                                              <div className="h12 reUploadToolTipDetails">
                                                <p>Re-upload requested on - {this.state.reUploadRequestedDate && this.state.reUploadRequestedDate.length > 0 ? this.state.reUploadRequestedDate : "Not Requested"}</p>
                                                <p>Reminder sent on - {this.state.reUploadReminderDate && this.state.reUploadReminderDate.length > 0 ? this.state.reUploadReminderDate : "No Reminder"}</p>
                                                <p>Picture uploaded - {this.state.reUploadCompletionDate && this.state.reUploadCompletionDate.length > 0 ? this.state.reUploadCompletionDate : "Not Completed"}</p>
                                              </div>
                                            }
                                            placement="right"
                                          >
                                            <img src={clockRotateLeft} alt="clock" className="clockRotateLeft"/>
                                          </ToolTip>
                                          </div>
                                        )
                                      }
                                    </>
                                  }
                                </>
                          </div>
                          <div>
                            {this.state.diagnosis?.map(function (
                              observation,
                              i
                            ) {
                              if (observation.observationImg === 'edit' && observation?.observation) {
                                return (
                                  <div
                                    key={'linenImages1' + i}
                                    className='margLeft8px'
                                  >
                                    <div className='flex alignItemsCenter'>
                                      <div className='toreaBay-bg linenImageCircle white posRelative'>
                                        {i + 1}
                                        {/* {observation.annotateNum} */}
                                      </div>
                                      <div className='h10'>
                                        {observation.type}
                                      </div>
                                    </div>
                                    <div className='h12 obsTxt pre-wrap--break-line'>
                                      {observation.observation}
                                    </div>
                                  </div>
                                )
                              } else {
                                return <div key={'linenImages1' + i}></div>
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <hr />
                    
                    <div
                      className='flex justifySB alignItemsCenter pointer'
                      onClick={() => this.arrowToggle('section3')}
                    >
                      <div className='h11 toreaBay marg16px'>
                        GONIOMETER READINGS
                      </div>
                      <img
                        className='arrow'
                        src={this.state.section3 ? up : down}
                        alt=''
                      />
                    </div>

                    <div
                        className={this.state.section3 ? 'fadeIn' : 'fadeOut'}
                      >
                    <div className='flex imgWP'>
                                    <ImageChangeable
                                      isChangeable={this.isChangeableImageWorkplace()}
                                      keyImage={'GoniometerInputImage'}
                                      src={this.state.userData.goniometerInputImageUrl}
                                      alt='Goniometer Input Image'
                                      onSelectNewImage={(newImageBase64) => this.getGoniometerReadings('goniometerInputImageBase64', newImageBase64)}
                                      changeIconPosition="left"
                                    />
                                    <div>
                                        {
                                          this.state.isGoniometerReadingGenetared 
                                          ? (<p className="toreaBay h9 paraGoniometerReadings">Goniometer reading has been generated. Upload new picture to generate again</p>) 
                                          : (
                                            this.isChangeableImageWorkplace() && <button
                                              onClick={() =>this.getGoniometerReadings('', userData.userSideImagePath)}
                                              className='btnReport btnGenerate'
                                            >
                                              GENERATE
                                            </button>
                                          )
                                        }
                                        {
                                          this.state.isGoniometerReadingsLoading && <p className="h9 loadingText">Generating goniometer readings, please wait ...</p>
                                        }
                                        {
                                          this.state.isErrorInGoniometerReadingRequest && <p className="h9 errorText">Error while generating goniometer readings, please try again ...</p>
                                        }
                                    </div>
                            </div>
                            </div>
                    <hr />
                          {
                            this.state.section3 && this.state.isGoniometerReadingGenetared && (
                              <>
                                <div className='goniometerEditIconContainer' onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      isGoniometerReadingEditable: !this.state.isGoniometerReadingEditable
                                    });
                                  }}>
                                    { this.isChangeableImageWorkplace() && <img src={this.state.isGoniometerReadingEditable ? tick : edit} alt='Icon change' className='goniometerEditIcon'/> }
                                  </div>
                                  <WorkstationGoniometer 
                                    initGoniometers={goniometers} 
                                    isGoniometerReadingEditable={this.state.isGoniometerReadingEditable} 
                                    onFinish={(goniometers) => {
                                      this.setState({
                                        ...this.state,
                                        goniometerReadings: {
                                          ...this.state.goniometerReadings,
                                          goniometers,
                                        },
                                        isGoniometerReadingGenetared: true
                                      })
                                    }}
                                  />
                                  <hr />
                                  </>
                                )
                              }
                    <div
                      className='flex justifySB alignItemsCenter pointer relative'
                      onClick={() => this.arrowToggle('section4')}
                    >
                      <div className='h11 toreaBay marg16px'>
                        WORKSTATION SNAPSHOT
                      </div>
                      <img
                        className='arrow'
                        src={this.state.section4 ? up : down}
                        alt=''
                      />
                    </div>

                      <div className={this.state.section4 ? 'fadeIn' : 'fadeOut'}>
                        <div className='flex flexWrap workstation-snapshot' style={{gap:'1.5rem'}}>
                          <WorkStationSnapshot
                            computedWorkStationSnapshot = {
                              this.state.userData?.workstationSnapshot
                            }
                            workstationDuration={this.state.userData?.workstationDuration}
                          />
                        </div>
                    </div>

                    <hr />
                    <div
                      className='flex justifySB alignItemsCenter pointer relative'
                      onClick={() => this.arrowToggle('section5')}
                    >
                      <div className='h11 toreaBay marg16px'>
                        NATURE OF WORK SUMMARY
                      </div>
                      <img
                        className='arrow'
                        src={this.state.section5 ? up : down}
                        alt=''
                      />
                    </div>

                    <div className={this.state.section5 ? 'fadeIn' : 'fadeOut'}>
                      <div className='flex  justifySB'>
                        <div className='imgNWS40'>
                          <div className='width80' style={{height:'80%'}}>
                            <Doughnut
                              options={this.state.doughnutOptions}
                              data={this.state.doughnutData}
                            />
                          </div>
                          <div>
                            <div className='phoneColor h10'>
                              {userData.phonePercentage}%
                            </div>
                            <div className='h9'>Phone</div>
                            <br />
                            <div className='deviceColor h10'>
                              {userData.devicePercentage}%
                            </div>
                            <div className='h9'>Laptop</div>
                          </div>
                        </div>
                        <div className='imgNWS60 barchart'>
                          <Bar
                            width={100}
                            height={50}
                            options={this.state.barOptions}
                            data={this.state.barData}
                          />
                        </div>
                        </div>
                        <hr />
                        <NatureOfWorkSummaryBreakInfo ewa={this.state.userData} computedBreakText={this.computedBreakInfo()} />
                    </div>

                    <hr />
                    <div
                      className='flex justifySB alignItemsCenter pointer relative'
                      onClick={() => this.arrowToggle('section6')}
                    >
                      <div className='h11 toreaBay marg16px'>
                        PAIN & DISCOMFORT
                      </div>
                      <img
                        className='arrow'
                        src={this.state.section6 ? up : down}
                        alt=''
                      />
                    </div>
                      <div className={this.state.section6 ? 'fadeIn' : 'fadeOut'}>
                        <div className={'flex-col'}>
                          <div className={'painDiscomfortSummaryHeader'}>{i18n.assessment.painDiscomfort.painDiscomfort}</div>
                          <div className={"white-bg painDiscomfortSummaryProvider"}>
                              <PainDiscomfortSummaryTable
                                  title={i18n.assessment.painDiscomfort.oftenQuestion}
                                  dataSet={[
                                      {
                                          key: 'duringWork',
                                          value: this.state.userData.duringWork,
                                          title: i18n.assessment.painDiscomfort.duringWork,
                                      },
                                      {
                                          key: 'afterWork',
                                          value: this.state.userData.afterWork,
                                          title: i18n.assessment.painDiscomfort.afterWork,
                                      },
                                      {
                                          key: 'morningAfterWork',
                                          value: this.state.userData.morningAfterWork,
                                          title: i18n.assessment.painDiscomfort.morningAfterWork,
                                      },
                                      {
                                          key: 'afterWeekend',
                                          value: this.state.userData.afterWeekend,
                                          title: i18n.assessment.painDiscomfort.afterWeekend,
                                      },
                                  ]}
                              />
                          </div>
                        </div>

                        <hr />
                        <br />
                        
                        <div className={'flex-col'}>
                          <div className={"painDiscomfortSummaryHeader"}>{i18n.assessment.painDiscomfort.consequenceOfPain}</div>
                          <div className={"white-bg painDiscomfortSummaryProvider"}>
                              <PainDiscomfortSummaryTable
                                  title={i18n.assessment.painDiscomfort.oftenQuestion}
                                  dataSet={[
                                      {
                                          key: 'unableToSleep',
                                          value: this.state.userData.unableToSleep,
                                          title: i18n.assessment.painDiscomfort.unableToSleep,
                                      },
                                      {
                                          key: 'lessStrength',
                                          value: this.state.userData.lessStrength,
                                          title: i18n.assessment.painDiscomfort.lessStrength,
                                      },
                                      {
                                          key: 'frequentStretching',
                                          value: this.state.userData.frequentStretching,
                                          title: i18n.assessment.painDiscomfort.frequentStretching,
                                      },
                                      {
                                          key: 'strainedEyes',
                                          value: this.state.userData.strainedEyes,
                                          title: i18n.assessment.painDiscomfort.strainedEyes,
                                      },
                                      {
                                          key: 'takeTimeOff',
                                          value: this.state.userData.takeTimeOff,
                                          title: i18n.assessment.painDiscomfort.takeTimeOff,
                                      },
                                      {
                                          key: 'affectsProductivity',
                                          value: this.state.userData.affectsProductivity,
                                          title: i18n.assessment.painDiscomfort.affectsProductivity,
                                      },
                                  ]}
                              />
                          </div>
                        </div>
                        <hr />
                        <br />
                        <div className='flex justifySB flex-col'>
                          <div className='sectionpdLeft'>
                            <div className='painDiscomfortSummaryHeader'>
                              Pain Distribution
                            </div>

                            <PainDistributionFilter
                              defaultOptionId = {1}
                              options={
                                [
                                  {
                                    id: 1,
                                    name: i18n.all,
                                    image: userData.bodyPainImageUrl,
                                  },
                                  {
                                    id: 2,
                                    name: `${i18n.assessment.painDiscomfort.troubleIn} 
                                    <b>${i18n.last12Months}</b>`,
                                    image: userData.troubleIn12MonthsImageUrl,
                                  },
                                  {
                                    id: 3,
                                    name: `${i18n.assessment.painDiscomfort.preventActivity} 
                                    <b>${i18n.assessment.painDiscomfort.carryOutActivity}</b>
                                    ${i18n.assessment.painDiscomfort.dueTo} ${i18n.last12Months}`,
                                    image: userData.preventedNormalActivitiesImageUrl,
                                  },
                                  {
                                    id: 4,
                                    name: `${i18n.assessment.painDiscomfort.troubleIn} 
                                    <b>${i18n.assessment.painDiscomfort.visitedPhysician}</b> 
                                    ${i18n.forIn} 
                                    <b>${i18n.last12Months}</b>`,
                                    image: userData.visitedAPhysicianImageUrl,
                                  },
                                  {
                                    id: 5,
                                    name: `${i18n.assessment.painDiscomfort.troubleIn} 
                                    <b>${i18n.last7Days}</b>`,
                                    image: userData.troubleInLast7DaysImageUrl,
                                  },
                                ]
                              }
                            />

                          </div>
                        </div>

                        <br />
                        <hr />
                        <br />
                        <div className={'flex-col'}>
                          <div>
                            {userData.painTreatment && (
                              <>
                                <div className='painDiscomfortSummaryHeader'>
                                Treatment For Pain
                                </div>
                                <div className='h4'>{userData.painTreatment}</div>
                              </>
                            )}
                            {userData.painTreatment && <br />}
                            {userData.concerns && (
                              <>
                                <div className='painDiscomfortSummaryHeader'>Other Concerns</div>
                                <div className='h4'>{userData.concerns}</div>
                              </>
                            )}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='whiteBoxBuilder margleft2 white-bg height80'>
                    <div className='flex justifySB tab__container'>
                    <div
                        onClick={() => this.setState({
                          ...this.state, tab: {
                            complaint: true,
                          }
                        })}
                      className={
                        this.state.tab.complaint
                          ? 'tab__header toreaBay h4 tab__header--active'
                          : 'tab__header h4'
                      }
                    >
                      {i18n.admin.complaints.toUpperCase()}&nbsp;&nbsp;
                    </div>
                    <div
                        onClick={() => this.setState({ ...this.state, tab: {
                          observation: true,
                        }
                     })}
                      className={
                        this.state.tab.observation
                          ? 'tab__header toreaBay h4 tab__header--active'
                          : 'tab__header h4'
                      }
                    >
                      OBSERVATIONS&nbsp;&nbsp;
                      <div className='tab__header__suffix'>
                        {this.state.observationCount}
                      </div>
                    </div>
                    <div
                        onClick={() => this.setState({
                          ...this.state, tab: {
                            recommendation: true
                          }
                        })}
                      className={
                        this.state.tab.recommendation
                          ? 'tab__header toreaBay h4 tab__header--active'
                          : 'tab__header h4'
                      }
                    >
                      RECOMMENDATIONS&nbsp;&nbsp;
                      <div className='tab__header__suffix'>
                        {this.state.recommendationCount}
                      </div>
                    </div>
                  </div>
                  {/* TAB COMPLAINTS */}
                  {this.state.tab.complaint && (
                      <div className={'tab__content'}>
                        <textarea
                            id={'complaint'}
                            value={this.state.userData?.complaint ?? ''}
                            onChange={evt =>
                              this.setState({
                                ...this.state,
                                userData:{
                                  ...this.state.userData,
                                  complaint: evt.target.value,
                                }
                              })
                            }
                            type='text'
                            className='text-area-input'
                            placeholder = {i18n.writeHere}
                        />
                      </div>
                  )}
                  {/* TAB OBSERVATION */}
                    {this.state.tab.observation && (
                        <div className='tab__content'>
                          {this.state.diagnosis?.map(function (observation, i) {
                            return (
                              <div key={'observation' + i}>
                                <div className='linenBox'>
                                  <div
                                    className='linenHeader pointer'
                                    onClick={() =>
                                      that.arrowToggle('observation', i)
                                    }
                                  >
                                    <div className='flex alignItemsCenter'>
                                      <div className='linenHeaderCircle'>
                                        {i + 1}
                                      </div>
                                      {/* {observation.annotateNum > 0 ? (
                                        <div className='linenHeaderCircle'>
                                          {observation.annotateNum}
                                        </div>
                                      ) : (
                                        ''
                                      )} */}
                                      <div className='h11 toreaBay'>
                                        {observation.type}
                                      </div>
                                      {/* &nbsp; */}
                                      {/* <div className='linenTabCircleWhite'>
                                        {observation.suggestsObs.length}
                                      </div> */}
                                    </div>
                                    <img
                                      className='arrow'
                                      src={
                                        observation.observationImg === 'down' ||
                                          userData.status === 'complete'
                                          ? edit
                                          : observation.observationImg === 'tick'
                                            ? tick
                                            : edit
                                      }
                                      alt=''
                                    />
                                  </div>
                                  {observation.observationImg !== 'tick' &&
                                    <div className={'flex-row'} style={{ marginLeft: '40px'}}>
                                      <div className={'tab__content__preview'}>{observation.observation}</div>
                                    </div>
                                  }
                                  <div
                                    className={
                                      observation.observationImg === 'edit'
                                        ? 'width100 fadeOut'
                                        : 'width100 fadeIn'
                                    }
                                  >
                                    <textarea
                                      id={'observation' + i}
                                      value={observation.observation}
                                      onChange={evt =>
                                        that.inputCapture(
                                          i,
                                          evt.target.value,
                                          'observation'
                                        )
                                      }
                                      type='text'
                                      disabled={
                                        observation.observationImg === 'edit'
                                          ? true
                                          : false
                                      }
                                      className='linenInput'
                                      placeholder={i18n.startTyping}
                                    />
                                    {observation.suggestsObsMisc?.map(function (
                                      item,
                                      j
                                    ) {
                                      return (
                                        <div
                                          key={'linenObsMisc' + i + j}
                                          className='h13 linenTxt'
                                        >
                                          <div className='linenInputCircle'>
                                            <img
                                              src={item[1] ? minus : plus}
                                              onClick={() =>
                                                that.addToInputMisc(
                                                  i,
                                                  j,
                                                  'observation'
                                                )
                                              }
                                              alt=''
                                            />
                                          </div>
                                          {item[0]}
                                        </div>
                                      )
                                    })}
                                    {observation.suggestsObs?.map(function (
                                      item,
                                      j
                                    ) {
                                      return (
                                        <div
                                          key={'linenObs' + i + j}
                                          className='h13 linenTxt'
                                        >
                                          <div className='linenInputCircle'>
                                            <img
                                              src={item[1] ? minus : plus}
                                              onClick={() =>
                                                that.addToInput(i, j, 'observation')
                                              }
                                              alt=''
                                            />
                                          </div>
                                          {item[0]}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                    )
                  }
                  {/* TAB RECOMMENDATION */}
                  {this.state.tab.recommendation && (
                    <div className='tab__content'>
                      {this.state.diagnosis?.map(function (observation, i) {
                        return (
                          <div key={'observation1' + i}>
                            <div className='linenBox' style={{ zIndex: { i } }}>
                              <div
                                className='linenHeader pointer'
                                onClick={() =>
                                  that.arrowToggle('recommendation', i)
                                }
                              >
                                <div className='flex alignItemsCenter'>
                                  <div className='linenHeaderCircle'>
                                    {i + 1}
                                  </div>
                                  {/* {observation.annotateNum > 0 ? (
                                    <div className='linenHeaderCircle'>
                                      {observation.annotateNum}
                                    </div>
                                  ) : (
                                    ''
                                  )} */}
                                  <div className='h11 toreaBay'>
                                    {observation.type}
                                  </div>
                                  {/* &nbsp; */}
                                  {/* <div className='linenTabCircleWhite'>
                                    {observation.suggestsRec.length}
                                  </div> */}
                                </div>
                                <img
                                  className='arrow'
                                  src={
                                    observation.recommendationImg === 'down' ||
                                    userData.status === 'complete'
                                      ? edit
                                      : observation.recommendationImg === 'tick'
                                      ? tick
                                      : edit
                                  }
                                  alt=''
                                />
                              </div>
                              {observation.recommendationImg !== 'tick' &&
                                <div className={'flex-row'} style={{ marginLeft: '40px'}}>
                                  <div className={'tab__content__preview'}>{observation.recommendation}</div>
                                </div>
                                }
                              <div
                                className={
                                  observation.recommendationImg === 'edit'
                                    ? 'width100 fadeOut'
                                    : 'width100 fadeIn'
                                }
                              >
                                <textarea
                                  id={'recommendation' + i}
                                  value={observation.recommendation}
                                  onChange={evt =>
                                    that.inputCapture(
                                      i,
                                      evt.target.value,
                                      'recommendation'
                                    )
                                  }
                                  type='text'
                                  disabled={
                                    observation.recommendationImg === 'edit'
                                      ? true
                                      : false
                                  }
                                  className='linenInput'
                                  placeholder={i18n.startTyping}
                                />
                                {observation.suggestsRecMisc?.map(function (
                                  item,
                                  j
                                ) {
                                  return (
                                    <div
                                      key={'linenRecMisc' + i + j}
                                      className='h13 linenTxt'
                                    >
                                      <div className='linenInputCircle'>
                                        <img
                                          src={item[1] ? minus : plus}
                                          onClick={() =>
                                            that.addToInputMisc(
                                              i,
                                              j,
                                              'recommendation'
                                            )
                                          }
                                          alt=''
                                        />
                                      </div>
                                      {item[0]}
                                    </div>
                                  )
                                })}
                                {observation.suggestsRec?.map(function (
                                  item,
                                  j
                                ) {
                                  return (
                                    <div
                                      key={'linenRec' + i + j}
                                      className='h13 linenTxt'
                                    >
                                      <div className='linenInputCircle'>
                                        <img
                                          src={item[1] ? minus : plus}
                                          onClick={() =>
                                            that.addToInput(
                                              i,
                                              j,
                                              'recommendation'
                                            )
                                          }
                                          alt=''
                                        />
                                      </div>
                                      {item[0]}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(this.state.viewReport || this.state.isShowModalReviewerSelector || this.state.isShowModalChangeSeverityScore || this.state.viewReUploadImageSection) ? (
              <div className='reportBg toreaBay-bg'></div>
            ) : (
              ''
            )}
            {this.state.viewReport &&
            userData.isSendReportAble ? (
              <div
                onClick={() => this.sendReport()}
                className='h10 toreaBay-bg sendReport white uppercase'
              >
                {this.state.sendReport}
                {this.state.sendReport === 'SENDING' ? (
                  <img src={loading} alt='' className='imgLoading' />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {this.state.viewReUploadImageSection && (
              <div className='viewReUploadImageSectionContainer white-bg'>
                <div className='width100 reportHeader h11 uppercase'>
                  REQUEST RE-UPLOAD
                </div>
                <div className='reportBody'>
                  <img
                    className='close'
                    src={close}
                    alt='close'
                    onClick={() => {
                      this.setState({ 
                        viewReUploadImageSection: false,
                        reUploadRequestAdditionalComments: "",
                        imageReUploadRequestStatus: {
                          workstationSide: false,
                          workstationBack: false,
                          userSide: false,
                          userBack: false
                        }
                       });
                    }}
                  />
                </div>
                <div className='h9 requestReUploadSection'>
                  <div>
                  <p className="requestReUploadSectionpara">Select image to request re-upload</p>
                  {!this.state.isImageReUploadRequestListEmpty && !this.state.isSendingReUploadRequestMail && !this.state.isErrorWhileStoringReUploadData && !this.state.isErrorWhileSendingReUploadMail && <div></div>}
                    {this.state.isImageReUploadRequestListEmpty
                      && <p className="h9 errorText selectImageError">Please select images from above for re-upload</p>
                    }
                    {this.state.isSendingReUploadRequestMail
                      && <p className="h9 loadingText sendingMailLoading">Please wait, sending mail for re-upload images</p>
                    }
                    {this.state.isErrorWhileStoringReUploadData
                      && <p className="h9 errorText selectImageError">Error: While storing data, try again!</p>
                    }
                    {
                      this.state.isErrorWhileSendingReUploadMail
                      && <p className="h9 errorText selectImageError">Error: Failed to send mail, try again!</p>
                    }
                  <div className="reUploadSectionImageViewContainer">
                    <div className="imageContainerSection">
                    <input type="checkbox" className="checkbox_reUploadImages" onChange={() => this.handleReUploadImageSelection("workstationSide")}/>
                    <img src={userData.workstationSideImageUrl} alt="workstation side" className="reUploadSectionImageView"/>
                    </div>
                    <div className="imageContainerSection">
                    <input type="checkbox" className="checkbox_reUploadImages" onChange={() => this.handleReUploadImageSelection("workstationBack")}/>
                    <img src={userData.workstationBackImageUrl} alt="workstation side" className="reUploadSectionImageView"/>
                    </div>
                    <div className="imageContainerSection">
                    <input type="checkbox" className="checkbox_reUploadImages" onChange={() => this.handleReUploadImageSelection("userSide")}/>
                    <img src={userData.userSideImageUrl} alt="workstation side" className="reUploadSectionImageView"/>
                    </div>
                    <div className="imageContainerSection">
                      <input type="checkbox" className="checkbox_reUploadImages" onChange={() => this.handleReUploadImageSelection("userBack")}/>
                    <img src={userData.userBackImageUrl} alt="workstation side" className="reUploadSectionImageView"/>
                    </div>
                  </div>
                  <p className="requestReUploadSectionpara">Additional Comments</p>
                  <textarea
                    value={this.state.reUploadRequestAdditionalComments}
                    onChange={evt =>
                      this.setState({
                        reUploadRequestAdditionalComments: evt.target.value
                      })
                    }
                    type='text'
                    className='text-area-input drRequestImageNote'
                  />
                  <div className="send-request-button-container">
                    <button
                      onClick={this.prepareImageReUploadRequestListAndSendMail}
                      className='toreaBay-bg btn white cta-btn send-request-btn'
                    >
                      SEND REQUEST
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.viewReport ? (
              <div className='report white-bg'>
                <div className='width100 reportHeader h11 uppercase'>
                    {i18n.report}
                </div>
                <div className='reportBody'>
                  <img
                    className='close'
                    src={close}
                    alt='close'
                    onClick={() => {
                      this.setState({ viewReport: false });
                    }}
                  />
                  <br />
                  <div className='h9 toreaBay'>Personal Details</div>
                  <br />
                  <br />
                  <PatientPersonalInfo ewa={this.state.userData} titleExtendClass={'toreaBay'} />
                  <br />
                  <hr />
                  <br />
                  <div className='flex justify-content-between'>
                    <div>
                      <br />
                      <div className='toreaBay h9'>Severity Score</div>
                      <br />
                      <div className='toreaBay h11'>
                        {this.computedEwaScoreDescription()}
                      </div>
                    </div>
                    <div className='doughnutChart'>
                      <div className='black h3' style={{height:100, width: 100}} key={`${userData?.ewaScore}_modal`}>
                          <CircularProgress
                            score={userData?.ewaScore}
                            data={[userData?.ewaScore ?? 0, 100 - userData?.ewaScore ?? 0]}
                          />
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div className='flex'>
                    <div className='width50'>
                      <div className='h9 toreaBay'>Task Distribution</div>
                      <div className='h9'>
                        <br />
                        Distribution of tasks on a typical workday uses
                        <br />
                        <br />
                      </div>
                      <div className='flex'>
                        <div className='width80'>
                          <Doughnut
                            options={this.state.doughnutOptions}
                            data={this.state.doughnutData}
                          />
                        </div>
                        <div className={'chartPercentageDetail'}>
                          <br />
                          <div className='phoneColor h10'>
                            {userData.phonePercentage}%
                          </div>
                          <div className='h9'>Phone</div>
                          <br />
                          <div className='deviceColor h10'>
                            {userData.devicePercentage}%
                          </div>
                          <div className='h9'>Laptop</div>
                        </div>
                      </div>
                    </div>
                    <div className='width50'>
                      <div className='h9 toreaBay'>Pain Distribution</div>
                      <div className='h9'>
                        <br />
                        Visualisation of Pain/discomfort and severity
                        <br />
                      </div>
                      <br />
                      <br />
                      <img
                        className='painImg margLeft8'
                        src={userData.bodyPainImageUrl}
                        alt=''
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />
                  <br />

                  <div className='h9 toreaBay'>Workstation Snapshot</div>
                  <div className='gridWS'>
                    {userData.workstationSnapshot?.map(function (item, i) {
                      return (
                        <div key={'chairHeight' + i} className='h9 imgWSRep'>
                          <img
                            src={require(`../../../assets/img/rosa/${item.image}.png`)}
                            alt=''
                          />
                          <br />
                          {item.text}
                        </div>
                      )
                    })}
                  </div>
                  <hr />
                  <br />
                  <br />
                  <div className='h9'>
                    <span className='h9 toreaBay'>Evaluated by:&nbsp;</span>
                    <span className='h10'>{userData.doctor?.name}</span>
                  </div>
                  <br />
                  <div className='flex justifySB width100'>
                    <div className='h9'>
                      <span className='h9 toreaBay'>
                        Assessment submitted:&nbsp;
                      </span>
                      <span className='h10'>{new Date(userData.assessmentDate).toDateString()}</span>
                    </div>
                    <div className='h9'>
                      <span className='h9 toreaBay'>
                        Report generated:&nbsp;
                      </span>
                      <span className='h10'>{new Date().toDateString()}</span>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  {/* COMPLAINTS */}
                    {
                      !!this.state.userData.complaint && 
                      <>
                        <div className='h9 toreaBay'>{i18n.admin.complaints}</div>
                        <br/>
                        <div className={'h10'}>{this.state.userData.complaint}</div>
                        <br />
                        <hr />
                        <br />
                      </>
                    }

                  {/* Observations */}
                  <div className='h9 toreaBay'>Key Observations</div>
                  <br />
                  <div className='h10'>
                    The observations have been made by assessing together, the
                    answers from the questionnaire and the photographs uploaded
                    by you.
                  </div>
                  <br />
                  <br />
                  <div className='flex'>
                    <div>
                      <div className='imgWP'>
                        {this.state.diagnosis?.map(function (observation, i) {
                          if (observation.annotateImg === 'image1') {
                            return (
                              <div
                                key={'linenImages1' + i}
                                className='linenImageCircle white-bg'
                                style={{
                                  top: observation.annotateCoord[1],
                                  left: observation.annotateCoord[0]
                                }}
                              >
                                {observation.annotateNum}
                              </div>
                            )
                          } else {
                            return <div key={'linenImages1' + i}></div>
                          }
                        })}
                        <img src={userData.workstationSideImageUrl ?? userData.workstationSide} alt='' />
                      </div>
                      <div className='imgWP'>
                        {this.state.diagnosis?.map(function (observation, i) {
                          if (observation.annotateImg === 'image2') {
                            return (
                              <div
                                key={'linenImages2' + i}
                                className='linenImageCircle white-bg'
                                style={{
                                  top: observation.annotateCoord[1],
                                  left: observation.annotateCoord[0]
                                }}
                              >
                                {observation.annotateNum}
                              </div>
                            )
                          } else {
                            return <div key={'linenImages2' + i}></div>
                          }
                        })}
                        <img src={userData.workstationBackImageUrl ?? userData.workstationBack} alt='' />
                      </div>
                      <div className='imgWP'>
                        {this.state.diagnosis?.map(function (observation, i) {
                          if (observation.annotateImg === 'image3') {
                            return (
                              <div
                                key={'linenImages3' + i}
                                className='linenImageCircle white-bg'
                                style={{
                                  top: observation.annotateCoord[1],
                                  left: observation.annotateCoord[0]
                                }}
                              >
                                {observation.annotateNum}
                              </div>
                            )
                          } else {
                            return <div key={'linenImages3' + i}></div>
                          }
                        })}
                        <img src={userData.userSideImageUrl ?? userData.userSideImage} alt='' />
                      </div>
                      <div className='imgWP'>
                        {this.state.diagnosis?.map(function (observation, i) {
                          if (observation.annotateImg === 'image4') {
                            return (
                              <div
                                key={'linenImages4' + i}
                                className='linenImageCircle white-bg'
                                style={{
                                  top: observation.annotateCoord[1],
                                  left: observation.annotateCoord[0]
                                }}
                              >
                                {observation.annotateNum}
                              </div>
                            )
                          } else {
                            return <div key={'linenImages4' + i}></div>
                          }
                        })}
                        <img src={userData.userBackImageUrl ?? userData.userBackImage} alt='' />
                      </div>
                    </div>
                    <div>
                      { this.renderObservationReportModal() }
                    </div>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <div className='h9 toreaBay'>Recommendations</div>
                  <br />
                  <div className='h10'>
                    The recommendations have been made focussing on your pain
                    and discomfort and the use of your current workstation that
                    might be causing it. They identify the risk factor and
                    suggest a solution to mitigate them.The solution can be in
                    the form of adjustments or changes in the workstation.
                  </div>
                  <br />
                  <br />
                  <div>
                    { this.renderRecommendedReportModal() }
                  </div>
                  <br />
                  <br />
                  <hr />
                  <br />
                  <div className='h9 toreaBay'>Add Doctor's Note</div>
                  <br />
                  <textarea
                    value={this.state.drNote}
                    onChange={evt =>
                      this.setState({ drNote: evt.target.value })
                    }
                    type='text'
                    placeholder='Write here'
                    className='drNote'
                  />
                  <br />
                  <br />
                  <br />
                  <hr />
                  <br />
                  <div className="additional-box">
                    <p className="text wt600">ADDITIONAL RESOURCES</p>
                    <br/>
                    <span className="text">
                      <span>•  </span><a className="link" href="https://www.deepaksharan.com/remote-ergonomics" target="_blank">General Ergonomic Recommendations</a> for computer users working from home.
                    </span>
                    <div className="h15"></div>
                    <span className="text">
                      <span>•  </span><a className="link" href="https://deepaksharan.com/ergonomic-products" target="_blank">Catalogue of Ergonomic Products</a>.
                    </span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <DoctorContactInfo doctor={userData.doctor}/>
                  <br />
                  <div className='h10 grey opacity64'>
                    Note: If the pain persists beyond 2 weeks after making the
                    above changes, a medical consultation with a Rehabilitation
                    or Occupational physician is recommended.
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                <br />
                <div className='reportFooter'></div>
              </div>
            ) : (
              ''
            )}
            {this.state.isShowModalReviewerSelector && (
              <ModalReviewerSelector
                onClose={this.closeModalReviewerSelector}
                ewa={userData}
              />
              )}
            {this.state.isShowModalChangeSeverityScore && (
              <ModalChangeSeverityScore
                  onClose={this.closeModalChangeSeverityScore}
                  ewa={userData}
                  ewaScoreDescriptionDefault={() => this.computedEwaScoreDescription()}
                  onUpdateSeverity={this.handleUpdateSeverityScore}
              />
              )}
              {this.state.isShowSavedSuccess &&
                <Alert content={i18n.savedSuccess} onClose={() => {
                  this.setState({
                    ...this.state,
                    isShowSavedSuccess : false
                  })
                }}/>
              }
              {this.state.isShowSentSuccess &&
              <Alert content={i18n.sentSuccess} onClose={() => {
                  this.setState({
                    ...this.state,
                    isShowSentSuccess : false
                  })
                }}/>
              }
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(AdminReportBuilderView)
