import i18n from '../i18n';

const assessmentPainDiscomfort = {
  painLevelEnums: {
    1: i18n.almostNever,
    2: i18n.sometimes,
    3: i18n.rarely,
    4: i18n.almostAlways,
    5: i18n.allTheTime,
  },
};

export default assessmentPainDiscomfort;
