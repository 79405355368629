import { format } from 'date-fns';

import * as ActionTypes from './ActionTypes';
import api from './api';


export const add_patient_info = (patientDetails) => ({
    type: ActionTypes.ADD_PATIENT_INFO,
    payload: {
        patientDetails
    }
});
export const add_basic_info = (
    workPlace,
    occupation,
    height,
    weight,
    dominantHand,
    pregnant,
    correctiveLens,
    lensType,
    monitorView,
    paperView,
    peopleView,
    lastSection,
    lastPage,
    restState
) => {
    let data = {
        workPlace: workPlace,
        occupation: occupation,
        height: height,
        weight: weight,
        dominantHand: dominantHand,
        pregnant: pregnant,
        correctiveLens: correctiveLens,
        lensType: lensType,
        monitorView: monitorView,
        paperView: paperView,
        peopleView: peopleView,
    };
    
    api.ewa.userUpsertEwa({
      ...data,
      lastSection,
      lastPage,
      savedProcessJson: {
        ...restState,
      },
    });


    return {
        type: ActionTypes.ADD_BASIC_INFO,
        payload: data
    }
};

export const add_Nature_Of_Work_Info = (
  workDevice,
  carryDevice,
  totalWorkDeviceTime,
  audioDevice,
  totalPhoneTime,
  stretchRegularly,
  exerciseRegularly,
  totalWorkTime,
  totalBreakTime,
  numberOfBreaks,
  docHolder,
  workTasks,
  devicePercentage,
  phonePercentage,
  breakPercentage,
  lastSection,
  lastPage,
  restState
) => {
  const data = {
    workDevice: workDevice,
    carryDevice: carryDevice,
    totalWorkDeviceTime: totalWorkDeviceTime,
    audioDevice: audioDevice,
    totalPhoneTime: totalPhoneTime,
    stretchRegularly: stretchRegularly,
    exerciseRegularly: exerciseRegularly,
    totalWorkTime: totalWorkTime,
    totalBreakTime: totalBreakTime,
    docHolder: docHolder,
    workTasks: workTasks,
    devicePercentage: devicePercentage,
    phonePercentage: phonePercentage,
    breakPercentage: breakPercentage,
    numberOfBreaks,
  };


  api.ewa.userUpsertEwa({
    ...data,
    lastSection,
    lastPage,
    numberOfBreaks,
    savedProcessJson: {
      ...restState,
      workTasks: JSON.stringify({ ...restState.workTasks }),
    },
  });

  return {
    type: ActionTypes.ADD_NATURE_OF_WORK_INFO,
    payload: data,
  };
};

export const add_pain_and_discomfort_info = (
    duringWork,
    afterWork,
    morningAfterWork,
    afterWeekend,
    unableToSleep,
    lessStrength,
    frequentStretching,
    strainedEyes,
    takeTimeOff,
    affectsProductivity,
    troubleIn12Months,
    preventedNormalActivities,
    visitedAPhysician,
    troubleInLast7Days,
    takingTreatment,
    painTreatment,
    concerns,
    bodyPainImg,
    lastSection,
    lastPage,
    specificPainDistributionImages,
    restState
) => {
    const data = {
      duringWork: duringWork,
      afterWork: afterWork,
      morningAfterWork: morningAfterWork,
      afterWeekend: afterWeekend,
      unableToSleep: unableToSleep,
      lessStrength: lessStrength,
      frequentStretching: frequentStretching,
      strainedEyes: strainedEyes,
      takeTimeOff: takeTimeOff,
      affectsProductivity: affectsProductivity,
      troubleIn12Months: troubleIn12Months,
      preventedNormalActivities: preventedNormalActivities,
      visitedAPhysician: visitedAPhysician,
      troubleInLast7Days: troubleInLast7Days,
      takingTreatment: takingTreatment,
      painTreatment: painTreatment,
      concerns: concerns,
      bodyPainImg: bodyPainImg
    };

    api.ewa.userUpsertEwa({
      ...data,
      lastSection,
      lastPage,
      ...specificPainDistributionImages,
      savedProcessJson: {
        ...restState,
      },
    });

    return {
        type: ActionTypes.ADD_PAIN_AND_DISCOMFORT_INFO,
        payload: data
    }
}


export const add_workstation_assessment_info = (
    chairHeight,
    seatPan,
    armRest,
    backSupport,
    monitorSetup,
    telephone,
    mouse,
    keyboard,
    softTissueCompression,
    rosaSelectedItems,
    rosaGrandScore,
    ewaScore,
    lastSection,
    lastPage,
    workstationDuration,
    restState
) => { 
      const data = {
        chairHeight: chairHeight,
        seatPan: seatPan,
        armRest: armRest,
        backSupport: backSupport,
        monitorSetup: monitorSetup,
        telephone: telephone,
        mouse: mouse,
        keyboard: keyboard,
        softTissueCompression: softTissueCompression,
        rosaSelectedItems: rosaSelectedItems,
        rosaGrandScore: rosaGrandScore,
        ewaScore: ewaScore,
        workstationDuration : workstationDuration,
      };

      api.ewa.userUpsertEwa({
        ...data,
        lastSection,
        lastPage,
        savedProcessJson: {
          ...restState,
          workTasks: JSON.stringify({ ...restState.workTasks }),
        },
      });
    
    return {
        type: ActionTypes.ADD_WORKSTATION_ASSESSMENT_INFO,
        payload: data
    }
}

export const add_workstation_images =  (
  workstationSide,
  workstationBack,
  userSideImage,
  userBackImage,
  lastSection,
  lastPage,
  isLastPage,
  dispatch,
  reUploadWorkstationImages
) => {
  let data = {
    workstationSide: workstationSide,
    workstationBack: workstationBack,
    userSideImage: userSideImage,
    userBackImage: userBackImage,
  };

  if(isLastPage && reUploadWorkstationImages && reUploadWorkstationImages.length > 0) {
    data["reUploadWorkstationImages"] = [];
    data["reUploadWorkstationImagesStatus"] = false;
    data["reUploadCompletionDate"] = format(new Date(), 'MMMM d, yyyy');
    data["reUploadRequestAdditionalComments"] = "";
  }
  
  api.ewa.userUpsertEwa({
    ...data,
    lastSection,
    lastPage,
    savedProcessJson: {}, // Reset savedProcessJson to reduce size ewa model
  }).then(() => {
    if (isLastPage) {
      dispatch({
        type: ActionTypes.ADD_WORKSTATION_IMAGES_DONE,
      });
    }
  });

  data.isLastPage = isLastPage;

  return {
    type: ActionTypes.ADD_WORKSTATION_IMAGES,
    payload: data,
  };
};


export const reset_ewa_assessment = () => {
  api.ewa.userDeleteEwa();
};