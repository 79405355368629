import React, { Component } from "react";
import Webcam from "react-webcam";
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import SectionIntro from "../../components/rosa/SectionIntro";
import workstation1 from "../../assets/img/rosa/workstation1.png"
import workstation2 from "../../assets/img/rosa/workstation2.png"
import workstation3 from "../../assets/img/rosa/workstation3.png"
import workstation4 from "../../assets/img/rosa/workstation4.png"
import camera from "../../assets/img/rosa/camera1.png"
import retake from "../../assets/img/rosa/retake1.png"
import "./WorkstationImagesView.scss"
import UploadImage from "../../components/atoms/uploadImage/UploadImage";
import UploadImageError from "../../components/atoms/uploadImage/UploadImageError";
import LoadingView from "../../components/atoms/LoadingView";
import FlipCamera from "./components/workstationImagesView/FlipCamera";

class WorkstationImagesView extends Component {

    constructor(props) {
        super(props);
        const reUploadImages = this.props.reUploadWorkstationImages
        this.state = {
            videoConstraints: {
                width: 296,
                height: 270,
                aspectRatio: 1.1,
                facingMode: "user"
                //facingMode: { exact: "environment" }
                //facingMode: this.state.facingMode,
            },
            isFlippableCamera: window.mobileCheck(),
            isWorkStationSideNextable: (this.props.workstationSide || this.props.workstationSideImageUrl) && !reUploadImages.includes("workstationSide"),
            isWorkStationBackNextable: (this.props.workstationBack || this.props.workstationBackImageUrl) && !reUploadImages.includes("workStationBack"),
            isUserSideNextable: (this.props.userSide || this.props.userSideImageUrl) && !reUploadImages.includes("userSide"),
            isUserBackNextable: (this.props.userBack || this.props.userBackImageUrl) && !reUploadImages.includes("userBack")
        };
    }
    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = (stateKey) => {
        if (stateKey === "workstationSide") {
            this.setState({isWorkStationSideNextable: true})
        }
        if (stateKey === "workstationBack") {
            this.setState({isWorkStationBackNextable: true})
        }
        if (stateKey === "userSide") {
            this.setState({isUserSideNextable: true})
        }
        if (stateKey === "userBack") {
            this.setState({isUserBackNextable: true})
        }
        const imageSrc = this.webcam.getScreenshot();
        this.props.capture(imageSrc, stateKey);
    };

    uploadImage = (stateKey, base64) => {
        if (stateKey === "workstationSide") {
            this.setState({isWorkStationSideNextable: true})
        }
        if (stateKey === "workstationBack") {
            this.setState({isWorkStationBackNextable: true})
        }
        if (stateKey === "userSide") {
            this.setState({isUserSideNextable: true})
        }
        if (stateKey === "userBack") {
            this.setState({isUserBackNextable: true})
        }
        this.props.capture(base64, stateKey);
    };
    
    cameraSettings = () => {
        let { videoConstraints } = this.state;
        setTimeout(() => {
            const newFacingMode = videoConstraints.facingMode === 'user' ? { exact: 'environment' } : 'user'
            this.setState({
                videoConstraints: { facingMode: newFacingMode },
            })
        }, 100)
    }

    render() {
        const videoConstraints = {
            width: 296,
            height: 270,
            aspectRatio: 1.1,
            facingMode: this.state.facingMode
        };

        // Image URL from server, and the last one is client image source
        let workStationSideSrc = this.props.workstationSide || this.props.workstationSideImageUrl
        let isWorkStationSideShowCam = !this.props.workstationSide && !this.props.workstationSideImageUrl

        let workStationBackSrc = this.props.workstationBack || this.props.workstationBackImageUrl
        let isWorkStationBackShowCam = !this.props.workstationBack && !this.props.workstationBackImageUrl

        let userSideSrc = this.props.userSide || this.props.userSideImageUrl
        let isUserSideShowCam = !this.props.userSide && !this.props.userSideImageUrl

        let userBackSrc = this.props.userBack || this.props.userBackImageUrl
        let isUserBackShowCam = !this.props.userBack && !this.props.userBackImageUrl
        
        return (
            <div className="container noScroll">
                {this.props.isLoading && <LoadingView />} 
                
                {this.props.sectionIntroOpen &&
                    <div className="capePalliser-bg linen">
                        <Header backBtnColor="linen"
                            intro={true}
                            goBack={this.props.warning}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="0%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle=""
                            isShowProfileNavigator={false}
                        />

                        <SectionIntro
                            sectionHeader1="Workstation"
                            sectionHeader2="Images"
                            sectionDesc={this.props.isRequestedToReuploadImages
                                ? "In the following section, kindly re-upload the requested images for a better assessment of your working posture alongside all the elements of your workspace and any areas of concern."
                                : "In the following section, kindly add the requested 4 images for a better assessment of your working posture alongside all the elements of your workspace and any areas of concern." 
                            }
                            bgColor="capePalliser-bg wsiBgImg"
                            sectionColor="white"
                            nxtBtnClass="spice nxt-btn1"
                            title=''
                            backBtnColor='linen'
                            nextSection={this.props.openQuiz} />
                    </div>
                }
                {this.props.modal1 &&
                    <Modal
                        modalClasses="tacao-bg mineShaft"
                        modalTitleClass="h3"
                        modalBodyClass="h7"
                        modalTitle="Are you sure?"
                        modalBody="Once you proceed, all entries made in this section will be lost. "
                        btnText1="PROCEED"
                        btnText2="CANCEL"
                        btn1Classes="linen"
                        txtBtn={true}
                        btn2Classes="vinRouge"
                        btn1={() => { this.props.reset(); window.location.href = '/intro' }}
                        btn2={this.props.closeWarning} />
                }
                {this.props.page1 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(1)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="11.1%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page1">
                            <div className='bizarre-bg main height80' >

                                <div className="h4 mineShaft nxtLine">{this.props.reUploadWorkstationImages.length > 0 ? this.props.reUploadWorkstationImages.indexOf("workstationSide")+1 : 1}.    A picture of your workstation from
                                    the side to capture your computer and chair.
                                    Here’s a reference photograph</div>
                                <div className="responsiveDiv">
                                    <div className="sampleImg">
                                        <img src={workstation1} alt="Sample workstation" />
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className="nxt-btn ws-nxt-btn spice-bg" onClick={() => this.props.nextPage(2)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page2 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(2)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="22.2%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page2">
                            <div className='bizarre-bg main height100' >
                                <div className="responsiveDiv">
                                        <div className="uploadImg">
                                        {isWorkStationSideShowCam ? (
                                            <>
                                                <Webcam
                                                    audio={false}
                                                    mirrored={true}
                                                    width={296}
                                                    ref={this.setRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                    style={{ borderRadius: 8 }}
                                                />
                                                <div className="row v-ctr cameraBar">
                                                    <UploadImage
                                                        keyImage={'workstationSide'}
                                                        onUploadImage={(base64) => this.uploadImage('workstationSide', base64)}
                                                        isShowErrorInside={false}
                                                    />
                                                    <img onClick={() => this.capture("workstationSide")} src={camera} alt="" />
                                                    <FlipCamera isShow={this.state.isFlippableCamera} onClick={this.cameraSettings}/>
                                                </div>
                                                <br/>
                                                <UploadImageError keyError="workstationSide"/>
                                            </>
                                        ) : (<>
                                            <img className="capturedImg" src={workStationSideSrc} alt="workstationSide" />


                                            <div className="row space-between cameraBar"> <img src={retake} onClick={() => this.props.reCapture("workstationSide")} alt="" />
                                                <button
                                                    disabled={!this.state.isWorkStationSideNextable}
                                                    className={"nxt-btn " + (this.state.isWorkStationSideNextable ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(3)}>
                                                    <i className='fa fa-angle-right linen'></i>
                                                </button></div>
                                        </>
                                        )}

                                    </div>
                                    {/* <div className="txtRight nxt-btn-div">
                                        <button
                                            disabled={!this.props.workstationSide}
                                            className={"nxt-btn " + (this.props.workstationSide ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(3)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div> */}
                                </div>


                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page3 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(3)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="33.3%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page3">
                            <div className='bizarre-bg main height100' >
                                <div className="h4 mineShaft">{this.props.reUploadWorkstationImages.length > 0 ? this.props.reUploadWorkstationImages.indexOf("workstationBack")+1 : 2}. A picture of your workstation from the back to capture the desktop, keyboard and mouse.
                                    Here’s a reference photograph.</div>
                                <form className="responsiveDiv">
                                    <div className="sampleImg">
                                        <img src={workstation2} alt="Sample workstation" />
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className="nxt-btn spice-bg" onClick={() => this.props.nextPage(4)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page4 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(4)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="44.4%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page4">
                            <div className='bizarre-bg main height100' >
                                <div className="responsiveDiv">
                                    <div className="uploadImg">
                                        {isWorkStationBackShowCam ? (
                                            <>
                                                <Webcam
                                                    audio={false}
                                                    mirrored={true}
                                                    width={296}
                                                    ref={this.setRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                    style={{ borderRadius: 8 }}
                                                />
                                                <div className="row v-ctr cameraBar">
                                                    <UploadImage
                                                        keyImage={'workstationBack'}
                                                        onUploadImage={(base64) => this.uploadImage('workstationBack', base64)}
                                                        isShowErrorInside={false}
                                                    />
                                                    <img onClick={() => this.capture("workstationBack")} src={camera} alt="" />
                                                    <FlipCamera isShow={this.state.isFlippableCamera} onClick={this.cameraSettings}/>
                                                </div>
                                                <br/>
                                                <UploadImageError keyError="workstationBack"/>
                                            </>
                                        ) : (
                                            <>
                                                <img className="capturedImg" src={workStationBackSrc} alt="workstationBack" />
                                                <div className="row space-between cameraBar"> <img src={retake} onClick={() => this.props.reCapture("workstationBack")} alt="" />
                                                    <button
                                                        disabled={!this.state.isWorkStationBackNextable}
                                                        className={"nxt-btn " + (this.state.isWorkStationBackNextable ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.openModal3()}>
                                                        <i className='fa fa-angle-right linen'></i>
                                                    </button></div>
                                            </>
                                        )}
                                    </div>
                                    {/* <div className="txtRight nxt-btn-div">
                                        <button
                                            disabled={!this.props.workstationBack}
                                            className={"nxt-btn " + (this.props.workstationBack ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.openModal3()}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                        {this.props.modal3 &&
                            <Modal
                                modalClasses="capePalliser-bg linen"
                                modalTitleClass="h4"
                                modalBodyClass="h9"
                                modalTitle="We strongly recommend that the following two pictures be clicked by someone else* as they properly need to capture you in your workspace."
                                modalBody="*If you do not have anyone to assist you, extend your arms and take a ‘selfie’ with you and as much of your workstation as possible in the background.  "
                                btn2={() => { this.props.closeModal3() }}
                                btnText1=""
                                txtBtn={true}
                                btnText2="OK"
                                btn2Classes="linen"
                            />}
                    </div>
                }
                {
                    this.props.page5 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(5)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="55.5%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page5">
                            <div className='bizarre-bg main height100' >
                                <div className="h4 mineShaft">{this.props.reUploadWorkstationImages.length > 0 ? this.props.reUploadWorkstationImages.indexOf("userSide")+1 : 3}. A side picture of you in your workstation. Here’s a reference photograph.</div>
                                <form className="responsiveDiv">
                                    <div className="sampleImg">
                                        <img src={workstation3} alt="Sample workstation" />
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className="nxt-btn spice-bg" onClick={() => this.props.nextPage(6)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page6 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(6)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="66.6%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page6">
                            <div className='bizarre-bg main height100' >
                                <div className="responsiveDiv">
                                    <div className="uploadImg">
                                        {isUserSideShowCam ? (
                                            <>
                                                <Webcam
                                                    audio={false}
                                                    mirrored={true}
                                                    width={296}
                                                    ref={this.setRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                    style={{ borderRadius: 8 }}
                                                />
                                                <div className="row v-ctr cameraBar">
                                                    <UploadImage
                                                        keyImage={'userSide'}
                                                        onUploadImage={(base64) => this.uploadImage('userSide', base64)}
                                                        isShowErrorInside={false}
                                                    />
                                                    <img onClick={() => this.capture("userSide")} src={camera} alt="" />
                                                    <FlipCamera isShow={this.state.isFlippableCamera} onClick={this.cameraSettings}/>
                                                </div>
                                                <br/>
                                                <UploadImageError keyError="userSide"/>
                                            </>
                                        ) : (
                                            <>
                                                <img className="capturedImg" src={userSideSrc} alt="userSide" />
                                                <div className="row space-between cameraBar"> <img src={retake} onClick={() => this.props.reCapture("userSide")} alt="" />
                                                    <button
                                                        disabled={!this.state.isUserSideNextable}
                                                        className={"nxt-btn " + (this.state.isUserSideNextable ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(7)}>
                                                        <i className='fa fa-angle-right linen'></i>
                                                    </button></div>

                                            </>
                                        )}
                                    </div>
                                    {/* <div className="txtRight nxt-btn-div">
                                        <button
                                            disabled={!this.props.userSide}
                                            className={"nxt-btn " + (this.props.userSide ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(7)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div> */}
                                </div>

                            </div>
                        </div>

                    </div>
                }

                {
                    this.props.page7 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(7)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="77.7%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page7">
                            <div className='bizarre-bg main height100' >
                                <div className="h4 mineShaft">{this.props.reUploadWorkstationImages.length > 0 ? this.props.reUploadWorkstationImages.indexOf("userBack")+1 : 4}. A picture from the back of you in your workstation. Here’s a reference photograph:</div>
                                <div className="responsiveDiv">
                                    <div className="sampleImg">
                                        <img src={workstation4} alt="Sample workstation" />
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className="nxt-btn spice-bg" onClick={() => this.props.nextPage(8)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page8 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(8)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="88.8%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page8">
                            <div className='bizarre-bg main height100' >
                                <div className="responsiveDiv">
                                    <div className="uploadImg">
                                        {isUserBackShowCam ? (
                                            <>
                                                <Webcam
                                                    audio={false}
                                                    mirrored={true}
                                                    width={296}
                                                    ref={this.setRef}
                                                    screenshotFormat="image/jpeg"
                                                    videoConstraints={this.state.videoConstraints}
                                                    style={{ borderRadius: 8 }}
                                                />
                                                <div className="row v-ctr cameraBar">
                                                    <UploadImage
                                                        keyImage={'userBack'}
                                                        onUploadImage={(base64) => this.uploadImage('userBack', base64)}
                                                        isShowErrorInside={false}
                                                    />
                                                    <img onClick={() => this.capture("userBack")} src={camera} alt="" />
                                                    <FlipCamera isShow={this.state.isFlippableCamera} onClick={this.cameraSettings}/>
                                                </div>
                                                <br/>
                                                <UploadImageError keyError="userBack"/>
                                            </>
                                        ) : (
                                            <>
                                                <img className="capturedImg" src={userBackSrc} alt="screenshot" />
                                                <div className="row space-between cameraBar"> <img src={retake} onClick={() => this.props.reCapture("userBack")} alt="" />
                                                    <button
                                                        disabled={!this.state.isUserBackNextable}
                                                        className={"nxt-btn " + (this.state.isUserBackNextable ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(9)}>
                                                        <i className='fa fa-angle-right linen'></i>
                                                    </button></div>

                                            </>
                                        )}
                                    </div>
                                    {/* <div className="txtRight nxt-btn-div">
                                        <button
                                            disabled={!this.props.userBack}
                                            className={"nxt-btn " + (this.props.userBack ? "spice-bg" : "mineShaft-bg")} onClick={() => this.props.nextPage(9)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page9 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="capePalliser-bg"
                            goBack={() => this.props.goBack(9)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="100%"
                            baseColor="#ECDCD3"
                            completedColor="#68422D"
                            active="workstationImages"
                            headerTitle="WORKSTATION IMAGES"
                            isShowProfileNavigator={false}
                        />
                        <div className="ws-page9">
                        {
                            this.props.reUploadWorkstationImages.length > 0
                            ? (
                                <div className='linen-bg main height100' >
                                    <div className="h3 spice">Re-Uploaded Workstation Photos</div>
                                    <div className="responsiveDiv reUploadResponsiveDiv">
                                        <div className="finalImgContainer reUploadFinalImgContainer">
                                            <div className="col">
                                                <div className="col width100">
                                                    {
                                                        this.props.reUploadWorkstationImages.includes("workstationSide") &&
                                                        <div className="finalImg">
                                                            <img src={workStationSideSrc} alt="Your Workstation from side" />
                                                        </div>
                                                    }
                                                    {
                                                        this.props.reUploadWorkstationImages.includes("workstationBack") &&
                                                        <div className="finalImg">
                                                            <img src={workStationBackSrc} alt="Your Workstation from back" />
                                                        </div>
                                                    }
                                                    {
                                                        this.props.reUploadWorkstationImages.includes("userSide") &&
                                                        <div className="finalImg">
                                                            <img src={userSideSrc} alt="Your Workstation with you from side" />
                                                        </div>
                                                    }
                                                    {
                                                        this.props.reUploadWorkstationImages.includes("userBack") &&
                                                        <div className="finalImg">
                                                            <img src={userBackSrc} alt="Your Workstation with you from back" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="txtRight">
                                            <button
                                                className={"save-btn save-progress linen spice-bg"}
                                                onClick={() => this.props.checkCompletion()}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className='linen-bg main height100' >
                                    <div className="h3 spice"> Workstation Photos</div>
                                    <div className="responsiveDiv">
                                        <div className="finalImgContainer">
                                            <div className="col">
                                                <div className="row width100 space-between">
                                                    <div className="finalImg">
                                                        <img src={workStationSideSrc} alt="Your Workstation from side" />
                                                    </div>
                                                    <div className="finalImg">
                                                        <img src={workStationBackSrc} alt="Your Workstation from back" />
                                                    </div>
                                                </div>
                                                <div className="row width100 space-between">
                                                    <div className="finalImg">
                                                        <img src={userSideSrc} alt="Your Workstation with you from side" />
                                                    </div>
                                                    <div className="finalImg">
                                                        <img src={userBackSrc} alt="Your Workstation with you from back" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="txtRight">
                                            <button
                                                className={"save-btn save-progress linen spice-bg"}
                                                onClick={() => this.props.checkCompletion()}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        </div>
                        {this.props.modal2 &&
                            <Modal
                                modalClasses="capePalliser-bg linen"
                                modalTitleClass="h3"
                                modalBodyClass="h11"
                                modalTitle="Your workplace ergonomic assessment is now complete!"
                                modalBody=""
                                txtBtn={false}
                                btn2={() => {
                                    this.props.saveProgress();
                                    this.props.reUploadRequestedDate && this.props.reUploadRequestedDate.length > 0
                                    ? this.props.reUploadCompletionAcknowledgementMail()
                                    : this.props.ewaAssessmentCompletionMail();
                                }}
                                btnText1=""
                                btnText2="OK"
                                btn2Classes="linen bilobaFlower"
                                />}
                    </div>
                }
            </div >
        )
    }
}

export default WorkstationImagesView;