import React from 'react';
import i18n from '../../../../i18n';
import Constants from '../../../../constants';

const PatientPersonalInfo = ({ ewa = {}, titleExtendClass = '' }) => {
  const isMale = () => {
    return ewa?.gender === Constants.Gender.male;
  };

  const isPregnantKey = (key) => 'pregnantString' === key;
  return (
    <>
      <div className='flex-row'>
        <span className={`h9 report__user__info__items ${titleExtendClass}`}>
          {`${i18n.admin.report.name}: `}
          <span className='h10 capitalize report__user__info__items__content'>
            {ewa?.name}
          </span>
        </span>
      </div>
      <div className='flex-row'>
        <span className={`h9 report__user__info__items ${titleExtendClass}`}>
          {`${i18n.admin.report.company}: `}
          <span className='h10 capitalize report__user__info__items__content'>
            {ewa?.workPlace}
          </span>
        </span>
      </div>
      <div className='flex-row'>
        <span className={`h9 report__user__info__items ${titleExtendClass}`}>
          {`${i18n.admin.report.occupation}: `}
          <span className='h10 capitalize report__user__info__items__content'>
            {ewa?.occupation}
          </span>
        </span>
      </div>
      <div className='flex-row'>
        <span className={`h9 report__user__info__items ${titleExtendClass}`}>
          {`${i18n.email}: `}
          <span className='h10 lowercase report__user__info__items__content'>
            {ewa?.email}
          </span>
        </span>
      </div>
      <br />
      <hr />
      <div className='report__user__info'>
        {[
          'age',
          'gender',
          'heightInUnitString',
          'weightInUnitString',
          'dominantHandString',
          'pregnantString',
        ].map((key) => {
          if (isMale() && isPregnantKey(key)) {
            return <div key={key}></div>;
          }

          return (
            <div
              key={key}
              className={`report__user__info__items ${titleExtendClass} ${
                key ? '' : 'report__user__info__items--empty'
              }`}
            >
              {key !== null && (
                <span className='h9'>
                  {`${i18n.admin.report?.[key] ?? key}: `}
                  <span className='report__user__info__items__content'>
                    {ewa?.[key]}
                  </span>
                </span>
              )}
            </div>
          );
        })}
      </div>

      <br />
      <hr />
      {
        <div className='report__user__info'>
          {[
            'lensTypeString',
            'monitorView',
            null,
            'paperView',
            null,
            'peopleView',
          ].map((key, index) => {
            return (
              <div
                key={key ?? index}
                className={`report__user__info__items ${titleExtendClass} ${
                  key && ewa?.[key] ? '' : 'report__user__info__items--empty'
                }`}
              >
                {key !== null && ewa?.[key] && (
                  <span className='h9'>
                    {`${i18n.admin.report?.[key] ?? key}: `}
                    <span className='report__user__info__items__content'>
                      {ewa?.[key]}
                    </span>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      }
    </>
  );
};

export default React.memo(PatientPersonalInfo);
