const Routes = {
  ADMIN_LOGIN_PAGE: '/ewadminlogin',
  CUSTOMER_LOGIN_PAGE: '/login',

  HOME: '/intro',
  // ASSESSMENT SECTIONS
  ASSESSMENT_INFO: '/basicInfo',
  ASSESSMENT_NATURE_OF_WORK: '/natureOfWork',
  ASSESSMENT_WORKSTATION: '/workstationAssessment',
  ASSESSMENT_PAIN: '/pain&discomfort',
  ASSESSMENT_WORKSTATION_IMAGE: '/workstationImages',

  ASSESSMENT_SUMMARY: '/summary',
};

export default Routes;
