import React, { Component } from 'react'
import { DateSelect } from '../../components/rosa/Input'
import CreateAccountScreenWave from '../../assets/img/rosa/onboard/create-account-wave.png'
import tickselect from '../../assets/img/rosa/admin/tick-select.png'
import tickunselect from '../../assets/img/rosa/admin/tick-unselect.png'
import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'

const getMaxDate = maxdate => {
  const date = new Date(maxdate)
  const dd = date.getDate(),
    mm = date.getMonth(),
    yy = date.getFullYear()
  return `${yy}-${mm < 10 ? '0' : ''}${mm + 1}-${dd}`
}

const CreateAccount = props => {
  const { form, error, onFormChange, Fields, currentTab, commonError } = props;
  return (
    <div className='container noScroll linen-bg height100vh width100'>
      <div className='col responsiveDiv login-view signup-view height100'>
        <img src={CreateAccountScreenWave} className='wave-img' />
        <div className='col form-box width100 height100'>
          <div className='h3'>Create New Account</div>
          <form
            className='col width100 f-rest'
            onSubmit={props.onSubmit}
            noValidate
          >
            {Fields.map(_ => {
              return (
                _.tab === currentTab && (
                  <div
                    key={_.name}
                    className={`h7 col mineShaft questionBox ${
                      Boolean(_.required) ? 'req' : ''
                    } ${Boolean(error[_.name]) ? 'error' : ''}`}
                  >
                    <label htmlFor={_.id} className='label'>
                      {_.label}
                    </label>
                    {_.type === 'radio' ? (
                      <div className='row space-between radio'>
                        {_.options.map((option, i) => {
                          return (
                            <div
                              className='row'
                              key={i}
                              onClick={() =>
                                onFormChange({
                                  target: { name: _.name, value: option.value }
                                })
                              }
                            >
                              <div
                                className={`radio-circle ${
                                  Boolean(error[_.name]) ? 'error' : ''
                                }`}
                              >
                                {form[_.name] === option.value && (
                                  <div className='width100 height100 radio-checked'></div>
                                )}
                              </div>
                              <label htmlFor={'radio' + _.name}>
                                {option.label}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    ) : _.type === 'date' ? (
                      <DateSelect
                        value={form[_.name]}
                        maxDate={new Date()}
                        onChange={date =>
                          onFormChange({
                            target: { value: date, name: _.name }
                          })
                        }
                      />
                    ) : (
                      <input
                        autoFocus={_.autoFocus}
                        max={_.type === 'date' ? getMaxDate(_.max) : undefined}
                        required={_.required}
                        type={_.type || 'text'}
                        id={_.id}
                        name={_.name}
                        className='cornflowerBlue-bg'
                        value={form[_.name]}
                        onChange={onFormChange}
                        {..._?.inputProps}
                      />
                    )}
                    {Boolean(error[_.name]) && (
                      <div className='h14 error-text'>{error[_.name]}</div>
                    )}
                  </div>
                )
              )
            })}
            {currentTab === 2 && (
              <div
                className={`check-box row mineShaft ${
                  props.isAcceptedTC ? 'choosen' : error.tc ? 'error' : ''
                }`}
                onClick={props.onChooseTC}
              >
                <img
                  src={props.isAcceptedTC ? tickselect : tickunselect}
                  className='box'
                />
                <div className='h9 '>
                  I have read and understood the privacy policy of Recoup
                </div>
              </div>
            )}
            <br />
            {commonError && (
              <div className={'error'}>
                <div className='h14 error-text'>{commonError}</div>
              </div>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='row v-ctr width100'>
              {currentTab === 1 ? (
                <>
                  <span className='h8 mineShaft'>ALREADY A MEMBER?</span>
                  <a className='h5 toreaBay create-link' href='/login'>
                    Sign in
                  </a>
                </>
              ) : (
                <div className='h12 mineShaft'>
                  Note: We will use your email address to send your reports
                  after assessment
                </div>
              )}
            </div>
            <br />
            <div className='col v-end f-rest v-ctr'>
              <button
                disabled={!props.enableNext}
                className='nxt-btn mineShaft-bg'
                type={currentTab === 1 ? 'button' : 'submit'}
                onClick={currentTab === 1 ? props.onSubmit : () => {}}
              >
                <i className='fa fa-angle-right linen'></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount
