import ewa from "./ewa";
import providerEwa from './providerEwa';
import providerAuth from './providerAuth';

const api = {
  ewa,
  providerEwa,
  providerAuth,
};

export default api;
