import React, { Component } from 'react'
import bglogin from '../../../assets/img/rosa/admin/bglogin.png'
import './AdminView.scss'
import Constants from '../../../constants'
var CryptoJS = require('crypto-js')

class AdminLoginView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      submitPress: false
    }
  }

  validateEmail = email => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  login = () => {
    this.setState({ submitPress: true })
    if (this.state.email !== '' && this.state.password !== '') {
      var data = JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })

      var xhr = new XMLHttpRequest()
      xhr.withCredentials = true

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          console.log('asdasdasd', this.responseText)
          if (JSON.parse(this.responseText).email) {
            // Encrypt
            let ciphertext = CryptoJS.AES.encrypt(
              this.responseText,
              'sensical@123$%6789true'
            ).toString()

            localStorage.setItem('sensical', ciphertext)
            localStorage.setItem("from", "login")
            window.location.href = '/ewadminlanding'
          } else {
            alert('User not found')
          }
        }
      })

      xhr.open('POST', process.env.REACT_APP_API_URL + '/api/auth/providersignin')
      xhr.setRequestHeader('conte', 'application/json')
      xhr.setRequestHeader('Content-Type', 'application/json')

      xhr.send(data)
    }
  }
  render () {
    return (
      <div
        className='container noScroll'
        style={{ backgroundImage: `url(${bglogin})` }}
      >
        <div className='frame white-bg'>
          <div className='innerFrame'>
            <div className='width100 h3 centerAlign toreaBay'>
              Ergonomic
              <br />
              Workplace Analysis
              <br />
              <span className='h11'>by Recoup</span>
            </div>
            <br />
            <br />
            <div className='white-bg h7'>
              <label className='h11'>Email</label>
              <br />
              <input
                autoFocus={true}
                type='text'
                id='adminEmail'
                className={
                  this.state.email === '' && this.state.submitPress
                    ? 'txtInput mineShaft width100 error-border'
                    : 'txtInput mineShaft width100 tacao-border'
                }
                onChange={evt => this.setState({ email: evt.target.value })}
              />
            </div>
            <br />
            <div className='white-bg h7 '>
              <label className='h11'>Password</label>
              <br />
              <input
                type='password'
                id='adminPassword'
                className={
                  this.state.password === '' && this.state.submitPress
                    ? 'txtInput mineShaft width100 error-border'
                    : 'txtInput mineShaft width100 tacao-border'
                }
                onChange={evt => this.setState({ password: evt.target.value })}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    this.login()
                  }
                }}
              />
            </div>
            <br />
            <br />
            <div className='txtCenter flexCenter'>
              <div
                onClick={evt => {
                  this.login()
                }}
                className='toreaBay-bg btn white flexCenter h9'
              >
                LOGIN
              </div>
            </div>
            <div className='col nxt-btn-div'>
              <a
                className='txtCenter h9 toreaBay forget-pass'
                href={`forget-password/${Constants.UserRole.userRoleProvider}`}
              >
                Forgot Password
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminLoginView
