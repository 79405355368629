import React, { Component } from "react";
import SplashScreenWave from "../../assets/img/rosa/onboard/splash-screen-wave.png";
import './style.scss'

class Splash extends Component {
  render() {
    return (
      <div className="container noScroll linen-bg height100vh width100">
        <div className="responsiveDiv splash-view height100 width100">
          <div className="text-block">
              <div className="txtCenter h1">Ergonomic<br/>Workplace<br/>Analysis</div>
              <div className="txtCenter h5">by Recoup</div>
          </div>
          <img src={SplashScreenWave} className='wave-img' />
        </div>
      </div>
    )
  }
}

export default Splash;