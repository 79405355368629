import { axios } from '../common';

const PATH_EWA = '/api/ewa/provider';

const getReviewers = () => {
  return axios.get(PATH_EWA + '/getReviewers');
};
const sendEwaToReviewers = ({ ewaId, reviewerIds }) => {
  return axios.post(PATH_EWA + '/sendEwaToReviewers', {
    ewa_id: ewaId,
    reviewer_ids: reviewerIds,
  });
};
const upsertEwaPrimitiveData = ({ ewaId, data }) => {
  return axios.post(PATH_EWA + '/upsertEwaPrimitiveDataById', {
    ewa_id: ewaId,
    ...data,
  });
};

const providerEwa = {
  getReviewers,
  sendEwaToReviewers,
  upsertEwaPrimitiveData,
};
export default providerEwa;
