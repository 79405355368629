/**
 * The function helper handle redirect to specific login page base on user role
 */
const filterNumberOnly = (value) => {
  const filtered = value.replace(/\D/g, ''); // remove all non-digit characters

  return filtered;
};

const inputHelpers = {
  filterNumberOnly,
};
export default inputHelpers;
