import { Tooltip, tooltipClasses } from "@mui/material";

export const ToolTip = ({ children, placement = "right", title }) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      slotProps={{
        tooltip: {
          sx:{
            [`&.${tooltipClasses.tooltip}`]: {
              backgroundColor: "#343434",
              color: "white"
            }
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
};
