import React, { Component } from "react";
import SummaryView from "../../views/rosa/SummaryView";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getUserInfo } from "../../redux";
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import ewa from "../../redux/api/ewa";

const mapStateToProps = (state) => {
    return {
        rosaGrandScore: state.rosaGrandScore,
        totalBreakTime: state.totalBreakTime,
        totalPhoneTime: state.totalPhoneTime,
        totalWorkDeviceTime: state.totalWorkDeviceTime,
        devicePercentage: state.devicePercentage,
        phonePercentage: state.phonePercentage,
        breakPercentage: state.breakPercentage,
        barData: state.barData,
        workTasks: state.workTasks,
        patientDetails: state
    }
}

class SummaryController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            introOpen: true,
            summaryReport: false,
            doughnutData: {
                labels: ['Break', 'Phone', 'Laptop'],
                datasets: [
                    {
                        label: 'Daily device usage',
                        data: [this.props.totalBreakTime, this.props.totalPhoneTime,
                        this.props.totalWorkDeviceTime],
                        backgroundColor: [
                            'rgba(250, 169, 188, 1)',
                            'rgba(238, 194, 128, 1)',
                            'rgba(72, 177, 175, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            barData: {
                labels: [],
                datasets: [
                    {
                        barThickness: 8,
                        data: [],
                        borderRadius: 8,
                        backgroundColor: "#48B1AF"
                    }
                ],
            },
            devicePercentage: this.props.devicePercentage,
            phonePercentage: this.props.phonePercentage,
            breakPercentage: this.props.breakPercentage,
            workTasks: this.props.workTasks,
            rosaGrandScore: this.props.rosaGrandScore,
            patientDetails: this.props.patientDetails
        }
    }
    async componentDidMount() {
    //     let {
    //         devicePercentage, phonePercentage, breakPercentage, workTasks, patientDetails,
    //         rosaGrandScore
    //     } = this.state;
    //     // WARNING: For GET requests, body is set to null by browsers.
    //     var data = JSON.stringify({
    //         "id": "63f1feccb03f2481dce98db3"
    //     });

    //     var xhr = new XMLHttpRequest();
    //     xhr.withCredentials = true;

    //     xhr.addEventListener("readystatechange", function () {
    //         if (this.readyState === 4) {
    //             console.log(this.responseText);
    //             //Get it from Database
    //             let patientDetailsEHR = JSON.parse(this.responseText);
    //             devicePercentage = patientDetailsEHR.devicePercentage;
    //             phonePercentage = patientDetailsEHR.phonePercentage;
    //             breakPercentage = patientDetailsEHR.breakPercentage;
    //             workTasks = patientDetailsEHR.workTasks;
    //             patientDetails = patientDetailsEHR.patientDetails;
    //             rosaGrandScore = patientDetailsEHR.rosaGrandScore;

    //         } else {
    //             //Get it from the local redux store
    //             devicePercentage = this.props.devicePercentage;
    //             phonePercentage = this.props.phonePercentage;
    //             breakPercentage = this.props.breakPercentage;
    //             workTasks = this.props.workTasks;
    //             patientDetails = this.props.patientDetails;
    //             rosaGrandScore = this.props.rosaGrandScore;
    //         }
    //         this.setState({
    //             devicePercentage, phonePercentage, breakPercentage,
    //             workTasks, patientDetails, rosaGrandScore
    //         });
    //     });

    //     xhr.open("GET", process.env.REACT_APP_API_URL + "/api/ewa/getEWAbyId");
    //     xhr.setRequestHeader("Content-Type", "application/json");
    //     xhr.send(data);
        let ewaRecord = await this.loadEwaRecord();
        if (!ewaRecord) {
            return;
        }

        this.setState({
          ...this.state,
          rosaGrandScore: ewaRecord.rosaGrandScore,
          reUploadCompletionDate: ewaRecord.reUploadCompletionDate
        });
    }
    saveToEHR = () => {
        // WARNING: For POST requests, body is set to null by browsers.
        let patientDetailsEHR = this.state.patientDetails;
        console.log("patientDetailsEHR ", patientDetailsEHR)
        delete patientDetailsEHR["doctor"];
        const user = getUserInfo()
        if (user) {
            patientDetailsEHR = { ...patientDetailsEHR, ...user }
        }
        var data = JSON.stringify(patientDetailsEHR);
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {

            }
        });
        const authToken = localStorage.getItem('API-Key');
        
        xhr.open("POST", process.env.REACT_APP_API_URL + "/api/ewa/user/upsert");
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader('x-access-token', authToken);
        xhr.send(data);
    }

    openSummaryReport = () => {
        this.setState({ summaryReport: true, introOpen: false }, () => {
            this.buildChart();
            this.saveToEHR();
        })
    }
    buildChart = () => {
        let { barData } = this.state;
        let workTasks = this.props.workTasks;
        for (const task in workTasks) {
            console.log("Task ", Object.values(workTasks[task])[0]);
            let taskMins = Number(Object.values(workTasks[task])[0]);
            let taskHrs = Math.round(taskMins / 60);
            barData["labels"].push(Object.keys(workTasks[task])[0]);
            barData["datasets"][0]["data"].push(taskHrs);
        }
    }
    render() {
        return (<SummaryView
            {...this.state}
            home={true}
            openSummaryReport={this.openSummaryReport}
            goIntro={() => this.setState({ introOpen: true, summaryReport: false })}
        />)

    }
}

export default withRouter(connect(mapStateToProps)(SummaryController));