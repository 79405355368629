import { Component } from 'react';
import api from '../../../redux/api';
import Routes from '../../../constants/routes';

const SUBSEQUENT_ASSESSMENT_ROUTES = {
  [Routes.ASSESSMENT_NATURE_OF_WORK]: Routes.ASSESSMENT_WORKSTATION,
};

/**
* Base component for each sections in assessment
*/
class EWAScoreComponent extends Component {

  /**
  * Find out the next SECTION by the current section.
  */
  computedNextSection = ({ isLastPage = true, currentRoute }) => {
    return isLastPage
      ? SUBSEQUENT_ASSESSMENT_ROUTES[currentRoute]
      : currentRoute;
  };

  /**
  * Computed the next PAGE that user need to continue the section
  */
  computedDonePages = ({ maxPage, lastPage }) => {
    if (lastPage > maxPage) {
      return { sectionIntroOpen: true };
    }

    let i = 1;
    let donePages = {};
    for (i; i <= maxPage; i++) {
      donePages = {
        ...donePages,
        [`page${i}`]: lastPage === i,
        sectionIntroOpen: lastPage === 0,
      };
    }
    return donePages;
  };
}

export default EWAScoreComponent;
