/**
 * The function helper handle redirect to specific login page base on user role
 */
const capitalizedWord = (text) => {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/*
* Note that we use 365.25 instead of 365 to account for leap years.
*/
const ageFromDate = (stringDate) => {
  if (!stringDate) {
    return '';
  }

  const birthday = new Date(stringDate);
  const today = new Date();
  const ageInMilliseconds = today - birthday;
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  return Math.floor(ageInYears);
};

const textHelpers = {
  capitalizedWord,
  ageFromDate,
};
export default textHelpers;
