import close from '../../assets/img/rosa/admin/close.png';
import tickCircle from '../../assets/img/rosa/admin/tickCircle.png';

const Alert = ({ content = '', onClose = () => {} }) => {
  return (
    <div className='alert'>
      <img className='close' alt='close' src={close} onClick={onClose} />
      <img src={tickCircle} alt='tick' />
      <br />
      <br />
      <div class='h14 white uppercase'>{content}</div>
    </div>
  );
};

export default Alert;
