import React, { Component } from "react";
import Header from "../../components/rosa/Header";
import SectionIntro from "../../components/rosa/SectionIntro";
import Modal from "../../components/rosa/Modal";
import "./WorkstationAssessmentView.scss";
import chairHeight1 from '../../assets/img/rosa/chairHeight1.png';
import chairHeight2 from '../../assets/img/rosa/chairHeight2.png';
import chairHeight3 from '../../assets/img/rosa/chairHeight3.png';
import chairHeight4 from '../../assets/img/rosa/chairHeight4.png';
import chairHeight5 from '../../assets/img/rosa/chairHeight5.png';
import chairHeight6 from '../../assets/img/rosa/chairHeight6.png';
import armRest1 from '../../assets/img/rosa/armRest1.png';
import armRest2 from '../../assets/img/rosa/armRest2.png';
import armRest3 from '../../assets/img/rosa/armRest3.png';
import armRest4 from '../../assets/img/rosa/armRest4.png';
import armRest5 from '../../assets/img/rosa/armRest5.png';
import backSupport1 from '../../assets/img/rosa/backSupport1.png';
import backSupport2 from '../../assets/img/rosa/backSupport2.png';
import backSupport3 from '../../assets/img/rosa/backSupport3.png';
import backSupport4 from '../../assets/img/rosa/backSupport4.png';
import backSupport5 from '../../assets/img/rosa/backSupport5.png';
import backSupport6 from '../../assets/img/rosa/backSupport6.png';
import keyboard1 from '../../assets/img/rosa/keyboard1.png';
import keyboard2 from '../../assets/img/rosa/keyboard2.png';
import keyboard3 from '../../assets/img/rosa/keyboard3.png';
import keyboard4 from '../../assets/img/rosa/keyboard4.png';
import keyboard5 from '../../assets/img/rosa/keyboard5.png';
import keyboard6 from '../../assets/img/rosa/keyboard6.png';
import monitorSetup1 from '../../assets/img/rosa/monitorSetup1.png';
import monitorSetup2 from '../../assets/img/rosa/monitorSetup2.png';
import monitorSetup3 from '../../assets/img/rosa/monitorSetup3.png';
import monitorSetup4 from '../../assets/img/rosa/monitorSetup4.png';
import monitorSetup5 from '../../assets/img/rosa/monitorSetup5.png';
import monitorSetup6 from '../../assets/img/rosa/monitorSetup6.png';
import monitorSetup7 from '../../assets/img/rosa/monitorSetup7.png';
import mouse1 from '../../assets/img/rosa/mouse1.png';
import mouse2 from '../../assets/img/rosa/mouse2.png';
import mouse3 from '../../assets/img/rosa/mouse3.png';
import mouse4 from '../../assets/img/rosa/mouse4.png';
import mouse5 from '../../assets/img/rosa/mouse5.png';
import seatPan1 from '../../assets/img/rosa/seatPan1.png';
import seatPan2 from '../../assets/img/rosa/seatPan2.png';
import seatPan3 from '../../assets/img/rosa/seatPan3.png';
import seatPan4 from '../../assets/img/rosa/seatPan4.png';
import softTissue1 from '../../assets/img/rosa/softTissue1.png';
import softTissue2 from '../../assets/img/rosa/softTissue2.png';
import softTissue3 from '../../assets/img/rosa/softTissue3.png';
import telephone1 from '../../assets/img/rosa/telephone1.png';
import telephone2 from '../../assets/img/rosa/telephone2.png';
import telephone3 from '../../assets/img/rosa/telephone3.png';
import telephone4 from '../../assets/img/rosa/telephone4.png';
import ErrorText from "../../components/atoms/ErrorText";
import i18n from '../../i18n/index';
import helpers from "../../helpers";
import Constants from "../../constants";
import { Redirect } from 'react-router-dom'

const ImgList =
{
    chairHeight1: chairHeight1, chairHeight2: chairHeight2, chairHeight3: chairHeight3,
    chairHeight4: chairHeight4, chairHeight5: chairHeight5, chairHeight6: chairHeight6,
    seatPan1: seatPan1, seatPan2: seatPan2, seatPan3: seatPan3, seatPan4: seatPan4,
    armRest1: armRest1, armRest2: armRest2, armRest3: armRest3, armRest4: armRest4, armRest5: armRest5,
    backSupport1: backSupport1, backSupport2: backSupport2, backSupport3: backSupport3, backSupport4: backSupport4,
    backSupport5: backSupport5, backSupport6: backSupport6, monitorSetup1: monitorSetup1, monitorSetup2: monitorSetup2, monitorSetup3: monitorSetup3,
    monitorSetup4: monitorSetup4, monitorSetup5: monitorSetup5, monitorSetup6: monitorSetup6, monitorSetup7: monitorSetup7,
    telephone1: telephone1, telephone2: telephone2, telephone3: telephone3, telephone4: telephone4, mouse1: mouse1,
    mouse2: mouse2, mouse3: mouse3, mouse4: mouse4, mouse5: mouse5,
    keyboard1: keyboard1, keyboard2: keyboard2, keyboard3: keyboard3, keyboard4: keyboard4, keyboard5: keyboard5,
    keyboard6: keyboard6, softTissue1: softTissue1, softTissue2: softTissue2, softTissue3: softTissue3
}

const radioParameters = [

    {
        assessment: "chairHeight",
        values: ["You’re sitting comfortably with knees at 90°", "Your chair is too low (knees less than 90°)",
            "Your chair is too high (knees greater than 90°)", "Your feet do not touch the ground"],
        imgs: ["chairHeight1", "chairHeight2", "chairHeight3", "chairHeight4"],
        score: [1, 2, 2, 3]
    },
    {
        assessment: "seatPan",
        values: ["You have approximately 3 inches of space between knee and edge of seat", "Your seat pan is too long  (less than 3 inches of space)",
            "Your seatpan is too short (greater than 3 inches of space)"],
        imgs: ["seatPan1", "seatPan2", "seatPan3"],
        score: [1, 2, 2]
    },
    {
        assessment: "armRest",
        values: ["Your elbows are supported in line with you shoulders & your shoulders are relaxed", "Your shoulder position is too high / too low"],
        imgs: ["armRest1", "armRest2"],
        score: [1, 2]
    },
    {
        assessment: "backSupport",
        values: ["Your chair has adequate lumbar support / recline between 95° - 110°", "Your chair doesn’t have lumbar support / does not support the small of your back",
            "Your chair is angled too far back ( >110°) or too far forward ( <95°)", "You do not have back support (working on a stool / leaning )"],
        imgs: ["backSupport1", "backSupport2", "backSupport3", "backSupport4"],
        score: [1, 2, 2]
    },
    {
        assessment: "monitorSetup",
        values: ["Your screen is arms length distance away & screen is at eye level", "Your monitor is too low",
            "Your monitor is too high"],
        imgs: ["monitorSetup1", "monitorSetup2", "monitorSetup3"],
        score: [1, 2, 3]
    },
    {
        assessment: "telephone",
        values: ["You use a headset / hold with one hand (neck in neutral position)", "Your telephone is too far (greater than 30cms)"],
        imgs: ["telephone1", "telephone2"],
        score: [1, 2]
    },
    {
        assessment: "mouse",
        values: ["Your Mouse or Trackpad is in line with you shoulders", "You are reaching out to the Mouse or Trackpad"],
        imgs: ["mouse1", "mouse2"],
        score: [1, 2]
    },
    {
        assessment: "keyboard",
        values: ["Your wrists are striaght & shoulders relaxed", "Your wrists are extended, keyboard at 15°"],
        imgs: ["keyboard1", "keyboard2"],
        score: [1, 2]
    },
    {
        assessment: "softTissueCompression",
        values: ["You do not have any point of contact between your wrist and the edge of the table", "You notice contact between your wrist and the edge of the table while typing or mousing",
            "You notice a visible red mark on your skin after resting your wrist on the edge of the table"],
        imgs: ["softTissue1", "softTissue2", "softTissue3"],
        score: [0, 0, 0]
    }
];
const optionalParameters = [
    {
        assessment: "chairHeight",
        values: ["Insufficient space under the desk",
            "Your chair height is not adjustable"],
        imgs: ["chairHeight5", "chairHeight6"]
    },
    {
        assessment: "seatPan",
        values: ["Your seat pan is not adjustable"],
        imgs: ["seatPan4"]
    },
    {
        assessment: "armRest",
        values: ["Your arm rests have hard / damaged surface", "Your arm rests are too wide",
            "Your arm rests are non adjustable"],
        imgs: ["armRest3", "armRest4", "armRest5"]
    },
    {
        assessment: "backSupport",
        values: ["Your work surface is too high / shoulders shrugged", "Your backrest is not adjustable"],
        imgs: ["backSupport5", "backSupport6"]
    },
    {
        assessment: "monitorSetup",
        values: ["Your monitor is too far", "For multiple screens, your neck twist is >30°",
            "Your screen has significant glare", "You do not have a document holder"],
        imgs: ["monitorSetup4", "monitorSetup5", "monitorSetup6", "monitorSetup7"],
    },
    {
        assessment: "telephone",
        values: ["You hold your telephone between your neck & shoulder", "You do not have a handsfree option"],
        imgs: ["telephone3", "telephone4"]
    },
    {
        assessment: "mouse",
        values: ["Your Mouse or Trackpad / keyboard are on different surfaces",
            "You have a pinch grip on the mouse",
            "You have a palm rest in front of the Mouse or Trackpad"],
        imgs: ["mouse3", "mouse4", "mouse5"]
    },
    {
        assessment: "keyboard",
        values: ["Your wrists deviate while typing", "Your keyboard is too high & shoulders shrugged", "You are reaching to overhead items",
            "Your worksurface is non adjustable"],
        imgs: ["keyboard3", "keyboard4", "keyboard5", "keyboard6"]
    }
];
class EWAScoreView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chairHeight: "",
            chairHeight5: false,
            chairHeight6: false,
            seatPan4: false,
            armRest3: false,
            armRest4: false,
            armRest5: false,
            backSupport4: false,
            backSupport5: false,
            monitorSetup4: false,
            monitorSetup5: false,
            monitorSetup6: false,
            monitorSetup7: false,
            telephone3: false,
            telephone4: false,
            mouse3: false,
            mouse4: false,
            mouse5: false,
            keyboard3: false,
            keyboard4: false,
            keyboard5: false,
            keyboard6: false,
            selectedItems: {},
            modal3: false,
            modal4: false,
            modal5: false,
            modal6: false,
            chairHeightSelected: [],
            seatPanSelected: [],
            armRestSelected: [],
            backSupportSelected: [],
            monitorSetupSelected: [],
            telephoneSelected: [],
            mouseSelected: [],
            keyboardSelected: [],
            softTissueCompressionSelected: [],
            unselectedError : {},
        }
    }

    /* 
    addSelectedItems handle merge the checkbox selection to selectedItems
    */
    addSelectedItems = (imgObj, value) => {
        let { selectedItems } = this.state;
        selectedItems = { ...selectedItems, ...this.props.selectedItems }
        
        if (!selectedItems[value]) {
            selectedItems[value] = imgObj;
        } else {
            delete selectedItems[value];
        }
        this.setState({ selectedItems });
        this.props.setSelectedItems(selectedItems);
    }

    /* 
    calculateSelectedItems handle merge the radio selection to selectedItems
    */
    calculateSelectedItems = ({ paramValue = []}) => {
        let { selectedItems,
            chairHeightSelectedImg, seatPanSelectedImg, armRestSelectedImg, backSupportSelectedImg,
            monitorSetupSelectedImg, telephoneSelectedImg, mouseSelectedImg, keyboardSelectedImg,
            softTissueCompressionSelectedImg,
        } = this.props;

        selectedItems = selectedItems == null ? {}: selectedItems
        let arr = [
            chairHeightSelectedImg, seatPanSelectedImg, armRestSelectedImg, backSupportSelectedImg,
            monitorSetupSelectedImg, telephoneSelectedImg, mouseSelectedImg, keyboardSelectedImg,
            softTissueCompressionSelectedImg,
        ];

        // Remove radio selected value by paramValue
        let i = 0;
        for (i; i < paramValue.length; i++) { 
            if (selectedItems[paramValue[i]]) {
              delete selectedItems[paramValue[i]];
            }
        }

        /*
            arrayImageValue = [['Text in options', imageKey]]
        */
        for (let i = 0; i < arr.length; i++) {
            let arrImageValue = arr?.[i]; 
            if (arrImageValue && arrImageValue?.[0] && arrImageValue?.[1]) {
              selectedItems[arrImageValue[0]] = arrImageValue[1];
            }
        }
        
        this.props.setSelectedItems(selectedItems);
    }
    drawList = (pageNo, listType) => {
        let arr = [];
        arr = [];

        if (listType === "checkbox" || listType === "radio") {
            let objParam = listType === "checkbox" ? optionalParameters : radioParameters;
            let param = objParam[pageNo - 1]["assessment"];
            let paramValue = objParam[pageNo - 1]["values"];
            let paramImgs = objParam[pageNo - 1]["imgs"];

            for (var i = 0; i < paramValue.length; i++) {
                let imgSrc = ImgList[paramImgs[i]];
                let imgRef = paramImgs[i];
                let val = paramValue[i];
                if (listType === "checkbox") {
                    arr.push(<label key={i}
                        className={"checkbox h7 row nxtLine" + ((this.props.keyImgs?.[imgRef]) ? " checked" : " ")}>{val}
                        <input type="checkbox" value={val}
                            name={param}
                            checked={this.props.keyImgs?.[imgRef]}
                            onChange={(event) => {
                                this.setState({ [imgRef]: !this.state[imgRef] }, () => {
                                    this.props.addItem(event, param, imgRef);
                                    this.addSelectedItems(imgRef, val)
                                });
                            }
                            } />
                        <img src={imgSrc} alt={val} />
                    </label>)
                }
                else if ((listType === "radio")) {
                    let score = objParam[pageNo - 1]["score"][i];
                    let paramSelected = param + "Selected";
                    arr.push(<label key={i}
                        className={"checkbox h7 row nxtLine" + ((this.props?.[paramSelected] === val) ? " checked" : "")}>{val}
                        <input type="radio" value={score} className="wa-radio"
                            name={param}
                            checked={(this.props?.[param] === val)}
                            onChange={(event) => {
                                this.setState({
                                    [param]: val,
                                    [paramSelected]: [val, imgRef],
                                    unselectedError : {},
                                }, async () => {
                                    await this.props.selectItem(event, param, val, imgRef);
                                    this.calculateSelectedItems({ paramValue });
                                });
                            }
                            } />
                        <img src={imgSrc} alt={val} />
                    </label>)
                }
            }
        } else {
            let { selectedItems } = this.props;
            const regex = Constants.Regex.firstLowerString;

            let i = 0;
            let matchKey = '';

            for (const item in selectedItems) {
                i++;

                let newMatchKey = regex.exec(selectedItems[item])?.[0];
                let isShowGroupKey = false;
                if (newMatchKey && newMatchKey !== matchKey) {
                    matchKey = newMatchKey;
                    isShowGroupKey = true;
                }

                if (isShowGroupKey) {
                    isShowGroupKey = false;
                    const durationType = this.props?.[`${matchKey}DurationType`];
                    const durationTypeCapitalized = helpers.stringHelpers.capitalizedWord(durationType)

                    const duration = this.props?.[`${matchKey}Duration`];

                    arr.push(
                        <div key={`groupKey_${i}`} className={'summary__group'}>
                            <div className='summary__group__header h8 w-100'>
                                {helpers.stringHelpers.capitalizedWord(matchKey)}
                            </div>
                            {durationTypeCapitalized && duration && (
                                <div className='summary__group__content white-bg'>
                                    <div className={'flex1'}>
                                        <div className={'h9'}>{i18n.usage}:</div>
                                        <div className={'h8'}>
                                            {durationTypeCapitalized}
                                        </div>
                                    </div>
                                    <div className={'flex1'}>
                                        <div className={'h9'}>{i18n.duration}:</div>
                                        <div className={'h8'}>
                                            {
                                            Constants.AssessmentWork.durationEnums
                                                ?.[durationType]?.[duration]
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                }

                if (selectedItems[item]) {
                    arr.push(
                      <div className='white-bg summary__content' key={i}>
                        <div className='txtCenter summary__content__items'>
                          <img src={ImgList[selectedItems[item]]} alt={item} />
                        </div>
                        <div className='txtCenter h9'>{item}</div>
                      </div>
                    );
                }
            }
        }


        return arr;
    }

    handleErrorOnNextPage(key, callback) {
        if (this.props?.[key] === '') {
            this.setState({
                ...this.state,
                unselectedError: {
                    [key]: true,
                }
            })

            return;
        }
        callback();
    }

    render() {
        return (
        <div>
        {!localStorage.getItem('sensical') ? (
          <Redirect to='/ewadminlogin' />
        ) : (<div className="container noScroll">
                {this.props.sectionIntroOpen &&
                    <div className="shipCove-bg linen">
                        <Header backBtnColor="linen"
                            goBack={this.props.warning}
                            intro={true}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="0%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle=""
                            isShowProfileNavigator={false}
                        />

                        <SectionIntro
                            sectionHeader1="Workstation"
                            sectionHeader2="Assessment"
                            bgColor="shipCove-bg wsaBgImg"
                            sectionColor="white"
                            nxtBtnClass="eastBay nxt-btn1"
                            title=''
                            backBtnColor='linen'
                            nextSection={this.props.openQuiz} />
                    </div>
                }
                {this.props.modal1 &&
                    <Modal
                        modalClasses="tacao-bg mineShaft"
                        modalTitleClass="h3"
                        modalBodyClass="h7"
                        modalTitle="Are you sure?"
                        modalBody="Once you proceed, all entries made in this section will be lost. "
                        btnText1="PROCEED"
                        btnText2="CANCEL"
                        btn1Classes="linen"
                        txtBtn={true}
                        btn2Classes="vinRouge"
                        btn1={() => { this.props.reset(); window.location.href = '/intro' }}
                        btn2={this.props.closeWarning} />
                }
                {this.props.page1 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(1)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="10%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page1">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>chair height</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(1, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(1, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.chairHeightSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.chairHeightSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('chairHeightSelected', () => this.props.nextPage(2))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page2 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(2)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="20%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page2 botticelli-bg">
                            <div className='main' >
                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>seat pan</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(2, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(2, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.seatPanSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.seatPanSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('seatPanSelected', () => this.props.nextPage(3))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page3 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(3)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="30%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page3">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>arm rest</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(3, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(3, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.armRestSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.armRestSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('armRestSelected', () => this.props.nextPage(4))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page4 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(4)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="40%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page4">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>back support</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(4, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(4, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.backSupportSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.backSupportSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('backSupportSelected', () => this.setState({ modal3: true }))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.modal3 &&
                            <div className='modal-container'>
                                <div className=' modal botticelli-bg'>
                                    <div className=' h3 modal-title'>
                                        Duration of use
                                    </div>
                                    <div className=' nxtLine h7 font-bold'>How do you use your chair?</div>
                                    <div className="grid-3-cols h7">
                                        <label htmlFor="continuouslyChair">
                                            <input id="continuouslyChair" name="chairDurationType" type='radio' value="continuously" className='cornflowerBlue-bg'
                                                checked={this.props.chairDurationType === "continuously"}
                                                onChange={(event) => this.props.saveInfo(event, "chairDurationType")}
                                            />
                                            Continuously</label>

                                        <label htmlFor="intermittentlyChair">
                                            <input id="intermittentlyChair" name="chairDurationType" type='radio' value="intermittently" className='cornflowerBlue-bg'
                                                checked={this.props.chairDurationType === "intermittently"}
                                                onChange={(event) => this.props.saveInfo(event, "chairDurationType")}
                                            />
                                            Intermittently</label>
                                        <label htmlFor="" style={{ visibility: "hidden" }}>
                                            <input id="" name="" type='radio' value="" className='cornflowerBlue-bg' />
                                        </label>
                                    </div>
                                    {this.props.chairDurationType !== "" &&
                                        <div className="durationModal">
                                            <div className=' nxtLine h7 font-bold font-bold'>Average duration of usage</div>
                                            {this.props.chairDurationType === "continuously" &&
                                                <div className="grid-3-cols h7">
                                                    <label htmlFor="chairDuration1">
                                                        <input id="chairDuration1" name="chairDurationContinuous" type='radio' value="-1" className='cornflowerBlue-bg'
                                                            //   checked={this.props.chairDuration === "less than 30 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "chairDuration")}
                                                        />
                                                        &lt; 30 Mins</label>

                                                    <label htmlFor="chairDuration2">
                                                        <input id="chairDuration2" name="chairDurationContinuous" type='radio' value="0" className='cornflowerBlue-bg'
                                                            //   checked={this.props.chairDuration === "30-60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "chairDuration")}
                                                        />
                                                        30 Mins - 1 Hr</label>
                                                    <label htmlFor="chairDuration3" >
                                                        <input id="chairDuration3" name="chairDurationContinuous" type='radio' value="1" className='cornflowerBlue-bg'
                                                            //   checked={this.props.chairDuration === "greater than 60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "chairDuration")} />
                                                        &gt; 1 Hr
                                                    </label>
                                                </div>}

                                            {this.props.chairDurationType === "intermittently" && <div className="grid-3-cols h7">
                                                <label htmlFor="chairDuration4">
                                                    <input id="chairDuration4" name="chairDurationIntermittent" type='radio' value="-1" className='cornflowerBlue-bg'
                                                        // checked={this.props.chairDuration === "less than 60 mins"}
                                                        onChange={(event) => this.props.saveInfo(event, "chairDuration")}
                                                    />
                                                    &lt; 1hr</label>

                                                <label htmlFor="chairDuration5">
                                                    <input id="chairDuration5" name="chairDurationIntermittent" type='radio' value="0" className='cornflowerBlue-bg'
                                                        // checked={this.props.chairDuration === "1hr to 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "chairDuration")}
                                                    />
                                                    1 - 4 Hrs</label>
                                                <label htmlFor="chairDuration6" >
                                                    <input id="chairDuration6" name="chairDurationIntermittent" type='radio' value="1" className='cornflowerBlue-bg'
                                                        // checked={this.props.chairDuration === "greater than 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "chairDuration")} />
                                                    &gt; 4 Hrs
                                                </label>
                                            </div>
                                            }
                                        </div>}
                                        {this.state.unselectedError?.chairDuration && <ErrorText error={i18n.commonError.selectRequired} classExtend={'mt-15'} />}
                                    <div className='txtRight durationModal'>
                                        <button className={" nxt-btn white " + (this.props.chairDuration === "" ? "mineShaft-bg" : "eastBay-bg")}
                                            onClick={() => this.handleErrorOnNextPage('chairDuration', () => this.setState({ modal3: false, unselectedError: {} }, () => this.props.nextPage(5)))}
                                        >
                                            <i className=' fa fa-angle-right '></i>
                                        </button>
                                    </div>

                                </div>
                            </div>}
                    </div>
                }
                {
                    this.props.page5 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(5)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="50%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page5">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>monitor setup</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(5, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(5, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.monitorSetupSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.monitorSetupSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('monitorSetupSelected', () => this.setState({ modal4: true }))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button></div>
                                </div>

                            </div>
                        </div>
                        {this.state.modal4 &&
                            <div className='modal-container'>
                                <div className=' modal botticelli-bg'>
                                    <div className=' h3 modal-title'>
                                        Duration of use
                                    </div>
                                    <div className=' nxtLine h7 font-bold'>How do you use your monitor?</div>
                                    <div className="grid-3-cols h7">
                                        <label htmlFor="continuouslyMonitor">
                                            <input id="continuouslyMonitor" name="monitorDurationType" type='radio' value="continuously" className='cornflowerBlue-bg'
                                                checked={this.props.monitorDurationType === "continuously"}
                                                onChange={(event) => this.props.saveInfo(event, "monitorDurationType")}
                                            />
                                            Continuously</label>

                                        <label htmlFor="intermittentlyMonitor">
                                            <input id="intermittentlyMonitor" name="monitorDurationType" type='radio' value="intermittently" className='cornflowerBlue-bg'
                                                checked={this.props.monitorDurationType === "intermittently"}
                                                onChange={(event) => this.props.saveInfo(event, "monitorDurationType")}
                                            />
                                            Intermittently</label>
                                        <label htmlFor="" style={{ visibility: "hidden" }}>
                                            <input id="" name="" type='radio' value="" className='cornflowerBlue-bg' />
                                        </label>
                                    </div>
                                    {this.props.monitorDurationType !== "" &&
                                        <div className="durationModal">
                                            <div className=' nxtLine h7 font-bold'>Average duration of usage</div>
                                            {this.props.monitorDurationType === "continuously" &&
                                                <div className="grid-3-cols h7">
                                                    <label htmlFor="monitorDuration1">
                                                        <input id="monitorDuration1" name="monitorDurationContinuous" type='radio' value="-1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.monitorDuration === "less than 30 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "monitorDuration")}
                                                        />
                                                        &lt; 30 Mins</label>

                                                    <label htmlFor="monitorDuration2">
                                                        <input id="monitorDuration2" name="monitorDurationContinuous" type='radio' value="0" className='cornflowerBlue-bg'
                                                            //  checked={this.props.monitorDuration === "30-60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "monitorDuration")}
                                                        />
                                                        30 Mins - 1 Hr</label>
                                                    <label htmlFor="monitorDuration3" >
                                                        <input id="monitorDuration3" name="monitorDurationContinuous" type='radio' value="1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.monitorDuration === "greater than 60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "monitorDuration")} />
                                                        &gt; 1 Hr
                                                    </label>
                                                </div>}

                                            {this.props.monitorDurationType === "intermittently" && <div className="grid-3-cols h7">
                                                <label htmlFor="monitorDuration4">
                                                    <input id="monitorDuration4" name="monitorDurationIntermittent" type='radio' value="-1" className='cornflowerBlue-bg'
                                                        //   checked={this.props.monitorDuration === "less than 60 mins"}
                                                        onChange={(event) => this.props.saveInfo(event, "monitorDuration")}
                                                    />
                                                    &lt; 1hr</label>

                                                <label htmlFor="monitorDuration5">
                                                    <input id="monitorDuration5" name="monitorDurationIntermittent" type='radio' value="0" className='cornflowerBlue-bg'
                                                        // checked={this.props.monitorDuration === "1hr to 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "monitorDuration")}
                                                    />
                                                    1 - 4 Hrs</label>
                                                <label htmlFor="monitorDuration6" >
                                                    <input id="monitorDuration6" name="monitorDurationIntermittent" type='radio' value="1" className='cornflowerBlue-bg'
                                                        // checked={this.props.monitorDuration === "greater than 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "monitorDuration")} />
                                                    &gt; 4 Hrs
                                                </label>
                                            </div>
                                            }
                                        </div>}
                                        {this.state.unselectedError?.monitorDuration && <ErrorText error={i18n.commonError.selectRequired} classExtend={'mt-15'} />}
                                    <div className='txtRight durationModal'>
                                        <button className={" nxt-btn white " + (this.props.monitorDuration === "" ? "mineShaft-bg" : "eastBay-bg")}
                                        onClick={() => this.handleErrorOnNextPage('monitorDuration', () => this.setState({ modal4: false, unselectedError: {} }, () => this.props.nextPage(6)))}
                                        >
                                            <i className=' fa fa-angle-right '></i>
                                        </button>
                                    </div>

                                </div>
                            </div>}
                    </div>
                }
                {
                    this.props.page6 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(6)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="60%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page6 botticelli-bg">
                            <div className='main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>telephone</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(6, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(6, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.telephoneSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.telephoneSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('telephoneSelected', () => this.setState({ modal7: true }))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.modal7 &&
                            <div className='modal-container'>
                                <div className=' modal botticelli-bg'>
                                    <div className=' h3 modal-title'>
                                        Duration of use
                                    </div>
                                    <div className=' nxtLine h7 font-bold'>How do you use your telephone?</div>
                                    <div className="grid-3-cols h7">
                                        <label htmlFor="continuouslyTelephone">
                                            <input id="continuouslyTelephone" name="telephoneDurationType" type='radio' value="continuously" className='cornflowerBlue-bg'
                                                checked={this.props.telephoneDurationType === "continuously"}
                                                onChange={(event) => this.props.saveInfo(event, "telephoneDurationType")}
                                            />
                                            Continuously</label>

                                        <label htmlFor="intermittentlyTelephone">
                                            <input id="intermittentlyTelephone" name="telephoneDurationType" type='radio' value="intermittently" className='cornflowerBlue-bg'
                                                checked={this.props.telephoneDurationType === "intermittently"}
                                                onChange={(event) => this.props.saveInfo(event, "telephoneDurationType")}
                                            />
                                            Intermittently</label>
                                        <label htmlFor="" style={{ visibility: "hidden" }}>
                                            <input id="" name="" type='radio' value="" className='cornflowerBlue-bg' />
                                        </label>
                                    </div>
                                    {this.props.telephoneDurationType !== "" &&
                                        <div className="durationModal">
                                            <div className=' nxtLine h7 font-bold'>Average duration of usage</div>
                                            {this.props.telephoneDurationType === "continuously" &&
                                                <div className="grid-3-cols h7">
                                                    <label htmlFor="telephoneDuration1">
                                                        <input id="telephoneDuration1" name="telephoneDurationContinuous" type='radio' value="-1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "less than 30 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "telephoneDuration")}
                                                        />
                                                        &lt; 30 Mins</label>

                                                    <label htmlFor="telephoneDuration2">
                                                        <input id="telephoneDuration2" name="telephoneDurationContinuous" type='radio' value="0" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "30-60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "telephoneDuration")}
                                                        />
                                                        30 Mins - 1 Hr</label>
                                                    <label htmlFor="telephoneDuration3" >
                                                        <input id="telephoneDuration3" name="telephoneDurationContinuous" type='radio' value="1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "greater than 60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "telephoneDuration")} />
                                                        &gt; 1 Hr
                                                    </label>
                                                </div>}

                                            {this.props.telephoneDurationType === "intermittently" && <div className="grid-3-cols h7">
                                                <label htmlFor="telephoneDuration4">
                                                    <input id="telephoneDuration4" name="telephoneDurationIntermittent" type='radio' value="-1" className='cornflowerBlue-bg'
                                                        //  checked={this.props.mouseDuration === "less than 60 mins"}
                                                        onChange={(event) => this.props.saveInfo(event, "telephoneDuration")}
                                                    />
                                                    &lt; 1hr</label>

                                                <label htmlFor="telephoneDuration5">
                                                    <input id="telephoneDuration5" name="telephoneDurationIntermittent" type='radio' value="0" className='cornflowerBlue-bg'
                                                        // checked={this.props.mouseDuration === "1hr to 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "telephoneDuration")}
                                                    />
                                                    1 - 4 Hrs</label>
                                                <label htmlFor="telephoneDuration6" >
                                                    <input id="telephoneDuration6" name="telephoneDurationIntermittent" type='radio' value="1" className='cornflowerBlue-bg'
                                                        // checked={this.props.mouseDuration === "greater than 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "telephoneDuration")} />
                                                    &gt; 4 Hrs
                                                </label>
                                            </div>
                                            }
                                        </div>}
                                    {this.state.unselectedError?.telephoneDuration && <ErrorText error={i18n.commonError.selectRequired} classExtend={'mt-15'} />}
                                    <div className='txtRight durationModal'>
                                        <button className={" nxt-btn white " + (this.props.telephoneDuration === "" ? "mineShaft-bg" : "eastBay-bg")}
                                            onClick={() => this.handleErrorOnNextPage('telephoneDuration', () => this.setState({ modal7: false, unselectedError: {} }, () => this.props.nextPage(7)))}
                                        >
                                            <i className=' fa fa-angle-right '></i>
                                        </button>
                                    </div>

                                </div>
                            </div>}
                    </div>
                }

                {
                    this.props.page7 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(7)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="70%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page7">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>Mouse or Trackpad</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(7, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(7, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.mouseSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            className={"nxt-btn ws-nxt-btn" + (this.props.mouseSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                            onClick={() => this.handleErrorOnNextPage('mouseSelected', () => this.setState({ modal5: true }))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.modal5 &&
                            <div className='modal-container'>
                                <div className=' modal botticelli-bg'>
                                    <div className=' h3 modal-title'>
                                        Duration of use
                                    </div>
                                    <div className=' nxtLine h7 font-bold'>How do you use your mouse?</div>
                                    <div className="grid-3-cols h7">
                                        <label htmlFor="continuouslyMouse">
                                            <input id="continuouslyMouse" name="mouseDurationType" type='radio' value="continuously" className='cornflowerBlue-bg'
                                                checked={this.props.mouseDurationType === "continuously"}
                                                onChange={(event) => this.props.saveInfo(event, "mouseDurationType")}
                                            />
                                            Continuously</label>

                                        <label htmlFor="intermittentlyMouse">
                                            <input id="intermittentlyMouse" name="mouseDurationType" type='radio' value="intermittently" className='cornflowerBlue-bg'
                                                checked={this.props.mouseDurationType === "intermittently"}
                                                onChange={(event) => this.props.saveInfo(event, "mouseDurationType")}
                                            />
                                            Intermittently</label>
                                        <label htmlFor="" style={{ visibility: "hidden" }}>
                                            <input id="" name="" type='radio' value="" className='cornflowerBlue-bg' />
                                        </label>
                                    </div>
                                    {this.props.mouseDurationType !== "" &&
                                        <div className="durationModal">
                                            <div className=' nxtLine h7 font-bold'>Average duration of usage</div>
                                            {this.props.mouseDurationType === "continuously" &&
                                                <div className="grid-3-cols h7">
                                                    <label htmlFor="mouseDuration1">
                                                        <input id="mouseDuration1" name="mouseDurationContinuous" type='radio' value="-1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "less than 30 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "mouseDuration")}
                                                        />
                                                        &lt; 30 Mins</label>

                                                    <label htmlFor="mouseDuration2">
                                                        <input id="mouseDuration2" name="mouseDurationContinuous" type='radio' value="0" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "30-60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "mouseDuration")}
                                                        />
                                                        30 Mins - 1 Hr</label>
                                                    <label htmlFor="mouseDuration3" >
                                                        <input id="mouseDuration3" name="mouseDurationContinuous" type='radio' value="1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.mouseDuration === "greater than 60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "mouseDuration")} />
                                                        &gt; 1 Hr
                                                    </label>
                                                </div>}

                                            {this.props.mouseDurationType === "intermittently" && <div className="grid-3-cols h7">
                                                <label htmlFor="mouseDuration4">
                                                    <input id="mouseDuration4" name="mouseDurationIntermittent" type='radio' value="-1" className='cornflowerBlue-bg'
                                                        //  checked={this.props.mouseDuration === "less than 60 mins"}
                                                        onChange={(event) => this.props.saveInfo(event, "mouseDuration")}
                                                    />
                                                    &lt; 1hr</label>

                                                <label htmlFor="mouseDuration5">
                                                    <input id="mouseDuration5" name="mouseDurationIntermittent" type='radio' value="0" className='cornflowerBlue-bg'
                                                        // checked={this.props.mouseDuration === "1hr to 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "mouseDuration")}
                                                    />
                                                    1 - 4 Hrs</label>
                                                <label htmlFor="mouseDuration6" >
                                                    <input id="mouseDuration6" name="mouseDurationIntermittent" type='radio' value="1" className='cornflowerBlue-bg'
                                                        // checked={this.props.mouseDuration === "greater than 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "mouseDuration")} />
                                                    &gt; 4 Hrs
                                                </label>
                                            </div>
                                            }
                                        </div>}
                                    {this.state.unselectedError?.mouseDuration && <ErrorText error={i18n.commonError.selectRequired} classExtend={'mt-15'} />}
                                    <div className='txtRight durationModal'>
                                        <button className={" nxt-btn white " + (this.props.mouseDuration === "" ? "mineShaft-bg" : "eastBay-bg")}
                                            onClick={() => this.handleErrorOnNextPage('mouseDuration', () => this.setState({ modal5: false, unselectedError: {} }, () => this.props.nextPage(8)))}
                                        >
                                            <i className=' fa fa-angle-right '></i>
                                        </button>
                                    </div>

                                </div>
                            </div>}
                    </div>
                }
                {
                    this.props.page8 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(8)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="90%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page8">
                            <div className='botticelli-bg main' >

                                <div className="h4 mineShaft nxtLine">Select the images that best represent your <b>keyboard</b></div>
                                <p className="h8 mineShaft">SELECT THE ONE THAT APPLIES</p>
                                <div className="responsiveDiv">
                                    <div className="nxtLine2">
                                        {this.drawList(8, "radio")}
                                    </div>
                                    <p className="h8 mineShaft nxtLine">
                                        ADDITIONAL OPTIONS <span className="h10">(Select all that apply)</span>
                                    </p>
                                    <div>
                                        {this.drawList(8, "checkbox")}
                                    </div>
                                    {this.state.unselectedError?.keyboardSelected && <ErrorText error={i18n.commonError.selectRequired} />}
                                    <div className="txtRight nxt-btn-div">
                                    <button
                                        className={"nxt-btn ws-nxt-btn" + (this.props.keyboardSelected === "" ? " mineShaft-bg" : " wedgeWood-bg")}
                                        onClick={() => this.handleErrorOnNextPage('keyboardSelected', () => this.setState({ modal6: true }))}
                                        >
                                            <i className='fa fa-angle-right linen'></i>
                                    </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {this.state.modal6 &&
                            <div className='modal-container'>
                                <div className=' modal botticelli-bg'>
                                    <div className=' h3 modal-title'>
                                        Duration of use
                                    </div>
                                    <div className=' nxtLine h7 font-bold'>How do you use your keyboard?</div>
                                    <div className="grid-3-cols h7">
                                        <label htmlFor="continuouslyKeyboard">
                                            <input id="continuouslyKeyboard" name="keyboardDurationType" type='radio' value="continuously" className='cornflowerBlue-bg'
                                                checked={this.props.keyboardDurationType === "continuously"}
                                                onChange={(event) => this.props.saveInfo(event, "keyboardDurationType")}
                                            />
                                            Continuously</label>

                                        <label htmlFor="intermittentlyKeyboard">
                                            <input id="intermittentlyKeyboard" name="keyboardDurationType" type='radio' value="intermittently" className='cornflowerBlue-bg'
                                                checked={this.props.keyboardDurationType === "intermittently"}
                                                onChange={(event) => this.props.saveInfo(event, "keyboardDurationType")}
                                            />
                                            Intermittently</label>
                                        <label htmlFor="" style={{ visibility: "hidden" }}>
                                            <input id="" name="" type='radio' value="" className='cornflowerBlue-bg' />
                                        </label>
                                    </div>
                                    {this.props.keyboardDurationType !== "" &&
                                        <div className="durationModal">
                                            <div className=' nxtLine h7 font-bold'>Average duration of usage</div>
                                            {this.props.keyboardDurationType === "continuously" &&
                                                <div className="grid-3-cols h7">
                                                    <label htmlFor="keyboardDuration1">
                                                        <input id="keyboardDuration1" name="keyboardDurationContinuous" type='radio' value="-1" className='cornflowerBlue-bg'
                                                            //  checked={this.props.keyboardDuration === "less than 30 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "keyboardDuration")}
                                                        />
                                                        &lt; 30 Mins</label>

                                                    <label htmlFor="keyboardDuration2">
                                                        <input id="keyboardDuration2" name="keyboardDurationContinuous" type='radio' value="0" className='cornflowerBlue-bg'
                                                            // checked={this.props.keyboardDuration === "30-60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "keyboardDuration")}
                                                        />
                                                        30 Mins - 1 Hr</label>
                                                    <label htmlFor="keyboardDuration3" >
                                                        <input id="keyboardDuration3" name="keyboardDurationContinuous" type='radio' value="1" className='cornflowerBlue-bg'
                                                            // checked={this.props.keyboardDuration === "greater than 60 mins"}
                                                            onChange={(event) => this.props.saveInfo(event, "keyboardDuration")} />
                                                        &gt; 1 Hr
                                                    </label>
                                                </div>}

                                            {this.props.keyboardDurationType === "intermittently" && <div className="grid-3-cols h7">
                                                <label htmlFor="keyboardDuration4">
                                                    <input id="keyboardDuration4" name="keyboardDurationIntermittent" type='radio' value="-1" className='cornflowerBlue-bg'
                                                        // checked={this.props.keyboardDuration === "less than 60 mins"}
                                                        onChange={(event) => this.props.saveInfo(event, "keyboardDuration")}
                                                    />
                                                    &lt; 1hr</label>

                                                <label htmlFor="keyboardDuration5">
                                                    <input id="keyboardDuration5" name="keyboardDurationIntermittent" type='radio' value="0" className='cornflowerBlue-bg'
                                                        //  checked={this.props.keyboardDuration === "1hr to 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "keyboardDuration")}
                                                    />
                                                    1 - 4 Hrs</label>
                                                <label htmlFor="keyboardDuration6" >
                                                    <input id="keyboardDuration6" name="keyboardDurationIntermittent" type='radio' value="1" className='cornflowerBlue-bg'
                                                        //  checked={this.props.keyboardDuration === "greater than 4hrs"}
                                                        onChange={(event) => this.props.saveInfo(event, "keyboardDuration")} />
                                                    &gt; 4 Hrs
                                                </label>
                                            </div>
                                            }
                                        </div>}
                                        {this.state.unselectedError?.keyboardDuration && <ErrorText error={i18n.commonError.selectRequired} classExtend={'mt-15'} />}
                                        <div className='txtRight durationModal'>
                                            <button className={" nxt-btn white " + (this.props.keyboardDuration === "" ? "mineShaft-bg" : "eastBay-bg")}
                                                onClick={() => this.handleErrorOnNextPage('keyboardDuration', () => {
                                                            this.setState({ modal6: false, unselectedError: {} }, () => this.props.nextPage(9), this.props.calculateRosaScore())
                                                            }
                                                        )}
                                            >

                                                <i className=' fa fa-angle-right '></i>
                                            </button>
                                        </div>
                                </div>
                            </div>}
                    </div>
                }
                {
                    this.props.page9 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="shipCove-bg"
                            goBack={() => this.props.goBack(9)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#DCE3EE"
                            completedColor="#425C86"
                            active="workstationAssessment"
                            headerTitle="WORKSTATION ASSESSMENT"
                            isShowProfileNavigator={false}
                        />
                        <div className="wa-page9">
                            <div className='linen-bg main height100' >
                                <div className="h3 eastBay">Workstation Summary - EWA Score <b>{this.props.ewaRosaScore}</b></div>
                                <div className="responsiveDiv">
                                    <div className="summaryFlex">
                                        {this.drawList(9, "")}
                                    </div>
                                    <div className="txtRight">
                                        <button
                                            className={"save-btn save-progress linen wedgeWood-bg"}
                                            onClick={() =>  window.location.reload(false)}
                                        >Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        )}
        </div>
        )
    }
}

export default EWAScoreView;