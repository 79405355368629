/**
 * The function helper handle redirect to specific login page base on user role
 */
const emptyValidate = ({
  key,
  value,
  emptyMessage = null,
  nextValidateCallback = () => {},
} = {}) => {
  if (!value) {
    /* '', undefined, null
     *  - 0 is invalid - '0' is valid as value can be 0 and input should be type text
     */
    return emptyMessage ? `${key} ${emptyMessage}` : `Enter valid ${key}`;
  }

  return nextValidateCallback ? nextValidateCallback(): '';
};

const inputValidatorHelpers = {
  emptyValidate,
};
export default inputValidatorHelpers;
