import Constants from "../constants";

/**
 * Check whether status code is unauthorized
 */
const isUnauthorizedResponse = ({
    statusCode,
}) => {
    return (statusCode === 401 || statusCode === 403);
}

const redirectToLoginPage = ({
    isProvider = false,
    windowLocation
}) => {
    if(isProvider){
        windowLocation.href = Constants.Routes.ADMIN_LOGIN_PAGE;

        return;
    }

    windowLocation.href = Constants.Routes.CUSTOMER_LOGIN_PAGE;
}

const unauthorizedResponseHelpers =  {
    isUnauthorizedResponse,
    redirectToLoginPage
}

export default unauthorizedResponseHelpers;
