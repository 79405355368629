import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import CreateAccountView from "../../../views/Onboarding/CreateAccount";
import { createUser, setAccessToken, setCurrentUser } from "../../../redux";
import ValidationRule from '../../../constants/validationRule';


const TABS = {
  BASIC_INFO: 1,
  ACCOUNT_INFO: 2,
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const Fields = [
  { label: 'First Name', name: "firstname", required: true, tab: 1, regex: /^[a-zA-Z ]{2,30}$/, autoFocus: true, inputProps: { maxLength: ValidationRule.MAX_LENGTH_INPUT_25 } },
  { label: 'Last Name', name: "lastname", required: true, tab: 1, regex: /^[a-zA-Z ]{1,30}$/ , inputProps: { maxLength: ValidationRule.MAX_LENGTH_INPUT_25 } },
  { label: 'Phone No', name: "mobile", required: true, tab: 1, type: 'tel', regex: /^(\+[\d]{1,5}|0)?[6-9]\d{9}$/ },
  { label: 'Date of Birth', name: "dob", required: true, tab: 1, type: 'date', max: new Date() },
  { label: 'Gender', name: 'gender', type: 'radio', required: true, tab: 1, options: [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, { label: 'Other', value: 'other' }] },
  { label: 'Email', name: "email", tab: 2, required: true, type: 'email', autoFocus: true },
  { label: 'Password', name: "password", tab: 2, required: true, type: 'password' },
  { label: 'Confirm Password', name: "confirmPassword", tab: 2, required: true, type: 'password' },
]

class CreateAccountController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: TABS.BASIC_INFO,
      form: {},
      error: {},
      commonError: null,
      isAcceptedTC: false
    }
  }
  handleFormChange = (e) => {
    const { form, error } = this.state;
    const { name, value } = e.target;
    form[name] = value;
    error[name] = false;

    let commonErrorText = null;

    this.setState({ form: { ...form }, error: { ...error }, commonError: commonErrorText });
  }
  handleFormSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { error, form, currentTab, isAcceptedTC } = this.state
    let valid = true;
    Fields.forEach((_) => {
      if (currentTab === _.tab) {
        if (_.required && !form[_.name]) {
          error[_.name] = 'required'
          valid = false
        } else if (form[_.name] && _.regex) {
          let validField = _.regex.test(form[_.name])
          if (!validField) {
            valid = false;
            error[_.name] = 'Invalid ' + _.label
          }
        }
        else if (_.type === 'email' && !validateEmail(form[_.name])) {
          error[_.name] = 'Invalid Email'
          valid = false
        } else if ((_.name === 'password' || _.name === 'confirmPassword') && form.password !== form.confirmPassword) {
          error[_.name] = 'Password Mismatch'
          valid = false
        }
      }
    })
    if (currentTab === TABS.ACCOUNT_INFO && !isAcceptedTC) {
      valid = false;
      error['tc'] = 'required';
    }
    if (!valid) {
      return this.setState({ error: { ...error } })
    }
    if (currentTab === TABS.BASIC_INFO) {
      return this.setState({ currentTab: TABS.ACCOUNT_INFO })
    }
    try {
      const res = await createUser(form)
      if (res.accessToken) {
        setAccessToken(res.accessToken)
        this.props.setCurrentUser(res)
        this.props.history.push("/intro")
      } else {
        throw res;
      }
    } catch (e) {
      const { errors } = e?.data;
      if (errors?.password) {
        return this.setState({ ...this.state, commonError: errors.password });
      }

      this.setState({ ...this.state, commonError: 'User account already exists' });
    }
  }
  shouldEnableNext = () => {
    let totalfields = 0, filled = 0;
    const { currentTab, form } = this.state
    Fields.forEach((_) => {
      if (currentTab === _.tab) {
        totalfields++;
        if (_.required && form[_.name]) {
          filled++;
        }
      }
    })

    let isValidAcceptTC = this.isTabNeedToAcceptTC() ? this.state.isAcceptedTC : true;

    return totalfields === filled && isValidAcceptTC;
  }
  handleTC = () => {
    const { error, isAcceptedTC } = this.state
    error['tc'] = false;
    this.setState({ isAcceptedTC: !isAcceptedTC, error: { ...error } })
  }

  isTabNeedToAcceptTC = () => {
    return this.state.currentTab === TABS.ACCOUNT_INFO;
  }
  
  render() {
    return (
      <CreateAccountView
        Fields={Fields}
        {...this.state}
        enableNext={this.shouldEnableNext()}
        onChooseTC={this.handleTC}
        onFormChange={this.handleFormChange}
        onSubmit={this.handleFormSubmit} />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user))
  }
}
export default withRouter(connect(null, mapDispatchToProps)(CreateAccountController));
