import { useEffect, useMemo, useState } from 'react';
import close from '../../../../assets/img/rosa/admin/close.png';
import useProvider from '../../../../redux/hooks/useProvider';
import { connect } from 'react-redux';
import Select from 'react-select';
import i18n from '../../../../i18n';

const SelectStyle = {
  height: 40,
  margin: 0,
}
const ModalReviewerSelector = (props) => {
  const { getReviewers, sendToReviewers } = useProvider(props.myDispatch);
  const {
    //parent state
    ewa,
    onClose,

    // global state
    reviewers,
  } = props;
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  useEffect(() => {
    getReviewers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computedReviewers = useMemo(() => {
    return reviewers.map((reviewer) => {
      return {
        value: reviewer._id,
        label: `${reviewer.prefix} ${reviewer.firstname} ${reviewer.lastname}`,
      };
    });
  }, [reviewers]);

  return (
    <div className={'flex-col v-ctr items-center'}>
      <div className='popupModal white-bg select-reviewer-modal'>
        <div className='width100 select-reviewer-modal__header h11 uppercase'>
          {i18n.sendEwaToReview.chooseAReviewer}
        </div>
        <div className='popupModal__body'>
          <img className='close' src={close} alt='close' onClick={onClose} />
          <div>
            <Select
              options={computedReviewers}
              isClearable={true}
              classNamePrefix='select2'
              className={'select2'}
              classNames={{
                control: (state) => 'select2__container',
              }}
              onChange={setSelectedReviewer}
              placeholder={i18n.sendEwaToReview.selectReviewer}
              styles={{
                control: (base) => ({
                  ...base,
                  ...SelectStyle,
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  padding: '0 6px',
                  ...SelectStyle,
                }),
                input: (provided, state) => ({
                  ...provided,
                  ...SelectStyle,
                  padding: 0,
                }),
                indicatorSeparator: (state) => ({
                  display: 'none',
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: 40,
                }),
              }}
            />
          </div>
          <div
            className={`h10 mainBtn white ${
              selectedReviewer ? '' : 'mainBtn__disabled'
            } select-reviewer-modal__btn`}
            onClick={() =>
              sendToReviewers(
                {
                  ewaId: ewa?._id,
                  reviewerIds: [selectedReviewer?.value],
                },
                onClose
              )
            }
          >
            {i18n.sendToReview}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  myDispatch: (type, payload) =>
    dispatch({
      type: type,
      payload: payload,
    }),
});
const mapStateToProps = (state) => {
  return state.provider;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalReviewerSelector);
