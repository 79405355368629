import { createStore } from 'redux';
import { ReducerProvider } from './ReducerProvider';
import { combineReducers } from 'redux';

export const ProviderConfigureStore = () => {
  const providerStore = createStore(
    combineReducers({
      provider: ReducerProvider,
    })
  );
  return providerStore;
};
