import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import LogInView from "../../../views/Onboarding/Login";
import { logInUser, setAccessToken, setCurrentUser, setUserInfo } from "../../../redux";
import { getUserInfo } from "../../../redux";
import { add_patient_info } from "../../../redux/ActionCreator";
import Constants from '../../../constants';

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
const mapStateToProps = (state) => {
  return {
    patientDetails: state
  }
}

class LoginController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { email: "", password: "" },
      error: { email: "", password: "" },
      repeatUserFlag: false,
      assessmentLastSection: null,
      isRequestedReUploadWorkstationImages: false
    };
  }
  handleFormChange = (e) => {
    const { form, error } = this.state;
    const { name, value } = e.target;
    form[name] = value;
    error[name] = false
    this.setState({ form: { ...form }, error: { ...error } })
  }
  handleFormSubmit = async (e) => {
    e.preventDefault();
    const { error, form } = this.state
    let valid = true
    if (form.email === '' || !validateEmail(form.email)) {
      error.email = 'req';
      valid = false
    }
    if (form.password === '') {
      error.password = 'req'
      valid = false
    }
    if (!valid) {
      return this.setState({ error: { ...error } })
    }
    try {
      const res = await logInUser(form)
      if (res.accessToken) {
        setAccessToken(res.accessToken)
        setUserInfo(res)
        this.props.setCurrentUser(res)
        const user = getUserInfo();
        this.repeatUser(user.id);
      } else {
        throw res;
      }
    } catch (error) {
      alert(error.data.message || 'Login Failed')
    }
  }

  repeatUser = (userId) => {
    var myHeaders = new Headers();
    const authToken = localStorage.getItem('API-Key');

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('x-access-token', authToken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let flag = false;
    fetch(process.env.REACT_APP_API_URL + "/api/ewa/user", requestOptions)
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result);
        if (result.message && result.message === 'EWA record Not found.') {
          flag = false;
        } else {
          this.sendToStore(result);
          flag = true;
        }
        this.setState(
          {
            repeatUserFlag: flag,
            assessmentLastSection: result.lastSection,
            isRequestedReUploadWorkstationImages: result.reUploadWorkstationImagesStatus
          },
          () => this.redirect()
        );
      })
      .catch(error => console.log('error', error));
    return flag
  }
  sendToStore = (patientInfo) => {
    this.props.add_patient_info(patientInfo);
  }
  redirect = () => {
    let { repeatUserFlag, isRequestedReUploadWorkstationImages } = this.state;
    if (
      repeatUserFlag &&
      Constants.AssessmentStatus.DONE.includes(this.state.assessmentLastSection) &&
      !isRequestedReUploadWorkstationImages
    ) {
      this.props.history.push('/summary')
    } else {
      if (isRequestedReUploadWorkstationImages) {
        this.props.history.push('/workstationImages')
      } else {
        this.props.history.push('/intro')
      }
    }
  }
  render() {
    return (
      <LogInView {...this.state} onFormChange={this.handleFormChange} onSubmit={this.handleFormSubmit} />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    add_patient_info: (patientDetails) => dispatch(add_patient_info(patientDetails))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginController));