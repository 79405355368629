import React, { Component } from 'react'


class Modal extends Component {

    render() {
        return (
            <div className='modal-container'>
                <div className={this.props.modalClasses + ' modal'}>
                    <div className={this.props.modalTitleClass + ' modal-title'}>
                        {this.props.modalTitle}
                    </div>
                    <div className={' modal-body h9 ' + this.props.modalBodyClass }>{this.props.modalBody}</div>
                    <div className='row space-between'>
                        <button className={this.props.btn1Classes + ' h8 modal-button left-btn'} onClick={this.props.btn1}>{this.props.btnText1}</button>
                        {this.props.txtBtn ?
                            <button className={this.props.btn2Classes + ' h8 modal-button'} onClick={this.props.btn2}>{this.props.btnText2}
                            </button>
                            :
                            <button className={this.props.btn2Classes + " nxt-btn white"} onClick={this.props.btn2}>
                                <i className={this.props.btn2IconClass + ' fa fa-angle-right '}></i>
                            </button>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;