import React, { useState } from 'react';
import close from '../../../../assets/img/rosa/admin/close.png';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import helpers from '../../../../helpers';
import useProvider from '../../../../redux/hooks/useProvider';

const ModalChangeSeverityScore = (props) => {
  const {
    //parent state
    ewa,
    ewaScoreDescriptionDefault,
    onUpdateSeverity = (ewaScore, ewaScoreDescription) => {},
    onClose,
  } = props;

  const { upsertEwaPrimitiveData } = useProvider();

  const [customEwaScoreDescription, setCustomEwaScoreDescription] =
    useState(ewaScoreDescriptionDefault);
  const [customEwaScore, setCustomEwaScore] = useState(ewa.ewaScore);

  const updateEwaScore = () => {
    upsertEwaPrimitiveData(
      {
        ewaId: ewa._id,
        data: {
          ewaScore: customEwaScore,
          ewaScoreDescription: customEwaScoreDescription,
        },
      },
      () => {
        onUpdateSeverity(customEwaScore, customEwaScoreDescription);
      }
    );
  };

  return (
    <div className={'flex-col v-ctr items-center'}>
      <div className='popupModal white-bg severity-modal'>
        <div className='width100 severity-modal__header h11'>
          {i18n.admin.severityScoreModalTitle}
        </div>
        <div className='popupModal__body'>
          <img className='close' src={close} alt='close' onClick={onClose} />
          <div></div>
          <div className={'flex-col'}>
            <div className={'flex-row'}>
              <textarea
                placeholder=''
                className={'severity-modal__score__description-input'}
                value={customEwaScoreDescription}
                onChange={(e) => setCustomEwaScoreDescription(e.target.value)}
              />
              <input
                type='text'
                className='cornflowerBlue-bg severity-modal__score__score-input'
                maxLength={2}
                value={customEwaScore}
                onChange={(e) => {
                  e.target.value = helpers.inputHelpers.filterNumberOnly(
                    e.target.value
                  );
                  setCustomEwaScore(e.target.value);
                }}
              />
            </div>
            <div
              className={`h10 mainBtn white severity-modal__score__btn`}
              onClick={updateEwaScore}
            >
              {i18n.admin.severityScoreSaveButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  myDispatch: (type, payload) =>
    dispatch({
      type: type,
      payload: payload,
    }),
});
const mapStateToProps = (state) => {
  return state.provider;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalChangeSeverityScore);
