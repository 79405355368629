import { axios } from '../common';

const PATH_AUTH = '/api/auth/provider';

// PROVIDER AUTH
const logOut = () => {
  localStorage.removeItem('sensical')
  localStorage.removeItem('ewaId')
  return axios.post(PATH_AUTH + '/logout');
};

const providerAuth = {
  logOut,
};
export default providerAuth;
