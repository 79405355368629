import React, { Component } from "react";
import AdminLandingView from "../../../views/rosa/admin/AdminLandingView";

class AdminLandingController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            errors: {

            },
            modal2: false
        }
    }
    saveInfo = (event, stateKey) => {

    }
    saveProgress = () => { }
    nextPage = (toPageNo) => {
        let fromPage = "page" + (toPageNo - 1);
        let toPage = "page" + toPageNo;
        this.setState({ [fromPage]: false, [toPage]: true })
    }
    goBack = (fromPageNo) => {
        if (fromPageNo === 1) {
            this.setState({ page1: false, sectionIntroOpen: true, page2: false });
        } else {
            let fromPage = "page" + (fromPageNo);
            let toPage = "page" + (fromPageNo - 1);
            this.setState({ [fromPage]: false, [toPage]: true });
        }
    }

    render() {
        return (<AdminLandingView
            {...this.state}
            openQuiz={() => this.setState({ page1: true, sectionIntroOpen: false })}
            nextPage={(toPageNo) => this.nextPage(toPageNo)}
            openPage3={() => this.setState({ page1: false, page2: false, page3: true })}
            warning={() => this.setState({ modal1: true })}
            closeWarning={() => this.setState({ modal1: false })}
            saveInfo={(event, stateKey) => this.saveInfo(event, stateKey)}
            goBack={(pageNo) => this.goBack(pageNo)}
            saveProgress={this.saveProgress}
            checkCompletion={() => this.setState({ modal2: true })}
            goNextModule={() => this.setState({ modal2: false })} />)
    }
}

export default AdminLandingController;