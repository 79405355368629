import api from '../api';

const useProviderAuth = (dispatch) => {
  const logOut = (onSuccess = () => {}, onError = () => {}) => {
    api.providerAuth
      .logOut()
      .then((res) => {
        onSuccess();
      })
      .catch((e) => {
        console.log('e', e);
        onError();
      });
  };

  return {
    logOut,
  };
};

export default useProviderAuth;
