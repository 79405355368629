import React, { Component } from 'react';
import Header from './Header';
import './SectionIntro.scss';
class SectionIntro extends Component {
    render() {
        return (<div className={this.props.bgColor + ' section-container responsiveDiv'}>
            <div className='main' >
                <div className={this.props.sectionColor + ' section-content txtLeft'}>
                    <div className='section-header h1'>
                        {this.props.sectionHeader1} <br />  {this.props.sectionHeader2}
                    </div>
                    <div className='section-desc h8'>
                        {this.props.sectionDesc}
                    </div>
                </div>
                <div className='nxtDiv row space-between'>
                    <div className='h9 notes txtLeft'>{this.props.notes}</div>
                    <button className={this.props.nxtBtnClass + ' nxt-btn'} onClick={this.props.nextSection}>
                        <i className='fa fa-angle-right'></i>
                    </button>

                </div>
            </div>
        </div >)
    }
}

export default SectionIntro;