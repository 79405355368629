import * as ActionTypes from './ActionTypes';
export const INITIAL_STATE =
{
    doctor: {
        prefix: "Dr.",
        name: "Rohan Mohan",
        qualification: "MBBS, MS Ortho",
        designation: "Ergonomics Consultant",
        hospital: "Recoup",
        contact: "+91 9922882288",
        email: "drrohan@recoup.health"
    },
    assessmentDate: new Date().toDateString(),
    patientId: "",
    name: "Naveen J",
    age: 27,
    gender: "Male",
    workPlace: "",
    occupation: "",
    height: 0,
    weight: 0,
    dominantHand: "",
    pregnant: false,
    correctiveLens: false,
    lensType: "",
    monitorView: "",
    paperView: "",
    peopleView: "",
    workDevice: "",
    carryDevice: "",
    totalWorkDeviceTime: 0,
    audioDevice: "",
    totalPhoneTime: 0,
    stretchRegularly: "",
    exerciseRegularly: "",
    totalWorkTime: 0,
    totalBreakTime: 0,
    docHolder: "",
    workTasks: [],
    devicePercentage: 0,
    phonePercentage: 0,
    breakPercentage: 0,
    duringWork: "5",
    afterWork: "5",
    morningAfterWork: "5",
    afterWeekend: "5",
    unableToSleep: "5",
    lessStrength: "5",
    frequentStretching: "5",
    strainedEyes: "5",
    takeTimeOff: "5",
    affectsProductivity: "5",
    troubleIn12Months: [],
    preventedNormalActivities: [],
    visitedAPhysician: [],
    troubleInLast7Days: [],
    takingTreatment: "",
    painTreatment: "",
    suggestions: "",
    concerns: "",
    bodyPainImg: "",
    chairHeight: [],
    seatPan: [],
    armRest: [],
    backSupport: [],
    monitorSetup: [],
    telephone: [],
    mouse: [],
    keyboard: [],
    softTissueCompression: [],
    rosaSelectedItems: null,
    rosaGrandScore: "",
    ewaScore: "",
    workstationSide: null,
    workstationBack: null,
    userSideImage: null,
    userBackImage: null
}

export const PatientInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case (ActionTypes.ADD_PATIENT_INFO):
            var patientInfo = action.payload.patientDetails;
            //state = patientInfo;
            // console.log("State length before", Object.keys(state).length)
            for (const key in patientInfo) {
                if (patientInfo.hasOwnProperty(key)) {
                    state[key] = patientInfo[key];
                }
            }
            // state.doctor = {
            //     prefix: "Dr.",
            //     name: "Rohan Mohan",
            //     qualification: "MBBS, MS Ortho",
            //     designation: "Ergonomics Consultant",
            //     hospital: "Recoup",
            //     contact: "+91 9922882288",
            //     email: "drrohan@recoup.health"
            // }
            // console.log("State length after", Object.keys(state).length)
            return state;
        case (ActionTypes.ADD_BASIC_INFO):
            var basicInfo = action.payload;
            for (const key in basicInfo) {
                if (basicInfo.hasOwnProperty(key)) {
                    state[key] = basicInfo[key];
                }
            }
            //   state = basicInfo;
            console.log("basic", state);
            return state;
        case (ActionTypes.ADD_NATURE_OF_WORK_INFO):
            var natureOfWork = action.payload;
            for (const key in natureOfWork) {
                if (natureOfWork.hasOwnProperty(key)) {
                    state[key] = natureOfWork[key];
                }
            }
            console.log("nature", state);
            return state
        case (ActionTypes.ADD_PAIN_AND_DISCOMFORT_INFO):
            var painAndDiscomfort = action.payload;
            for (const key in painAndDiscomfort) {
                if (painAndDiscomfort.hasOwnProperty(key)) {
                    state[key] = painAndDiscomfort[key];
                }
            }
            console.log("painDiscomfort", state);
            return state
        case (ActionTypes.ADD_WORKSTATION_ASSESSMENT_INFO):
            var workstationAssessment = action.payload;
            //console.log("Is it working ? ", workstationAssessment);
            for (const key in workstationAssessment) {
                if (workstationAssessment.hasOwnProperty(key)) {
                    state[key] = workstationAssessment[key];
                }
            }
            //console.log("Hope so ", state);
            return state
        case (ActionTypes.ADD_WORKSTATION_IMAGES):
            var workstationImages = action.payload;
            let isLastPage = action.payload?.isLastPage;

            for (const key in workstationImages) {
                if (workstationImages.hasOwnProperty(key)) {
                    state[key] = workstationImages[key];
                }
            }

            return {
              ...state,
              isLoading: isLastPage,
            };
        case (ActionTypes.ADD_WORKSTATION_IMAGES_DONE):

            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }

}
