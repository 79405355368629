const en = {
  // validation
  image: {
    maxSize: 'Image size should be less than ',
  },
  commonError: {
    selectRequired: 'Please select an option',
  },
  //End validation

  admin: {
    report: {
      occupation: 'Occupation',
      heightInUnitString: 'Height',
      weightInUnitString: 'Weight',
      pregnantString: 'Pregnant',
      dominantHandString: 'Dominant Hand',
      lensTypeString: 'Lens',
      name: 'Name',
      company: 'Company',
      age: 'Age',
      gender: 'Gender',
      monitorView: 'To view monitor',
      paperView: 'To read paper',
      peopleView: 'To look at people',
      breaksDuringWork: 'Breaks during work',
      regularStretch: 'Regular stretch',
      regularExercise: 'Regular exercise',
      inputDataFromDocumentHolder: 'Input data from document holder',
    },
    ergonomicRecommendations: 'Ergonomic Recommendations',
    productRecommendations: 'Product Recommendations',
    resources: 'Resources',
    recoupProductUrl: 'Recoup Products URL',
    recoupResourceUrl: 'Recoup Resources URL',
    severityScoreModalTitle: 'SEVERITY SCORE: Edit',
    severityScoreSaveButton: 'Save severity score',
    complaints: 'Complaints',
    contactSupport:
      'For clarifications regarding this report or for assistance in ordering Ergonomic furniture or products, please contact-',
    goniometerReading: 'Goniometer Reading'
  },

  assessment: {
    homeViewConsentPolicyContent: `This supportive tool guides your assessment in a step-wise manner. 
    For best use of the tool, or any clarifications, seek guidance of Recoup Care Team at info@recoup.health`,
    workAssessment: {
      softTissueCompression: 'soft tissue compression',
      softTissueCompressionDescription:
        'Soft tissue compression applies when any part of your body contacts a hard surface/edge and your soft tissue is compressed',
      selectWorkAssessment: 'Select the images that best represent your',
      severityScoreLow:
        'Calculated ROSA is Low, further assessment is not immediately required.',
      severityScoreHigh:
        'Your ROSA Score is HIGH, your workstation requires further assessment; changes should be considered immediately.',
    },
    painDiscomfort: {
      painDiscomfort: 'Pain / Discomfort',
      consequenceOfPain: 'Consequence of pain',

      oftenQuestion: 'How often do you experience this?',
      // group 1
      duringWork: 'During Work',
      afterWork: 'After work',
      morningAfterWork: 'Morning after a previous workday',
      afterWeekend: 'After a weekend',

      // group 2
      unableToSleep: 'I am unable to sleep due to pain',
      lessStrength: 'I have less strength in my hands/ arms',
      frequentStretching:
        'I find myself frequently stretching/ massaging my neck/ back/ shoulders during work',
      strainedEyes: 'My eyes get achy/ watery/ strained while working',
      takeTimeOff: 'I have had to take time off work',
      affectsProductivity: 'I feel my productivity gets affected',

      troubleIn: "Areas where you've had trouble in the",

      preventActivity: 'Areas that have prevented you from',
      carryOutActivity: 'carrying out normal activities',
      dueTo: 'due to pain in',

      visitedPhysician: 'visited a physician',
      forInTheLast: 'for in the last',
    },
  },

  forgotPassword: {
    description:
      "Enter the email for your Recoup account and we'll send you a link to reset your password",
  },

  sendEwaToReview: {
    chooseAReviewer: 'Choose a reviewer',
    pleaseChooseOneReviewer: 'Please choose one reviewer',
    selectReviewer: 'Select reviewer',
  },

  // Common
  takeABreakOf: 'Take a break of',
  every: 'every',
  hours: 'hours',
  minutes: 'minutes',
  and: 'and',
  usage: 'Usage',
  duration: 'Duration',
  morningAfterAPreviousWorkday: 'Morning after a previous workday',
  severityScore: 'Severity score',
  almostNever: 'Almost never',
  sometimes: 'Sometimes',
  rarely: 'Rarely',
  almostAlways: 'Almost always',
  allTheTime: 'All the time',
  all: 'All',
  last12Months: 'last 12 Months',
  last7Days: 'last 7 days',
  forIn: 'for in',
  logOut: 'Logout',
  ewaNotStart: 'Not Started',
  ewaReadyForReview: 'Ready for Review',
  ewaInProgress: 'In Progress',
  report: 'Report',
  createReport: 'Create Report',
  viewReport: 'View Report',
  continue: 'Continue',
  startReview: 'Start Review',
  view: 'View',
  startAssessment: 'Start Assessment',
  resumeAssessment: 'Resume Assessment',
  sendToReview: 'Send To Review',
  contactUs: 'Contact Us',
  writeHere : 'Write here',
  startTyping: 'Start typing',
  savedSuccess : 'Saved success',
  sentSuccess: 'Report Sent Successfully',
  email: 'Email',
  sendReport: 'Send Report',
  reUploadStatus: 'Customer Input Awaited'

};

export default en;
