import React from "react";
import { connect } from "react-redux";
import { add_Nature_Of_Work_Info } from "../../redux/ActionCreator";
import { withRouter } from 'react-router-dom';
import NatureOfWorkView from "../../views/rosa/NatureOfWorkView";
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import Routes from "../../constants/routes";

const mapDispatchToProps = (dispatch) => ({
  add_Nature_Of_Work_Info: (
    workDevice,
    carryDevice,
    totalWorkDeviceTime,
    audioDevice,
    totalPhoneTime,
    stretchRegularly,
    exerciseRegularly,
    totalWorkTime,
    totalBreakTime,
    numberOfBreaks,
    docHolder,
    workTasks,
    devicePercentage,
    phonePercentage,
    breakPercentage,
    lastSection,
    lastPage,
    restState,
  ) =>
    dispatch(
      add_Nature_Of_Work_Info(
        workDevice,
        carryDevice,
        totalWorkDeviceTime,
        audioDevice,
        totalPhoneTime,
        stretchRegularly,
        exerciseRegularly,
        totalWorkTime,
        totalBreakTime,
        numberOfBreaks,
        docHolder,
        workTasks,
        devicePercentage,
        phonePercentage,
        breakPercentage,
        lastSection,
        lastPage,
        restState,
      )
    ),
});
class NatureOfWorkController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            workDevice: "",
            carryDevice: "",
            laptopHrs: "0",
            laptopMins: "0",
            desktopHrs: "0",
            desktopMins: "0",
            audioDevice: "",
            phoneHrs: "0",
            phoneMins: "0",
            stretchRegularly: "",
            exerciseRegularly: "",
            workHours: "0",
            workMins: "0",
            workBreak: "",
            breakNumHrs: "0",
            breakNumMins: "0",
            breakTimeHrs: "0",
            breakTimeMins: "0",
            docHolder: "",
            workTasks: {},
            workTasksMins: [],
            enableNext1: false,
            enableNext2: false,
            enableNext3: false,
            doughnutData: {
                labels: ['Phone', 'Laptop'],
                datasets: [
                    {
                        label: 'Daily device usage',
                        data: [],
                        backgroundColor: [
                            'rgba(238, 194, 128, 1)',
                            'rgba(72, 177, 175, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            barData: {
                labels: [],
                datasets: [
                    {
                        barThickness: 8,
                        data: [],
                        borderRadius: 8,
                        backgroundColor: "#48B1AF"
                    }
                ],
            },
            errors: {
                workDevice: "",
                carryDevice: "",
                desktopHrs: "",
                laptopHrs: "",
                audioDevice: "",
                phoneHrs: "",
                stretchRegularly: "",
                exerciseRegularly: "",
                workHours: "",
                workBreak: "",
                breakFrequency: "",
                breakLength: "",
                docHolder: "",
                workTasks: ""

            },
            devicePercentage: 0,
            phonePercentage: 0,
            breakPercentage: 0,
            modal2: false,
            totalWorkDeviceTime: 0,
            totalPhoneTime: 0,
            totalWorkTime: 0,
            totalBreakTime: 0,
            othersWorkTask: ""
        }
    }
    saveInfo = (event, stateKey) => {
        let { errors, workDevice, carryDevice, laptopHrs, laptopMins, desktopHrs, desktopMins, phoneHrs, phoneMins,
            workHours, workMins, workBreak, breakNumHrs, breakNumMins, breakTimeHrs, breakTimeMins, docHolder } = this.state;
        let value = event.target.value;
        if (stateKey === "workDevice") {
            errors.workDevice = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "carryDevice") {
            errors.carryDevice = ((workDevice === "laptop" || workDevice === "both") && value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "laptopHrs") {
            errors.laptopHrs = ((workDevice === "laptop" || workDevice === "both") && (value === "0" && laptopMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "laptopMins") {
            errors.laptopHrs = ((workDevice === "laptop" || workDevice === "both") && (laptopHrs === "0" && value === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "desktopHrs") {
            errors.desktopHrs = ((workDevice === "desktop") && (value === "0" && desktopMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "desktopMins") {
            errors.desktopHrs = ((workDevice === "desktop") && (desktopHrs === "0" && value === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "audioDevice") {
            errors.audioDevice = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "phoneHrs") {
            errors.phoneHrs = (value === "0" && phoneMins === "0") ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "phoneMins") {
            errors.phoneHrs = (phoneHrs === "0" && value === "0") ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "stretchRegularly") {
            errors.stretchRegularly = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "exerciseRegularly") {
            errors.exerciseRegularly = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "workHours") {
            errors.workHours = (value === "0" && workMins === "0") ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "workMins") {
            errors.workHours = (workHours === "0" && value === "0") ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "exerciseRegularly") {
            errors.exerciseRegularly = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "workBreak") {
            errors.workBreak = (value.length === 0) ? "Please select an option" : "";
        }
        if (stateKey === "breakNumHrs") {
            errors.breakFrequency = ((workBreak === "yes") && (value === "0" && breakNumMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "breakNumMins") {
            errors.breakFrequency = ((workBreak === "yes") && (breakNumHrs === "0" && value === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "breakTimeHrs") {
            errors.breakLength = ((workBreak === "yes") && (value === "0" && breakTimeMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "breakTimeMins") {
            errors.breakLength = ((workBreak === "yes") && (breakTimeHrs === "0" && value === "0")) ? "Duration must be greater than atleast 0 mins" : "";
        }
        if (stateKey === "docHolder") {
            errors.docHolder = (value.length === 0) ? "Please select an option" : "";
        }
        this.setState({ [stateKey]: value }, () => { this.checkFilled() });
    }
    mandatoryFields = (pageNo) => {
        let { errors, workDevice, carryDevice, laptopHrs, laptopMins, desktopHrs, desktopMins, audioDevice, phoneHrs, phoneMins,
            workHours, workMins, stretchRegularly, exerciseRegularly, workBreak, breakNumHrs, breakNumMins, breakTimeHrs, breakTimeMins,
            docHolder } = this.state;
        if (pageNo === 1) {
            errors.workDevice = (workDevice.length === 0) ? "Please select an option" : "";
            if (workDevice === "laptop" || workDevice === "both") {
                errors.carryDevice = (carryDevice.length === 0) ? "Please select an option" : "";
                errors.laptopHrs = (laptopHrs === "0" && laptopMins === "0") ? "Duration must be greater than atleast 0 mins" : "";
            }
            errors.desktopHrs = ((workDevice === "desktop") && (desktopHrs === "0" && desktopMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
            errors.audioDevice = (audioDevice.length === 0) ? "Please select an option" : "";
            errors.phoneHrs = (audioDevice.length > 0 && (phoneHrs === "0" && phoneMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
            errors.stretchRegularly = (stretchRegularly.length === 0) ? "Please select an option" : "";
            errors.exerciseRegularly = (exerciseRegularly.length === 0) ? "Please select an option" : "";
        }
        if (pageNo === 2) {
            errors.workHours = (workHours === "0" && workMins === "0") ? "Duration must be greater than atleast 0 mins" : "";
            errors.workBreak = (workBreak.length === 0) ? "Please select an option" : "";
            errors.breakFrequency = ((workBreak === "yes") && (breakNumHrs === "0" && breakNumMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
            errors.breakLength = ((workBreak === "yes") && (breakTimeHrs === "0" && breakTimeMins === "0")) ? "Duration must be greater than atleast 0 mins" : "";
            errors.docHolder = (docHolder.length === 0) ? "Please select an option" : "";
        }
        if (pageNo === 3) {
            errors.workTasks = (Object.keys(this.state.workTasks).length === 0) ? "Please select aleast one option" : "";
        }
        this.setState({ errors })
    }
    checkFilled = () => {
        let { workDevice, audioDevice, stretchRegularly, exerciseRegularly, page1Filled, workHours, workMins, workBreak,
            docHolder, page2Filled, carryDevice, laptopHrs, laptopMins, desktopHrs, desktopMins, phoneHrs, phoneMins,
            breakNumHrs, breakNumMins, breakTimeHrs, breakTimeMins, errors, enableNext1, enableNext2 } = this.state;

        page1Filled = (workDevice?.length !== 0 && audioDevice?.length !== 0 && stretchRegularly?.length !== 0 && exerciseRegularly?.length !== 0) &&
            ((!(laptopHrs === "0" && laptopMins === "0")) || (!(desktopHrs === "0" && desktopMins === "0"))) &&
            (!(phoneHrs === "0" && phoneMins === "0")) && ((workDevice !== "desktop" && carryDevice.length !== 0) || workDevice === "desktop")

        page2Filled = ((!(workHours === "0" && workMins === "0")) && workBreak?.length !== 0 && docHolder?.length !== 0) &&
            ((workBreak === "no") || (workBreak === "yes" && (!(breakNumHrs === "0" && breakNumMins === "0")) && (!(breakTimeHrs === "0" && breakTimeMins === "0"))));

        if (page1Filled) {
            errors.workDevice = ((workDevice === "desktop" && desktopHrs === "0" && desktopMins === "0") || (workDevice === "laptop" && laptopHrs === "0" && laptopMins === "0")) ? "The minimum average " + workDevice + " usage must be greater than 0 minutes" : "";
            errors.phoneHrs = (phoneHrs === "0" && phoneMins === "0") ? "The minimum average phone usage must be greater than 0 minutes" : ""
        }
        if (page2Filled) {
            errors.workHours = (workHours === "0" && workMins === "0") ? "Work time cannot be 0 minutes" : "";
            if (workBreak === "yes") {
                let totalWorkMins = Number(workHours) * 60 + Number(workMins);
                let totalBreakNum = Number(breakNumHrs) * 60 + Number(breakNumMins);
                errors.breakFrequency = totalBreakNum > totalWorkMins ? "This value cannot be greater than your work hours" : "";
                let breakLength = (Number(breakTimeHrs) * 60) + Number(breakTimeMins);
                errors.breakLength = breakLength > totalWorkMins ? "Break time cannot be greater than work time" : "";
            }
        }
        enableNext1 = page1Filled && (errors.workDevice?.length === 0 && errors.phoneHrs?.length === 0);
        enableNext2 = page2Filled && (errors.workHours?.length === 0 && errors.breakFrequency?.length === 0 && errors.breakLength?.length === 0);
        this.setState({ page1Filled, page2Filled, errors, enableNext1, enableNext2 });
    }

    addToWorkTask = (event, workName, workParam, workNameDuration) => {
        let { workTasks, errors, enableNext3 } = this.state;
        let workParamVal = event.target.value;
        if (!workTasks[workName]) {
            workTasks[workName] = { hrs: "0", mins: "0" };
        }
        workTasks[workName][workParam] = workParamVal;
        errors.workTasks = (Object.keys(workTasks).length === 0) ? "Please select an option" : "";
        enableNext3 = Object.keys(workTasks).length > 0;
        
        this.setState({
            workTasks,
            enableNext3,
            // Set duration of workName to save on database and restore it when user come back
            [workNameDuration]: event.target.value,
        });
    }
    saveProgress = () => {
        // let state = this.state;

        this.setState({ modal2: false }, () => {
            this.sendToStore({page : 5});

            const params = { isPreventReloadEwa: true };
            this.props.history.push('/workstationAssessment', params);
        })
    }
    sendToStore = ({ page = 1 }) => {
        let state = this.state;

        this.props.add_Nature_Of_Work_Info(
            state.workDevice,
            state.carryDevice,
            state.totalWorkDeviceTime,
            state.audioDevice,
            state.totalPhoneTime,
            state.stretchRegularly,
            state.exerciseRegularly,
            state.totalWorkTime,
            state.totalBreakTime,
            state.numberOfBreaks,
            state.docHolder,
            state.workTasksMins,
            state.devicePercentage,
            state.phonePercentage,
            state.breakPercentage,
            this.computedNextSection({isLastPage: page > 4, currentRoute: Routes.ASSESSMENT_NATURE_OF_WORK}),
            page > 4 ? 0 : page,
            this.state,
        );
    }
    nextPage = (toPageNo) => {
        let fromPage = "page" + (toPageNo - 1);
        let toPage = "page" + toPageNo;
        this.setState({ [fromPage]: false, [toPage]: true })
        this.sendToStore({page: toPageNo})
    }
    goBack = (fromPageNo) => {
        if (fromPageNo === 1) {
            this.setState({ page1: false, sectionIntroOpen: true, page2: false });
        } else {
            let fromPage = "page" + (fromPageNo);
            let toPage = "page" + (fromPageNo - 1);
            this.setState({ [fromPage]: false, [toPage]: true });
        }
    }
    reset = () => {
        this.setState({
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            workDevice: "",
            carryDevice: "",
            laptopHrs: "",
            laptopMins: "",
            desktopHrs: "",
            desktopMins: "",
            audioDevice: "",
            phoneHrs: "",
            phoneMins: "",
            stretchRegularly: "",
            exerciseRegularly: "",
            workHours: "",
            workMins: "",
            workBreak: "",
            breakNumHrs: "",
            breakNumMins: "",
            breakTimeHrs: "",
            breakTimeMins: "",
            docHolder: "",
            workTasks: {},
            workTasksMins: [],
            enableNext1: false,
            enableNext2: false,
            doughnutData: {
                labels: ['Phone', 'Laptop'],
                datasets: [
                    {
                        label: 'Daily device usage',
                        data: [],
                        backgroundColor: [
                            'rgba(238, 194, 128, 1)',
                            'rgba(72, 177, 175, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
            },
            barData: {
                labels: [],
                datasets: [
                    {
                        barThickness: 8,
                        data: [],
                        borderRadius: 8,
                        backgroundColor: "#48B1AF"
                    }
                ],
            },
            errors: {
                workDevice: "",
                phoneHrs: "",
                workHours: "",
                breakFrequency: "",
                breakLength: ""

            },
            devicePercentage: 0,
            phonePercentage: 0,
            breakPercentage: 0,
            modal2: false,
            totalWorkDeviceTime: 0,
            totalPhoneTime: 0,
            totalWorkTime: 0,
            totalBreakTime: 0
        });

    }

    buildChart = () => {
        let { barData, workTasks, workBreak, desktopHrs, desktopMins, workDevice, laptopHrs, laptopMins, phoneHrs,
            phoneMins, workHours, workMins, breakNumHrs, breakNumMins, breakTimeHrs, breakTimeMins,
            doughnutData, devicePercentage, phonePercentage, breakPercentage } = this.state;
        barData["labels"] = [];
        barData["datasets"][0]["data"] = [];
        let totalWorkMins = 0;
        let numberOfBreaks = 0;
        let totalBreakTime = 0;
        let totalLaptopMins = 0;
        let totalPhoneMins = 0;
        let totalDesktopMins = 0;
        let totalWorkDeviceTime = 0;
        totalWorkMins = (Number(workHours) * 60) + Number(workMins);
        numberOfBreaks = totalWorkMins / ((Number(breakNumHrs) * 60) + Number(breakNumMins));
        totalBreakTime = workBreak === "no" ? 0 : Math.round(((Number(breakTimeHrs) * 60) + Number(breakTimeMins)) * (numberOfBreaks));
        totalLaptopMins = (Number(laptopHrs) * 60) + Number(laptopMins);
        totalDesktopMins = (Number(desktopHrs) * 60) + Number(desktopMins);
        totalWorkDeviceTime = (workDevice === "desktop") ? totalDesktopMins : totalLaptopMins;
        totalPhoneMins = (Number(phoneHrs) * 60) + Number(phoneMins);
        doughnutData.datasets[0].data = [totalPhoneMins, totalWorkDeviceTime];
        let tasks = [];
        for (const task in workTasks) {
            let taskHrs = Number(workTasks[task]["hrs"]) + Number((Number(workTasks[task]["mins"]) / 60).toFixed(2));
            let taskMins = (Number(workTasks[task]["hrs"]) * 60) + Number(workTasks[task]["mins"]);
            barData["labels"].push(task);
            barData["datasets"][0]["data"].push(taskHrs);
            tasks.push({ [task]: taskMins })
        }
        devicePercentage = Math.round((totalWorkDeviceTime / (totalWorkDeviceTime + totalPhoneMins)) * 100);
        phonePercentage = Math.round((totalPhoneMins / (totalWorkDeviceTime + totalPhoneMins )) * 100);
        breakPercentage = Math.round((totalBreakTime / (totalWorkDeviceTime + totalPhoneMins + totalBreakTime)) * 100);

        this.setState({
          totalBreakTime: totalBreakTime,
          totalPhoneTime: totalPhoneMins,
          totalWorkDeviceTime: totalWorkDeviceTime,
          totalWorkTime: totalWorkMins,
          doughnutData,
          devicePercentage,
          phonePercentage,
          breakPercentage,
          workTasksMins: tasks,
          numberOfBreaks,
        });
        this.nextPage(4);
    }

    async componentDidMount() {
        let ewaRecord = await this.loadEwaRecord();
        if (!ewaRecord) {
            return;
        }

        let jsonState = JSON.parse(ewaRecord.savedProcessJson) ?? {};

        this.setState({
            ...this.state,
            workBreak: ewaRecord.workBreak ?? {},
            workDevice: ewaRecord.workDevice ?? '',
            ...jsonState,
            workTasks: jsonState.workTasks ? JSON.parse(jsonState.workTasks) : {},
            ...this.computedDonePages({
                maxPage: 4,
                lastPage: ewaRecord.lastPage,
            }),
        });
    }

    render() {
        console.log("State ", this.state)
        return (<NatureOfWorkView
            {...this.state}
            openQuiz={() => this.setState({ page1: true, sectionIntroOpen: false })}
            nextPage={(toPageNo) => this.nextPage(toPageNo)}
            openPage3={() => this.setState({ page1: false, page2: false, page3: true })}
            warning={() => this.setState({ modal1: true })}
            closeWarning={() => this.setState({ modal1: false })}
            saveInfo={(event, stateKey) => this.saveInfo(event, stateKey)}
            goBack={(pageNo) => this.goBack(pageNo)}
            saveProgress={this.saveProgress}
            checkCompletion={() => this.setState({ modal2: true })}
            // goNextModule={() => this.setState({ modal2: false })}
            reset={this.reset}
            buildChart={this.buildChart}
            addToWorkTask={(event, workName, workParam, workNameDuration) => this.addToWorkTask(event, workName, workParam, workNameDuration)}
            mandatoryFields={(pageNo) => this.mandatoryFields(pageNo)}
        />)
    }
}

export default withRouter(connect(null, mapDispatchToProps)(NatureOfWorkController));