import React from 'react';
import i18n from '../../../../i18n';

const NatureOfWorkSummaryBreakInfo = ({
  ewa = {},
  titleExtendClass = '',
  computedBreakText = '',
}) => {
  return (
    <>
      {computedBreakText &&
        <div className='flex-row'>
          <span
            className={`h9 report__user__nature-of-work__items ${titleExtendClass}`}
          >
            {`${i18n.admin.report.breaksDuringWork}: `}
            <span className='h10 lowercase report__user__nature-of-work__items__content'>
              {computedBreakText}
            </span>
          </span>
      </div>
      }
      <div className='flex-row'>
        <span
          className={`h9 report__user__nature-of-work__items ${titleExtendClass}`}
        >
          {`${i18n.admin.report.regularStretch}: `}
          <span className='h10 capitalize report__user__nature-of-work__items__content'>
            {ewa?.stretchRegularly}
          </span>
        </span>
      </div>
      <div className='flex-row'>
        <span
          className={`h9 report__user__nature-of-work__items ${titleExtendClass}`}
        >
          {`${i18n.admin.report.regularExercise}: `}
          <span className='h10 capitalize report__user__nature-of-work__items__content'>
            {ewa?.exerciseRegularly}
          </span>
        </span>
      </div>
      <div className='flex-row'>
        <span
          className={`h9 report__user__nature-of-work__items ${titleExtendClass}`}
        >
          {`${i18n.admin.report.inputDataFromDocumentHolder}: `}
          <span className='h10 capitalize report__user__nature-of-work__items__content'>
            {ewa?.docHolder}
          </span>
        </span>
      </div>
      <br />
    </>
  );
};

export default React.memo(NatureOfWorkSummaryBreakInfo);
