import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ForgetPasswordView from "../../../views/Onboarding/ForgetPassword";
import { sendResetPasswordLink } from "../../../redux";
import helpers from "../../../helpers";

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

class ForgetPasswordController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { email: "", password: "" },
      error: { email: "", password: "" },
      userRole: this.props.match.params?.userRole,
    };
  }
  handleFormChange = (e) => {
    const { form, error } = this.state;
    const { name, value } = e.target;
    form[name] = value;
    error[name] = false
    this.setState({
      form: { ...form },
      error: { ...error },
      userRole: this.state.userRole,
    });
  };
  handleFormSubmit = async (e) => {
    e.preventDefault();
    const { error, form } = this.state
    let valid = true
    if (form.email === '' || !validateEmail(form.email)) {
      error.email = 'req';
      valid = false
    }
    if (!valid) {
      return this.setState({
        error: { ...error },
        userRole: this.state.userRole,
      })
    }
    try {
      const res = await sendResetPasswordLink(form.email, this.state.userRole);
      this.setState({
        mailSent: res.success,
        message: res.success
          ? 'To change password, click on the link sent to your Email ID.'
          : 'Failed to send reset password mail',
        userRole: this.state.userRole,
      });
    } catch (error) {
      if (error.data.message) {
        this.setState({
          message: error.data.message,
          userRole: this.state.userRole,
        })
      }
    }
  }
  handleAlertNext = () => {
    this.setState({
      message: null,
      userRole: this.state.userRole,
    });
    if (this.state.mailSent) {
      helpers.resetPasswordHelpers.handleRedirectAfterSentMail({
        routerHistory : this.props.history,
        userRole: this.state.userRole,
      });

      return;
    }
    const input = document.querySelector('#email');
    if (input) {
      input.focus();
    }
  }
  render() {
    return (
      <ForgetPasswordView {...this.state} onAlertNext={this.handleAlertNext} onFormChange={this.handleFormChange} onSubmit={this.handleFormSubmit} />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {

  }
}
export default withRouter(connect(null, mapDispatchToProps)(ForgetPasswordController));
