import { axios } from "../common";

const PATH = '/api/auth/'
export const createUser = (newUser) => {
  return axios.post(PATH + "customersignup", newUser)
}
export const logInUser = (credentials) => {
  return axios.post(PATH + "customersignin", credentials)
}
export const sendResetPasswordLink = (email, userRole) => {
  return axios.post(PATH + "customerpasswordlost", { email: email, userRole });
};
export const getCustomerByResetToken = (token, userRole) => {
  return axios.post(PATH + "customerbytoken", {
    token: token,
    userRole,
  });
};
export const changeCustomerPasswordByToken = (token, password, userRole) => {
  return axios.post(PATH + "changepasswordbytoken", {
    token: token,
    password: password,
    userRole,
  });
}
export const getCustomerById = (customerId) => {
  return axios.get(PATH + "customer/" + customerId)
}
