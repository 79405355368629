import React from 'react';
import AssessmentPainDiscomfortEnums from '../../../constants/assessmentPainDiscomfort';

const PainDiscomfortSummaryTable = ({ title = '', dataSet = [] }) => {
  const computedDataSet = React.useMemo(() => {
    return dataSet.map((e) => {
      return {
        key: e.key,
        title: e.title,
        value: e.value,
        computedValue: AssessmentPainDiscomfortEnums.painLevelEnums?.[e.value],
      };
    });
  }, [dataSet]);

  return (
    <div>
      <div className={'h4'}>{title}</div>
      <br></br>
      <div>
        <table
          className={'painDiscomfortSummary__table'}
        >
          <tbody>
            {computedDataSet.map((e) => (
              <tr key={`summary_${e.key}`}>
                <td width={'75%'} className={'font12 mineShaft'}>
                  {e.title}
                </td>
                <td width={'25%'} className={'painDiscomfortSummary__level'}>
                  {e.computedValue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(PainDiscomfortSummaryTable);
