import loading from '../../assets/img/rosa/loading.gif';

const LoadingView = () => {
  return (
    <div className='loading'>
      <img src={loading} alt='loading' />
    </div>
  );
};

export default LoadingView;
