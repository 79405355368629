import React from 'react';
import i18n from '../../../i18n';

const IMAGE_MAX_SIZE = process.env.REACT_APP_FILE_MAX_SIZE;

const UploadImageError = ({ keyError = '' }) => {
  return (
    <div className={'error self-center'}>
      <div
        className={'h14 error-text'}
        key={`error_${keyError}`}
        id={`error_${keyError}`}
        style={{ display: 'none' }}
      >
        {i18n.image.maxSize + IMAGE_MAX_SIZE / 1024 / 1024 + 'MB'}
      </div>
    </div>
  );
};

export default React.memo(UploadImageError);
