import React, { Component } from "react";
import LoginScreenWave from "../../assets/img/rosa/onboard/login-wave.png";
import { Input } from "../../components/rosa/Input";
import './Profile.scss'

const Profile = (props) => {
	const { user, Fields, onFormChange, goBack } = props
	return (
		<div className="container noScroll linen-bg height100vh width100">
			<div className="col responsiveDiv login-view profile-view height100 mineShaft">
				<img src={LoginScreenWave} className='wave-img' />
				<div className='navigation row space-between form flex-row width95'>
					<div className='row'>
						<button className="back-btn-header" onClick={goBack}>
							<i className={'fa fa-angle-left '}></i>
						</button>
						<span className="h15">Profile Page</span>
					</div>
					<div className="row v-ctr">
						<button className="cranberry-bg logout-btn white" onClick={props.logout}>
							<span className="h8">Logout</span>
						</button>
					</div>
				</div>
				{
					user === null ?
						<div></div>
						: user === 'NotFound' ?
							<div></div>
							:
							<form className="col width100 f-rest form" onSubmit={props.onSubmit} noValidate>
								{
									Fields.map((_) => {
										return (
											<Input
												{..._}
												value={user[_.attribute]}
												key={_.attribute}
												disabled
											/>
										)
									})
								}
							</form>
				}
			</div>
		</div>
	)
}

export default Profile;