import React, { useMemo, useState } from 'react';

const PainDistributionFilter = (props) => {
  const { options, defaultOptionId } = props;
  const [selectedOptionId, setSelectedOptionId] = useState(defaultOptionId);

  const selectedOption = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return options.find((o) => o?.id == selectedOptionId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionId]);

  return (
    <div>
      <div className='flex-row justify-content-between'>
        {/* LEFT CONTENT */}
        <div className={'flex2'}>
          {!!options.length &&
            options.map((option) => (
              <div
                key={option?.id}
                className={'options__row--top'}
              >
                <input
                  className={'options__input__checkbox--sm'}
                  type='radio'
                  id={option?.id}
                  name={option?.name}
                  value={option?.id}
                  // eslint-disable-next-line eqeqeq
                  checked={option?.id == selectedOptionId}
                  onChange={(e) => {
                    setSelectedOptionId(e.target.id);
                  }}
                />
                <label className='mineShaft font12' htmlFor={option.id}>
                  <div dangerouslySetInnerHTML={{ __html: option.name }}></div>
                </label>
              </div>
            ))}
        </div>

        {/* RIGHT CONTENT */}
        <div className={'flex-col flex3 items-center'}>
          <div className='sectionpdRight'>
            <img src={selectedOption?.image} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PainDistributionFilter);
