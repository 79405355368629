import React from "react";
import PainAndDiscomfortView from "../../views/rosa/PainAndDiscomfortView";
import { add_pain_and_discomfort_info } from "../../redux/ActionCreator";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import Routes from "../../constants/routes";

import helpers from '../../helpers';
const YES = 'yes';

const mapDispatchToProps = (dispatch) => ({
  add_pain_and_discomfort_info: (
    duringWork,
    afterWork,
    morningAfterWork,
    afterWeekend,
    unableToSleep,
    lessStrength,
    frequentStretching,
    strainedEyes,
    takeTimeOff,
    affectsProductivity,
    troubleIn12Months,
    preventedNormalActivities,
    visitedAPhysician,
    troubleInLast7Days,
    takingTreatment,
    painTreatment,
    concerns,
    bodyPainImg,
    lastSection,
    lastPage,
    specificPainDistributionImages,
    restState,
  ) =>
    dispatch(
      add_pain_and_discomfort_info(
        duringWork,
        afterWork,
        morningAfterWork,
        afterWeekend,
        unableToSleep,
        lessStrength,
        frequentStretching,
        strainedEyes,
        takeTimeOff,
        affectsProductivity,
        troubleIn12Months,
        preventedNormalActivities,
        visitedAPhysician,
        troubleInLast7Days,
        takingTreatment,
        painTreatment,
        concerns,
        bodyPainImg,
        lastSection,
        lastPage,
        specificPainDistributionImages,
        restState,
      )
    )
});

class PainAndDiscomfortController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            errors: {
                painTreatment: false,
            },
            modal2: false,
            troubleIn12Months: [],
            preventedNormalActivities: [],
            visitedAPhysician: [],
            troubleInLast7Days: [],
            neck: 0,
            shoulder: 0,
            midBack: 0,
            lowerBack: 0,
            leftElbow: 0,
            rightElbow: 0,
            leftWrist: 0,
            rightWrist: 0,
            leftThigh: 0,
            rightThigh: 0,
            leftKnee: 0,
            rightKnee: 0,
            leftAnkle: 0,
            rightAnkle: 0,
            duringWork: "3",
            afterWork: "3",
            morningAfterWork: "3",
            afterWeekend: "3",
            unableToSleep: "3",
            lessStrength: "3",
            frequentStretching: "3",
            strainedEyes: "3",
            takeTimeOff: "3",
            affectsProductivity: "3",
            takingTreatment: "",
            painTreatment: "",
            concerns: "",
            bodyPainImg: "",
            troubleIn12MonthsImage: '',
            preventedNormalActivitiesImage: '',
            visitedAPhysicianImage: '',
            troubleInLast7DaysImage: '',
        }
    }
    saveInfo = (event, stateKey) => {
        let value = event.target.value;
        this.setState({ [stateKey]: value }, () => {
            this.checkFilled({ stateKey, value })
        })

    }
    checkFilled = ({ stateKey = '', value = '' }) => {
        let isSetStateValidation = false;
        let errors = this.state.errors;
        
        // Treatment handle validation
        if (this.state.takingTreatment === YES && stateKey === 'painTreatment') { 
            errors.painTreatment = helpers.inputValidatorHelpers.emptyValidate({
                key: 'Pain Treatment',
                value,
                nextValidateCallback: null,
            });
            isSetStateValidation = true;
        }
        //

        this.setState({
            ...this.state,
            errors: isSetStateValidation ? errors : {},

            painTreatment : this.state.takingTreatment === YES ? this.state.painTreatment : '',
        });
    }
    saveProgress = (image) => {
        this.setState({ bodyPainImg: image, modal2: false }, () => {
            this.sendToStore({page : 9});

            const params = { isPreventReloadEwa: true };
            this.props.history.push('/workstationImages', params);
        });
    }
    sendToStore = ({ page = 0}) => {
        let state = this.state;
        this.props.add_pain_and_discomfort_info(
          state.duringWork,
          state.afterWork,
          state.morningAfterWork,
          state.afterWeekend,
          state.unableToSleep,
          state.lessStrength,
          state.frequentStretching,
          state.strainedEyes,
          state.takeTimeOff,
          state.affectsProductivity,
          state.troubleIn12Months,
          state.preventedNormalActivities,
          state.visitedAPhysician,
          state.troubleInLast7Days,
          state.takingTreatment,
          state.painTreatment,
          state.concerns,
          state.bodyPainImg,
          this.computedNextSection({
            isLastPage: page > 8,
            currentRoute: Routes.ASSESSMENT_PAIN,
          }),
          page > 8 ? 0 : page,
          {
            troubleIn12MonthsImage : this.state.troubleIn12MonthsImage,
            preventedNormalActivitiesImage: this.state.preventedNormalActivitiesImage,
            visitedAPhysicianImage: this.state.visitedAPhysicianImage,
            troubleInLast7DaysImage: this.state.troubleInLast7DaysImage,
          },
          this.state,
        );
    }
    nextPage = (toPageNo) => {
        let fromPage = "page" + (toPageNo - 1);
        let toPage = "page" + toPageNo;
        this.setState({ [fromPage]: false, [toPage]: true })
        this.sendToStore({ page: toPageNo });
    }
    goBack = (fromPageNo) => {
        if (fromPageNo === 1) {
            this.setState({ page1: false, sectionIntroOpen: true, page2: false });
        } else {
            let fromPage = "page" + (fromPageNo);
            let toPage = "page" + (fromPageNo - 1);
            this.setState({ [fromPage]: false, [toPage]: true });
        }
    }

    addBodyPain = (bodyPart, questionNo) => {
        let { troubleIn12Months, preventedNormalActivities, visitedAPhysician, troubleInLast7Days } = this.state;
        if (questionNo === 1) {
            troubleIn12Months.push(bodyPart)
        } else if (questionNo === 2) {
            preventedNormalActivities.push(bodyPart);
        }
        else if (questionNo === 3) {
            visitedAPhysician.push(bodyPart);
        }
        else if (questionNo === 4) {
            console.log("Yes !!!")
            troubleInLast7Days.push(bodyPart);
        }

        // this.setState(prevState => ({
        //     [stateKey]: [...prevState[stateKey], value]
        // }));
        this.setState({ troubleIn12Months, preventedNormalActivities, visitedAPhysician, troubleInLast7Days, [bodyPart]: this.state[bodyPart] + 1 })

    }
    removeBodyPain = (bodyPart, questionNo) => {
        let { troubleIn12Months, preventedNormalActivities, visitedAPhysician, troubleInLast7Days } = this.state;

        // let arr = [...this.state["arrayVariable"]];
        // arr = arr.filter(part => part !== bodyPart);
        // this.setState({["arrayVariable"]: arr})
        if (questionNo === 1) {
            troubleIn12Months = troubleIn12Months.filter(part => part !== bodyPart);
            console.log("why not ", bodyPart, troubleIn12Months)
        } else if (questionNo === 2) {
            preventedNormalActivities = preventedNormalActivities.filter(part => part !== bodyPart)
        } else if (questionNo === 3) {
            visitedAPhysician = visitedAPhysician.filter(part => part !== bodyPart)
        } else if (questionNo === 4) {
            troubleInLast7Days = troubleInLast7Days.filter(part => part !== bodyPart)
        };
        this.setState({ troubleIn12Months, preventedNormalActivities, visitedAPhysician, troubleInLast7Days, [bodyPart]: this.state[bodyPart] - 1 })
    }
    reset = () => {
        this.setState({

            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            errors: {

            },
            modal2: false,
            troubleIn12Months: [],
            preventedNormalActivities: [],
            visitedAPhysician: [],
            troubleInLast7Days: [],
            neck: 0,
            shoulder: 0,
            midBack: 0,
            lowerBack: 0,
            leftElbow: 0,
            rightElbow: 0,
            leftWrist: 0,
            rightWrist: 0,
            leftThigh: 0,
            rightThigh: 0,
            leftKnee: 0,
            rightKnee: 0,
            leftAnkle: 0,
            rightAnkle: 0,
            duringWork: "5",
            afterWork: "5",
            morningAfterWork: "5",
            afterWeekend: "5",
            unableToSleep: "5",
            lessStrength: "5",
            frequentStretching: "5",
            strainedEyes: "5",
            takeTimeOff: "5",
            affectsProductivity: "5",
            takingTreatment: "",
            painTreatment: "",
            concerns: "",
            bodyPainImg: ""
        });

    }

    isValidTakingTreatment = () => {
        return (this.state.takingTreatment !== YES && !this.state.painTreatment) ||
            (this.state.takingTreatment === YES && this.state.painTreatment !== '');
    }

    async componentDidMount() {
        let ewaRecord = await this.loadEwaRecord();
        if (!ewaRecord) {
            return;
        }

        let jsonState = JSON.parse(ewaRecord.savedProcessJson) ?? {};

        this.setState({
          ...this.state,
          ...jsonState,
          ...this.computedDonePages({
            maxPage: 8,
            lastPage: ewaRecord.lastPage,
          }),
        });
    }
    render() {
        console.log("State ", this.state)
        return (<PainAndDiscomfortView
            {...this.state}
            openQuiz={() => this.setState({ page1: true, sectionIntroOpen: false })}
            nextPage={(toPageNo) => this.nextPage(toPageNo)}
            openPage3={() => this.setState({ page1: false, page2: false, page3: true })}
            warning={() => this.setState({ modal1: true })}
            closeWarning={() => this.setState({ modal1: false })}
            saveInfo={(event, stateKey) => this.saveInfo(event, stateKey)}
            goBack={(pageNo) => this.goBack(pageNo)}
            saveProgress={(bodyPainImage) => this.saveProgress(bodyPainImage)}
            checkCompletion={() => this.setState({ modal2: true })}
            // goNextModule={() => this.setState({ modal2: false })}
            addBodyPain={this.addBodyPain}
            removeBodyPain={this.removeBodyPain}
            reset={this.reset}
            isValidTakingTreatment = {() => this.isValidTakingTreatment()}
            saveSpecificPainImage = {(specificPainId, imageData) => {
                this.setState({
                    ...this.state,
                    [specificPainId]: imageData,
                });
            } }
        />)
    }
}

export default withRouter(connect(null, mapDispatchToProps)(PainAndDiscomfortController));