import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ProfileView from "../../views/rosa/ProfileView";
import { getUserInfo, getCustomerById, clearCurrentUser } from "../../redux";
import Routes from "../../constants/routes";

const Fields = [
  { label: 'First Name', attribute: 'firstname' },
  { label: 'Last Name', attribute: 'lastname' },
  { label: 'Phone Number', attribute: 'mobile', type: 'tel' },
  { label: 'Date of Birth', attribute: 'dob', type: 'date' },
  { label: 'Email', attribute: 'email', type: 'email' }
]
class ProfileController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }
  componentDidMount() {
    this.handleGetCustomer()
  }
  handleGetCustomer = async () => {
    const user = getUserInfo()
    const res = await getCustomerById(user.id)
    this.setState({ user: res })
  }
  handleLogout = () => {
    clearCurrentUser();
    this.props.history.push("/")
  }
  render() {
    return (
      <ProfileView Fields={Fields}  {...this.state} logout={this.handleLogout}
      goBack={() => this.props.history > 1 ? this.props.goBack : window.location.href = Routes.HOME}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {

  }
}
export default withRouter(connect(null, mapDispatchToProps)(ProfileController));