import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ResetPasswordView from "../../../views/Onboarding/ResetPassword";
import { getCustomerByResetToken, changeCustomerPasswordByToken } from "../../../redux";
import helpers from "../../../helpers";

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

class ResetPasswordController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { confirmPassword: "", password: "" },
      error: { confirmPassword: "", password: "" },
      tokenStatus: null,
      userRole: this.props.match.params.userRole,
      commonError: null,
    };
  }
  componentDidMount() {
    this.handleValidateToken();
  }
  handleValidateToken = async () => {
    if (!this.props.match.params.token) {
      return this.props.history.push("/")
    }
    const token = this.props.match.params.token
    try {
      const res = await getCustomerByResetToken(token, this.state.userRole);
      this.setState({ tokenStatus: res.success })
    } catch (error) {
      if (error.data.message) {
        this.setState({ tokenStatus: error.data.message })
      }
    }
  }
  handleFormChange = (e) => {
    const { form, error } = this.state;
    const { name, value } = e.target;
    form[name] = value;
    error[name] = false
    this.setState({ form: { ...form }, error: { ...error }, commonError: null })
  }
  handleFormSubmit = async (e) => {
    e.preventDefault();
    const { error, form } = this.state
    let valid = true
    if (form.password === '') {
      error.password = 'Required';
      valid = false
    }
    if (form.confirmPassword === '') {
      error.confirmPassword = 'Required';
      valid = false
    }
    if (form.confirmPassword !== form.password) {
      error.confirmPassword = 'Password Mismatch';
      valid = false
    }
    if (!valid) {
      return this.setState({ error: { ...error } })
    }
    try {
      const token = this.props.match.params.token
      const res = await changeCustomerPasswordByToken(
        token,
        form.password,
        this.state.userRole
      );
      this.setState({ mailSent: res.success, message: res.success ? 'Your password is changed!' : 'Failed to Change the password. Please retry' })
    } catch (error) {
      const { errors } = error?.data;

      if (errors?.password) {
        return this.setState({ ...this.state, commonError: errors.password });
      }
      
      if (error.data.message) {
        this.setState({ message: error.data.message })
      }
    }
  }
  handleAlertNext = () => {
    this.setState({ message: null })

    if (this.state.mailSent) {
      helpers.resetPasswordHelpers.handleRedirectAfterSentMail({
        routerHistory : this.props.history,
        userRole: this.state.userRole,
      });

      return;
    }
    const input = document.querySelector('#password');
    if (input) {
      input.focus();
    }
  }
  render() {
    return (
      <ResetPasswordView {...this.state} onAlertNext={this.handleAlertNext} onFormChange={this.handleFormChange} onSubmit={this.handleFormSubmit} />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {

  }
}
export default withRouter(connect(null, mapDispatchToProps)(ResetPasswordController));
