import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import bglanding from '../../../assets/img/rosa/admin/bglanding.png'
import './AdminView.scss'
import { Redirect } from 'react-router-dom'
import close from '../../../assets/img/rosa/admin/close.png'
import loading from '../../../assets/img/rosa/loading.gif'
import drsharan from '../../../assets/img/rosa/drsharan.png'
import tickCircle from '../../../assets/img/rosa/admin/tickCircle.png'

import helpers from "../../../helpers";
import Constants from '../../../constants'

import i18n from '../../../i18n';
import LogoutArea from './components/LogoutArea';

var CryptoJS = require('crypto-js')

class AdminLandingView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ewasPending: [],
      ewas: [],
      ewasCompleted: [],
      showCompleted: false,
      docDetails: {},
      loading: true
    }
  }

  componentDidMount () {
    var data = JSON.stringify({})
    let that = this
    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function () {
      let isUnauthorizedUser = helpers.unauthorizedResponseHelpers.isUnauthorizedResponse({statusCode: this.status});
      if (isUnauthorizedUser) {
        helpers.unauthorizedResponseHelpers.redirectToLoginPage({
          isProvider: true,
          windowLocation: window.location,
        });

        return;
      }

        if (this.readyState === 4) {
          let ewasPending = [];
          let ewasCompleted = [];

        JSON.parse(this.responseText).forEach(function (ewa, i) {
          // ewa review progress also in ewasPending
          if (ewa.status == null || ewa.status !== Constants.EwaStatus.COMPLETED.toLowerCase()) {
            ewasPending.push(ewa)
          } else {
            ewasCompleted.push(ewa)
          }
        })
        let bytes = CryptoJS.AES.decrypt(
          localStorage.getItem('sensical'),
          'sensical@123$%6789true'
        )
        
        const ewas = JSON.parse(this.responseText);
          
        that.setState({
          ewas: ewas,
          ewasPending: ewasPending,
          ewasCompleted: ewasCompleted,
          docDetails: JSON.parse(bytes.toString(CryptoJS.enc.Utf8)),
          loading: false,
        });
          
        const paramEwaId = that.props?.match?.params?.ewaId || localStorage.getItem('ewaId')
        if (paramEwaId && ewas?.length) {
          that.handleRedirectToEwaDetailByParamId(paramEwaId, ewas);
        }
      }
    })

    xhr.open('POST', process.env.REACT_APP_API_URL + '/api/ewa/getAllEWA')
    xhr.setRequestHeader('Content-Type', 'application/json')

    xhr.send(data)
  }

  searchEWA = term => {
    let ewasPending = []
    let ewasCompleted = []
    this.state.ewas.forEach(function (ewa, i) {
      if (ewa.status == null || ewa.status !== 'complete') {
        if (ewa.name.toLowerCase().includes(term.toLowerCase())) {
          ewasPending.push(ewa)
        }
      } else {
        if (ewa.name.toLowerCase().includes(term.toLowerCase())) {
          ewasCompleted.push(ewa)
        }
      }
    })

    this.setState({
      ewasPending: ewasPending,
      ewasCompleted: ewasCompleted
    })
  }

  goToReportBuilder = ewa => {
    this.props.history.push('/ewreportbuilder/' + ewa._id)
  }
  computedEwaStatus = (statusString, reUploadWorkstationImagesStatus) => helpers.providerEwaProcessHelpers.computedEwaStatusText({
      ewaStatus: statusString,
      reUploadStatus: reUploadWorkstationImagesStatus
    });

  computedEwaCallToAction = (ewa, profile) => helpers.providerEwaProcessHelpers.computedCallToActionText({
      ewa, profile
    });

  handleRedirectToEwaDetailByParamId = (ewaId, ewas) => {
    const FoundEwaException = {};
    try {
      ewas.forEach((e) => {
        if (e._id === ewaId) {
          this.goToReportBuilder(e);
          throw FoundEwaException;
        }
      });
    } catch (error) {}
  }

  isShowCTA = ({ ewa = {} } = {}) => {
    return true;
  } 
  
  render() {
    let that = this
    return (
      <div>
        {!localStorage.getItem('sensical') ? (
          <Redirect to='/ewadminlogin' />
        ) : (
          <div className='container landing noScroll'>
            {this.state.loading ? (
              <div className='loading'>
                <img src={loading} alt='loading' />
              </div>
            ) : (
              <div></div>
            )}
            <div className='toreaBay-bg leftPanelFrame'>
              <div className='width100 profileFrame'>
                <div className='profileImgDiv'>
                  <div className='profileImg white-bg'>
                    <img alt='' src={drsharan} className='profileImg' />
                  </div>
                  </div>
                <div className={'flex-col'}>
                  <div className='h9 white'>
                    {this.state.docDetails.prefix +
                      ' ' +
                      this.state.docDetails.name}
                    </div>
                  <LogoutArea/>
                </div>
              </div>
              <div
                className={['width100 menu toreaBay pointer', !this.state.showCompleted ? "linen-bg toreaBay bold" : "alto-bg" ].join(" ")}
                onClick={() => this.setState({ showCompleted: false })}
              >
                <div className='h9 margLeft8'>Pending</div>
                <div className='profileImgDiv'>
                  <div className='pendingImg pendingImgBorder toreaBay-bg white'>
                    {this.state.ewasPending.length}
                  </div>
                </div>
              </div>
              <div
                className={['width100 menu toreaBay pointer', this.state.showCompleted ? "linen-bg toreaBay bold" : "alto-bg" ].join(" ")}
                onClick={() => this.setState({ showCompleted: true })}
              >
                <div className='h9 margLeft8'>Completed</div>
              </div>
            </div>
            <div
              className='rightPanelFrame'
              style={{ backgroundImage: `url(${bglanding})` }}
            >
              <div className='landingHeader'>
                <div className='h3'>EWA Request</div>
                <div>
                  <input
                    type='text'
                    id='adminEmail'
                    placeholder='Search'
                    className='searchInput'
                    onChange={event => this.searchEWA(event.target.value)}
                  />
                </div>
              </div>
              <br />
              <br />
              <div className='scrollY'>
              {!this.state.showCompleted ? (
                <div>
                  <div className='dbTable linen-bg sticky-top'>
                <div className='h10 toreaBay width25'>Name</div>
                <div className='h10 toreaBay width10'>EWA Score</div>
                <div className='h10 toreaBay width15'>Received</div>
                <div className='h10 toreaBay width20'>Initial Evaluator</div>
                <div className='h10 toreaBay width20'>Report Reviewer</div>
                <div className='h10 toreaBay width20'>Status</div>
                <div className='h10 toreaBay width20'>
                  <div style={{width: "136px"}}></div>
                </div>
              </div>
                  <div className='whiteBox white-bg'>
                    <br />
                    <br />
                    <div className='h6 toreaBay priority'>
                      PRIORITY<span className='h9 mineShaft'> to be sent today</span>
                    </div>
                    <br />
                    {this.state.ewasPending.slice(0,5).map(function (ewa, i) {
                        const initialEvaluator = helpers.providerEwaProcessHelpers.computedInitialEvaluator({ ewa: ewa })
                        return (
                          <div key={'priority' + i}>
                            {i !== 0 ? <hr className='dbTableLine' /> : ''}
                            <div className='dbTableNoMargin'>
                              <div className='ewa__table__name h10 toreaBay width25'>
                                {ewa.name}
                              </div>
                              <div className='h10 toreaBay width10'>
                                {ewa.ewaScore}
                              </div>
                              <div className='h10 toreaBay width15 assessmentDate'>
                                {!!ewa.assessmentDate && new Date(ewa.assessmentDate).toDateString()}
                              </div>
                              <div className='h10 toreaBay width20 text-overflow'>
                                {initialEvaluator.computedName}
                              </div>
                              <div className='h10 toreaBay width20 text-overflow'>
                                {ewa.ewaHistories.map((history, index) => {
                                  return (
                                    <div key={index} className='text-overflow'>
                                      {history?.reviewer?.firstname} {history?.reviewer?.lastname}
                                    </div>
                                  );
                                })}
                              </div>

                              <div className='h10 toreaBay width20'>
                                {that.computedEwaStatus(ewa.status, ewa.reUploadWorkstationImagesStatus)}
                              </div>

                              <div className='h10 toreaBay width20 cta'>
                                { that.isShowCTA({ ewa }) &&
                                  <div
                                  onClick={() => that.goToReportBuilder(ewa)}
                                  className='toreaBay-bg btnDB white flexCenter'
                                  >
                                    {that.computedEwaCallToAction(ewa, that.state.docDetails)}
                                </div>}
                              </div>
                            </div>
                          </div>
                        )
                    })}
                    <hr className={'hrBlank'}/>
                  </div>
                    {this.state.ewasPending.map(function (ewa, i) {
                      if (i > 4) {
                        const initialEvaluator = helpers.providerEwaProcessHelpers.computedInitialEvaluator({ ewa: ewa })

                        return (
                          <div key={'priorityc' + i}>
                            {(i !== 0 && i !== 5) ? <hr className='dbTableLine' /> : ''}
                            <div className='dbTableNoMargin'>
                              <div className='ewa__table__name h10 toreaBay width25'>
                                {ewa.name}
                              </div>
                              <div className='h10 toreaBay width10'>
                                {ewa.ewaScore}
                              </div>
                              <div className='h10 toreaBay width15 assessmentDate'>
                                 {!!ewa.assessmentDate && new Date(ewa.assessmentDate).toDateString()}
                              </div>
                              <div className='h10 toreaBay width20 text-overflow'>
                                {initialEvaluator.computedName}
                              </div>
                              <div className='h10 toreaBay width20 text-overflow'>
                                {ewa.ewaHistories.map((history, index) => {
                                  return (
                                    <div key={index} className='text-overflow'>
                                      {history?.reviewer?.firstname} {history?.reviewer?.lastname}
                                    </div>
                                  );
                                })}
                              </div>

                              <div className='h10 toreaBay width20'>
                                {that.computedEwaStatus(ewa.status, ewa.reUploadWorkstationImagesStatus)}
                              </div>
                              <div className='h10 toreaBay width20 cta'>
                                { that.isShowCTA({ ewa }) &&
                                <div
                                  onClick={() => that.goToReportBuilder(ewa)}
                                  className='toreaBay-bg btnDB white flexCenter'
                                >
                                  {that.computedEwaCallToAction(ewa, that.state.docDetails)}
                                </div>}
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return <div key={'priority' + i}></div>
                      }
                    })}
                    <hr className={'hrBlank'} />
                  </div>
              ) : (
                <div className='scrollY'>
                  <div className='dbTable linen-bg sticky-top'>
                <div className='h10 toreaBay width30'>Name</div>
                <div className='h10 toreaBay width10'>EWA Score</div>
                <div className='h10 toreaBay width20'>Received</div>
                <div className='h10 toreaBay width15'>Initial Evaluator</div>
                <div className='h10 toreaBay width15'>Report Reviewer</div>
                <div className='h10 toreaBay width15'>Report Sent Date</div>
                <div className='h10 toreaBay width15'>Status</div>
                <div className='h10 toreaBay width20'>
                  <div style={{width: "136px"}}></div>
                </div>
              </div>
                    <hr />
                      {this.state.ewasCompleted.map(function (ewa, i) {
                        const initialEvaluator = helpers.providerEwaProcessHelpers.computedInitialEvaluator({ ewa: ewa })
                        
                      return (
                        <div key={'priorityx' + i}>
                          {i !== 0 ? <hr className='dbTableLine' /> : ''}
                          <div className='dbTableNoMargin'>
                            <div className='ewa__table__name h10 toreaBay width30'>
                              {ewa.name}
                            </div>
                            <div className='h10 toreaBay width10'>
                              {ewa.ewaScore}
                            </div>
                            <div className='h10 toreaBay width20 assessmentDate'>
                              {!!ewa.assessmentDate && new Date(ewa.assessmentDate).toDateString()}
                            </div>
                            <div className='h10 toreaBay width15 text-overflow'>
                                {initialEvaluator?.computedName}
                            </div>
                            <div className='h10 toreaBay width15 text-overflow'>
                                {ewa.ewaHistories.map((history, index) => {
                                  return (
                                    <div key={index} className='text-overflow'>
                                      {history?.reviewer?.firstname} {history?.reviewer?.lastname}
                                    </div>
                                  );
                                })}
                            </div>

                            <div className='h10 toreaBay width15 reportSentDate'>
                              {ewa.updatedAt ? new Date(ewa.updatedAt).toDateString() : ''}
                            </div>
                            <div className='h10 toreaBay width15'>Complete</div>
                            <div className='h10 toreaBay width20'>
                              <div
                                onClick={() => that.goToReportBuilder(ewa)}
                                className='toreaBay-bg btnDB white flexCenter'
                              >
                                View Report
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(AdminLandingView);
