import React from 'react';
import i18n from '../../../../i18n';

const DoctorContactInfo = ({ doctor }) => {
  return (
    <div className={'col report-contact-info'}>
      <div className='h10 bold cta'>{i18n.contactUs}</div>
      <div className={'report-contact-info__contact h10'}>
        <div className='bold'>Dr Deepak Sharan</div>
        <div className=''>
          Medical Director and Consultant in Orthopaedics, Rehabilitation,
          Ergonomics, Occupational Health, Functional and Lifestyle Medicine
        </div>
        <div className='report-contact-info__contact__detail'>
          <a className='email' href={`mailto:deepak@recoup.health`}>
            deepak@recoup.health
          </a>
        </div>
      </div>

      <div className={'report-contact-info__contact h10'}>
        <div className='h10 sub-text'>{i18n.admin.contactSupport}</div>
        <br></br>
        <div className='bold'>Dr Anurag S Kumar</div>
        <div className=''>Consultant Physician</div>
        <div className='report-contact-info__contact__detail'>
          <div className='phone'>+91- 8792657625</div>
          <a className='email' href={`mailto:anurag@recoup.health`}>
            anurag@recoup.health
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DoctorContactInfo);
