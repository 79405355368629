import React, { Component } from 'react';
import { Provider } from 'react-redux';
// import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './controllers/rosa/HomeController';
import BasicInformationController from './controllers/rosa/BasicInformationController';
import NatureOfWorkController from './controllers/rosa/NatureOfWorkController';
import PainAndDiscomfortController from './controllers/rosa/PainAndDiscomfortController';
import WorkstationImagesController from './controllers/rosa/WorkstationImagesController';
import AdminLoginController from './controllers/rosa/admin/AdminLoginController';
import AdminLandingController from './controllers/rosa/admin/AdminLandingController';
import AdminReportBuilderController from './controllers/rosa/admin/AdminReportBuilderController';
import WorkstationAssessmentController from './controllers/rosa/WorkstationAssessmentController';
import EWAScoreController from './controllers/rosa/admin/EWAScoreController';
import SummaryController from './controllers/rosa/SummaryController';
import ProfileController from './controllers/rosa/ProfileController';
import LoginController from './controllers/rosa/onboard/LoginController';
import ForgetPasswordController from './controllers/rosa/onboard/ForgetPasswordController';
import ResetPasswordController from './controllers/rosa/onboard/ResetPasswordController';
import CreateAccountController from './controllers/rosa/onboard/CreateAccountController';
import SplashController from './controllers/rosa/onboard/SplashController';
import { ConfigureStore } from './redux/configureStore';
import { ProviderConfigureStore } from './redux/configureStoreProvider';
const store = ConfigureStore();
const providerStore = ProviderConfigureStore();
class App extends Component {
  render() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    return (
      <>
      <Provider store={store}>
        <div>
          {/* <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/basicInfo" element={<BasicInformationController />} />
            <Route path="/natureOfWork" element={<NatureOfWorkController />} />
            <Route path="/pain&discomfort" element={<PainAndDiscomfortController />} />
            <Route path="/workstationImages" element={<WorkstationImagesController />} />
            <Route path="/ewadminlogin" element={<AdminLoginController />} />
            <Route path="/ewadminlanding" element={<AdminLandingController />} />
            <Route path="/ewreportbuilder" element={<AdminReportBuilderController />} />
            <Route path="/workstationAssessment" element={<WorkstationAssessmentController />} />
            <Route path="/summary" element={<SummaryController />} />
            </Routes> */}
          <Switch>
            { urlParams.get('path') === 'login' && <Redirect to="/login" component={LoginController} /> }
            <Route path='/login' component={LoginController} />
            <Route
              path='/forget-password/:userRole?'
              component={ForgetPasswordController}
            />
            <Route
              path='/reset-password/:token/:userRole?'
              component={ResetPasswordController}
            />
            <Route path='/signup' component={CreateAccountController} />
            <Route path='/intro' component={Home} />
            <Route path='/profile' component={ProfileController} />
            <Route exact path='/basicInfo' component={BasicInformationController} />
            <Route path="/natureOfWork" component={NatureOfWorkController} />
            <Route path="/pain&discomfort" component={PainAndDiscomfortController} />
            <Route path="/workstationImages" component={WorkstationImagesController} />
            <Route path="/workstationAssessment" component={WorkstationAssessmentController} />
            <Route path="/summary" component={SummaryController} />
            {/* <Redirect to="/" component={Home} /> */}
          </Switch>
        </div>
        </Provider>
        <Provider store={providerStore}>
          <div>
            <Switch>
              { urlParams.get('path') === 'ewadminlogin' && 
                <Redirect to="/ewadminlogin" component={AdminLoginController} /> 
              }
              { urlParams.get('path') === 'ewadminlanding' && 
                <Redirect to="/ewadminlanding" component={AdminLandingController} /> 
              }
              
              <Route path="/ewadminlogin" component={AdminLoginController} />
              <Route path="/ewadminlanding/:ewaId?" component={AdminLandingController} />
              <Route path="/ewreportbuilder/:ewaId" component={AdminReportBuilderController} />
              <Route path="/ewreportbuilder" component={AdminLandingController} />
              <Route path="/calculateEWAScore" component={EWAScoreController} />
              <Route exact path='/' component={SplashController} />
            </Switch>
          </div>
      </Provider>
      </>
      

    );
  }
}

export default App;
