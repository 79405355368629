import React, { Component } from "react";
import html2canvas from 'html2canvas';
import Header from "../../components/rosa/Header";
import Modal from "../../components/rosa/Modal";
import SectionIntro from "../../components/rosa/SectionIntro";
import bodyPain from "../../assets/img/rosa/bodyPain.png";
import joint from "../../assets/img/rosa/joints.png";
import shoulders from "../../assets/img/rosa/shoulder.png";
import middleBack from "../../assets/img/rosa/midBack.png";
import lowerBack from "../../assets/img/rosa/lowerBack.png";
import thigh from "../../assets/img/rosa/thighs.png";
import shoulderPain1 from "../../assets/img/rosa/shoulderPain1.png";
import jointPain1 from "../../assets/img/rosa/jointPain1.png";
import midBackPain1 from "../../assets/img/rosa/midBackPain1.png";
import lowerBackPain1 from "../../assets/img/rosa/lowerBackPain1.png";
import thighPain1 from "../../assets/img/rosa/thighPain1.png";
import shoulderPain2 from "../../assets/img/rosa/shoulderPain2.png";
import jointPain2 from "../../assets/img/rosa/jointPain2.png";
import midBackPain2 from "../../assets/img/rosa/midBackPain2.png";
import lowerBackPain2 from "../../assets/img/rosa/lowerBackPain2.png";
import thighPain2 from "../../assets/img/rosa/thighPain2.png";
import shoulderPain3 from "../../assets/img/rosa/shoulderPain3.png";
import jointPain3 from "../../assets/img/rosa/jointPain3.png";
import midBackPain3 from "../../assets/img/rosa/midBackPain3.png";
import lowerBackPain3 from "../../assets/img/rosa/lowerBackPain3.png";
import thighPain3 from "../../assets/img/rosa/thighPain3.png";
import shoulderPain4 from "../../assets/img/rosa/shoulderPain4.png";
import jointPain4 from "../../assets/img/rosa/jointPain4.png";
import midBackPain4 from "../../assets/img/rosa/midBackPain4.png";
import lowerBackPain4 from "../../assets/img/rosa/lowerBackPain4.png";
import thighPain4 from "../../assets/img/rosa/thighPain4.png";
import "./PainAndDiscomfortView.scss"
import i18n from '../../i18n'
import PainDiscomfortSummaryTable from "./components/painDiscomfortSummaryTable";

class PainAndDiscomfortView extends Component {
    constructor(props) {
        super(props);
        this.bodyPainImgRef = React.createRef();
        this.state = {
            showDuringWork: false,
            showAfterWork: false,
            showWriting: false,
            showFiling: false,
            showCalls: false,
            showTimeAway: false,
            showSleep: false,
            showArmStrength: false,
            showBackStretch: false,
            showEyes: false,
            showTimeOff: false,
            showProductivity: false,
            shoulderCount: 0,
            bodyPainImg: ""
        }
        this.arr1 = []
    }

    getImage = () => {
        const screenshotTarget = document.getElementById("screenshot");
        let base64image = "";
        html2canvas(screenshotTarget).then((canvas) => {
            base64image = canvas.toDataURL("image/png");
            this.props.saveProgress(base64image);
        });
    }

    captureImage = ({ id }) => {
        if (!id) {
            return;
        }
        const screenshotTarget = document.getElementById(id);

        const needToHideblocks = document.getElementsByClassName('oldPain');
        for (var i = 0; i < needToHideblocks.length; i++) {
            needToHideblocks[i].style.display = 'none';
        }
        
        let base64Image = "";
        html2canvas(screenshotTarget).then((canvas) => {
            base64Image = canvas.toDataURL("image/png");
            this.props.saveSpecificPainImage(id, base64Image);

            for (var i = 0; i < needToHideblocks.length; i++) {
                needToHideblocks[i].style.display = 'block';
            }
        });
    }

    drawBodyPain = (questionNo) => {
        let arr = [];
        let bodyParts = [
            { name: "neck", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "shoulder", img: shoulders, pain1: shoulderPain1, pain2: shoulderPain2, pain3: shoulderPain3, pain4: shoulderPain4 },
            { name: "midBack", img: middleBack, pain1: midBackPain1, pain2: midBackPain2, pain3: midBackPain3, pain4: midBackPain4 },
            { name: "lowerBack", img: lowerBack, pain1: lowerBackPain1, pain2: lowerBackPain2, pain3: lowerBackPain3, pain4: lowerBackPain4 },
            { name: "leftElbow", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "rightElbow", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "rightWrist", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "leftWrist", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "leftThigh", img: thigh, pain1: thighPain1, pain2: thighPain2, pain3: thighPain3, pain4: thighPain4 },
            { name: "rightThigh", img: thigh, pain1: thighPain1, pain2: thighPain2, pain3: thighPain3, pain4: thighPain4 },
            { name: "leftKnee", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "rightKnee", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "leftAnkle", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 },
            { name: "rightAnkle", img: joint, pain1: jointPain1, pain2: jointPain2, pain3: jointPain3, pain4: jointPain4 }
        ]
        for (var i = 0; i < bodyParts.length; i++) {
            let bodyPart = bodyParts[i];
            let questions = ["troubleIn12Months", "preventedNormalActivities", "visitedAPhysician", "troubleInLast7Days"];
            let painImg = "pain" + this.props[bodyPart.name];
            let arr1 = [];
            // for (var j = 0; j <= this.props[bodyPart.name]; j++) {
            //     painImg = "pain" + j;
            //     arr1.push(bodyPart[painImg] && <div className={bodyPart.name} key={i + j * 1}>
            //         <img src={bodyPart[painImg] && bodyPart[painImg]} alt={bodyPart.name + " pain"} />
            //     </div>)

            // }
            let bodyPartclick = 0;
            for (var j = questionNo; j >= 1; j--) {
                let isCurrentQuestion = j === questionNo;
                if ((this.props[questions[j - 1]]) && (this.props[questions[j - 1]]).includes(bodyPart.name)) {
                    bodyPartclick++;
                    painImg = "pain" + bodyPartclick;
                    arr1.push(bodyPart[painImg] &&
                        <div className={`${bodyPart.name} ${isCurrentQuestion ? 'newPain' : 'oldPain'}`} key={i + j * 1}>
                            <img src={bodyPart[painImg] && bodyPart[painImg]} alt={bodyPart.name + " pain"} />
                        </div>)
                }
            }
            if ((this.props[questions[questionNo - 1]]) && !(this.props[questions[questionNo - 1]]).includes(bodyPart.name)) {
                arr.push(questions[questionNo - 1] ?
                    <div onClick={() => this.props.addBodyPain(bodyPart.name, questionNo)} key={i}>
                        <div className={bodyPart.name} ><img src={bodyPart.img} alt={bodyPart.name} /></div>
                        {arr1}
                    </div> :
                    <div key={i}>
                        <div className={bodyPart.name} ><img src={bodyPart.img} alt={bodyPart.name} /></div>
                        {arr1}
                    </div>
                )
            } else {
                arr.push(
                    questions[questionNo - 1] ?
                        <div onClick={() => this.props.removeBodyPain(bodyPart.name, questionNo)} key={i + 1 * 17}>
                            <div className={bodyPart.name} ><img src={bodyPart.img} alt={bodyPart.name} /></div>
                            {arr1}
                        </div> :
                        <div key={i + 1 * 17}>
                            <div className={bodyPart.name} ><img src={bodyPart.img} alt={bodyPart.name} /></div>
                            {arr1}
                        </div>)
            }
        }
        return arr;
    }


    render() {
        return (
            <div className="container noScroll">
                {this.props.sectionIntroOpen &&
                    <div className="patina-bg linen">
                        <Header backBtnColor="linen"
                            intro={true}
                            goBack={this.props.warning}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="0%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle=""
                            isShowProfileNavigator={false}
                        />

                        <SectionIntro
                            sectionHeader1="Pain &"
                            sectionHeader2="discomfort"
                            sectionDesc="Please answer the following questions to the best of your 
                            knowledge to help us assess your predisposition to musculoskeletal 
                            disorders."
                            bgColor="patina-bg pdBgImg"
                            sectionColor="white"
                            nxtBtnClass="william nxt-btn1"
                            title=''
                            backBtnColor='linen'
                            notes='(Note : Pain refers to overall body pain/ pain in specific body parts)'
                            nextSection={this.props.openQuiz} />
                    </div>
                }
                {this.props.modal1 &&
                    <Modal
                        modalClasses="tacao-bg mineShaft"
                        modalTitleClass="h3"
                        modalBodyClass="h7"
                        modalTitle="Are you sure?"
                        modalBody="Once you proceed, all entries made in this section will be lost. "
                        btnText1="PROCEED"
                        btnText2="CANCEL"
                        btn1Classes="linen"
                        btn2Classes="vinRouge"
                        txtBtn={true}
                        btn1={() => { this.props.reset(); window.location.href = '/intro' }}
                        btn2={this.props.closeWarning} />
                }
                {this.props.page1 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(1)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="12.5%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page1 ">
                            <div className='mystic-bg main height100' >
                                <div className="h4 mineShaft">When do you feel pain / discomfort ?</div>
                                <p className="h8 paradiso">SELECT ALL THAT APPLY</p>
                                <div className="responsiveDiv">
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showDuringWork: !this.state.showDuringWork })}
                                        >
                                            <div></div>
                                            During Work
                                            <button className="paradiso" >
                                                <i className={"fa " + (this.state.showDuringWork ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showDuringWork &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="duringWork" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="duringWork" type="range" min="1" max="5" step="1" list="stepList1"
                                                        value={this.props.duringWork}
                                                        onChange={(event) => this.props.saveInfo(event, "duringWork")} /></label>
                                                <datalist id="stepList1" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showAfterWork: !this.state.showAfterWork })}
                                        >
                                            <div></div>
                                            After work
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showAfterWork ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showAfterWork &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="afterWork" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="afterWork" type="range" min="1" max="5" step="1" list="stepList2"
                                                        value={this.props.afterWork}
                                                        onChange={(event) => this.props.saveInfo(event, "afterWork")} /></label>
                                                <datalist id="stepList2" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showWriting: !this.state.showWriting })}
                                        >
                                            <div></div>
                                            {i18n.morningAfterAPreviousWorkday}
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showWriting ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showWriting &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="morningAfterWork" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="morningAfterWork" type="range" min="1" max="5" step="1" list="stepList3"
                                                        value={this.props.morningAfterWork}
                                                        onChange={(event) => this.props.saveInfo(event, "morningAfterWork")} /></label>
                                                <datalist id="stepList3" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine pointer"
                                            onClick={() => this.setState({ showFiling: !this.state.showFiling })}
                                        >
                                            <div></div>
                                            After a weekend
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showFiling ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showFiling &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="afterWeekend" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="afterWeekend" type="range" min="1" max="5" step="1" list="stepList4"
                                                        value={this.props.afterWeekend}
                                                        onChange={(event) => this.props.saveInfo(event, "afterWeekend")} /></label>
                                                <datalist id="stepList4" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            // disabled={!this.props.page1Filled} 
                                            className="nxt-btn william-bg" onClick={() => this.props.nextPage(2)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page2 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(2)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="25%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page2">
                            <div className='mystic-bg main' >
                                <div className="h4 mineShaft">What do you feel as a consequence of pain?</div>
                                <p className="h8 paradiso">SELECT ALL THAT APPLY</p>
                                <div className="responsiveDiv">
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                            onClick={() => this.setState({ showSleep: !this.state.showSleep })}
                                        >
                                            <div></div>
                                            I am unable to sleep due to pain
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showSleep ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showSleep &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="sleepPain" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="sleepPain" type="range" min="1" max="5" step="1" list="stepList5"
                                                        value={this.props.unableToSleep}
                                                        onChange={(event) => this.props.saveInfo(event, "unableToSleep")} /></label>
                                                <datalist id="stepList5" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                                onClick={() => this.setState({ showArmStrength: !this.state.showArmStrength })}
                                        >
                                            <div></div>
                                            I have less strength in my
                                            hands/ arms
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showArmStrength ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showArmStrength &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="armStrength" className='font-bold'>I have less strength in my
                                                    hands/ arms
                                                    <input className="nxtLine" id="armStrength" type="range" min="1" max="5" step="1" list="stepList6"
                                                        value={this.props.lessStrength}
                                                        onChange={(event) => this.props.saveInfo(event, "lessStrength")} /></label>
                                                <datalist id="stepList6" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='white-bg workTasks'>
                                            <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                                onClick={() => this.setState({ showBackStretch: !this.state.showBackStretch })}
                                            >
                                            <div></div>
                                            I find myself frequently stretching/ massaging my neck/ back/ shoulders during work
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showBackStretch ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showBackStretch &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="backStretch" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="backStretch" type="range" min="1" max="5" step="1" list="stepList7"
                                                        value={this.props.frequentStretching}
                                                        onChange={(event) => this.props.saveInfo(event, "frequentStretching")} /></label>
                                                <datalist id="stepList7" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='white-bg workTasks'>
                                            <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                                onClick={() => this.setState({ showEyes: !this.state.showEyes })}
                                            >
                                            <div></div>
                                            My eyes get achy/ watery/ strained while working
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showEyes ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showEyes &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="wateryEyes" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="wateryEyes" type="range" min="1" max="5" step="1" list="stepList8"
                                                        value={this.props.strainedEyes}
                                                        onChange={(event) => this.props.saveInfo(event, "strainedEyes")} /></label>
                                                <datalist id="stepList8" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                            onClick={() => this.setState({ showTimeOff: !this.state.showTimeOff })}
                                        >
                                            <div></div>
                                            I have had to take time off work
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showTimeOff ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showTimeOff &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="timeOff" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="timeOff" type="range" min="1" max="5" step="1" list="stepList9"
                                                        value={this.props.takeTimeOff}
                                                        onChange={(event) => this.props.saveInfo(event, "takeTimeOff")} /></label>
                                                <datalist id="stepList9" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='white-bg workTasks'>
                                        <div className="row space-between h4 paradiso nxtLine txtCenter pointer"
                                            onClick={() => this.setState({ showProductivity: !this.state.showProductivity })}
                                        >
                                            <div></div>
                                            I feel my productivity gets affected
                                            <button className="paradiso">
                                                <i className={"fa " + (this.state.showProductivity ? "fa-angle-up" : "fa-angle-down")} ></i></button>
                                        </div>
                                        {this.state.showProductivity &&
                                            <div className="h7 mineShaft">
                                                <label htmlFor="productivity" className='font-bold'>How often do you experience this?
                                                    <input className="nxtLine" id="productivity" type="range" min="1" max="5" step="1" list="stepList10"
                                                        value={this.props.affectsProductivity}
                                                        onChange={(event) => this.props.saveInfo(event, "affectsProductivity")} /></label>
                                                <datalist id="stepList10" className="nxtLine">
                                                    <option label="1">1</option>
                                                    <option label="1">2</option>
                                                    <option label="1">3</option>
                                                    <option label="1">4</option>
                                                    <option label="1">5</option>
                                                </datalist>
                                                <div className="row h13 space-between">
                                                    <div>Almost<br /> never</div>
                                                    <div>Sometimes</div>
                                                    <div>Rarely</div>
                                                    <div>Almost<br /> always</div>
                                                    <div>All the<br /> time</div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            // disabled={!this.props.page1Filled} 
                                            className="nxt-btn william-bg" onClick={() => this.props.nextPage(3)}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }

                {
                    this.props.page3 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(3)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="37.5%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page3">
                            <div className='mystic-bg main height100 pdBgImg2' >
                                <div className="h4 mineShaft">Select the areas where you’ve had trouble in the last <b>12 months</b></div>
                                <p className="h10 mineShaft">(SUCH AS PAIN / DISCOMFORT / NUMBNESS)</p>
                                <div className="responsiveDiv">
                                    <div className="imgDiv txtCenter" id="troubleIn12MonthsImage">
                                        <img src={bodyPain} alt="Body pain" />
                                        {this.drawBodyPain(1)}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <div className="txtRight nxt-btn-div">
                                            <button
                                                    className="nxt-btn william-bg" onClick={() => {
                                                        this.captureImage({
                                                            id: 'troubleIn12MonthsImage',
                                                        });
                                                        this.props.nextPage(4)
                                                    }}>
                                                <i className='fa fa-angle-right linen'></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page4 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(4)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="50%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page4">
                            <div className='mystic-bg main height100 pdBgImg2' >
                                <div className="h4 mineShaft">Select the areas that have prevented you from <b>carrying out normal activities</b> due to pain in the last <b>12 months</b></div>
                                <p className="h10 william">(SUCH AS PAIN / DISCOMFORT / NUMBNESS)</p>
                                <div className="responsiveDiv">
                                    <div className="imgDiv txtCenter" id="preventedNormalActivitiesImage">
                                        <img src={bodyPain} alt="Body pain" />
                                        {this.drawBodyPain(2)}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                                className="nxt-btn william-bg" onClick={() => {
                                                    this.captureImage({
                                                        id: 'preventedNormalActivitiesImage',
                                                    });
                                                    this.props.nextPage(5)
                                                }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page5 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(5)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="62.5%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page5">
                            <div className='mystic-bg main height100 pdBgImg2' >
                                <div className="h4 mineShaft">Select the areas for which you have <b>visited a physician</b> for in the last <b>12 months</b></div>
                                <p className="h10 william">(SUCH AS PAIN / DISCOMFORT / NUMBNESS)</p>
                                <div className="responsiveDiv">
                                    <div className="imgDiv txtCenter" id="visitedAPhysicianImage">
                                        <img src={bodyPain} alt="Body pain" />
                                        {this.drawBodyPain(3)}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                                className="nxt-btn william-bg" onClick={() => {
                                                    this.captureImage({
                                                        id: 'visitedAPhysicianImage',
                                                    });
                                                    this.props.nextPage(6)
                                                }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page6 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(6)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="75%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page6">
                            <div className='mystic-bg main height100 pdBgImg2' >
                                <div className="h4 mineShaft">Select the areas where you’ve had trouble in the last <b>7 days</b></div>
                                <p className="h10 william">(SUCH AS PAIN / DISCOMFORT / NUMBNESS)</p>
                                <div className="responsiveDiv">
                                    <div className="imgDiv txtCenter" id="troubleInLast7DaysImage">
                                        <img src={bodyPain} alt="Body pain" />
                                        {this.drawBodyPain(4)}
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                                className="nxt-btn william-bg" onClick={() => {
                                                    this.captureImage({id : 'troubleInLast7DaysImage'});
                                                    this.props.nextPage(7)
                                                }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page7 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(7)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="87.5%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="pd-page7">
                            <div className='mystic-bg main height100' >

                                <form className="responsiveDiv">
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <p className="nxtLine font-bold">Have you been taking any treatment for your pain?</p>
                                        <div className="row space-between nxtLine">
                                            <label htmlFor="online"><input id="online" name="natureOfWork" type='radio' value="yes" className='cornflowerBlue-bg'
                                                checked={this.props.takingTreatment === "yes"}
                                                onChange={(event) => this.props.saveInfo(event, "takingTreatment")} />
                                                Yes</label>

                                            <label htmlFor="offline">
                                                <input id="offline" name="natureOfWork" type='radio' value="no" className='cornflowerBlue-bg'
                                                    checked={this.props.takingTreatment === "no"}
                                                    onChange={(event) => this.props.saveInfo(event, "takingTreatment")} />
                                                No</label>

                                            <label style={{ "visibility": "hidden" }}>
                                                <input type='radio' className='cornflowerBlue-bg' />
                                            </label>
                                        </div>
                                        {this.props.takingTreatment === "yes" &&
                                            <div>
                                                <p className='nxtLine font-bold'>Describe your pain treatment briefly</p>
                                                <label htmlFor='painTreatment'>
                                                    <input type='text' id="painTreatment" className='cornflowerBlue-bg'
                                                        value={this.props.painTreatment}
                                                        onChange={(event) => this.props.saveInfo(event, "painTreatment")} /></label>

                                                {this.props.errors.painTreatment && <div className="crail h9">{this.props.errors.painTreatment}</div>}
                                            </div>}
                                    </div>
                                    <div className='white-bg h7 mineShaft questionBox nxtLine'>
                                        <label htmlFor='designation' className='font-bold'>Do you have any other concerns about your workspace?</label><br />
                                        <input type='text' id="designation" className='cornflowerBlue-bg'
                                            value={this.props.concerns}
                                            onChange={(event) => this.props.saveInfo(event, "concerns")} />
                                    </div>
                                    <div className="txtRight nxt-btn-div">
                                        <button
                                            disabled={!this.props.isValidTakingTreatment()}
                                            className={"nxt-btn " + (!this.props.isValidTakingTreatment() ? "mineShaft-bg" : "william-bg")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.nextPage(8)
                                                }}>
                                            <i className='fa fa-angle-right linen'></i>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.page8 &&
                    <div>
                        <Header backBtnColor="linen"
                            headerBG="patina-bg"
                            goBack={() => this.props.goBack(8)}
                            basicInfoCompleted="100%"
                            natureOfWorkCompleted="100%"
                            workstationAssessCompleted="100%"
                            painAndDiscomfortCompleted="100%"
                            workstationImgCompleted="0%"
                            baseColor="#D8E7E3"
                            completedColor="#3A6C5E"
                            active="painAndDiscomfort"
                            headerTitle="PAIN AND DISCOMFORT"
                            isShowProfileNavigator={false}
                        />
                        <div className="page8">
                            <div className='linen-bg main height100' >
                                <div className="h3 paradiso"> Pain & Discomfort Summary</div>
                                    <div className="responsiveDiv">
                                        <div className={'h7 mineShaft'}>{i18n.assessment.painDiscomfort.painDiscomfort}</div>
                                        <div className={"white-bg painDiscomfortSummary"}>
                                            <PainDiscomfortSummaryTable
                                                title={i18n.assessment.painDiscomfort.oftenQuestion}
                                                dataSet={[
                                                    {
                                                        key: 'duringWork',
                                                        value: this.props.duringWork,
                                                        title: i18n.assessment.painDiscomfort.duringWork,
                                                    },
                                                    {
                                                        key: 'afterWork',
                                                        value: this.props.afterWork,
                                                        title: i18n.assessment.painDiscomfort.afterWork,
                                                    },
                                                    {
                                                        key: 'morningAfterWork',
                                                        value: this.props.morningAfterWork,
                                                        title: i18n.assessment.painDiscomfort.morningAfterWork,
                                                    },
                                                    {
                                                        key: 'afterWeekend',
                                                        value: this.props.afterWeekend,
                                                        title: i18n.assessment.painDiscomfort.afterWeekend,
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className={"h7 mineShaft"}>{i18n.assessment.painDiscomfort.consequenceOfPain}</div>
                                        <div className={"white-bg painDiscomfortSummary"}>
                                            <PainDiscomfortSummaryTable
                                                title={i18n.assessment.painDiscomfort.oftenQuestion}
                                                dataSet={[
                                                    {
                                                        key: 'unableToSleep',
                                                        value: this.props.unableToSleep,
                                                        title: i18n.assessment.painDiscomfort.unableToSleep,
                                                    },
                                                    {
                                                        key: 'lessStrength',
                                                        value: this.props.lessStrength,
                                                        title: i18n.assessment.painDiscomfort.lessStrength,
                                                    },
                                                    {
                                                        key: 'frequentStretching',
                                                        value: this.props.frequentStretching,
                                                        title: i18n.assessment.painDiscomfort.frequentStretching,
                                                    },
                                                    {
                                                        key: 'strainedEyes',
                                                        value: this.props.strainedEyes,
                                                        title: i18n.assessment.painDiscomfort.strainedEyes,
                                                    },
                                                    {
                                                        key: 'takeTimeOff',
                                                        value: this.props.takeTimeOff,
                                                        title: i18n.assessment.painDiscomfort.takeTimeOff,
                                                    },
                                                    {
                                                        key: 'affectsProductivity',
                                                        value: this.props.affectsProductivity,
                                                        title: i18n.assessment.painDiscomfort.affectsProductivity,
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="imgDiv txtCenter" id="screenshot">
                                            <img src={bodyPain} alt="Body pain" />
                                            {this.drawBodyPain(5)}
                                        </div>
                                        <div className="txtRight">
                                            <button
                                                className={"save-btn save-progress linen paradiso-bg"}
                                                onClick={() => this.props.checkCompletion()}
                                            >Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.modal2 &&
                            <Modal
                                modalClasses="patina-bg linen"
                                modalTitleClass="h3"
                                modalBodyClass="h11"
                                modalTitle="Section 4: Completed!"
                                modalBody="You can now proceed to fill in the details for 
                                Section 5 : Workstation Photos"
                                btn2={this.getImage}
                                txtBtn={false}
                                btnText1=""
                                btnText2="OK"
                                btn2Classes=" monteCarlo-bg"
                                btn2IconClass="william "
                            />}
                    </div>
                }
            </div >
        )
    }
}

export default PainAndDiscomfortView;