import React, { useEffect, useState } from 'react';
import inputHelpers from '../../../../helpers/inputHelpers';

const GONIOMETER_CONFIG = [
  {
    id: 'goniometer_reading_1',
    value: '',
  },
  {
    id: 'goniometer_reading_2',
    value: '',
  },
  {
    id: 'goniometer_reading_3',
    value: '',
  },
];

const WorkstationGoniometer = ({
  initGoniometers = [],
  onFinish = () => {},
  isGoniometerReadingEditable = false
}) => {
  const [goniometers, setGoniometers] = useState(GONIOMETER_CONFIG);

  useEffect(() => {
    if (initGoniometers?.length) {
      setGoniometers(initGoniometers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initGoniometers]);

  return (
    <>
          {goniometers.map((goniometer, index) => {
            return (
              <div key={goniometer.id} className='flex imgWP'>
                
                <div className='flex imgWP'>
                  <img src={goniometer.image} alt="Goniometer Side Look"/>
                </div>
                <div className='goniometerData'>
                  <p className="h9 paraGoniometerReadingName">{goniometer.name}</p>
                  <p className="h9">Body Part: <span className='h10 paraGoniometerReadingBody'>{goniometer.bodyPart}</span></p>
                  {
                    isGoniometerReadingEditable
                    ? (<div className="h9">
                      <label htmlFor="goniometerValue">Value ({goniometer.side}):</label>
                      <input style={styles.input} type='text' value={goniometer.value} autoComplete='off' maxLength={3}
                        onChange={(e) => {
                          e.target.value = inputHelpers.filterNumberOnly(e.target.value)
                          const newGoniometers = goniometers.map((g) => {
                            if (g.id === goniometer.id) {
                              return { ...g, value: e.target.value };
                            }
                            return g;
                          });
                          setGoniometers(newGoniometers);
                          onFinish(newGoniometers);
                        }}
                        pattern='[0-9]'
                      />
                    </div>)
                    : <p className="h9">Value ({goniometer.side}): <span className='h10 paraGoniometerReadingValue'>{goniometer.value}</span></p>
                  }
                </div>
              </div>
            );
          })}
    </>
  );
};

const styles = {
  input: {
    width: 'calc(100% - 70%)',
    height: '35px',
    border: '1px solid #133F9B',
    borderRadius: "4px",
    margin: '0px 0px 0px 8px',
    padding: '5px',
  }
};

export default React.memo(WorkstationGoniometer);
