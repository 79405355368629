import React, { Component, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const getDateFormatValue = () => {
  return null
}
const DateInput = (props) => {
  console.log('props', props);
  return (
    <input {...props} />
  )
}

export const DateSelect = (props) => {
  const { id, label, className, placeholder, value, ...rest } = props
  const [open, setOpen] = useState(false)
  let ref = useRef(null)
  const handleOpen = (val) => setOpen(val)
  const handleFocus = (e) => {
    handleOpen(true)
  }
  const handleBlur = (e) => {
    handleOpen(false)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onBlur={handleBlur}
        onClose={handleBlur}
        {...rest}
        open={open}
        inputFormat='DD/MM/YYYY'
        className='ewa-date'
        value={value}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <div className='row'>
              <input type='text' className='h7' ref={inputRef} {...inputProps} onClick={handleFocus} />
              <button type='button' className='date-pick' onClick={handleFocus} disabled={rest.disabled}>
              </button>
            </div>
          )
        }}
      />
    </LocalizationProvider>
  )
}
export const Input = (props) => {
  const { label, type, attribute, value, onInputChange, required, disabled } = props
  return (
    <div className={`h7 mineShaft questionBox`}>
      <label htmlFor={attribute}>{label}</label><br />
      {
        type === 'date' ?
          <DateSelect
            value={value ? new Date(value) : null}
            disabled={disabled}
            onChange={(date) => onInputChange({ target: { value: date, name: attribute } })} />
          :
          <input required={required}
            disabled={disabled}
            type={type || 'text'}
            id={attribute}
            name={attribute}
            className={`${disabled ? '': 'cornflowerBlue-bg '} `}
            value={value}
            onChange={onInputChange}
          />
      }
    </div>
  )
}