export const ADD_BASIC_INFO = 'ADD_BASIC_INFO';
export const ADD_NATURE_OF_WORK_INFO = 'ADD_NATURE_OF_WORK_INFO';
export const ADD_PAIN_AND_DISCOMFORT_INFO = 'ADD_PAIN_AND_DISCOMFORT_INFO';
export const ADD_WORKSTATION_ASSESSMENT_INFO = 'ADD_WORKSTATION_ASSESSMENT_INFO';
export const ADD_WORKSTATION_IMAGES = 'ADD_WORKSTATION_IMAGES';
export const ADD_WORKSTATION_IMAGES_DONE = 'ADD_WORKSTATION_IMAGES_DONE';
export const ADD_PATIENT_INFO = 'ADD_PATIENT_INFO';
export const RESTORE_EWA_RECORD = 'RESTORE_EWA_RECORD'

export const PROVIDER_ACTIONS = {
  GET_REVIEWERS: 'GET_REVIEWERS',
  SET_REVIEWERS: 'SET_REVIEWERS',
  SEND_TO_REVIEWERS: 'SEND_TO_REVIEWERS',
};
