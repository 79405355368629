import { PROVIDER_ACTIONS } from '../ActionTypes';
import api from '../api';

const useProvider = (dispatch) => {
  const getReviewers = () => {
    dispatch(PROVIDER_ACTIONS.GET_REVIEWERS);
    api.providerEwa
      .getReviewers()
      .then((res) => {
        const { reviewers } = res?.data;

        dispatch(PROVIDER_ACTIONS.SET_REVIEWERS, { reviewers });
      })
      .catch((e) => {
        console.log('e', e);
      });
  };

  const sendToReviewers = (
    { ewaId, reviewerIds = [] },
    onSuccess = () => {},
    onError = () => {}
  ) => {
    dispatch(PROVIDER_ACTIONS.SEND_TO_REVIEWERS);

    api.providerEwa
      .sendEwaToReviewers({ ewaId, reviewerIds })
      .then((res) => {
        onSuccess();
      })
      .catch((e) => {
        console.log('e', e);
        onError();
      });
  };

  const upsertEwaPrimitiveData = (
    { ewaId, data },
    onSuccess = () => {},
    onError = () => {}
  ) => {
    api.providerEwa
      .upsertEwaPrimitiveData({ ewaId, data })
      .then((res) => {
        onSuccess();
      })
      .catch((e) => {
        console.log('e', e);
        onError();
      });
  };

  return {
    getReviewers,
    sendToReviewers,
    upsertEwaPrimitiveData,
  };
};

export default useProvider;
