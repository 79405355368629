import React, { Component } from 'react'
import LoginScreenWave from '../../assets/img/rosa/onboard/login-wave.png'
import './style.scss'

const Login = props => {
  const { form, error, onFormChange } = props
  return (
    <div className='container noScroll linen-bg height100vh width100'>
      <div className='col responsiveDiv login-view height100'>
        <img src={LoginScreenWave} className='wave-img' />
        <div className='col form-box width100 height100'>
          <div className='h3'>Login</div>
          <form
            className='col width100 f-rest'
            onSubmit={props.onSubmit}
            noValidate
          >
            <div
              className={`h7 mineShaft questionBox ${
                Boolean(error.email) ? 'error' : ''
              }`}
            >
              <label htmlFor='email'>Email</label>
              <br />
              <input
                required
                type='email'
                id='email'
                name='email'
                className='cornflowerBlue-bg'
                value={form.email}
                onChange={onFormChange}
              />
            </div>
            <div
              className={`h7 mineShaft questionBox ${
                Boolean(error.password) ? 'error' : ''
              }`}
            >
              <label htmlFor='password'>Password</label>
              <br />
              <input
                required
                type='password'
                id='password'
                name='password'
                className='cornflowerBlue-bg'
                value={form.password}
                onChange={onFormChange}
              />
            </div>
            <div className='col v-end f-rest space-between'>
              <a
                className='txtRight h9 toreaBay forget-pass'
                href='forget-password'
              >
                Forgot Password
              </a>
              <br />
              <br />
              <div className='row v-ctr width100'>
                <span className='h8 mineShaft'>NOT A MEMBER?</span>
                <a className='h5 toreaBay create-link' href='/signup'>
                  Create Account
                </a>
              </div>
              <button
                className='nxt-btn mineShaft-bg'
                type='submit'
                disabled={!form.email || !form.password}
              >
                <i className='fa fa-angle-right linen'></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
