import React, { Component } from "react";
import WorkstationAssessmentView from "../../views/rosa/WorkstationAssessmentView";
import { add_workstation_assessment_info } from "../../redux/ActionCreator";
import { connect } from "react-redux";
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import Routes from "../../constants/routes";
const mapDispatchToProps = (dispatch) => ({
  add_workstation_assessment_info: (
    chairHeight,
    seatPan,
    armRest,
    backSupport,
    monitorSetup,
    telephone,
    mouse,
    keyboard,
    softTissueCompression,
    rosaSelectedItems,
    rosaGrandScore,
    ewaScore,
    lastSection,
    lastPage,
    workstationDuration,
    restState,
  ) =>
    dispatch(
      add_workstation_assessment_info(
        chairHeight,
        seatPan,
        armRest,
        backSupport,
        monitorSetup,
        telephone,
        mouse,
        keyboard,
        softTissueCompression,
        rosaSelectedItems,
        rosaGrandScore,
        ewaScore,
        lastSection,
        lastPage,
        workstationDuration,
        restState,
      )
    ),
});

class WorkstationAssessmentController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            page9: false,
            page10: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            errors: {

            },
            modal2: false,
            chairHeightSelected: "",
            chairHeight: [],
            chairHeightScore: 0,
            chairDurationType: "",
            chairDuration: "",
            seatPanSelected: "",
            seatPan: [],
            seatPanScore: 0,
            armRestSelected: "",
            armRest: [],
            armRestScore: 0,
            backSupportSelected: "",
            backSupport: [],
            backSupportScore: 0,
            monitorSetupSelected: "",
            monitorSetup: [],
            monitorSetupScore: 0,
            monitorDurationType: "",
            monitorDuration: "",
            telephoneScore: 0,
            telephoneSelected: "",
            telephone: [],
            telephoneDurationType: "",
            telephoneDuration: "",
            mouseSelected: "",
            mouse: [],
            mouseScore: 0,
            mouseDurationType: "",
            mouseDuration: "",
            keyboardSelected: "",
            keyboard: [],
            keyboardScore: 0,
            keyboardDurationType: "",
            keyboardDuration: "",
            softTissueCompressionSelected: "",
            softTissueCompressionScore: 0,
            selectedItems: null,
            rosaGrandScore: "",
            ewaScore: ""
        }
    }
    saveInfo = (event, stateKey) => {
        let value = event.target.value;
        this.setState({ [stateKey]: value })
    }
    saveProgress = () => {
        this.sendToStore({ page: 11 });
        this.setState({ modal2: false }, () => {
            this.calculateRosaScore();
        });

        this.setState({ modal2: false })
    }
    calculateRosaScore = () => {
        let { chairHeightScore, seatPanScore, armRestScore, backSupportScore, monitorSetupScore, telephoneScore, mouseScore,
            keyboardScore, chairHeight, seatPan, armRest, backSupport, monitorSetup, telephone,
            mouse, keyboard, chairDuration, monitorDuration, mouseDuration,
            keyboardDuration, rosaGrandScore } = this.state;
        console.log("Entering calculation ");
        let chairScore = 0;
        let seatScore = 0;
        let armScore = 0;
        let backScore = 0;
        let monitorScore = 0;
        let mouseUseScore = 0;
        let keyboardUseScore = 0;
        let phoneScore = 0;
        let sectionAScore = 0;
        let sectionBScore = 0;
        let sectionABScore = 0;
        let sectionCScore = 0;
        let sectionDScore = 0;
        let sectionCDScore = 0;
        let finalScore = 0;
        chairScore = Number(chairHeightScore) + chairHeight.length;
        console.log("chairHeight selected", this.state.chairHeightSelected);
        console.log("chairHeightOptions ", chairHeight);
        console.log("chairHeightScore ", chairHeightScore);
        console.log("chairHeightOptionScore ", chairHeight.length);
        console.log("chairScore calculation ---- 1", chairScore);
        seatScore = Number(seatPanScore) + seatPan.length;
        console.log("seatPan selected", this.state.seatPanSelected);
        console.log("seatPanOptions ", seatPan);
        console.log("seatPanScore ", seatPanScore);
        console.log("seatPanOptionScore ", seatPan.length);
        console.log("seatScore calculation ---- 2", seatScore);
        sectionAScore = Number(chairScore) + Number(seatScore);
        console.log("sectionAScore calculation ---- 3", sectionAScore);
        //logic for sectionBScore
        armScore = Number(armRestScore) + armRest.length;
        console.log("armRest selected ", this.state.armRestSelected);
        console.log("armRestOptions ", armRest);
        console.log("armRestScore ", armRestScore);
        console.log("armRestScoreOptionScore ", armRest.length);
        console.log("armScore calculation ---- 4", armScore);
        backScore = Number(backSupportScore) + backSupport.length;
        console.log("backSupport selected ", this.state.backSupportSelected);
        console.log("backSupportOptions ", backSupport);
        console.log("backSupportScore ", backSupportScore);
        console.log("backSupportOptionScore ", backSupport.length);
        console.log("backScore calculation ---- 5", backScore);
        sectionBScore = Number(armScore) + Number(backScore);
        console.log("sectionBScore calculation ---- 6", sectionBScore);
        let sectionABScoringTable = [[2, 2, 3, 4, 5, 6, 7, 8],
        [2, 2, 3, 4, 5, 6, 7, 8],
        [3, 3, 3, 4, 5, 6, 7, 8],
        [4, 4, 4, 4, 5, 6, 7, 8],
        [5, 5, 5, 5, 6, 7, 8, 9],
        [6, 6, 6, 7, 7, 8, 8, 9],
        [7, 7, 7, 8, 8, 9, 9, 9]];
        console.log("sectionABScore calculation ---- 7", sectionABScore);
        sectionABScore = sectionABScoringTable[(sectionAScore - 2)][(sectionBScore - 2)]
        console.log("chairDuration ", chairDuration);
        sectionABScore = sectionABScore + Number(chairDuration);
        console.log("sectionABScore calculation with chairduration ---- 8", sectionABScore);
        monitorScore = Number(monitorSetupScore) + monitorSetup.length + Number(monitorDuration);
        console.log("monitor selected ", this.state.monitorSetupSelected);
        console.log("monitorOptions ", monitorSetup);
        console.log("monitorSetupScore ", monitorSetupScore);
        console.log("monitorOptionScore ", monitorSetup.length);
        console.log("monitorDuration ", monitorDuration);
        console.log("monitorScore calculation with duration ---- 9", monitorScore);
        phoneScore = Number(telephoneScore) + telephone.length;
        console.log("phone selected ", this.state.telephoneSelected);
        console.log("telephoneOptions ", telephone);
        console.log("telephoneScore ", telephoneScore);
        console.log("telephoneOptionScore ", telephone.length);
        console.log("phoneScore calculation with duration ---- 10", phoneScore);

        let sectionCScoringTable = [[1, 1, 1, 2, 3, 4, 5, 6],
        [1, 1, 2, 2, 3, 4, 5, 6],
        [1, 2, 2, 3, 3, 4, 6, 7],
        [2, 2, 3, 3, 4, 5, 6, 8],
        [3, 3, 4, 4, 5, 6, 7, 8],
        [4, 4, 5, 5, 6, 7, 8, 9],
        [5, 5, 6, 7, 8, 8, 9, 9]];
        sectionCScore = sectionCScoringTable[phoneScore][monitorScore]
        console.log("sectionCScore calculation ---- 11", sectionCScore);
        console.log("mouseDuration ", mouseDuration);
        let mouseLength = 0;
        if (mouse.includes("Your mouse / keyboard are on different surfaces")) {
            mouseLength = mouse.length + 1;
        } else {
            mouseLength = mouse.length;
        }
        mouseUseScore = Number(mouseScore) + mouseLength + Number(mouseDuration);
        console.log("mouseSelected ", this.state.mouseSelected);
        console.log("mouseOptions ", mouse);
        console.log("mouseScore ", mouseScore);
        console.log("mouseOptionScore ", mouse.length);
        console.log("mouseDuration ", mouseDuration);
        console.log("mouseUseScore calculation ---- 12", mouseUseScore);
        keyboardUseScore = Number(keyboardScore) + keyboard.length + Number(keyboardDuration);
        console.log("keyboardSelected ", this.state.keyboardSelected);
        console.log("keyboardOptions ", keyboard);
        console.log("keyboardScore ", keyboardScore);
        console.log("keyboardScoreOptionScore ", keyboard.length);
        console.log("keyboardDuration ", keyboardDuration);
        console.log("keyboardUseScore calculation ---- 13", keyboardUseScore);


        let sectionDScoringTable = [[1, 1, 1, 2, 3, 4, 5, 6],
        [1, 1, 2, 3, 4, 5, 6, 7],
        [1, 2, 2, 3, 4, 5, 6, 7],
        [2, 3, 3, 3, 5, 6, 7, 8],
        [3, 4, 4, 5, 5, 6, 7, 8],
        [4, 5, 5, 6, 6, 7, 8, 9],
        [5, 6, 6, 7, 7, 8, 8, 9],
        [6, 7, 7, 8, 8, 9, 9, 9]]
        sectionDScore = sectionDScoringTable[mouseUseScore][keyboardUseScore];
        console.log("sectionDScore calculation ---- 14", sectionDScore);
        let peripheralScoreTable = [[1, 2, 3, 4, 5, 6, 7, 8, 9],
        [2, 2, 3, 4, 5, 6, 7, 8, 9],
        [3, 3, 3, 4, 5, 6, 7, 8, 9],
        [4, 4, 4, 4, 5, 6, 7, 8, 9],
        [5, 5, 5, 5, 5, 6, 7, 8, 9],
        [6, 6, 6, 6, 6, 6, 7, 8, 9],
        [7, 7, 7, 7, 7, 7, 7, 8, 9],
        [8, 8, 8, 8, 8, 8, 8, 8, 9],
        [9, 9, 9, 9, 9, 9, 9, 9, 9]];
        sectionCDScore = peripheralScoreTable[sectionCScore - 1][sectionDScore - 1]
        console.log("sectionCDScore calculation ---- 15", sectionCDScore);
        let grandScoreTable = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [2, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [3, 3, 3, 4, 5, 6, 7, 8, 9, 10],
        [4, 4, 4, 4, 5, 6, 7, 8, 9, 10],
        [5, 5, 5, 5, 5, 6, 7, 8, 9, 10],
        [6, 6, 6, 6, 6, 6, 7, 8, 9, 10],
        [7, 7, 7, 7, 7, 7, 7, 8, 9, 10],
        [8, 8, 8, 8, 8, 8, 8, 8, 9, 10],
        [9, 9, 9, 9, 9, 9, 9, 9, 9, 10],
        [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]]
        finalScore = grandScoreTable[sectionABScore - 1][sectionCDScore - 1]
        console.log("finalScore calculation  ---- 16 ", finalScore);
        rosaGrandScore = finalScore < 5 ? "Low" : "High";
        console.log("Rosa score ", rosaGrandScore);
        this.setState({ rosaGrandScore, ewaScore: finalScore }, () => {
            this.sendToStore({page : 1});
        });
    }
    sendToStore = ({ page = 1 }) => {
        let state = this.state;
        this.props.add_workstation_assessment_info(
            state.chairHeight,
            state.seatPan,
            state.armRest,
            state.backSupport,
            state.monitorSetup,
            state.telephone,
            state.mouse,
            state.keyboard,
            state.softTissueCompression,
            state.selectedItems,
            state.rosaGrandScore,
            state.ewaScore,
            this.computedNextSection({
            isLastPage: page > 10,
            currentRoute: Routes.ASSESSMENT_WORKSTATION,
            }),
            page > 10 ? 0 : page,
            {
                chairDuration: this.state.chairDuration,
                chairDurationType: this.state.chairDurationType,
                keyboardDuration: this.state.keyboardDuration,
                keyboardDurationType: this.state.keyboardDurationType,
                monitorDuration: this.state.monitorDuration,
                monitorDurationType: this.state.monitorDurationType,
                mouseDuration: this.state.mouseDuration,
                mouseDurationType: this.state.mouseDurationType,
                telephoneDuration: this.state.telephoneDuration,
                telephoneDurationType: this.state.telephoneDurationType,
            },
            this.state
        );
        if (page > 10) {
            const params = { isPreventReloadEwa: true };
            this.props.history.push('/pain&discomfort', params);
        }
    }
    nextPage = (toPageNo) => {
        let fromPage = "page" + (toPageNo - 1);
        let toPage = "page" + toPageNo;
        this.setState({ [fromPage]: false, [toPage]: true })
        this.sendToStore({ page: toPageNo });
    }
    goBack = (fromPageNo) => {
        if (fromPageNo === 1) {
            this.setState({ page1: false, sectionIntroOpen: true, page2: false });
        } else {
            let fromPage = "page" + (fromPageNo);
            let toPage = "page" + (fromPageNo - 1);
            this.setState({ [fromPage]: false, [toPage]: true });
        }
    }
    selectItem = (event, stateKey, val, imgRef) => {
        return new Promise((resolve) => {
        let scoreString = stateKey + "Score";
        let paramString = stateKey + "Selected"
        let value = event.target.value;
        this.setState({
            [paramString]: val,
            [scoreString]: value,
            [paramString + 'Img']: [val, imgRef],
        }, () => {
          resolve();
        });
      });
    }
    addItem = (event, stateKey, imgRef) => {
        let value = event.target.value;

        /*
            chairHeight: ['Insufficient space under the desk']
            keyImgs: {chairHeight : true/ false}
        */
        // Remove selected stateKey
        if ((this.state[stateKey]).includes(value)) {
            let arr = [...this.state[stateKey]];
            arr = arr.filter(item => item !== value);
            this.setState({
                [stateKey]: arr,
                keyImgs: {
                    ...this.state.keyImgs,
                    [imgRef]: false,
                },
            });
        } else {
            this.setState((prevState) => ({
                [stateKey]: [...prevState[stateKey], value],
                keyImgs: {
                    ...this.state.keyImgs,
                    [imgRef]: true,
                },
            }));
        }
    }
    reset = () => {
        this.setState({
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            page9: false,
            page10: false,
            modal1: false,
            page1Filled: false,
            page2Filled: false,
            errors: {

            },
            modal2: false,
            chairHeightSelected: "",
            chairHeight: [],
            chairHeightScore: 0,
            chairDurationType: "",
            chairDuration: "",
            seatPanSelected: "",
            seatPan: [],
            seatPanScore: 0,
            armRestSelected: "",
            armRest: [],
            armRestScore: 0,
            backSupportSelected: "",
            backSupport: [],
            backSupportScore: 0,
            monitorSetupSelected: "",
            monitorSetup: [],
            monitorSetupScore: 0,
            monitorDurationType: "",
            monitorDuration: "",
            telephoneScore: 0,
            telephoneSelected: "",
            telephone: [],
            mouseSelected: "",
            mouse: [],
            mouseScore: 0,
            mouseDurationType: "",
            mouseDuration: "",
            keyboardSelected: "",
            keyboard: [],
            keyboardScore: 0,
            keyboardDurationType: "",
            keyboardDuration: "",
            softTissueCompressionSelected: "",
            softTissueCompressionScore: 0,
            selectedItems: null,
            rosaGrandScore: "",
            ewaScore: "",
            keyImgs: {
                chairHeight5: false,
                chairHeight6: false,
                seatPan4: false,
                armRest3: false,
                armRest4: false,
                armRest5: false,
                backSupport4: false,
                backSupport5: false,
                monitorSetup4: false,
                monitorSetup5: false,
                monitorSetup6: false,
                monitorSetup7: false,
                telephone3: false,
                telephone4: false,
                mouse3: false,
                mouse4: false,
                mouse5: false,
                keyboard3: false,
                keyboard4: false,
                keyboard5: false,
                keyboard6: false,
            }
        });

    }

    async componentDidMount() {
        let ewaRecord = await this.loadEwaRecord();
        if (!ewaRecord) {
            return;
        }

        let jsonState = JSON.parse(ewaRecord.savedProcessJson) ?? {};

        this.setState({
          ...this.state,
          page1Filled: ewaRecord.lastPage > 1,
          page2Filled: ewaRecord.lastPage >= 2,
          workTasks: ewaRecord.workBreak ?? {},
          sectionIntroOpen: !ewaRecord.lastPage,
          chairHeight: ewaRecord.chairHeight ?? [],
          seatPan: ewaRecord.seatPan ?? [],
          armRest: ewaRecord.armRest ?? [],
          backSupport: ewaRecord.backSupport ?? [],
          monitorSetup: ewaRecord.monitorSetup ?? [],
          telephone: ewaRecord.telephone ?? [],
          mouse: ewaRecord.mouse ?? [],
          keyboard: ewaRecord.keyboard ?? [],
          ...jsonState,
          ...this.computedDonePages({
            maxPage: 10,
            lastPage: ewaRecord.lastPage,
          }),
          selectedItems: jsonState.selectedItems,
          keyImgs: jsonState.keyImgs,
        });
    }


    render() {
        return (<WorkstationAssessmentView
            {...this.state}
            openQuiz={() => this.setState({ page1: true, sectionIntroOpen: false })}
            nextPage={(toPageNo) => this.nextPage(toPageNo)}
            openPage3={() => this.setState({ page1: false, page2: false, page3: true })}
            warning={() => this.setState({ modal1: true })}
            closeWarning={() => this.setState({ modal1: false })}
            saveInfo={(event, stateKey) => this.saveInfo(event, stateKey)}
            goBack={(pageNo) => this.goBack(pageNo)}
            saveProgress={this.saveProgress}
            checkCompletion={() => this.setState({ modal2: true })}
            goNextModule={() => this.setState({ modal2: false })}
            addItem={(event, stateKey, imgRef) => this.addItem(event, stateKey, imgRef)}
            selectItem={(event, stateKey, val, imgRef) => this.selectItem(event, stateKey, val, imgRef)}
            calculateRosaScore={this.calculateRosaScore}
            generateReport={this.generateReport}
            setSelectedItems={(allItems) => this.setState({ selectedItems: allItems })}
            reset={this.reset}
        />)
    }
}

export default connect(null, mapDispatchToProps)(WorkstationAssessmentController);