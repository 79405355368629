import React, { Component } from "react";
import WorkstationImagesView from "../../views/rosa/WorkstationImagesView";
import { add_workstation_images } from "../../redux/ActionCreator";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseAssessmentComponent from './baseComponent/BaseAssessmentComponent';
import Routes from '../../constants/routes';
import { getUserInfo } from '../../redux';

const mapDispatchToProps = (dispatch) => ({
  add_workstation_images: (
    workstationSide,
    workstationBack,
    userSide,
    userBack,
    lastSection,
    lastPage,
    isLastPage,
    reUploadWorkstationImages
  ) =>
    dispatch(
      add_workstation_images(
        workstationSide,
        workstationBack,
        userSide,
        userBack,
        lastSection,
        lastPage,
        isLastPage,
        dispatch,
        reUploadWorkstationImages
      )
    ),
});
class WorkstationImagesController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            modal1: false,
            modal2: false,
            modal3: false,
            workstationSide: null,
            workstationBack: null,
            userSide: null,
            userBack: null,
            errors: {},
            isRequestedToReuploadImages: false,
            reUploadWorkstationImages: [],
            pagesToVisit: []
        }
    }

  saveProgress = () => {
    this.setState({ 
      modal2: false,
      isRequestedToReuploadImages: false
     }, () => {
    this.sendToStore({ page: 9 });
    });
  };

  sendToStore = ({ page = 1 }) => {
    let isSaveImage = [3,5,7,9].includes(page);
    let state = this.state;

    this.props.add_workstation_images(
      isSaveImage ? state.workstationSide : null,
      isSaveImage ? state.workstationBack : null,
      isSaveImage ? state.userSide : null,
      isSaveImage ? state.userBack : null,
      this.computedNextSection({
        isLastPage: page > 8,
        currentRoute: Routes.ASSESSMENT_WORKSTATION_IMAGE,
      }),
      page > 8 ? 0 : page,
      page === 9,
      this.state.reUploadWorkstationImages
    );
  };

  nextPage = (toPageNo) => {
    const { pagesToVisit, isRequestedToReuploadImages } = this.state;
    
    if(isRequestedToReuploadImages && toPageNo <= pagesToVisit[0]) {
      this.setState({
        ...this.state,
        [`page${toPageNo - 1}`]: false,
        [`page${pagesToVisit[0]}`]: true
      });
      return;
    }
    
    if(isRequestedToReuploadImages && toPageNo > pagesToVisit[pagesToVisit.length -1]) {
      this.setState({
        ...this.state,
        [`page${pagesToVisit[pagesToVisit.length - 1]}`]: false,
        page9: true
      });
      this.sendToStore({page: 9});
      return;
    }
    
    let fromPage = null, toPage = null;
    let fromPageCounter = 1, toPageCounter = 1;
    fromPage = toPageNo - 1;
    toPage = toPageNo;

    if(pagesToVisit.length > 0) {
      while(!pagesToVisit.includes(fromPage) || !pagesToVisit.includes(toPage)) {
          if(!pagesToVisit.includes(fromPage)) {
              fromPage= (toPageNo - fromPageCounter--);
          }
          if(!pagesToVisit.includes(toPage)) {
              toPage= (toPageNo + toPageCounter++);
          }
      }
    }

    fromPage = "page"+fromPage;
    toPage = "page"+toPage;

    this.setState({
      [fromPage]:false,
      [toPage]:true
    });
    this.sendToStore({page : toPage.charAt(toPage.length - 1)});
  }

  goBack = (fromPageNo) => {
    const { pagesToVisit } = this.state;

    if (fromPageNo === 1 || fromPageNo === pagesToVisit[0]) {
      this.setState({ page1: false, sectionIntroOpen: true, page2: false });
      return;
    } 
    
    if(fromPageNo > pagesToVisit[pagesToVisit.length -1]) {
      this.setState({
        [`page${fromPageNo}`]: false,
        [`page${pagesToVisit[pagesToVisit.length -1]}`]: true
      });
      return;
    }
    
    let fromPage = null, toPage = null;
    let fromPageCounter = 1, toPageCounter = 1;
    fromPage = fromPageNo;
    toPage = fromPageNo - 1;

    if(pagesToVisit.length > 0) {
      while(!pagesToVisit.includes(fromPage) || !pagesToVisit.includes(toPage)) {
          if(!pagesToVisit.includes(fromPage)) {
              fromPage= (fromPageNo + fromPageCounter++);
          }
          if(!pagesToVisit.includes(toPage)) {
              toPage= (fromPageNo - toPageCounter++);
          }
      }
    }

    fromPage = "page"+fromPage;
    toPage = "page"+toPage;

    this.setState({
      [fromPage]: false,
      [toPage]: true
    });
  }

  capture = (imageSrc, stateKey) => {
    this.setState({ [stateKey]: imageSrc })
  }

  reCapture = (stateKey) => {
      this.setState({ [stateKey]: null, [`${stateKey}ImageUrl`]: null })
    }

  reset = () => {
    this.setState({
            sectionIntroOpen: true,
            page1: false,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            modal1: false,
            modal2: false,
            modal3: false,
            workstationSide: null,
            workstationBack: null,
            userSide: null,
            userBack: null,
            errors: {},
            isRequestedToReuploadImages: false,
            reUploadWorkstationImages: [],
            pagesToVisit: []
        });
  }

  getPagesToVisitForReUpload = () => {
  const workstationUploadImagePages = {
      "workstationSide": [1, 2],
      "workstationBack": [3,4],
      "userSide": [5, 6],
      "userBack": [7, 8]
  };
  const { workstationSide, workstationBack, userSide, userBack} = workstationUploadImagePages;
  const { reUploadWorkstationImages } = this.state;
  let pages = [];
  
  reUploadWorkstationImages.forEach((imageType) => {
      if(imageType === "workstationSide") {
          pages = [...pages, ...workstationSide];
      } else if(imageType === "workstationBack") {
          pages = [...pages, ...workstationBack];
      } else if(imageType === "userSide") {
          pages = [...pages, ...userSide];
      } else if(imageType === "userBack") {
          pages = [...pages, ...userBack];
      }
  });

  this.setState({
    ...this.state,
    pagesToVisit: pages
  });
  }
  
  async componentDidMount() {
    if (!this.state.user) { 
      const user = getUserInfo();
      
      this.setState({ ...this.state, ...user });
    }
    let ewaRecord = await this.loadEwaRecord();

    if (!ewaRecord) {
      return;
    }
    
    if(ewaRecord.reUploadWorkstationImagesStatus) {
      this.setState({
        ...this.state,
        isRequestedToReuploadImages: true,
        reUploadWorkstationImages: ewaRecord.reUploadWorkstationImages,
        ...ewaRecord
      }, () => this.getPagesToVisitForReUpload())
    } else {
      this.setState({
        ...this.state,
        ...this.computedDonePages({
          maxPage: 8,
          lastPage: ewaRecord.lastPage,
        }),
        ...ewaRecord
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLastPage, isLoading } = this.props;
    const { isRequestedToReuploadImages } = this.state;

    // Redirect to summary screen, prevent user go back by browser back button
    if (isLastPage && !isLoading && !isRequestedToReuploadImages) {
      this.props.history.push('/summary');
    }
  }

  openQuiz = () => {
    const { isRequestedToReuploadImages, pagesToVisit } = this.state;
    if(isRequestedToReuploadImages && pagesToVisit[0] !== 1) {
      this.setState({
        sectionIntroOpen: false,
        [`page${pagesToVisit[0]}`]: true
      });
    } else {
      this.setState({
        page1: true,
        sectionIntroOpen: false
      })
    }
  }

  reUploadCompletionAcknowledgementMail = async () => {
    try {
      const data = {
        email:  this.state.email,
        name: this.state.name,
        ewaId: this.state._id
      }
      const authToken = localStorage.getItem('API-Key');

      const response = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/userWorkstationImageReUpload/acknowledgementToProviderMail', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': authToken
      },
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      });

      if(!response?.ok) {
        throw new Error("Error while sending acknowledgement mail");
      }
    } catch(error) {
      console.log("ERROR :::",error.message);
    }
  }

  ewaAssessmentCompletionMail = async () => {
    try {
      const data = {
        email: this.state.email,
        name: this.state.name,
        ewaId: this.state._id
      }
      const authToken = localStorage.getItem('API-Key');

      const response = await fetch(process.env.REACT_APP_API_URL + '/api/ewa/ewaAssessmentCompletionMail', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': authToken
      },
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      });

      if(!response?.ok) {
        throw new Error("Error while sending ewa completion mail");
      }
    } catch(error) {
      console.log("ERROR :::",error.message);
    }
  }

    render() {
        return (<WorkstationImagesView
            {...this.state}
            openQuiz={this.openQuiz}
            nextPage={(toPageNo) => this.nextPage(toPageNo)}
            openPage3={() => this.setState({ page1: false, page2: false, page3: true })}
            warning={() => this.setState({ modal1: true })}
            closeWarning={() => this.setState({ modal1: false })}
            openModal3={() => this.setState({ modal3: true })}
            closeModal3={() => this.setState({ modal3: false }, () => this.nextPage(5))}
            goBack={(pageNo) => this.goBack(pageNo)}
            saveProgress={this.saveProgress}
            checkCompletion={() => this.setState({ modal2: true })}
            goNextModule={() => this.setState({ modal2: false })}
            capture={this.capture}
            reset={this.reset}
            reCapture={(imageSrc) => this.reCapture(imageSrc)}
            isLoading = {this.props.isLoading}
            reUploadCompletionAcknowledgementMail={this.reUploadCompletionAcknowledgementMail}
            ewaAssessmentCompletionMail={this.ewaAssessmentCompletionMail}
        />)
    }
}

const mapStateToProps = (state) => {
  return state;
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkstationImagesController)
);