import Routes from './routes';
import UserRole from './userRole';
import AssessmentStatus from './assessmentStatus';
import ModelDefault from './modelDefault';
import EwaStatus from './ewaStatus';
import BasicInformationInput from './basicInformationInput';
import ValidationRule from './validationRule';
import Regex from './regex';
import AssessmentWork from './assessmentWork';
import AssessmentPainDiscomfort from './assessmentPainDiscomfort';
import Gender from './gender';

const Constants = {
  Routes,
  UserRole,
  AssessmentStatus,
  ModelDefault,
  EwaStatus,
  BasicInformationInput,
  ValidationRule,
  Regex,
  AssessmentWork,
  AssessmentPainDiscomfort,
  Gender,
};

export default Constants;
