import Constants from '../constants';
import Routes from '../constants/routes';
import i18n from '../i18n';

const computedCallToActionText = ({ ewa, profile }) => {
  if (ewa.isSendReportAble) {
    // Reviewer
    return i18n.startReview;
  } else {
    
    let ewaInProgress =
      ewa.status?.toLowerCase() === Constants.EwaStatus.PROGRESS.toLowerCase();

    let ewaCompleted =
      ewa.status?.toLowerCase() === Constants.EwaStatus.COMPLETED.toLowerCase();

    if (ewa.status.toLowerCase() === Constants.EwaStatus.REVIEW.toLowerCase()) {
       let reviewer = ewa.ewaHistories?.[0]?.reviewer
       if (reviewer && profile && reviewer.email ===  profile.email) {
         return i18n.startReview
       }
    }

    if (ewaInProgress) { 
      return i18n.createReport;
    }
    if (ewaCompleted) { 
      return i18n.viewReport;
    }

    if (ewa?.ewaHistories?.length) {
      return i18n.viewReport;
    }

    return i18n.continue;
  }
};

const computedEwaStatusText = ({ ewaStatus = '', reUploadStatus = false}) => {
  let statusString = ewaStatus.toLowerCase();

  let computedStatus = '';
  if (reUploadStatus) {
    return i18n.reUploadStatus
  }
  switch (statusString) {
    case Constants.EwaStatus.PROGRESS.toLowerCase():
      computedStatus = i18n.ewaNotStart;
      break;
    case Constants.EwaStatus.REVIEW.toLowerCase():
      computedStatus = i18n.ewaReadyForReview;
      break;
    default:
      computedStatus = i18n.ewaInProgress;
      break;
  }

  return computedStatus;
};

const computedInitialEvaluator = ({ ewa = {} }) => {
  let initialEvaluator =
    ewa.ewaHistories?.[0]?.initialEvaluator ?? Constants.ModelDefault.provider;

  initialEvaluator.computedName = `${initialEvaluator.prefix} ${initialEvaluator.firstname} ${initialEvaluator.lastname}`;

  return initialEvaluator;
};
const isCompletedAssessment = (ewa) => {
  //  '' for old data
  return [Routes.ASSESSMENT_SUMMARY, ''].includes(
    ewa?.lastSection?.toLowerCase()
  );
};

const providerEwaProcessHelpers = {
  computedCallToActionText,
  computedEwaStatusText,
  computedInitialEvaluator,
  isCompletedAssessment,
};

export default providerEwaProcessHelpers;
