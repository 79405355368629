import React from "react";
import HomeView from "../../views/rosa/HomeView";
import { getUserInfo } from "../../redux";
import BaseAssessmentComponent from "./baseComponent/BaseAssessmentComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import providerEwaProcessHelpers from "../../helpers/providerEwaProcessHelpers";

const mapDispatchToProps = (dispatch) => ({});
class HomeController extends BaseAssessmentComponent {
    constructor(props) {
        super(props);
        this.state = {
            consentModal: false,
            introOpen: true,
            menuOpen: false,
            show: false,
            ewa: {},
        }
    }

    async componentDidMount() {
        const user = getUserInfo()

        try {
            let ewa = await this.loadEwaRecord();
        if (user === null) {
            this.props.history.replace("/login")
        } else {
                this.setState({
                    ...this.state,
                    ewa: ewa,
                    show: true,
                    user: user,
                });
            }
        } catch (error) {
            this.setState({
                show: true,
                user: user,
            });
        }
    }

    hasEwaRecord = () => {
        return this.state?.ewa ? Object.keys(this.state?.ewa).length : false;
    }
    isCompletedAssessment = () => {
        return providerEwaProcessHelpers.isCompletedAssessment(this.state.ewa);
    }
    isShowContinueAssessment = () => {
        return !this.isCompletedAssessment() && this.hasEwaRecord();
    }
    isShowStartAssessment = () => {
        return !this.isCompletedAssessment() && !this.hasEwaRecord();
    }
    isRequestedReUploadWorkstationImages = () => {
        return this.isCompletedAssessment() && this.state?.ewa?.reUploadWorkstationImagesStatus;
    }

    render() {
        return (
            this.state.show ?
                <HomeView
                    {...this.state}
                    home={true}
                    onProfileClick={() => this.props.history.push("/profile")}
                    openConsentModal={() => this.setState({ consentModal: true })}
                    ConsentDisagree={() => this.setState({ consentModal: false })}
                    ConsentAgree={() => this.setState({ consentModal: false, introOpen: false, menuOpen: true })}
                    goIntro={() => this.setState({ introOpen: true, menuOpen: false })}
                    onContinueAssessment={() => {
                        this.props.history.replace(
                            this.state.ewa.lastSection
                        );
                    }}
                    isShowContinueAssessment = {this.isShowContinueAssessment()}
                    isShowStartAssessment = {this.isShowStartAssessment()}
                    isRequestedReUploadWorkstationImages = {this.isRequestedReUploadWorkstationImages()}
                    requestedReUploadWorkstationImages = {this.state?.ewa?.reUploadWorkstationImages ?? []}
                />
                : null
        )

    }
}

export default withRouter(connect(null, mapDispatchToProps)(HomeController));