import { Component } from 'react';
import api from '../../../redux/api';
import Routes from '../../../constants/routes';

const SUBSEQUENT_ASSESSMENT_ROUTES = {
  [Routes.ASSESSMENT_INFO]: Routes.ASSESSMENT_NATURE_OF_WORK,
  [Routes.ASSESSMENT_NATURE_OF_WORK]: Routes.ASSESSMENT_WORKSTATION,
  [Routes.ASSESSMENT_WORKSTATION]: Routes.ASSESSMENT_PAIN,
  [Routes.ASSESSMENT_PAIN]: Routes.ASSESSMENT_WORKSTATION_IMAGE,
  [Routes.ASSESSMENT_WORKSTATION_IMAGE]: Routes.ASSESSMENT_SUMMARY,
};

/**
* Base component for each sections in assessment
*/
class BaseAssessmentComponent extends Component {
  /**
  * Load ewa record. 
  * Handle redirect to lastPage when user access the route directly.
  * Handle redirect user to the login page in case unauthorized user
  */
  loadEwaRecord = async () => {
    try {
      let currentRoute = this.props.location?.pathname;
      

      // In case the previous section push to new section route.
      // Prevent re-fetch the ewa record.
      if (this.props.location.state?.isPreventReloadEwa) { 

        // Clear the state of history.
        window.history.replaceState({}, document.title);
        return null;
      }

      const ewa = await api.ewa.userGetEwa();

      if (
        ewa.lastSection !== currentRoute &&
        SUBSEQUENT_ASSESSMENT_ROUTES?.[currentRoute] &&
        !ewa.reUploadWorkstationImagesStatus
      ) {
        this.props.history.push(ewa.lastSection);
        return;
      }

      return ewa;
    } catch (e) {
      if (e.status === 403 || e.status === 401) {
        window.location.href = Routes.CUSTOMER_LOGIN_PAGE;
      }
    }

    return null;
  };

  /**
  * Find out the next SECTION by the current section.
  */
  computedNextSection = ({ isLastPage = true, currentRoute }) => {
    return isLastPage
      ? SUBSEQUENT_ASSESSMENT_ROUTES[currentRoute]
      : currentRoute;
  };

  /**
  * Computed the next PAGE that user need to continue the section
  */
  computedDonePages = ({ maxPage, lastPage }) => {
    if (lastPage > maxPage) {
      return { sectionIntroOpen: true };
    }

    let i = 1;
    let donePages = {};
    for (i; i <= maxPage; i++) {
      donePages = {
        ...donePages,
        [`page${i}`]: lastPage === i,
        sectionIntroOpen: lastPage === 0,
      };
    }
    return donePages;
  };
}

export default BaseAssessmentComponent;
