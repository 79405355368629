// import { createStore, combineReducers } from "redux";
// import { BasicInfo } from './basicInfo';
// import { NatureOfWork } from './natureOfWork';
// import { PainAndDiscomfort } from './painAndDiscomfort';
// import { WorkStationAssessment } from './workStationAssessment';
// import { WorkStationImages } from './workStationImages';
import { createStore } from "redux";
import { INITIAL_STATE, PatientInfo } from "./Reducer";

export const ConfigureStore = () => {
    // const store = createStore(
    //     combineReducers({
    //         basicInfo: BasicInfo,
    //         natureOfWork: NatureOfWork,
    //         painAndDiscomfort: PainAndDiscomfort,
    //         workStationAssessment: WorkStationAssessment,
    //         workStationImages: WorkStationImages
    //     })
    // );
    const store = createStore(
        PatientInfo,
        INITIAL_STATE
    );
    return store;
}


