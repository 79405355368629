import * as ActionTypes from "./actionTypes"
export const setAccessToken = (iToken) => {
  localStorage.setItem('API-Key', iToken)
}
export const setUserInfo = (iUser) => {
  localStorage.setItem('User', JSON.stringify(iUser))
}
export const clearCurrentUser = () => {
  localStorage.removeItem('API-Key')
  localStorage.removeItem('User')
}
export const getAccessToken = () => {
  return localStorage.getItem('API-Key')
}
export const getUserInfo = () => {
  let user = localStorage.getItem('User');
  return user ? JSON.parse(user) : null;
}
export const setCurrentUser = (user) => {
  return { type: ActionTypes.SET_CURRENT_USER, payload: user }
}
export * from "./actionTypes";
export * from "./middleware"