import React from 'react';
import helpers from '../../../../helpers';
import i18n from '../../../../i18n';
import Constants from '../../../../constants';

const WorkStationSnapshot = ({
  computedWorkStationSnapshot = {},
  workstationDuration = {},
  classImage = 'imgWS h9 workstation-snapshot__summary_image_content',
  classGroupKey = 'h9 workstation-snapshot__summary__header',
}) => {
  const outputs = [];

  for (const index in computedWorkStationSnapshot) {
    const item = computedWorkStationSnapshot[index];
    if (item?.isShowGroupKey) {
      const durationType =
        workstationDuration?.[`${item?.keyPattern}DurationType`];
      const durationTypeCapitalized =
        helpers.stringHelpers.capitalizedWord(durationType);
      const duration = workstationDuration?.[`${item?.keyPattern}Duration`];

      outputs.push(
        <div
          key={`workstation_${item?.keyPattern}`}
          className={'workstation-snapshot__summary__group'}
        >
          <div className={classGroupKey}>
            {helpers.stringHelpers.capitalizedWord(item?.keyPattern)}
          </div>
          {durationTypeCapitalized && duration && (
            <div className={'flex-row'}>
              <div className={'flex1'}>
                <span className={'h9'}>
                  {i18n.usage}: <b>{durationTypeCapitalized}</b>
                </span>
              </div>
              <div className={'flex1'}>
                <span className={'h9'}>
                  {i18n.duration}:{' '}
                  <b>
                    {
                      Constants.AssessmentWork.durationEnums?.[durationType]?.[
                        duration
                      ]
                    }
                  </b>
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }

    outputs.push(
      <div key={`imgWS_${item.image}`} className={classImage}>
        <img
          src={require(`../../../../assets/img/rosa/${item.image}.png`)}
          alt=''
        />
        {item.text}
      </div>
    );
  }

  return outputs;
};

export default React.memo(WorkStationSnapshot);
